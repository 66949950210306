import { useMarketplaceFeatures } from '@slmpserv/hooks'
import { useCurrentDealerContext } from '@slmpserv/self-service-current-dealer-context'
import { useFetchMarketplace } from '@slmpserv/common/api/use-query-wrapper'

import { SellerDealershipContactGroup } from './DealershipContactGroup/SellerDealershipContactGroup'

interface SellerDashboardProps {
    entryDn: string
}

export function SellerDashboard({ entryDn }: SellerDashboardProps) {
    const { marketplaceKey } = useCurrentDealerContext()
    const { marketplace, queryResult: marketplaceQueryResult } = useFetchMarketplace({
        marketplaceKey: marketplaceKey,
    })
    const marketplaceFeatures = useMarketplaceFeatures({ marketplace })

    const showPurchaseRequestSettings = marketplaceQueryResult.isSuccess
        ? marketplaceFeatures.PURCHASE_REQUEST_SETTINGS
        : false

    return <SellerDealershipContactGroup entryDn={entryDn} showPurchaseRequestSettings={showPurchaseRequestSettings} />
}
