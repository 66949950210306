import { PHeading, PSwitch, useToastManager } from '@porsche-design-system/components-react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useEffect } from 'react'
import { useMutation } from '@tanstack/react-query'
import { spacingFluid } from '@porsche-design-system/components-react/styles'
import { styled } from 'styled-components'

import { LoadingAndErrorWrapper } from '@slmpserv/self-service-shared'
import { MultipleEmailsField } from '@slmpserv/common-components'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { purchaseRequestSettingsApi, useFetchPurchaseRequestSettings } from '@slmpserv/purchase-request-settings-api'
import { useCurrentDealerContext } from '@slmpserv/self-service-current-dealer-context'
import { FormButtons } from '@slmpserv/common-components-legacy'

import { messages } from './messages'
import { PurchaseRequestSettingsStatus } from './PurchaseRequestSettingsStatus'

const StyledFormGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
`

const SettingsStatus = styled.div`
    margin-top: ${spacingFluid.small};
`

const StyledSwitch = styled(PSwitch)`
    margin-bottom: ${spacingFluid.medium};
`

const StyledMultipleEmailsFieldWrapper = styled.div`
    grid-column: 2;
`

const StyledFormButtons = styled(FormButtons)`
    grid-column: 2;
`

export type EmailsForPurchaseRequestNotificationsFormState = {
    notificationsEnabled: boolean
    contactEmailAddresses: Array<{ id: string; value: string }>
}

type PurchaseRequestNotificationsConfigProps = {
    entryDn: string
}

export function PurchaseRequestNotificationsConfig({ entryDn }: PurchaseRequestNotificationsConfigProps) {
    const { addMessage } = useToastManager()
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const { marketplaceKey } = useCurrentDealerContext()

    const {
        register,
        handleSubmit: useFormHandleSubmit,
        reset,
        formState,
        control,
        watch,
    } = useForm<EmailsForPurchaseRequestNotificationsFormState>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: { notificationsEnabled: false, contactEmailAddresses: [{ value: '' }] },
    })

    const {
        queryResult: { data, isLoading, isError, refetch },
    } = useFetchPurchaseRequestSettings({ dealerEntryDn: entryDn, marketplace: marketplaceKey, queryOptions: {} })

    useEffect(() => {
        if (!isLoading && !isError && data) {
            reset({
                notificationsEnabled: data.notificationsEnabled,
                contactEmailAddresses: data.contactEmailAddresses.length
                    ? data.contactEmailAddresses.map(email => ({ value: email }))
                    : [{ value: '' }],
            })
        }
    }, [data, isError, isLoading, reset])

    const { mutate, isPending: isSaving } = useMutation({
        mutationFn: (formData: EmailsForPurchaseRequestNotificationsFormState) => {
            return purchaseRequestSettingsApi.storeDealershipSettings({
                dealerEntryDn: entryDn,
                marketplace: marketplaceKey,
                dealershipSettingsDto: {
                    notificationsEnabled: formData.notificationsEnabled,
                    contactEmailAddresses: formData.contactEmailAddresses.map(({ value }) => value),
                },
            })
        },
        onSuccess: async () => {
            addMessage({
                state: 'success',
                text: formatMessage(messages.toastSubmitSuccess),
            })
            // To refresh status tag on edit page
            await refetch()
        },
        onError: error => {
            console.error(error)
            addMessage({
                state: 'neutral',
                text: formatMessage(messages.toastSubmitError),
            })
        },
    })

    const handleSubmit = (formData: EmailsForPurchaseRequestNotificationsFormState) => {
        mutate(formData)
    }

    const handleCancel = () => {
        navigate(StaticRoutes.DASHBOARD)
    }

    const noEmailEnteredYet = !watch('contactEmailAddresses')?.[0]?.value

    return (
        <LoadingAndErrorWrapper isLoading={isLoading} isError={isError}>
            <form onSubmit={useFormHandleSubmit(handleSubmit)}>
                <StyledFormGrid>
                    <div>
                        <PHeading tag="h4" size="medium">
                            {formatMessage(messages.purchaseRequestNotificationsTitle)}
                        </PHeading>

                        {data && (
                            <SettingsStatus>
                                <PurchaseRequestSettingsStatus purchaseRequestConfig={data} />
                            </SettingsStatus>
                        )}
                    </div>

                    <div>
                        <Controller
                            name="notificationsEnabled"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <StyledSwitch
                                    checked={value}
                                    onUpdate={e => onChange(e.detail.checked)}
                                    disabled={noEmailEnteredYet}
                                    data-testid={'purchase-request-notifications-switch'}
                                >
                                    {formatMessage(messages.notificationsEnabledLabel)}
                                </StyledSwitch>
                            )}
                        />

                        <StyledMultipleEmailsFieldWrapper>
                            <MultipleEmailsField
                                control={control}
                                register={register}
                                formState={formState}
                                fieldName={'contactEmailAddresses'}
                                messages={{
                                    firstEntryLabel: messages.emailLabel,
                                    nthEntryLabel: messages.additionalEmailLabel,
                                }}
                            />
                        </StyledMultipleEmailsFieldWrapper>

                        <StyledFormButtons isLoading={isSaving} onCancel={handleCancel} />
                    </div>
                </StyledFormGrid>
            </form>
        </LoadingAndErrorWrapper>
    )
}
