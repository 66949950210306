import { ReactNode } from 'react'
import { GridItemSize, PHeading } from '@porsche-design-system/components-react'
import { Flex, FlexItem } from '@slcheckout/ui-kit'
import { styled } from 'styled-components'
import { spacingFluid } from '@porsche-design-system/components-react/styles'

type FormGroupProps = {
    label?: string
    completedBadge: ReactNode
    children: ReactNode | ReactNode[]
    labelColumnWidth?: GridItemSize
    contentColumnWidth?: GridItemSize
}

const StyledFormGroupGrid = styled.div<{ labelColumnWidth: GridItemSize; contentColumnWidth: GridItemSize }>`
    display: grid;
    grid-template-columns: ${({ labelColumnWidth, contentColumnWidth }) =>
        `${labelColumnWidth}fr ${contentColumnWidth}fr`};
    gap: ${spacingFluid.medium};
`

export function FormGroup({
    label = '',
    completedBadge,
    children,
    labelColumnWidth = 1,
    contentColumnWidth = 3,
}: FormGroupProps) {
    return (
        <StyledFormGroupGrid labelColumnWidth={labelColumnWidth} contentColumnWidth={contentColumnWidth}>
            <Flex direction={'column'}>
                <FlexItem>
                    <PHeading tag="h4" size="medium">
                        {label}
                    </PHeading>
                </FlexItem>
                <FlexItem>{completedBadge}</FlexItem>
            </Flex>

            <Flex direction={'column'}>
                {Array.isArray(children) ? (
                    children.map((child, index) => <FlexItem key={index}>{child}</FlexItem>)
                ) : (
                    <FlexItem>{children}</FlexItem>
                )}
            </Flex>
        </StyledFormGroupGrid>
    )
}
