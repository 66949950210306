/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { AppRole, ErrorResponse } from '../models/index'
import { AppRoleFromJSON, AppRoleToJSON, ErrorResponseFromJSON, ErrorResponseToJSON } from '../models/index'

/**
 *
 */
export class UserRestControllerApi extends runtime.BaseAPI {
    /**
     */
    async getRoleRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<Array<AppRole>>> {
        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/user/roles`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(AppRoleFromJSON))
    }

    /**
     */
    async getRole(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AppRole>> {
        const response = await this.getRoleRaw(initOverrides)
        return await response.value()
    }
}
