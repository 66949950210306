import { defineMessages } from 'react-intl'

export const payoutContactConfigurationMessages = defineMessages({
    payoutReportEmailLabel: {
        id: 'dealer.payoutReport.label.email',
        defaultMessage: 'Email address for your Payout Report',
    },
    payoutReportAdditionalEmailLabel: {
        id: 'dealer.payoutReport.label.additionalEmail',
        defaultMessage: 'Additional Email address for your Payout Report',
    },
    toastSubmitSuccess: {
        id: 'dealer.payoutReport.toast.submit.success',
        defaultMessage: 'Saved',
    },
    toastSubmitError: {
        id: 'dealer.payoutReport.toast.submit.error',
        defaultMessage: 'Error while saving',
    },
})
