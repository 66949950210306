import { PTextFieldWrapper } from '@porsche-design-system/components-react'
import { get } from 'lodash'
import { InputHTMLAttributes } from 'react'
import { RegisterOptions, useFormContext } from 'react-hook-form'

type FormTextInputProps = {
    fieldName: string
    label?: string
    registerOptions?: RegisterOptions
    className?: string
} & InputHTMLAttributes<HTMLInputElement>

export function FormTextInput({ fieldName, label, registerOptions, className, ...rest }: FormTextInputProps) {
    const { register, formState } = useFormContext()

    // lodash get is used to retrieve values from fields with nested field names e.g. foo.0.bar
    const errorMessage = get(formState.errors, fieldName)?.message

    return (
        <PTextFieldWrapper
            label={label}
            message={errorMessage as string}
            state={errorMessage ? 'error' : 'none'}
            className={className}
        >
            <input type={'text'} {...register(fieldName, registerOptions)} {...rest} />
        </PTextFieldWrapper>
    )
}
