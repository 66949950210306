import { defineMessages } from 'react-intl'

export const loadingAndErrorMessages = defineMessages({
    isLoading: {
        id: 'dealer.onlineOrderingConfig.loading.ariaLabel',
        defaultMessage: 'The Online Order Config is loading.',
    },
    isError: {
        id: 'dealer.onlineOrderingConfig.error.displayLabel',
        defaultMessage: 'Failed to load Online Order Config.',
    },
})
