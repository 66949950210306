/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SubmerchantEvent
 */
export interface SubmerchantEvent {
    /**
     * The event name that will be triggered if no action is taken
     * @type {string}
     * @memberof SubmerchantEvent
     */
    event?: string
    /**
     * The execution date scheduled for the event
     * @type {string}
     * @memberof SubmerchantEvent
     */
    executionDate?: string
    /**
     * The reason that leads to scheduling of the event
     * @type {string}
     * @memberof SubmerchantEvent
     */
    reason?: string
}

export function SubmerchantEventFromJSON(json: any): SubmerchantEvent {
    return SubmerchantEventFromJSONTyped(json, false)
}

export function SubmerchantEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmerchantEvent {
    if (json === undefined || json === null) {
        return json
    }
    return {
        event: !exists(json, 'event') ? undefined : json['event'],
        executionDate: !exists(json, 'executionDate') ? undefined : json['executionDate'],
        reason: !exists(json, 'reason') ? undefined : json['reason'],
    }
}

export function SubmerchantEventToJSON(value?: SubmerchantEvent | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        event: value.event,
        executionDate: value.executionDate,
        reason: value.reason,
    }
}
