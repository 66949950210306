import { FieldError, useFormContext } from 'react-hook-form'
import { PText } from '@porsche-design-system/components-react'

import { DocumentType } from '@slmpserv/common-api-merchant'

import { DocumentsFormState } from '../../DocumentsForm/DocumentsFormTypesAndConsts'

type FileUploadErrorProps = {
    documentType: DocumentType
    language: string
}

export function FileUploadError({ documentType, language }: FileUploadErrorProps) {
    const { formState } = useFormContext<DocumentsFormState>()

    const error = (formState.errors.documents?.[language]?.files as Record<DocumentType, FieldError | undefined>)?.[
        documentType
    ]

    return <PText color={'notification-error'}>{error?.message}</PText>
}
