import { useIntl } from 'react-intl'

import { DocumentType } from '@slmpserv/common-api-merchant'
import { descriptionMessages, documentManagementMessages } from '@slmpserv/common-translations'
import { CompletenessBadge, FormGroup, SpacedDivider } from '@slmpserv/common-components'

import { DocumentSelection } from '../components/DocumentSelection/DocumentSelection'
import { useDocumentProgress } from '../hooks/useDocumentProgress'
import { useFilterDisabledDocumentTypes } from '../hooks/useFilterDisabledDocumentTypes'

import { StyledHeading, StyledText } from './shared.styles'

type UsedCarSectionProps = {
    language: string
}

export function UsedCarSection({ language }: UsedCarSectionProps) {
    const { formatMessage } = useIntl()

    const mainDocument = DocumentType.USED_CAR_GENERAL
    const additionalDocuments = [
        DocumentType.USED_CAR_BEV,
        DocumentType.USED_CAR_PHEV,
        DocumentType.USED_CAR_APPROVED_GENERAL,
        DocumentType.USED_CAR_APPROVED_BEV,
        DocumentType.USED_CAR_APPROVED_PHEV,
    ]

    const documentTypes = useFilterDisabledDocumentTypes([mainDocument, ...additionalDocuments])
    const { selectedDocumentCount, complete, total } = useDocumentProgress(language, documentTypes)

    return (
        <>
            <SpacedDivider />
            <FormGroup
                label={formatMessage(documentManagementMessages.usedCarsSection)}
                completedBadge={<CompletenessBadge done={selectedDocumentCount} total={total} complete={complete} />}
                labelColumnWidth={1}
                contentColumnWidth={4}
            >
                <StyledHeading tag="h4" size="large">
                    {formatMessage(descriptionMessages.usedCarHeader)}
                </StyledHeading>
                <StyledText color={'neutral-contrast-high'}>
                    {formatMessage(descriptionMessages.usedCarDetail)}
                </StyledText>
                <DocumentSelection documentType={mainDocument} language={language} />
                <StyledHeading tag="h4" size="large">
                    {formatMessage(descriptionMessages.usedCarAdditionalDocumentsHeader)}
                </StyledHeading>
                <StyledText color={'neutral-contrast-high'}>
                    {formatMessage(descriptionMessages.usedCarAdditionalDocumentsDetail)}
                </StyledText>
                {additionalDocuments.map(documentType => (
                    <DocumentSelection documentType={documentType} language={language} key={documentType} />
                ))}
            </FormGroup>
        </>
    )
}
