import { AuthorizationHeader } from '@finder/cognito-react'

import { Environment, getEnvironment } from '@slmpserv/environment'

import {
    Configuration,
    FeaturesApi,
    MarketplacesApi,
    MarketplacesDocumentConfigurationApi,
    MarketplacesLegalDocumentApi,
    MarketplacesLegalNoticeApi,
    MerchantImprintApi,
    MerchantsApi,
    MerchantTermsAndConditionsApi,
    Middleware,
    OnlineOrderConfigurationApi,
    PaymentApi,
    RightsRestControllerApi,
    RequestContext,
    SellerContactApi,
    UserRestControllerApi,
} from './api'
import { MerchantApiClient } from './MerchantApiClient'

class PpnMiddleware implements Middleware {
    private tokenFunction?: () => Promise<AuthorizationHeader | undefined>

    public setTokenFunction(tokenFunction: () => Promise<AuthorizationHeader | undefined>) {
        this.tokenFunction = tokenFunction
    }

    pre = async ({ url, init }: RequestContext) => {
        if (this.tokenFunction) {
            const existingHeaders = init.headers || {}
            const headersWithAuth = {
                ...existingHeaders,
                ...(await this.tokenFunction()),
            }
            const initWithAuth = { ...init, headers: headersWithAuth }
            return { init: initWithAuth, url }
        }
        return { url, init }
    }
}

class BasePathMiddleware implements Middleware {
    private env: Environment = Environment.LOCAL
    public setEnv = (env: Environment) => (this.env = env)
    pre = async ({ url, init }: RequestContext) => {
        const backendUrl = new URL(backendUrls[this.env])
        const requestUrl = new URL(url)
        requestUrl.hostname = backendUrl.hostname
        requestUrl.protocol = backendUrl.protocol
        requestUrl.port = backendUrl.port
        return { init, url: requestUrl.toString() }
    }
}

export const apiMiddleware = new PpnMiddleware()
export const basePathMiddleware = new BasePathMiddleware()

export const backendUrls: Record<Environment, string> = {
    DEV: 'https://dev.slmpserv.aws.porsche-preview.cloud',
    PREVIEW: 'https://slmpserv.aws.porsche-preview.cloud',
    PROD: 'https://slmpserv.aws.porsche.cloud',
    LOCAL: 'http://localhost:8080',
    E2E: 'http://localhost:20000',
}

const config = new Configuration({
    basePath: backendUrls[getEnvironment()],
    middleware: [apiMiddleware, basePathMiddleware],
})

export const merchantsApi = new MerchantsApi(config)
export const marketplacesApi = new MarketplacesApi(config)
export const marketplacesLegalDocumentsApi = new MarketplacesLegalDocumentApi(config)
export const marketplacesLegalNoticeApi = new MarketplacesLegalNoticeApi(config)
export const onlineOrderConfigurationApi = new OnlineOrderConfigurationApi(config)
export const applicationFeatureApi = new FeaturesApi(config)
export const userApi = new UserRestControllerApi(config)
export const marketplacesDocumentConfigurationApi = new MarketplacesDocumentConfigurationApi(config)
export const merchantTermsAndConditionsApi = new MerchantTermsAndConditionsApi(config)
export const merchantImprintApi = new MerchantImprintApi(config)
export const paymentApi = new PaymentApi(config)
export const sellerContactApi = new SellerContactApi(config)
export const rightsRestControllerApi = new RightsRestControllerApi(config)

export const baseApi = new MerchantApiClient(config)
