/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface EntryDnUpdateRequestDto
 */
export interface EntryDnUpdateRequestDto {
    /**
     *
     * @type {string}
     * @memberof EntryDnUpdateRequestDto
     */
    entryDn: string
}

/**
 * Check if a given object implements the EntryDnUpdateRequestDto interface.
 */
export function instanceOfEntryDnUpdateRequestDto(value: object): boolean {
    if (!('entryDn' in value)) return false
    return true
}

export function EntryDnUpdateRequestDtoFromJSON(json: any): EntryDnUpdateRequestDto {
    return EntryDnUpdateRequestDtoFromJSONTyped(json, false)
}

export function EntryDnUpdateRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntryDnUpdateRequestDto {
    if (json == null) {
        return json
    }
    return {
        entryDn: json['entryDn'],
    }
}

export function EntryDnUpdateRequestDtoToJSON(value?: EntryDnUpdateRequestDto | null): any {
    if (value == null) {
        return value
    }
    return {
        entryDn: value['entryDn'],
    }
}
