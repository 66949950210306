import { ErrorResponse, ErrorResponseFromJSON } from '../models/ErrorResponse'
import { ResponseError } from '../runtime'

export interface ApiError {
    errorResponse: ErrorResponse | null
    apiResponse: Response
}

function getResponse(res: ResponseError | Response): Response {
    return res instanceof ResponseError ? res.response : res
}

export async function toApiError(response: ResponseError | Response) {
    const errorResponse: ErrorResponse | null = await toErrorResponse(getResponse(response))
    const apiError: ApiError = {
        errorResponse: errorResponse,
        apiResponse: getResponse(response),
    }
    return apiError
}

async function toErrorResponse(response: Response): Promise<ErrorResponse | null> {
    try {
        const json = await response.json()
        return ErrorResponseFromJSON(json)
    } catch (e) {
        return null
    }
}
