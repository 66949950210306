import { PText } from '@porsche-design-system/components-react'
import { spacingStatic } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'
import { styled } from 'styled-components'

import { documentFileUploadMessages } from '@slmpserv/common-translations'

const StyledText = styled(PText)`
    margin-top: ${spacingStatic.small};
`

type AcceptedFilesInfoProps = {
    maxFileSizeInMB: number
}

export function AcceptedFilesInfo({ maxFileSizeInMB }: AcceptedFilesInfoProps) {
    const { formatMessage } = useIntl()

    return (
        <StyledText color={'neutral-contrast-medium'}>
            {formatMessage(documentFileUploadMessages.pdfUpToSize, { maxSize: maxFileSizeInMB })}
        </StyledText>
    )
}
