/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { DownPaymentMarketplaceStatus } from './DownPaymentMarketplaceStatus'
import {
    DownPaymentMarketplaceStatusFromJSON,
    DownPaymentMarketplaceStatusFromJSONTyped,
    DownPaymentMarketplaceStatusToJSON,
} from './DownPaymentMarketplaceStatus'
import type { LegalDocumentType } from './LegalDocumentType'
import { LegalDocumentTypeFromJSON, LegalDocumentTypeFromJSONTyped, LegalDocumentTypeToJSON } from './LegalDocumentType'
import type { MarketplaceCurrency } from './MarketplaceCurrency'
import {
    MarketplaceCurrencyFromJSON,
    MarketplaceCurrencyFromJSONTyped,
    MarketplaceCurrencyToJSON,
} from './MarketplaceCurrency'
import type { MarketplaceFeatures } from './MarketplaceFeatures'
import {
    MarketplaceFeaturesFromJSON,
    MarketplaceFeaturesFromJSONTyped,
    MarketplaceFeaturesToJSON,
} from './MarketplaceFeatures'
import type { MarketplaceOperator } from './MarketplaceOperator'
import {
    MarketplaceOperatorFromJSON,
    MarketplaceOperatorFromJSONTyped,
    MarketplaceOperatorToJSON,
} from './MarketplaceOperator'

/**
 *
 * @export
 * @interface MarketplaceUpdate
 */
export interface MarketplaceUpdate {
    /**
     *
     * @type {Array<LegalDocumentType>}
     * @memberof MarketplaceUpdate
     */
    legalDocumentTypes: Array<LegalDocumentType>
    /**
     *
     * @type {Array<string>}
     * @memberof MarketplaceUpdate
     */
    locales: Array<string>
    /**
     *
     * @type {boolean}
     * @memberof MarketplaceUpdate
     */
    kycEnabled: boolean
    /**
     *
     * @type {DownPaymentMarketplaceStatus}
     * @memberof MarketplaceUpdate
     */
    downPaymentStatus: DownPaymentMarketplaceStatus
    /**
     *
     * @type {MarketplaceCurrency}
     * @memberof MarketplaceUpdate
     */
    currency: MarketplaceCurrency
    /**
     *
     * @type {boolean}
     * @memberof MarketplaceUpdate
     */
    csvEnabled: boolean
    /**
     *
     * @type {boolean}
     * @memberof MarketplaceUpdate
     */
    checkoutEnabled: boolean
    /**
     *
     * @type {MarketplaceOperator}
     * @memberof MarketplaceUpdate
     */
    operator: MarketplaceOperator
    /**
     *
     * @type {Array<MarketplaceFeatures>}
     * @memberof MarketplaceUpdate
     */
    features: Array<MarketplaceFeatures>
    /**
     *
     * @type {string}
     * @memberof MarketplaceUpdate
     */
    supportEmail?: string
    /**
     *
     * @type {boolean}
     * @memberof MarketplaceUpdate
     */
    isImprintRequired: boolean
    /**
     *
     * @type {boolean}
     * @memberof MarketplaceUpdate
     */
    reservationEnabled: boolean
}

/**
 * Check if a given object implements the MarketplaceUpdate interface.
 */
export function instanceOfMarketplaceUpdate(value: object): boolean {
    if (!('legalDocumentTypes' in value)) return false
    if (!('locales' in value)) return false
    if (!('kycEnabled' in value)) return false
    if (!('downPaymentStatus' in value)) return false
    if (!('currency' in value)) return false
    if (!('csvEnabled' in value)) return false
    if (!('checkoutEnabled' in value)) return false
    if (!('operator' in value)) return false
    if (!('features' in value)) return false
    if (!('isImprintRequired' in value)) return false
    if (!('reservationEnabled' in value)) return false
    return true
}

export function MarketplaceUpdateFromJSON(json: any): MarketplaceUpdate {
    return MarketplaceUpdateFromJSONTyped(json, false)
}

export function MarketplaceUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketplaceUpdate {
    if (json == null) {
        return json
    }
    return {
        legalDocumentTypes: (json['legalDocumentTypes'] as Array<any>).map(LegalDocumentTypeFromJSON),
        locales: json['locales'],
        kycEnabled: json['kycEnabled'],
        downPaymentStatus: DownPaymentMarketplaceStatusFromJSON(json['downPaymentStatus']),
        currency: MarketplaceCurrencyFromJSON(json['currency']),
        csvEnabled: json['csvEnabled'],
        checkoutEnabled: json['checkoutEnabled'],
        operator: MarketplaceOperatorFromJSON(json['operator']),
        features: (json['features'] as Array<any>).map(MarketplaceFeaturesFromJSON),
        supportEmail: json['supportEmail'] == null ? undefined : json['supportEmail'],
        isImprintRequired: json['isImprintRequired'],
        reservationEnabled: json['reservationEnabled'],
    }
}

export function MarketplaceUpdateToJSON(value?: MarketplaceUpdate | null): any {
    if (value == null) {
        return value
    }
    return {
        legalDocumentTypes: (value['legalDocumentTypes'] as Array<any>).map(LegalDocumentTypeToJSON),
        locales: value['locales'],
        kycEnabled: value['kycEnabled'],
        downPaymentStatus: DownPaymentMarketplaceStatusToJSON(value['downPaymentStatus']),
        currency: MarketplaceCurrencyToJSON(value['currency']),
        csvEnabled: value['csvEnabled'],
        checkoutEnabled: value['checkoutEnabled'],
        operator: MarketplaceOperatorToJSON(value['operator']),
        features: (value['features'] as Array<any>).map(MarketplaceFeaturesToJSON),
        supportEmail: value['supportEmail'],
        isImprintRequired: value['isImprintRequired'],
        reservationEnabled: value['reservationEnabled'],
    }
}
