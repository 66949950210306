import { themeLight } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'
import { isNumber } from 'lodash'

import {
    dashboardPopoverMessages,
    dashboardPreviewMessages,
    dashboardStatusMessages,
    dashboardStatusMessageTooltip,
    dashboardTitles,
} from '@slmpserv/common-translations'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { useMatomoTrackEditEvent } from '@slmpserv/common/analytics'
import { AccessSensitiveProps } from '@slmpserv/self-service-shared'

import { DashboardGroupItem, DashboardWarningTag } from '../DashboardGroupItem/DashboardGroupItem'
import { DashboardGroupItemPreview } from '../DashboardGroupItemPreview/DashboardGroupItemPreview'

type ReservationFeeItemProps = AccessSensitiveProps & {
    configuredAmount?: number
    currency: string
    isInWarningState: boolean
}

export function ReservationFeeItem({
    configuredAmount,
    currency,
    isInWarningState,
    isAllowedToEdit,
}: ReservationFeeItemProps) {
    const { formatMessage, formatNumber } = useIntl()

    const reservationFeePreview = isNumber(configuredAmount)
        ? formatNumber(configuredAmount, {
              style: 'currency',
              currency,
              currencyDisplay: 'code',
          })
        : '-'

    const { trackEvent } = useMatomoTrackEditEvent('reservation_fee', isInWarningState)

    return (
        <DashboardGroupItem
            hightlightColor={isInWarningState ? themeLight.notification.warning : null}
            title={formatMessage(dashboardTitles.reservationFee)}
            tooltipText={formatMessage(dashboardPopoverMessages.reservationFee)}
            preview={
                <DashboardGroupItemPreview
                    title={formatMessage(dashboardPreviewMessages.amount)}
                    text={reservationFeePreview}
                />
            }
            badge={
                isInWarningState && (
                    <DashboardWarningTag
                        tagText={formatMessage(dashboardStatusMessages.missingReservationFee)}
                        popoverText={formatMessage(dashboardStatusMessageTooltip.reservationFee)}
                    />
                )
            }
            editLink={{
                isAllowedToEdit,
                props: { to: StaticRoutes.ONLINE_ORDER_CONFIGURATION },
                dataTestId: 'reservation-fee-edit-link',
                matomoEvent: trackEvent,
            }}
        />
    )
}
