/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
    GetKycEmail,
    GetKycEmailFromJSON,
    GetKycEmailToJSON,
    UpdateKycEmail,
    UpdateKycEmailFromJSON,
    UpdateKycEmailToJSON,
} from '../models'

export interface KycEmailsGETRequest {
    ppnId: string
}

export interface KycEmailsPUTRequest {
    ppnId: string
    updateKycEmail: UpdateKycEmail
}

/**
 *
 */
export class KYCEmailsApi extends runtime.BaseAPI {
    /**
     * Provides all of the KYC emails of a PPN user.
     * Provides KYC emails of a PPN user
     */
    async kycEmailsGETRaw(requestParameters: KycEmailsGETRequest): Promise<runtime.ApiResponse<GetKycEmail>> {
        if (requestParameters.ppnId === null || requestParameters.ppnId === undefined) {
            throw new runtime.RequiredError(
                'ppnId',
                'Required parameter requestParameters.ppnId was null or undefined when calling kycEmailsGET.'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.apiKey) {
            headerParameters['Authorization'] = this.configuration.apiKey('Authorization') // apikey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('bearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/kycemails/{ppnId}`.replace(`{${'ppnId'}}`, encodeURIComponent(String(requestParameters.ppnId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => GetKycEmailFromJSON(jsonValue))
    }

    /**
     * Provides all of the KYC emails of a PPN user.
     * Provides KYC emails of a PPN user
     */
    async kycEmailsGET(requestParameters: KycEmailsGETRequest): Promise<GetKycEmail> {
        const response = await this.kycEmailsGETRaw(requestParameters)
        return await response.value()
    }

    /**
     * Can be used to update KYC emails for a PPN user.
     * Updates the KYC emails for a PPN user
     */
    async kycEmailsPUTRaw(requestParameters: KycEmailsPUTRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ppnId === null || requestParameters.ppnId === undefined) {
            throw new runtime.RequiredError(
                'ppnId',
                'Required parameter requestParameters.ppnId was null or undefined when calling kycEmailsPUT.'
            )
        }

        if (requestParameters.updateKycEmail === null || requestParameters.updateKycEmail === undefined) {
            throw new runtime.RequiredError(
                'updateKycEmail',
                'Required parameter requestParameters.updateKycEmail was null or undefined when calling kycEmailsPUT.'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.apiKey) {
            headerParameters['Authorization'] = this.configuration.apiKey('Authorization') // apikey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('bearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/kycemails/{ppnId}`.replace(`{${'ppnId'}}`, encodeURIComponent(String(requestParameters.ppnId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateKycEmailToJSON(requestParameters.updateKycEmail),
        })

        return new runtime.VoidApiResponse(response)
    }

    /**
     * Can be used to update KYC emails for a PPN user.
     * Updates the KYC emails for a PPN user
     */
    async kycEmailsPUT(requestParameters: KycEmailsPUTRequest): Promise<void> {
        await this.kycEmailsPUTRaw(requestParameters)
    }
}
