import { themeLight } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'

import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { translateLanguage } from '@slmpserv/hooks'
import {
    dashboardPopoverMessages,
    dashboardStatusMessages,
    dashboardStatusMessageTooltip,
    dashboardTitles,
} from '@slmpserv/common-translations'
import { useMatomoTrackEditEvent } from '@slmpserv/common/analytics'
import { AccessSensitiveProps } from '@slmpserv/self-service-shared'

import { DashboardGroupItem, DashboardWarningTag } from '../DashboardGroupItem/DashboardGroupItem'
import { DashboardGroupItemPreview } from '../DashboardGroupItemPreview/DashboardGroupItemPreview'

type ImprintItemProps = AccessSensitiveProps & {
    languages: string[]
    imprintMissing: boolean
    missing: string[]
}

export function ImprintItem({ languages, imprintMissing, missing, isAllowedToEdit }: ImprintItemProps) {
    const { formatMessage, locale: browserLocale } = useIntl()
    const { trackEvent } = useMatomoTrackEditEvent('imprint', imprintMissing)
    return (
        <DashboardGroupItem
            hightlightColor={imprintMissing ? themeLight.notification.warning : null}
            title={formatMessage(dashboardTitles.imprint)}
            tooltipText={formatMessage(dashboardPopoverMessages.imprint)}
            preview={languages.map(language => (
                <DashboardGroupItemPreview
                    key={language}
                    title={translateLanguage(language, browserLocale)}
                    text={missing.includes(language) ? '0/1' : '1/1'}
                />
            ))}
            badge={
                imprintMissing && (
                    <DashboardWarningTag
                        tagText={formatMessage(dashboardStatusMessages.uploadDocument)}
                        popoverText={formatMessage(dashboardStatusMessageTooltip.uploadImprint)}
                    />
                )
            }
            editLink={{
                isAllowedToEdit,
                props: {
                    to: StaticRoutes.DOCUMENT_MANAGEMENT_IMPRINT,
                },
                dataTestId: 'imprint-edit-link',
                matomoEvent: trackEvent,
            }}
        />
    )
}
