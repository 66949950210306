/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { DownPaymentMarketplaceStatus } from './DownPaymentMarketplaceStatus'
import {
    DownPaymentMarketplaceStatusFromJSON,
    DownPaymentMarketplaceStatusFromJSONTyped,
    DownPaymentMarketplaceStatusToJSON,
} from './DownPaymentMarketplaceStatus'
import type { LegalDocumentType } from './LegalDocumentType'
import { LegalDocumentTypeFromJSON, LegalDocumentTypeFromJSONTyped, LegalDocumentTypeToJSON } from './LegalDocumentType'
import type { MarketplaceCurrency } from './MarketplaceCurrency'
import {
    MarketplaceCurrencyFromJSON,
    MarketplaceCurrencyFromJSONTyped,
    MarketplaceCurrencyToJSON,
} from './MarketplaceCurrency'
import type { MarketplaceFeatures } from './MarketplaceFeatures'
import {
    MarketplaceFeaturesFromJSON,
    MarketplaceFeaturesFromJSONTyped,
    MarketplaceFeaturesToJSON,
} from './MarketplaceFeatures'
import type { MarketplaceOperator } from './MarketplaceOperator'
import {
    MarketplaceOperatorFromJSON,
    MarketplaceOperatorFromJSONTyped,
    MarketplaceOperatorToJSON,
} from './MarketplaceOperator'

/**
 *
 * @export
 * @interface Marketplace
 */
export interface Marketplace {
    /**
     *
     * @type {string}
     * @memberof Marketplace
     */
    key: string
    /**
     *
     * @type {Array<LegalDocumentType>}
     * @memberof Marketplace
     */
    legalDocumentTypes: Array<LegalDocumentType>
    /**
     *
     * @type {Array<string>}
     * @memberof Marketplace
     */
    locales: Array<string>
    /**
     *
     * @type {boolean}
     * @memberof Marketplace
     */
    kycEnabled: boolean
    /**
     *
     * @type {DownPaymentMarketplaceStatus}
     * @memberof Marketplace
     */
    downPaymentStatus: DownPaymentMarketplaceStatus
    /**
     *
     * @type {MarketplaceCurrency}
     * @memberof Marketplace
     */
    currency: MarketplaceCurrency
    /**
     *
     * @type {boolean}
     * @memberof Marketplace
     */
    csvEnabled: boolean
    /**
     *
     * @type {boolean}
     * @memberof Marketplace
     */
    checkoutEnabled: boolean
    /**
     *
     * @type {MarketplaceOperator}
     * @memberof Marketplace
     */
    operator: MarketplaceOperator
    /**
     *
     * @type {Array<MarketplaceFeatures>}
     * @memberof Marketplace
     */
    features: Array<MarketplaceFeatures>
    /**
     *
     * @type {string}
     * @memberof Marketplace
     */
    supportEmail?: string
    /**
     *
     * @type {boolean}
     * @memberof Marketplace
     */
    isImprintRequired: boolean
    /**
     *
     * @type {boolean}
     * @memberof Marketplace
     */
    reservationEnabled: boolean
}

/**
 * Check if a given object implements the Marketplace interface.
 */
export function instanceOfMarketplace(value: object): boolean {
    if (!('key' in value)) return false
    if (!('legalDocumentTypes' in value)) return false
    if (!('locales' in value)) return false
    if (!('kycEnabled' in value)) return false
    if (!('downPaymentStatus' in value)) return false
    if (!('currency' in value)) return false
    if (!('csvEnabled' in value)) return false
    if (!('checkoutEnabled' in value)) return false
    if (!('operator' in value)) return false
    if (!('features' in value)) return false
    if (!('isImprintRequired' in value)) return false
    if (!('reservationEnabled' in value)) return false
    return true
}

export function MarketplaceFromJSON(json: any): Marketplace {
    return MarketplaceFromJSONTyped(json, false)
}

export function MarketplaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Marketplace {
    if (json == null) {
        return json
    }
    return {
        key: json['key'],
        legalDocumentTypes: (json['legalDocumentTypes'] as Array<any>).map(LegalDocumentTypeFromJSON),
        locales: json['locales'],
        kycEnabled: json['kycEnabled'],
        downPaymentStatus: DownPaymentMarketplaceStatusFromJSON(json['downPaymentStatus']),
        currency: MarketplaceCurrencyFromJSON(json['currency']),
        csvEnabled: json['csvEnabled'],
        checkoutEnabled: json['checkoutEnabled'],
        operator: MarketplaceOperatorFromJSON(json['operator']),
        features: (json['features'] as Array<any>).map(MarketplaceFeaturesFromJSON),
        supportEmail: json['supportEmail'] == null ? undefined : json['supportEmail'],
        isImprintRequired: json['isImprintRequired'],
        reservationEnabled: json['reservationEnabled'],
    }
}

export function MarketplaceToJSON(value?: Marketplace | null): any {
    if (value == null) {
        return value
    }
    return {
        key: value['key'],
        legalDocumentTypes: (value['legalDocumentTypes'] as Array<any>).map(LegalDocumentTypeToJSON),
        locales: value['locales'],
        kycEnabled: value['kycEnabled'],
        downPaymentStatus: DownPaymentMarketplaceStatusToJSON(value['downPaymentStatus']),
        currency: MarketplaceCurrencyToJSON(value['currency']),
        csvEnabled: value['csvEnabled'],
        checkoutEnabled: value['checkoutEnabled'],
        operator: MarketplaceOperatorToJSON(value['operator']),
        features: (value['features'] as Array<any>).map(MarketplaceFeaturesToJSON),
        supportEmail: value['supportEmail'],
        isImprintRequired: value['isImprintRequired'],
        reservationEnabled: value['reservationEnabled'],
    }
}
