import { useEffect } from 'react'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { sellerContactApi, SellerContactPhone } from '@slmpserv/common-api-merchant'

export type UseFetchSellerContactPhoneNumberProps = {
    entryDn: string
    queryOptions: Omit<UseQueryOptions<SellerContactPhone, Error, SellerContactPhone, string[]>, 'queryKey' | 'queryFn'>
}

export function useFetchSellerContactPhoneNumber({
    entryDn,
    queryOptions,
    onSuccess,
}: UseFetchSellerContactPhoneNumberProps & { onSuccess?: (data: SellerContactPhone) => void }) {
    const queryKey = ['dealer', entryDn, 'seller', 'phoneNumber']
    const queryResult = useQuery({
        queryKey,
        queryFn: () => sellerContactApi.getPhoneByEntryDn({ entryDn }),
        ...queryOptions,
    })

    useEffect(() => {
        if (queryResult.isSuccess && onSuccess) {
            onSuccess(queryResult.data)
        }
    }, [onSuccess, queryResult.data, queryResult.isSuccess])

    return {
        phoneNumberNew: queryResult.data?.phoneNumberNew,
        phoneNumberPreowned: queryResult.data?.phoneNumberPreowned,
        queryResult,
        queryKey,
    }
}
