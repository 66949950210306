import { useEffect } from 'react'
import { AuthorizationHeader } from '@finder/cognito-react/build/types/getAuthorizationHeader'

import { apiMiddleware as merchantApiMiddleware } from '@slmpserv/common-api-merchant'
import { apiMiddleware as kycApiMiddleware } from '@slmpserv/kyc-service-api'
import { apiMiddleware as purchaseRequestSettingsApiMiddleware } from '@slmpserv/purchase-request-settings-api'

export const useApiTokenFn = (tokenFunction: () => Promise<AuthorizationHeader | undefined>): void => {
    useEffect(() => {
        merchantApiMiddleware.setTokenFunction(tokenFunction)
        kycApiMiddleware.setTokenFunction(tokenFunction)
        purchaseRequestSettingsApiMiddleware.setTokenFunction(tokenFunction)
    }, [tokenFunction])
}
