/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ErrorResponse, MarketplaceLegalDocumentMeta, MarketplaceLegalDocumentType } from '../models/index'
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    MarketplaceLegalDocumentMetaFromJSON,
    MarketplaceLegalDocumentMetaToJSON,
    MarketplaceLegalDocumentTypeFromJSON,
    MarketplaceLegalDocumentTypeToJSON,
} from '../models/index'

export interface AddUpdateMarketplaceLegalDocumentRequest {
    marketplaceKey: string
    language: string
    documentType: MarketplaceLegalDocumentType
    legalDocument: Blob
}

export interface GetLegalDocumentsByMarketplaceRequest {
    marketplaceKey: string
}

export interface GetLegalDocumentsByMarketplaceAndLanguageRequest {
    marketplaceKey: string
    language: string
}

export interface GetMarketplaceLegalDocumentRequest {
    marketplaceKey: string
    language: string
    documentId: string
}

/**
 *
 */
export class MarketplacesLegalDocumentApi extends runtime.BaseAPI {
    /**
     */
    async addUpdateMarketplaceLegalDocumentRaw(
        requestParameters: AddUpdateMarketplaceLegalDocumentRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<MarketplaceLegalDocumentMeta>> {
        if (requestParameters['marketplaceKey'] == null) {
            throw new runtime.RequiredError(
                'marketplaceKey',
                'Required parameter "marketplaceKey" was null or undefined when calling addUpdateMarketplaceLegalDocument().'
            )
        }

        if (requestParameters['language'] == null) {
            throw new runtime.RequiredError(
                'language',
                'Required parameter "language" was null or undefined when calling addUpdateMarketplaceLegalDocument().'
            )
        }

        if (requestParameters['documentType'] == null) {
            throw new runtime.RequiredError(
                'documentType',
                'Required parameter "documentType" was null or undefined when calling addUpdateMarketplaceLegalDocument().'
            )
        }

        if (requestParameters['legalDocument'] == null) {
            throw new runtime.RequiredError(
                'legalDocument',
                'Required parameter "legalDocument" was null or undefined when calling addUpdateMarketplaceLegalDocument().'
            )
        }

        const queryParameters: any = {}

        if (requestParameters['documentType'] != null) {
            queryParameters['documentType'] = requestParameters['documentType']
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes)

        let formParams: { append(param: string, value: any): any }
        let useForm = false
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm
        if (useForm) {
            formParams = new FormData()
        } else {
            formParams = new URLSearchParams()
        }

        if (requestParameters['legalDocument'] != null) {
            formParams.append('legalDocument', requestParameters['legalDocument'] as any)
        }

        const response = await this.request(
            {
                path: `/api/marketplaces/{marketplaceKey}/legalDocuments/{language}`
                    .replace(`{${'marketplaceKey'}}`, encodeURIComponent(String(requestParameters['marketplaceKey'])))
                    .replace(`{${'language'}}`, encodeURIComponent(String(requestParameters['language']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: formParams,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => MarketplaceLegalDocumentMetaFromJSON(jsonValue))
    }

    /**
     */
    async addUpdateMarketplaceLegalDocument(
        requestParameters: AddUpdateMarketplaceLegalDocumentRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<MarketplaceLegalDocumentMeta> {
        const response = await this.addUpdateMarketplaceLegalDocumentRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async getLegalDocumentsByMarketplaceRaw(
        requestParameters: GetLegalDocumentsByMarketplaceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<Array<MarketplaceLegalDocumentMeta>>> {
        if (requestParameters['marketplaceKey'] == null) {
            throw new runtime.RequiredError(
                'marketplaceKey',
                'Required parameter "marketplaceKey" was null or undefined when calling getLegalDocumentsByMarketplace().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/marketplaces/{marketplaceKey}/legalDocuments`.replace(
                    `{${'marketplaceKey'}}`,
                    encodeURIComponent(String(requestParameters['marketplaceKey']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(MarketplaceLegalDocumentMetaFromJSON))
    }

    /**
     */
    async getLegalDocumentsByMarketplace(
        requestParameters: GetLegalDocumentsByMarketplaceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<Array<MarketplaceLegalDocumentMeta>> {
        const response = await this.getLegalDocumentsByMarketplaceRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async getLegalDocumentsByMarketplaceAndLanguageRaw(
        requestParameters: GetLegalDocumentsByMarketplaceAndLanguageRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<Array<MarketplaceLegalDocumentMeta>>> {
        if (requestParameters['marketplaceKey'] == null) {
            throw new runtime.RequiredError(
                'marketplaceKey',
                'Required parameter "marketplaceKey" was null or undefined when calling getLegalDocumentsByMarketplaceAndLanguage().'
            )
        }

        if (requestParameters['language'] == null) {
            throw new runtime.RequiredError(
                'language',
                'Required parameter "language" was null or undefined when calling getLegalDocumentsByMarketplaceAndLanguage().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/marketplaces/{marketplaceKey}/legalDocuments/{language}`
                    .replace(`{${'marketplaceKey'}}`, encodeURIComponent(String(requestParameters['marketplaceKey'])))
                    .replace(`{${'language'}}`, encodeURIComponent(String(requestParameters['language']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(MarketplaceLegalDocumentMetaFromJSON))
    }

    /**
     */
    async getLegalDocumentsByMarketplaceAndLanguage(
        requestParameters: GetLegalDocumentsByMarketplaceAndLanguageRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<Array<MarketplaceLegalDocumentMeta>> {
        const response = await this.getLegalDocumentsByMarketplaceAndLanguageRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async getMarketplaceLegalDocumentRaw(
        requestParameters: GetMarketplaceLegalDocumentRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['marketplaceKey'] == null) {
            throw new runtime.RequiredError(
                'marketplaceKey',
                'Required parameter "marketplaceKey" was null or undefined when calling getMarketplaceLegalDocument().'
            )
        }

        if (requestParameters['language'] == null) {
            throw new runtime.RequiredError(
                'language',
                'Required parameter "language" was null or undefined when calling getMarketplaceLegalDocument().'
            )
        }

        if (requestParameters['documentId'] == null) {
            throw new runtime.RequiredError(
                'documentId',
                'Required parameter "documentId" was null or undefined when calling getMarketplaceLegalDocument().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/marketplaces/{marketplaceKey}/legalDocuments/{language}/{documentId}`
                    .replace(`{${'marketplaceKey'}}`, encodeURIComponent(String(requestParameters['marketplaceKey'])))
                    .replace(`{${'language'}}`, encodeURIComponent(String(requestParameters['language'])))
                    .replace(`{${'documentId'}}`, encodeURIComponent(String(requestParameters['documentId']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.BlobApiResponse(response)
    }

    /**
     */
    async getMarketplaceLegalDocument(
        requestParameters: GetMarketplaceLegalDocumentRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<Blob> {
        const response = await this.getMarketplaceLegalDocumentRaw(requestParameters, initOverrides)
        return await response.value()
    }
}
