/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CreatePaymentLinkResponseRestModel
 */
export interface CreatePaymentLinkResponseRestModel {
    /**
     *
     * @type {string}
     * @memberof CreatePaymentLinkResponseRestModel
     */
    paymentLinkUrl?: string
    /**
     *
     * @type {string}
     * @memberof CreatePaymentLinkResponseRestModel
     */
    expiryDate?: string
    /**
     *
     * @type {string}
     * @memberof CreatePaymentLinkResponseRestModel
     */
    transactionId?: string
    /**
     *
     * @type {string}
     * @memberof CreatePaymentLinkResponseRestModel
     */
    orderId?: string
}

/**
 * Check if a given object implements the CreatePaymentLinkResponseRestModel interface.
 */
export function instanceOfCreatePaymentLinkResponseRestModel(value: object): boolean {
    return true
}

export function CreatePaymentLinkResponseRestModelFromJSON(json: any): CreatePaymentLinkResponseRestModel {
    return CreatePaymentLinkResponseRestModelFromJSONTyped(json, false)
}

export function CreatePaymentLinkResponseRestModelFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): CreatePaymentLinkResponseRestModel {
    if (json == null) {
        return json
    }
    return {
        paymentLinkUrl: json['paymentLinkUrl'] == null ? undefined : json['paymentLinkUrl'],
        expiryDate: json['expiryDate'] == null ? undefined : json['expiryDate'],
        transactionId: json['transactionId'] == null ? undefined : json['transactionId'],
        orderId: json['orderId'] == null ? undefined : json['orderId'],
    }
}

export function CreatePaymentLinkResponseRestModelToJSON(value?: CreatePaymentLinkResponseRestModel | null): any {
    if (value == null) {
        return value
    }
    return {
        paymentLinkUrl: value['paymentLinkUrl'],
        expiryDate: value['expiryDate'],
        transactionId: value['transactionId'],
        orderId: value['orderId'],
    }
}
