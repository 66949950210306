import { currentEnvironment, Environment as FinderEnvironment } from '@finder/environment'
import { DealerContextProps } from '@slfinrtl/context'

import { Environment } from '@slmpserv/environment'

export const finderEnvToMerchantEnvMapping: Record<FinderEnvironment, Environment> = {
    P: Environment.PROD,
    PP: Environment.PREVIEW,
    K: Environment.DEV,
}

export const isProd = (): boolean => {
    return getCurrentEnvironment() === Environment.PROD
}

const frontendUrls: Record<FinderEnvironment, URL> = {
    P: new URL('https://finder-dealer-wrapper.slmpserv.aws.porsche.cloud'),
    PP: new URL('https://finder-dealer-wrapper.slmpserv.aws.porsche-preview.cloud'),
    K: new URL('https://dev.finder-dealer-wrapper.slmpserv.aws.porsche-preview.cloud'),
}

export const getBucketUrl = (env?: FinderEnvironment) => {
    const environment = env ?? currentEnvironment()
    if (environment !== null) {
        return frontendUrls[environment]
    } else {
        return new URL('http://localhost:4199')
    }
}

export function getCurrentEnvironment(dealerContext?: DealerContextProps): Environment {
    const finderEnv = currentEnvironment()
    const dealerContextEnv = dealerContext?.environment
    const isCypressDefined = typeof window['Cypress'] !== 'undefined'

    if (dealerContextEnv) {
        return finderEnvToMerchantEnvMapping[dealerContextEnv]
    }

    if (finderEnv) {
        return finderEnvToMerchantEnvMapping[finderEnv]
    }

    if (isCypressDefined) {
        return Environment.E2E
    }

    return Environment.LOCAL
}
