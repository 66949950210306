import { PHeading } from '@porsche-design-system/components-react'
import { spacingFluid, spacingStatic, themeLight } from '@porsche-design-system/components-react/styles'
import { ReactNode } from 'react'
import { styled } from 'styled-components'

const StyledContainer = styled.div`
    margin-top: ${spacingFluid.medium};
`

const StyledHeadingContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: start;
    background: ${themeLight.background.base};
    padding: ${spacingStatic.large} ${spacingStatic.large};
`

const StyledBadgeContainer = styled.div`
    margin-left: ${spacingStatic.small};
`

type DashboardBoxProps = {
    boxTitle: string
    badge?: ReactNode
    children?: ReactNode
}

export function DashboardGroup({ boxTitle, badge, children }: DashboardBoxProps) {
    return (
        <StyledContainer>
            <StyledHeadingContainer>
                <PHeading tag="h3" size="large">
                    {boxTitle}
                </PHeading>
                <StyledBadgeContainer>{badge}</StyledBadgeContainer>
            </StyledHeadingContainer>
            {children}
        </StyledContainer>
    )
}
