import { useLocation } from 'react-router-dom'

import { Marketplace, MarketplaceFeatures } from '@slmpserv/common-api-merchant'

type UseMarketplaceFeaturesProps = {
    marketplace?: Marketplace
}
export const useMarketplaceFeatures = ({
    marketplace,
}: UseMarketplaceFeaturesProps): Record<MarketplaceFeatures, boolean> => {
    const location = useLocation()

    const features = Object.values(MarketplaceFeatures)
    const ignoreFeatureFlag = new URLSearchParams(location.search).get('ignoreFeatureToggles') === 'true'
    const featureMap = features.reduce(
        (featureObject, currentFeature) => ({
            ...featureObject,
            [currentFeature]: ignoreFeatureFlag ? true : Boolean(marketplace?.features.includes(currentFeature)),
        }),
        {}
    )
    return featureMap as Record<MarketplaceFeatures, boolean>
}
