import { ChangeEventHandler, ComponentProps, useCallback, useState } from 'react'
import { PPagination, PSelectWrapper } from '@porsche-design-system/components-react'
import { spacingFluid } from '@porsche-design-system/components-react/styles'
import { styled } from 'styled-components'

const StyledPaginationGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: ${spacingFluid.medium};
`

const StyledPagination = styled(PPagination)`
    grid-column: 2;
    justify-self: center;
`

const StyledSelectWrapper = styled(PSelectWrapper)`
    grid-column: 3;
    justify-self: end;
`

interface Pagination {
    page: number
    size: number
    totalElements: number
}

export const usePagination = () => {
    const [pagination, setPagination] = useState<Pagination>({
        page: 0,
        size: 20,
        totalElements: 0,
    })

    const setTotalElements = useCallback((totalElements?: Pagination['totalElements']) => {
        setPagination(prev => ({
            ...prev,
            totalElements: totalElements !== undefined ? totalElements : prev.totalElements,
        }))
    }, [])

    const onPageChange: ComponentProps<typeof PPagination>['onPageChange'] = event => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: mock returns event.page instead of event.details.page
        const newActivePage = (event.detail?.page ?? event.page) - 1 // first page of PPagination is 1, we need 0
        setPagination(prev => ({ ...prev, page: newActivePage }))
    }

    const onPageSizeChange: ChangeEventHandler<HTMLSelectElement> = event => {
        const newPageSize = Number(event.currentTarget.value)
        setPagination(prev => ({ ...prev, page: 0, size: newPageSize }))
    }

    const goToPage = useCallback((page: number) => {
        setPagination(prevState => ({ ...prevState, page }))
    }, [])

    return {
        pagination: { page: pagination.page, size: pagination.size },
        setTotalElements,
        goToPage,
        PaginationRow: (
            <StyledPaginationGrid>
                <StyledPagination
                    activePage={pagination.page + 1} // activePage starts with 1
                    itemsPerPage={pagination.size}
                    totalItemsCount={pagination.totalElements}
                    onUpdate={onPageChange}
                />
                <StyledSelectWrapper>
                    <select value={pagination.size} onChange={onPageSizeChange}>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </StyledSelectWrapper>
            </StyledPaginationGrid>
        ),
    }
}
