import { useIntl } from 'react-intl'
import { themeLight } from '@porsche-design-system/components-react/styles'

import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { translateLanguage } from '@slmpserv/hooks'
import {
    dashboardPopoverMessages,
    dashboardStatusMessages,
    dashboardStatusMessageTooltip,
    dashboardTitles,
} from '@slmpserv/common-translations'
import { useMatomoTrackEditEvent } from '@slmpserv/common/analytics'
import { AccessSensitiveProps } from '@slmpserv/self-service-shared'

import { DashboardGroupItem, DashboardWarningTag } from '../DashboardGroupItem/DashboardGroupItem'
import { DashboardGroupItemPreview } from '../DashboardGroupItemPreview/DashboardGroupItemPreview'

type PrivacyPolicyItemProps = AccessSensitiveProps & {
    languages: string[]
    privacyPolicyMissing: boolean
    missing: string[]
}

export function PrivacyPolicyItem({
    languages,
    privacyPolicyMissing,
    missing,
    isAllowedToEdit,
}: PrivacyPolicyItemProps) {
    const { formatMessage, locale: browserLocale } = useIntl()
    const { trackEvent } = useMatomoTrackEditEvent('privacy_policy', privacyPolicyMissing)
    return (
        <DashboardGroupItem
            hightlightColor={privacyPolicyMissing ? themeLight.notification.warning : null}
            title={formatMessage(dashboardTitles.privacyPolicy)}
            tooltipText={formatMessage(dashboardPopoverMessages.privacyPolicy)}
            preview={languages.map(language => (
                <DashboardGroupItemPreview
                    key={language}
                    title={translateLanguage(language, browserLocale)}
                    text={missing.includes(language) ? '0/1' : '1/1'}
                />
            ))}
            badge={
                privacyPolicyMissing && (
                    <DashboardWarningTag
                        tagText={formatMessage(dashboardStatusMessages.uploadDocument)}
                        popoverText={formatMessage(dashboardStatusMessageTooltip.privacyPolicy)}
                    />
                )
            }
            editLink={{
                isAllowedToEdit,
                props: {
                    to: StaticRoutes.DOCUMENT_MANAGEMENT_PRIVACY_POLICY,
                },
                dataTestId: 'privacy-policy-edit-link',
                matomoEvent: trackEvent,
            }}
        />
    )
}
