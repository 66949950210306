import { useNavigate } from 'react-router-dom'

import { useMultipleFormSubmits } from '@slmpserv/common-contexts'
import { FormButtons } from '@slmpserv/common-components-legacy'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'

import { ImprintForm } from './ImprintForm'

export function ImprintSelfService() {
    const { submitAll, isSubmitting } = useMultipleFormSubmits()
    const navigate = useNavigate()

    const handleCancel = () => {
        navigate(StaticRoutes.DASHBOARD)
    }

    return (
        <>
            <ImprintForm />
            <FormButtons isLoading={isSubmitting} onClick={submitAll} onCancel={handleCancel} />
        </>
    )
}
