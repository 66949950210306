import { PHeading, PSelectWrapper, PTag, PTextFieldWrapper } from '@porsche-design-system/components-react'
import { FormProvider, useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { styled } from 'styled-components'

import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { sellerContactPhoneMessages } from '@slmpserv/common-translations'

import { UsedNewCarContact } from '../types'

import { SellerContactPhoneNumberFormState, useSellerContactPhoneNumberForm } from './useSellerContactPhoneNumberForm'
import { StyledDivider, StyledFormButtons, StyledFormGrid, StyledInputWrapper } from './sharedStyledComponents'

const StyledUsedCarPhoneNumber = styled(PTag)`
    grid-column: 2;
`

type SellerContactPhoneProps = {
    entryDn: string
}

export function SellerContactPhone({ entryDn }: SellerContactPhoneProps) {
    const navigate = useNavigate()
    const { methods, handleSubmit } = useSellerContactPhoneNumberForm(entryDn)

    const handleCancel = () => {
        navigate(StaticRoutes.DASHBOARD)
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit}>
                <StyledFormGrid>
                    <UsedCarPhone />
                    <StyledDivider />
                    <NewCarPhone />
                    <StyledFormButtons onCancel={handleCancel} />
                </StyledFormGrid>
            </form>
        </FormProvider>
    )
}

function UsedCarPhone() {
    const { formatMessage } = useIntl()
    const { register, formState } = useFormContext<SellerContactPhoneNumberFormState>()

    return (
        <>
            <PHeading tag="h4" size="medium">
                {formatMessage(sellerContactPhoneMessages.usedCarsTitle)}
            </PHeading>

            <StyledInputWrapper>
                <PTextFieldWrapper
                    label={formatMessage(sellerContactPhoneMessages.phoneNumberLabel)}
                    message={formState.errors?.usedCarPhone ? formState.errors?.usedCarPhone?.message : undefined}
                    state={formState.errors?.usedCarPhone ? 'error' : 'none'}
                >
                    <input
                        placeholder={formatMessage(sellerContactPhoneMessages.phoneNumberPlaceholder)}
                        {...register(`usedCarPhone`, {
                            required: formatMessage(sellerContactPhoneMessages.phoneNumberRequired),
                        })}
                        type={'text'}
                    />
                </PTextFieldWrapper>
            </StyledInputWrapper>
        </>
    )
}

function NewCarPhone() {
    const { formatMessage } = useIntl()
    const { register, watch, formState } = useFormContext<SellerContactPhoneNumberFormState>()
    const showNewCarPhoneNumberInput = watch('newCarSameAsUsedCar') === UsedNewCarContact.Different
    const usedCarPhoneNumber = watch('usedCarPhone')

    return (
        <>
            <PHeading tag="h4" size="medium">
                {formatMessage(sellerContactPhoneMessages.newCarsTitle)}
            </PHeading>

            <StyledInputWrapper>
                <PSelectWrapper>
                    <select
                        {...register('newCarSameAsUsedCar')}
                        defaultValue={UsedNewCarContact.Same}
                        data-testid={'use-same-phone-select'}
                    >
                        <option value={UsedNewCarContact.Same}>
                            {formatMessage(sellerContactPhoneMessages.useSamePhoneNumberSelectOption)}
                        </option>
                        <option value={UsedNewCarContact.Different}>
                            {formatMessage(sellerContactPhoneMessages.useDifferentPhoneNumberSelectOption)}
                        </option>
                    </select>
                </PSelectWrapper>
            </StyledInputWrapper>

            {showNewCarPhoneNumberInput ? (
                <>
                    <StyledDivider />
                    <StyledInputWrapper>
                        <PTextFieldWrapper
                            label={formatMessage(sellerContactPhoneMessages.phoneNumberLabel)}
                            message={formState.errors?.newCarPhone ? formState.errors?.newCarPhone?.message : undefined}
                            state={formState.errors?.newCarPhone ? 'error' : 'none'}
                        >
                            <input
                                placeholder={formatMessage(sellerContactPhoneMessages.phoneNumberPlaceholder)}
                                {...register(`newCarPhone`, {
                                    required: formatMessage(sellerContactPhoneMessages.phoneNumberRequired),
                                })}
                                type={'text'}
                            />
                        </PTextFieldWrapper>
                    </StyledInputWrapper>
                </>
            ) : (
                usedCarPhoneNumber && <StyledUsedCarPhoneNumber>{usedCarPhoneNumber}</StyledUsedCarPhoneNumber>
            )}
        </>
    )
}
