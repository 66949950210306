/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ErrorResponse } from '../models/index'
import { ErrorResponseFromJSON, ErrorResponseToJSON } from '../models/index'

/**
 *
 */
export class FeaturesApi extends runtime.BaseAPI {
    /**
     */
    async getFeaturesRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<{ [key: string]: boolean }>> {
        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/features`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse<any>(response)
    }

    /**
     */
    async getFeatures(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: boolean }> {
        const response = await this.getFeaturesRaw(initOverrides)
        return await response.value()
    }
}
