import { defineMessages } from 'react-intl'

export const updateMerchantMessages = defineMessages({
    fetchError: {
        id: 'merchant.form.update.fetchError',
        defaultMessage: 'Dealers could not be loaded',
    },
    saveError: {
        id: 'merchant.form.update.saveError',
        defaultMessage: 'Saving changes failed',
    },
    fetchSuccess: {
        id: 'merchant.form.update.fetchSucces',
        defaultMessage: 'Dealer updated',
    },
})
