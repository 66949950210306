import { PText } from '@porsche-design-system/components-react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { IntlShape } from 'react-intl/src/types'
import { Flex } from '@slcheckout/ui-kit'

import { BoxLayout, PageHeader, PageLayout } from '@slmpserv/common-components-legacy'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { errorMessages } from '@slmpserv/common-translations'

import { ErrorRouteState, RouteErrors } from './ErrorRedirectBuilder'

export function Error() {
    const intl: IntlShape = useIntl()
    const formatMessage = intl.formatMessage
    const state = useLocation().state as ErrorRouteState

    const errorText = toErrorText(state, intl)

    return (
        <PageLayout
            header={
                <PageHeader
                    title={formatMessage(errorMessages.pageTitle)}
                    backButton={{
                        label: formatMessage(errorMessages.backToDashboard),
                        to: StaticRoutes.DASHBOARD,
                    }}
                />
            }
        >
            <BoxLayout>
                <Flex direction={'column'} alignItems={'center'}>
                    <PText>{errorText}</PText>
                </Flex>
            </BoxLayout>
        </PageLayout>
    )
}

function toErrorText(state: ErrorRouteState, intl: IntlShape) {
    const formatMessage = intl.formatMessage
    switch (state.error.type) {
        case RouteErrors.FEATURE_NOT_ENABLED:
            return formatMessage(errorMessages.featureNotEnabled)
        case RouteErrors.MERCHANT_NOT_FOUND:
            return formatMessage(errorMessages.merchantNotFound, {
                ppnId: state.error.ppnId,
            })
        case RouteErrors.INSUFFICIENT_ROLE:
            return formatMessage(errorMessages.insufficientRole)
        default:
            return formatMessage(errorMessages.fallback)
    }
}
