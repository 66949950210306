import { useMutation } from '@tanstack/react-query'

import { Imprint, merchantImprintApi } from '@slmpserv/common-api-merchant'

type UseSaveImprintsProps = {
    merchantId: string
}

export function useSaveImprints({ merchantId }: UseSaveImprintsProps) {
    const mutationResult = useMutation<Imprint[], Error, { imprints: Imprint[] }>({
        mutationFn: variables => merchantImprintApi.addUpdateImprints({ imprint: variables.imprints, merchantId }),
    })

    return { saveImprints: mutationResult.mutateAsync, mutationResult }
}
