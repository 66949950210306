import { useIntl } from 'react-intl'
import { themeLight } from '@porsche-design-system/components-react/styles'

import {
    dashboardPopoverMessages,
    dashboardPreviewMessages,
    dashboardStatusMessages,
    dashboardStatusMessageTooltip,
    dashboardTitles,
} from '@slmpserv/common-translations'
import { useMatomoTrackEditEvent } from '@slmpserv/common/analytics'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { AccessSensitiveProps } from '@slmpserv/self-service-shared'

import { DashboardGroupItem, DashboardWarningTag } from '../DashboardGroupItem/DashboardGroupItem'
import { DashboardGroupItemPreview } from '../DashboardGroupItemPreview/DashboardGroupItemPreview'

type PhoneItemProps = AccessSensitiveProps & {
    usedCarsPhoneNumber: string
    newCarsPhoneNumber: string
    phoneNumberMissing: boolean
}

export function PhoneItem({
    usedCarsPhoneNumber,
    newCarsPhoneNumber,
    phoneNumberMissing,
    isAllowedToEdit,
}: PhoneItemProps) {
    const { formatMessage } = useIntl()
    const { trackEvent } = useMatomoTrackEditEvent('online_order_phone_number', phoneNumberMissing)
    return (
        <DashboardGroupItem
            hightlightColor={phoneNumberMissing ? themeLight.notification.warning : undefined}
            title={formatMessage(dashboardTitles.inquiriesViaPhone)}
            tooltipText={formatMessage(dashboardPopoverMessages.inquiriesViaPhone)}
            preview={
                <>
                    <DashboardGroupItemPreview
                        title={formatMessage(dashboardPreviewMessages.phoneNumberUsedCar)}
                        text={usedCarsPhoneNumber || '-'}
                    />
                    <DashboardGroupItemPreview
                        title={formatMessage(dashboardPreviewMessages.phoneNumberNewCar)}
                        text={newCarsPhoneNumber || '-'}
                    />
                </>
            }
            badge={
                phoneNumberMissing && (
                    <DashboardWarningTag
                        tagText={formatMessage(dashboardStatusMessages.addContact)}
                        popoverText={formatMessage(dashboardStatusMessageTooltip.inquiriesViaPhone)}
                    />
                )
            }
            editLink={{
                isAllowedToEdit,
                props: {
                    to: StaticRoutes.SELLER_CONTACT_PHONE,
                },
                dataTestId: 'phone-edit-link',
                matomoEvent: trackEvent,
            }}
        />
    )
}
