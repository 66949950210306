/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { MarketplaceLegalDocumentType } from './MarketplaceLegalDocumentType'
import {
    MarketplaceLegalDocumentTypeFromJSON,
    MarketplaceLegalDocumentTypeFromJSONTyped,
    MarketplaceLegalDocumentTypeToJSON,
} from './MarketplaceLegalDocumentType'

/**
 *
 * @export
 * @interface MarketplaceLegalDocumentMeta
 */
export interface MarketplaceLegalDocumentMeta {
    /**
     *
     * @type {string}
     * @memberof MarketplaceLegalDocumentMeta
     */
    id: string
    /**
     *
     * @type {MarketplaceLegalDocumentType}
     * @memberof MarketplaceLegalDocumentMeta
     */
    type: MarketplaceLegalDocumentType
    /**
     *
     * @type {string}
     * @memberof MarketplaceLegalDocumentMeta
     */
    name: string
    /**
     *
     * @type {string}
     * @memberof MarketplaceLegalDocumentMeta
     */
    mimeType: string
    /**
     *
     * @type {string}
     * @memberof MarketplaceLegalDocumentMeta
     */
    marketplace: string
    /**
     *
     * @type {string}
     * @memberof MarketplaceLegalDocumentMeta
     */
    language: string
    /**
     *
     * @type {string}
     * @memberof MarketplaceLegalDocumentMeta
     */
    url: string
    /**
     *
     * @type {Date}
     * @memberof MarketplaceLegalDocumentMeta
     */
    createdAt: Date
    /**
     *
     * @type {string}
     * @memberof MarketplaceLegalDocumentMeta
     */
    checksum: string
}

/**
 * Check if a given object implements the MarketplaceLegalDocumentMeta interface.
 */
export function instanceOfMarketplaceLegalDocumentMeta(value: object): boolean {
    if (!('id' in value)) return false
    if (!('type' in value)) return false
    if (!('name' in value)) return false
    if (!('mimeType' in value)) return false
    if (!('marketplace' in value)) return false
    if (!('language' in value)) return false
    if (!('url' in value)) return false
    if (!('createdAt' in value)) return false
    if (!('checksum' in value)) return false
    return true
}

export function MarketplaceLegalDocumentMetaFromJSON(json: any): MarketplaceLegalDocumentMeta {
    return MarketplaceLegalDocumentMetaFromJSONTyped(json, false)
}

export function MarketplaceLegalDocumentMetaFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): MarketplaceLegalDocumentMeta {
    if (json == null) {
        return json
    }
    return {
        id: json['id'],
        type: MarketplaceLegalDocumentTypeFromJSON(json['type']),
        name: json['name'],
        mimeType: json['mimeType'],
        marketplace: json['marketplace'],
        language: json['language'],
        url: json['url'],
        createdAt: new Date(json['createdAt']),
        checksum: json['checksum'],
    }
}

export function MarketplaceLegalDocumentMetaToJSON(value?: MarketplaceLegalDocumentMeta | null): any {
    if (value == null) {
        return value
    }
    return {
        id: value['id'],
        type: MarketplaceLegalDocumentTypeToJSON(value['type']),
        name: value['name'],
        mimeType: value['mimeType'],
        marketplace: value['marketplace'],
        language: value['language'],
        url: value['url'],
        createdAt: value['createdAt'].toISOString(),
        checksum: value['checksum'],
    }
}
