/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface HostedOnboardingResponseRestModel
 */
export interface HostedOnboardingResponseRestModel {
    /**
     *
     * @type {string}
     * @memberof HostedOnboardingResponseRestModel
     */
    onboardingLink?: string
}

export function HostedOnboardingResponseRestModelFromJSON(json: any): HostedOnboardingResponseRestModel {
    return HostedOnboardingResponseRestModelFromJSONTyped(json, false)
}

export function HostedOnboardingResponseRestModelFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): HostedOnboardingResponseRestModel {
    if (json === undefined || json === null) {
        return json
    }
    return {
        onboardingLink: !exists(json, 'onboardingLink') ? undefined : json['onboardingLink'],
    }
}

export function HostedOnboardingResponseRestModelToJSON(value?: HostedOnboardingResponseRestModel | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        onboardingLink: value.onboardingLink,
    }
}
