/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { PaymentInformation } from './PaymentInformation'
import {
    PaymentInformationFromJSON,
    PaymentInformationFromJSONTyped,
    PaymentInformationToJSON,
} from './PaymentInformation'

/**
 *
 * @export
 * @interface MerchantResponse
 */
export interface MerchantResponse {
    /**
     *
     * @type {string}
     * @memberof MerchantResponse
     */
    id: string
    /**
     *
     * @type {PaymentInformation}
     * @memberof MerchantResponse
     */
    paymentInformation: PaymentInformation
    /**
     *
     * @type {string}
     * @memberof MerchantResponse
     */
    ppnId: string
    /**
     *
     * @type {string}
     * @memberof MerchantResponse
     */
    entryDn: string
    /**
     *
     * @type {string}
     * @memberof MerchantResponse
     */
    pagOid: string
    /**
     *
     * @type {string}
     * @memberof MerchantResponse
     */
    businessName: string
    /**
     *
     * @type {string}
     * @memberof MerchantResponse
     */
    legalBusinessName: string
    /**
     *
     * @type {string}
     * @memberof MerchantResponse
     */
    marketplaceKey: string
    /**
     *
     * @type {string}
     * @memberof MerchantResponse
     */
    debtorNumber?: string
    /**
     *
     * @type {boolean}
     * @memberof MerchantResponse
     */
    offersNewCars: boolean
    /**
     *
     * @type {boolean}
     * @memberof MerchantResponse
     */
    offersCsvCars: boolean
    /**
     *
     * @type {boolean}
     * @memberof MerchantResponse
     */
    reservationEnabled: boolean
}

/**
 * Check if a given object implements the MerchantResponse interface.
 */
export function instanceOfMerchantResponse(value: object): boolean {
    if (!('id' in value)) return false
    if (!('paymentInformation' in value)) return false
    if (!('ppnId' in value)) return false
    if (!('entryDn' in value)) return false
    if (!('pagOid' in value)) return false
    if (!('businessName' in value)) return false
    if (!('legalBusinessName' in value)) return false
    if (!('marketplaceKey' in value)) return false
    if (!('offersNewCars' in value)) return false
    if (!('offersCsvCars' in value)) return false
    if (!('reservationEnabled' in value)) return false
    return true
}

export function MerchantResponseFromJSON(json: any): MerchantResponse {
    return MerchantResponseFromJSONTyped(json, false)
}

export function MerchantResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantResponse {
    if (json == null) {
        return json
    }
    return {
        id: json['id'],
        paymentInformation: PaymentInformationFromJSON(json['paymentInformation']),
        ppnId: json['ppnId'],
        entryDn: json['entryDn'],
        pagOid: json['pagOid'],
        businessName: json['businessName'],
        legalBusinessName: json['legalBusinessName'],
        marketplaceKey: json['marketplaceKey'],
        debtorNumber: json['debtorNumber'] == null ? undefined : json['debtorNumber'],
        offersNewCars: json['offersNewCars'],
        offersCsvCars: json['offersCsvCars'],
        reservationEnabled: json['reservationEnabled'],
    }
}

export function MerchantResponseToJSON(value?: MerchantResponse | null): any {
    if (value == null) {
        return value
    }
    return {
        id: value['id'],
        paymentInformation: PaymentInformationToJSON(value['paymentInformation']),
        ppnId: value['ppnId'],
        entryDn: value['entryDn'],
        pagOid: value['pagOid'],
        businessName: value['businessName'],
        legalBusinessName: value['legalBusinessName'],
        marketplaceKey: value['marketplaceKey'],
        debtorNumber: value['debtorNumber'],
        offersNewCars: value['offersNewCars'],
        offersCsvCars: value['offersCsvCars'],
        reservationEnabled: value['reservationEnabled'],
    }
}
