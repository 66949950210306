import { useIntl } from 'react-intl'

import {
    useFetchKycAuthToken,
    useFetchMerchantOnlineOrderConfig,
    useIsAllowedToEditMerchant,
    useOnlineOrderingStatus,
} from '@slmpserv/common/api/use-query-wrapper'
import { MerchantResponse, MerchantStatus, MerchantStatusErrors } from '@slmpserv/common-api-merchant'
import { dashboardTitles } from '@slmpserv/common-translations'
import { useFetchKycEmails } from '@slmpserv/kyc-service-api'

import { DashboardGroup } from '../DashboardGroup/DashboardGroup'
import { DataCompletedTag, DataWriteAccessIsRestricted } from '../DashboardGroupItem/DashboardGroupItem'

import { ReservationFeeItem } from './ReservationFeeItem'
import { PaymentProcessingItem } from './PaymentProcessingItem'
import { PaymentProviderNotificationsItem } from './PaymentProviderNotificationsItem'
import { PayoutsNotificationItem } from './PayoutsNotificationItem'

type OnlineOrderGroupProps = {
    merchant: MerchantResponse
    merchantStatus: MerchantStatus
    showDownPaymentBox: boolean
    showPaymentServiceSettings: boolean
    showPayoutsNotificationSettings: boolean
    currency: string
}

export function OnlineOrderGroup({
    merchant,
    merchantStatus,
    showDownPaymentBox,
    showPaymentServiceSettings,
    showPayoutsNotificationSettings,
    currency,
}: OnlineOrderGroupProps) {
    const isAllowedToEditMerchant = useIsAllowedToEditMerchant(merchant.entryDn)

    const { onlineOrderingStatus } = useOnlineOrderingStatus({
        ppnId: merchant.ppnId,
    })
    const { onlineOrderConfig } = useFetchMerchantOnlineOrderConfig({
        ppnId: merchant.ppnId,
    })
    const { token: kycAuthToken } = useFetchKycAuthToken(merchant.id)
    const {
        kycEmails: { data },
    } = useFetchKycEmails({ ppnId: merchant.ppnId }, showPaymentServiceSettings)

    const allowKycEdit = Boolean(kycAuthToken)

    const { formatMessage } = useIntl()

    const reservationFeeMissing = merchantStatus.errors.includes(MerchantStatusErrors.RESERVATION_FEE)
    const paymentProcessingInWarning = merchantStatus.errors.includes(MerchantStatusErrors.PAYMENT_SERVICE)

    return (
        <DashboardGroup
            boxTitle={formatMessage(dashboardTitles.onlineOrderingGroup)}
            badge={
                <>
                    <DataCompletedTag isDataComplete={!merchantStatus.onlineOrderAndPaymentIncomplete} />
                    <DataWriteAccessIsRestricted hasAllWriteAccess={isAllowedToEditMerchant} />
                </>
            }
        >
            {showDownPaymentBox && (
                <ReservationFeeItem
                    configuredAmount={onlineOrderConfig?.downPayment.value}
                    currency={currency}
                    isInWarningState={reservationFeeMissing}
                    isAllowedToEdit={isAllowedToEditMerchant}
                />
            )}
            {showPaymentServiceSettings && (
                <>
                    <PaymentProcessingItem
                        merchant={merchant}
                        onlineOrderingStatus={onlineOrderingStatus}
                        isInWarningState={paymentProcessingInWarning}
                        isAllowedToEdit={allowKycEdit && isAllowedToEditMerchant}
                    />
                    <PaymentProviderNotificationsItem
                        contactEmailAddress={data?.statusEmails || []}
                        emailsMissing={!data?.statusEmails.length}
                        isAllowedToEdit={isAllowedToEditMerchant}
                    />
                </>
            )}
            {showPayoutsNotificationSettings && (
                <PayoutsNotificationItem
                    contactEmailAddress={onlineOrderConfig?.emailsForPayoutInformation}
                    isAllowedToEdit={isAllowedToEditMerchant}
                />
            )}
        </DashboardGroup>
    )
}
