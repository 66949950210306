import { PIcon, PText } from '@porsche-design-system/components-react'
import { spacingStatic } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'
import { styled } from 'styled-components'

import { baseApi, MerchantDocumentMetadata } from '@slmpserv/common-api-merchant'
import { documentFileUploadMessages } from '@slmpserv/common-translations'

import { openBlobInNewTab } from './openBlobInNewTab'

const StyledButton = styled.button`
    color: inherit;
    background-color: unset;
    text-decoration: underline;
    border: initial;
    padding: 0;
    word-break: break-word;
`

const StyledText = styled(PText)`
    margin-left: ${spacingStatic.large};
`

const handleShowUploadedFile = async (url: string, name: string) => {
    const blob = await baseApi.downloadFile(url)
    openBlobInNewTab(blob, name)
}

type FileStoredInBackendProps = {
    existingDocument: MerchantDocumentMetadata
    disabled?: boolean
}

export function FileStoredInBackend({ existingDocument, disabled }: FileStoredInBackendProps) {
    const { formatDate, formatMessage } = useIntl()

    const textColor = disabled ? 'neutral-contrast-medium' : 'neutral-contrast-high'

    return (
        <>
            <PText weight={'bold'} color={textColor}>
                <PIcon name={'document'} color={textColor} />
                <StyledButton
                    onClick={() => handleShowUploadedFile(existingDocument.url, existingDocument.name)}
                    disabled={disabled}
                >
                    {existingDocument.name}
                </StyledButton>
            </PText>
            <StyledText color={'neutral-contrast-medium'}>
                {formatMessage(documentFileUploadMessages.lastUpdatedOn, {
                    date: formatDate(existingDocument.updated),
                })}
            </StyledText>
        </>
    )
}
