/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface OnlineOrderConfigurationRequest
 */
export interface OnlineOrderConfigurationRequest {
    /**
     *
     * @type {number}
     * @memberof OnlineOrderConfigurationRequest
     */
    downPaymentValue: number
    /**
     *
     * @type {Array<string>}
     * @memberof OnlineOrderConfigurationRequest
     */
    emailsForOrderConfirmation: Array<string>
    /**
     *
     * @type {boolean}
     * @memberof OnlineOrderConfigurationRequest
     */
    downPaymentActive: boolean
    /**
     *
     * @type {boolean}
     * @memberof OnlineOrderConfigurationRequest
     */
    differentEmailCsvCars?: boolean
    /**
     *
     * @type {Array<string>}
     * @memberof OnlineOrderConfigurationRequest
     */
    emailsForCsvOrderConfirmation?: Array<string>
}

/**
 * Check if a given object implements the OnlineOrderConfigurationRequest interface.
 */
export function instanceOfOnlineOrderConfigurationRequest(value: object): boolean {
    if (!('downPaymentValue' in value)) return false
    if (!('emailsForOrderConfirmation' in value)) return false
    if (!('downPaymentActive' in value)) return false
    return true
}

export function OnlineOrderConfigurationRequestFromJSON(json: any): OnlineOrderConfigurationRequest {
    return OnlineOrderConfigurationRequestFromJSONTyped(json, false)
}

export function OnlineOrderConfigurationRequestFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): OnlineOrderConfigurationRequest {
    if (json == null) {
        return json
    }
    return {
        downPaymentValue: json['downPaymentValue'],
        emailsForOrderConfirmation: json['emailsForOrderConfirmation'],
        downPaymentActive: json['downPaymentActive'],
        differentEmailCsvCars: json['differentEmailCsvCars'] == null ? undefined : json['differentEmailCsvCars'],
        emailsForCsvOrderConfirmation:
            json['emailsForCsvOrderConfirmation'] == null ? undefined : json['emailsForCsvOrderConfirmation'],
    }
}

export function OnlineOrderConfigurationRequestToJSON(value?: OnlineOrderConfigurationRequest | null): any {
    if (value == null) {
        return value
    }
    return {
        downPaymentValue: value['downPaymentValue'],
        emailsForOrderConfirmation: value['emailsForOrderConfirmation'],
        downPaymentActive: value['downPaymentActive'],
        differentEmailCsvCars: value['differentEmailCsvCars'],
        emailsForCsvOrderConfirmation: value['emailsForCsvOrderConfirmation'],
    }
}
