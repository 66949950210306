/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { DownPaymentConfigurationStatus } from './DownPaymentConfigurationStatus'
import {
    DownPaymentConfigurationStatusFromJSON,
    DownPaymentConfigurationStatusFromJSONTyped,
    DownPaymentConfigurationStatusToJSON,
} from './DownPaymentConfigurationStatus'
import type { PaymentStatusResponse } from './PaymentStatusResponse'
import {
    PaymentStatusResponseFromJSON,
    PaymentStatusResponseFromJSONTyped,
    PaymentStatusResponseToJSON,
} from './PaymentStatusResponse'

/**
 *
 * @export
 * @interface OnlineOrderingStatusDto
 */
export interface OnlineOrderingStatusDto {
    /**
     *
     * @type {boolean}
     * @memberof OnlineOrderingStatusDto
     */
    readyForOnlineOrdering: boolean
    /**
     *
     * @type {boolean}
     * @memberof OnlineOrderingStatusDto
     */
    readyForOnlineReservation?: boolean
    /**
     *
     * @type {boolean}
     * @memberof OnlineOrderingStatusDto
     */
    onlineOrderingConfigurationAvailable: boolean
    /**
     *
     * @type {boolean}
     * @memberof OnlineOrderingStatusDto
     */
    onlineOrderingContactValid: boolean
    /**
     *
     * @type {DownPaymentConfigurationStatus}
     * @memberof OnlineOrderingStatusDto
     */
    downPaymentConfigurationStatus: DownPaymentConfigurationStatus
    /**
     *
     * @type {Date}
     * @memberof OnlineOrderingStatusDto
     */
    inactivationDate?: Date
    /**
     *
     * @type {Date}
     * @memberof OnlineOrderingStatusDto
     */
    suspensionDate?: Date
    /**
     *
     * @type {PaymentStatusResponse}
     * @memberof OnlineOrderingStatusDto
     */
    paymentStatus: PaymentStatusResponse
    /**
     *
     * @type {Array<string>}
     * @memberof OnlineOrderingStatusDto
     */
    missingImprints: Array<string>
}

/**
 * Check if a given object implements the OnlineOrderingStatusDto interface.
 */
export function instanceOfOnlineOrderingStatusDto(value: object): boolean {
    if (!('readyForOnlineOrdering' in value)) return false
    if (!('onlineOrderingConfigurationAvailable' in value)) return false
    if (!('onlineOrderingContactValid' in value)) return false
    if (!('downPaymentConfigurationStatus' in value)) return false
    if (!('paymentStatus' in value)) return false
    if (!('missingImprints' in value)) return false
    return true
}

export function OnlineOrderingStatusDtoFromJSON(json: any): OnlineOrderingStatusDto {
    return OnlineOrderingStatusDtoFromJSONTyped(json, false)
}

export function OnlineOrderingStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnlineOrderingStatusDto {
    if (json == null) {
        return json
    }
    return {
        readyForOnlineOrdering: json['readyForOnlineOrdering'],
        readyForOnlineReservation:
            json['readyForOnlineReservation'] == null ? undefined : json['readyForOnlineReservation'],
        onlineOrderingConfigurationAvailable: json['onlineOrderingConfigurationAvailable'],
        onlineOrderingContactValid: json['onlineOrderingContactValid'],
        downPaymentConfigurationStatus: DownPaymentConfigurationStatusFromJSON(json['downPaymentConfigurationStatus']),
        inactivationDate: json['inactivationDate'] == null ? undefined : new Date(json['inactivationDate']),
        suspensionDate: json['suspensionDate'] == null ? undefined : new Date(json['suspensionDate']),
        paymentStatus: PaymentStatusResponseFromJSON(json['paymentStatus']),
        missingImprints: json['missingImprints'],
    }
}

export function OnlineOrderingStatusDtoToJSON(value?: OnlineOrderingStatusDto | null): any {
    if (value == null) {
        return value
    }
    return {
        readyForOnlineOrdering: value['readyForOnlineOrdering'],
        readyForOnlineReservation: value['readyForOnlineReservation'],
        onlineOrderingConfigurationAvailable: value['onlineOrderingConfigurationAvailable'],
        onlineOrderingContactValid: value['onlineOrderingContactValid'],
        downPaymentConfigurationStatus: DownPaymentConfigurationStatusToJSON(value['downPaymentConfigurationStatus']),
        inactivationDate: value['inactivationDate'] == null ? undefined : value['inactivationDate'].toISOString(),
        suspensionDate: value['suspensionDate'] == null ? undefined : value['suspensionDate'].toISOString(),
        paymentStatus: PaymentStatusResponseToJSON(value['paymentStatus']),
        missingImprints: value['missingImprints'],
    }
}
