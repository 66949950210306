/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PaymentSecurityTokenResponse
 */
export interface PaymentSecurityTokenResponse {
    /**
     *
     * @type {string}
     * @memberof PaymentSecurityTokenResponse
     */
    tokenValue?: string
}

/**
 * Check if a given object implements the PaymentSecurityTokenResponse interface.
 */
export function instanceOfPaymentSecurityTokenResponse(value: object): boolean {
    return true
}

export function PaymentSecurityTokenResponseFromJSON(json: any): PaymentSecurityTokenResponse {
    return PaymentSecurityTokenResponseFromJSONTyped(json, false)
}

export function PaymentSecurityTokenResponseFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): PaymentSecurityTokenResponse {
    if (json == null) {
        return json
    }
    return {
        tokenValue: json['tokenValue'] == null ? undefined : json['tokenValue'],
    }
}

export function PaymentSecurityTokenResponseToJSON(value?: PaymentSecurityTokenResponse | null): any {
    if (value == null) {
        return value
    }
    return {
        tokenValue: value['tokenValue'],
    }
}
