import { defineMessages } from 'react-intl'

export const matomoMessages = defineMessages({
    dataUsage: {
        id: 'dealer.matomo.dataUsage',
        defaultMessage: 'Data Usage',
    },
    optOutLabel: {
        id: 'dealer.matomo.optOut.label',
        defaultMessage: 'Data Tracking',
    },
    optOutDescription: {
        id: 'dealer.matomo.optOut.description',
        defaultMessage:
            'We want to collect and analyze visitor data to improve your experience. We do not track any personal data.',
    },
})
