import { css } from 'styled-components'
import { themeLight } from '@porsche-design-system/components-react/styles'

export const FullScreenCss = css`
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: ${themeLight.background.base};
    opacity: 0.9;
`
