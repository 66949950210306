import { useIntl } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import { PHeading, PLinkPure, PText, useToastManager } from '@porsche-design-system/components-react'
import { spacingStatic } from '@porsche-design-system/components-react/styles'
import { useMemo } from 'react'
import { Flex, FlexItem } from '@slcheckout/ui-kit'

import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { BoxLayout, FormButtons, PageLayout } from '@slmpserv/common-components-legacy'
import { MultipleFormSubmitProvider, useMarketplaceContext, useMultipleFormSubmits } from '@slmpserv/common-contexts'
import { createMerchantMessages, routesMessages, updateMerchantMessages } from '@slmpserv/common-translations'
import { LanguageTabs } from '@slmpserv/common-components'
import {
    CsvCarSection,
    DocumentsForm,
    NewCarSection,
    PrivacyPolicySection,
    UsedCarSection,
    ReservationSection,
} from '@slmpserv/common/document-management'
import { useSyncOnlineOrderingStatus } from '@slmpserv/common/api/use-query-wrapper'
import { MerchantResponse } from '@slmpserv/common-api-merchant'

/**
 * This is a collection of New-, Used- and CSV-Car documents as well as financial products
 * to be used to the DocumentManagementPage component.
 */
export function TermsAndConditionsDocuments() {
    const { submitAll, isSubmitting } = useMultipleFormSubmits()
    const navigate = useNavigate()
    const { locales, key, checkoutEnabled, csvEnabled, reservationEnabled } = useMarketplaceContext()
    const languages = useMemo(() => locales.map(locale => locale.substring(0, 2)), [locales])

    const handleCancel = () => {
        navigate(StaticRoutes.DASHBOARD)
    }

    return (
        <>
            <DocumentsForm marketplaceKey={key} languages={languages}>
                <LanguageTabs languages={languages}>
                    {language => (
                        <>
                            {checkoutEnabled ? <NewCarSection language={language} /> : null}
                            {checkoutEnabled ? <UsedCarSection language={language} /> : null}
                            {csvEnabled ? <CsvCarSection language={language} /> : null}
                            {reservationEnabled ? <ReservationSection language={language} /> : null}
                        </>
                    )}
                </LanguageTabs>
            </DocumentsForm>
            <FormButtons isLoading={isSubmitting} onClick={submitAll} onCancel={handleCancel} />
        </>
    )
}

/**
 * To be used to the DocumentManagementPage component.
 */
export function PrivacyPolicyDocuments() {
    const { submitAll, isSubmitting } = useMultipleFormSubmits()
    const navigate = useNavigate()
    const { locales, key } = useMarketplaceContext()
    const languages = useMemo(() => locales.map(locale => locale.substring(0, 2)), [locales])

    const handleCancel = () => {
        navigate(StaticRoutes.DASHBOARD)
    }

    return (
        <>
            <DocumentsForm marketplaceKey={key} languages={languages}>
                <LanguageTabs languages={languages}>
                    {language => <PrivacyPolicySection language={language} />}
                </LanguageTabs>
            </DocumentsForm>
            <FormButtons isLoading={isSubmitting} onClick={submitAll} onCancel={handleCancel} />
        </>
    )
}

type DocumentManagementPageProps = {
    title: string
    description: string
    existingMerchant?: MerchantResponse
    children: ReturnType<typeof PrivacyPolicyDocuments> | ReturnType<typeof TermsAndConditionsDocuments>
}

export function DocumentManagementPage({
    title,
    description,
    existingMerchant,
    children,
}: DocumentManagementPageProps) {
    const { formatMessage } = useIntl()
    const syncOnlineOrderingStatus = useSyncOnlineOrderingStatus(existingMerchant?.id)
    const { addMessage } = useToastManager()

    return (
        <PageLayout
            header={
                <>
                    <Link to={StaticRoutes.DASHBOARD}>
                        <PLinkPure icon={'arrow-head-left'} style={{ marginBottom: spacingStatic.large }}>
                            {formatMessage(routesMessages.backToDashboard)}
                        </PLinkPure>
                    </Link>
                    <Flex direction="column">
                        <FlexItem>
                            <PHeading tag="h3" size="large">
                                {title}
                            </PHeading>
                        </FlexItem>
                        <FlexItem>
                            <PText color={'neutral-contrast-medium'} style={{ whiteSpace: 'break-spaces' }}>
                                {description}
                            </PText>
                        </FlexItem>
                    </Flex>
                </>
            }
        >
            <BoxLayout>
                <MultipleFormSubmitProvider
                    onError={() =>
                        addMessage({ state: 'neutral', text: formatMessage(updateMerchantMessages.saveError) })
                    }
                    onSuccess={async () => {
                        if (existingMerchant) {
                            await syncOnlineOrderingStatus()
                        }
                        addMessage({ state: 'success', text: formatMessage(createMerchantMessages.saveSuccess) })
                    }}
                >
                    {children}
                </MultipleFormSubmitProvider>
            </BoxLayout>
        </PageLayout>
    )
}
