import { defineMessages, MessageDescriptor } from 'react-intl'

import { LegalDocumentType } from '@slmpserv/common-api-merchant'

export const legalNoticeSectionMessages = defineMessages({
    sectionTitle: {
        id: 'merchant.form.legalNoticeSection.title',
        defaultMessage: 'Terms & Conditions',
    },
    newCar: {
        id: 'merchant.form.legalNoticeSection.tnc.newCar.label',
        defaultMessage: 'New car TnC documents',
    },
    newCarBev: {
        id: 'merchant.form.legalNoticeSection.tnc.newCarBev.label',
        defaultMessage: 'T&Cs for New Cars (BEV)',
    },
    newCarPhev: {
        id: 'merchant.form.legalNoticeSection.tnc.newCarPhev.label',
        defaultMessage: 'T&Cs for New Cars (PHEV)',
    },
    usedWithWarranty: {
        id: 'merchant.form.legalNoticeSection.tnc.usedWithWarranty.label',
        defaultMessage: 'T&Cs for Used Cars (with PAW)',
    },
    usedWithoutWarranty: {
        id: 'merchant.form.legalNoticeSection.tnc.usedWithoutWarranty.label',
        defaultMessage: 'T&Cs for Used Cars (without PAW)',
    },
    usedBevWithoutWarranty: {
        id: 'merchant.form.legalNoticeSection.tnc.usedBevWithoutWarranty.label',
        defaultMessage: 'T&Cs for Used Cars (BEV without PAW)',
    },
    usedBevWithWarranty: {
        id: 'merchant.form.legalNoticeSection.tnc.usedBevWithWarranty.label',
        defaultMessage: 'T&Cs for Used Cars (BEV with PAW)',
    },
    dataProtectionExplanationTitle: {
        id: 'merchant.form.dataProtectionExplanation.title',
        defaultMessage: 'Privacy Policy',
    },
    imprintTitle: {
        id: 'merchant.form.imprint.title',
        defaultMessage: 'Imprint',
    },
    imprintLabel: {
        id: 'merchant.form.imprint.label',
        defaultMessage: 'Imprint ({market})',
    },
    legalNoticeTitle: {
        id: 'merchant.form.legalNotice.title',
        defaultMessage: 'Legal Notice',
    },
    documentMarketLabel: {
        id: 'merchant.form.documentMarketLabel',
        defaultMessage: '{documentLabel} ({market})',
    },
})

export const LabelForLegalDocumentType: Record<LegalDocumentType | 'IMPRINT', MessageDescriptor> = {
    NEW_CAR_BEV_CONDITIONS: legalNoticeSectionMessages.newCarBev,
    NEW_CAR_CONDITIONS: legalNoticeSectionMessages.newCar,
    NEW_CAR_PHEV_CONDITIONS: legalNoticeSectionMessages.newCarPhev,
    PRIVACY_POLICY: legalNoticeSectionMessages.dataProtectionExplanationTitle,
    USED_CAR_BEV_CONDITIONS_WITHOUT_WARRANTY: legalNoticeSectionMessages.usedBevWithoutWarranty,
    USED_CAR_BEV_CONDITIONS_WITH_WARRANTY: legalNoticeSectionMessages.usedBevWithWarranty,
    USED_CAR_CONDITIONS_WITHOUT_WARRANTY: legalNoticeSectionMessages.usedWithoutWarranty,
    USED_CAR_CONDITIONS_WITH_WARRANTY: legalNoticeSectionMessages.usedWithWarranty,
    IMPRINT: legalNoticeSectionMessages.imprintTitle,
}
