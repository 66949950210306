/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { DocumentType } from './DocumentType'
import { DocumentTypeFromJSON, DocumentTypeFromJSONTyped, DocumentTypeToJSON } from './DocumentType'
import type { PaymentStatusResponse } from './PaymentStatusResponse'
import {
    PaymentStatusResponseFromJSON,
    PaymentStatusResponseFromJSONTyped,
    PaymentStatusResponseToJSON,
} from './PaymentStatusResponse'

/**
 *
 * @export
 * @interface MerchantStatusData
 */
export interface MerchantStatusData {
    /**
     *
     * @type {{ [key: string]: Array<DocumentType>; }}
     * @memberof MerchantStatusData
     */
    missingTncDocuments: { [key: string]: Array<DocumentType> }
    /**
     *
     * @type {{ [key: string]: Array<DocumentType>; }}
     * @memberof MerchantStatusData
     */
    missingReservationDocuments?: { [key: string]: Array<DocumentType> }
    /**
     *
     * @type {Array<string>}
     * @memberof MerchantStatusData
     */
    missingPrivacyPolicies: Array<string>
    /**
     *
     * @type {Array<string>}
     * @memberof MerchantStatusData
     */
    missingImprints: Array<string>
    /**
     *
     * @type {PaymentStatusResponse}
     * @memberof MerchantStatusData
     */
    paymentStatus: PaymentStatusResponse
}

/**
 * Check if a given object implements the MerchantStatusData interface.
 */
export function instanceOfMerchantStatusData(value: object): boolean {
    if (!('missingTncDocuments' in value)) return false
    if (!('missingPrivacyPolicies' in value)) return false
    if (!('missingImprints' in value)) return false
    if (!('paymentStatus' in value)) return false
    return true
}

export function MerchantStatusDataFromJSON(json: any): MerchantStatusData {
    return MerchantStatusDataFromJSONTyped(json, false)
}

export function MerchantStatusDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantStatusData {
    if (json == null) {
        return json
    }
    return {
        missingTncDocuments: json['missingTncDocuments'],
        missingReservationDocuments:
            json['missingReservationDocuments'] == null ? undefined : json['missingReservationDocuments'],
        missingPrivacyPolicies: json['missingPrivacyPolicies'],
        missingImprints: json['missingImprints'],
        paymentStatus: PaymentStatusResponseFromJSON(json['paymentStatus']),
    }
}

export function MerchantStatusDataToJSON(value?: MerchantStatusData | null): any {
    if (value == null) {
        return value
    }
    return {
        missingTncDocuments: value['missingTncDocuments'],
        missingReservationDocuments: value['missingReservationDocuments'],
        missingPrivacyPolicies: value['missingPrivacyPolicies'],
        missingImprints: value['missingImprints'],
        paymentStatus: PaymentStatusResponseToJSON(value['paymentStatus']),
    }
}
