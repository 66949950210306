import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import { useToastManager } from '@porsche-design-system/components-react'

import { sellerContactApi, SellerContactPhone } from '@slmpserv/common-api-merchant'
import { useFetchSellerContactPhoneNumber } from '@slmpserv/common/api/use-query-wrapper'

import { onlineOrderConfigurationMessages } from '../OnlineOrderConfiguration.messages'
import { UsedNewCarContact } from '../types'

export type SellerContactPhoneNumberFormState = {
    newCarPhone: string
    usedCarPhone: string
    newCarSameAsUsedCar: UsedNewCarContact
}

export function useSellerContactPhoneNumberForm(entryDn: string) {
    const { addMessage } = useToastManager()
    const { formatMessage } = useIntl()

    const methods = useForm<SellerContactPhoneNumberFormState>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: { newCarPhone: '', usedCarPhone: '' },
    })

    const initForm = useCallback(
        (data: SellerContactPhone) => {
            methods.reset({
                newCarPhone: data.phoneNumberNew,
                usedCarPhone: data.phoneNumberPreowned,
                newCarSameAsUsedCar: data.differNewPreowned ? UsedNewCarContact.Different : UsedNewCarContact.Same,
            })
        },
        [methods]
    )

    const { queryResult: fetchSellerContactQueryResult } = useFetchSellerContactPhoneNumber({
        entryDn,
        queryOptions: {
            refetchOnWindowFocus: false,
        },
        onSuccess: initForm,
    })

    const savePhoneNumberQueryResult = useMutation({
        mutationFn: (formValues: SellerContactPhoneNumberFormState) => {
            const phoneNumberNew = formValues.newCarPhone
            const phoneNumberPreowned = formValues.usedCarPhone
            const differNewPreowned = formValues.newCarSameAsUsedCar === UsedNewCarContact.Different

            const sellerContactPhone = {
                entryDn,
                phoneNumberNew: differNewPreowned ? phoneNumberNew : phoneNumberPreowned,
                phoneNumberPreowned,
                differNewPreowned,
            }

            return sellerContactApi.createOrUpdatePhone({
                sellerContactPhone,
            })
        },
    })

    const submitCallback = async (data: SellerContactPhoneNumberFormState): Promise<void> => {
        try {
            await savePhoneNumberQueryResult.mutateAsync(data)
            addMessage({ state: 'success', text: formatMessage(onlineOrderConfigurationMessages.toastSubmitSuccess) })
        } catch (err) {
            console.error(err)
            addMessage({ state: 'neutral', text: formatMessage(onlineOrderConfigurationMessages.toastSubmitError) })
        }
    }

    return {
        methods,
        handleSubmit: methods.handleSubmit(submitCallback),
        fetchSellerContactQueryResult: fetchSellerContactQueryResult,
    }
}
