import { PIcon, PText } from '@porsche-design-system/components-react'
import { spacingStatic } from '@porsche-design-system/components-react/styles'
import { useFormContext } from 'react-hook-form'
import { RefObject } from 'react'
import { useIntl } from 'react-intl'
import { styled } from 'styled-components'

import { documentFileUploadMessages } from '@slmpserv/common-translations'
import { DocumentType } from '@slmpserv/common-api-merchant'

import { openBlobInNewTab } from './openBlobInNewTab'

const StyledText = styled(PText)`
    margin-left: ${spacingStatic.large};
`

const StyledButton = styled.button`
    color: inherit;
    background-color: unset;
    text-decoration: underline;
    border: initial;
    padding: 0;
    word-break: break-word;
`

type FileJustSelectedProps = {
    language: string
    documentType: DocumentType
    inputRef: RefObject<HTMLInputElement>
    disabled?: boolean
}

export function FileJustSelected({ language, documentType, inputRef, disabled }: FileJustSelectedProps) {
    const { formatMessage } = useIntl()
    const { getValues, setValue } = useFormContext()

    const fieldPath = `documents.${language}.files.${documentType}`
    const file = (getValues(fieldPath) as FileList)[0]

    const resetFileSelection = () => {
        setValue(`documents.${language}.files.${documentType}`, undefined)
        if (inputRef.current) {
            inputRef.current.value = ''
        }
    }

    const textColor = disabled ? 'neutral-contrast-low' : 'default'

    return (
        <>
            <PText weight={'bold'} color={textColor}>
                <PIcon name={'document'} color={textColor} />
                <StyledButton onClick={() => openBlobInNewTab(file, file.name)} disabled={disabled}>
                    {file.name}
                </StyledButton>
            </PText>
            <StyledText color={textColor}>
                {formatMessage(documentFileUploadMessages.lastUpdatedRightNow)}&nbsp;
                <StyledButton onClick={resetFileSelection} disabled={disabled}>
                    {formatMessage(documentFileUploadMessages.cancelFileUpload)}
                </StyledButton>
            </StyledText>
        </>
    )
}
