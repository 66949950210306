/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    VerificationImageForIndividual,
    VerificationImageForIndividualFromJSON,
    VerificationImageForIndividualFromJSONTyped,
    VerificationImageForIndividualToJSON,
} from './'

/**
 *
 * @export
 * @interface SignatoryWithMultipleVerificationImages
 */
export interface SignatoryWithMultipleVerificationImages {
    /**
     * This value is returned on GET request and has to be provided to update/delete an existing signatory. It cannot be defined when creating a new one.
     * @type {string}
     * @memberof SignatoryWithMultipleVerificationImages
     */
    signatoryCode?: string
    /**
     *
     * @type {string}
     * @memberof SignatoryWithMultipleVerificationImages
     */
    firstName: string
    /**
     *
     * @type {string}
     * @memberof SignatoryWithMultipleVerificationImages
     */
    lastName: string
    /**
     *
     * @type {string}
     * @memberof SignatoryWithMultipleVerificationImages
     */
    email: string
    /**
     * Full phone number with country prefix
     * @type {string}
     * @memberof SignatoryWithMultipleVerificationImages
     */
    phoneNumber?: string
    /**
     *
     * @type {Date}
     * @memberof SignatoryWithMultipleVerificationImages
     */
    birthDate: Date
    /**
     * Nationality of signatory, if signatory resides in Hong Kong or Singapore
     * @type {string}
     * @memberof SignatoryWithMultipleVerificationImages
     */
    nationality?: string
    /**
     *
     * @type {string}
     * @memberof SignatoryWithMultipleVerificationImages
     */
    documentStatus?: SignatoryWithMultipleVerificationImagesDocumentStatusEnum
    /**
     *
     * @type {Address}
     * @memberof SignatoryWithMultipleVerificationImages
     */
    address: Address
    /**
     *
     * @type {Array<VerificationImageForIndividual>}
     * @memberof SignatoryWithMultipleVerificationImages
     */
    images?: Array<VerificationImageForIndividual>
}

/**
 * @export
 * @enum {string}
 */
export enum SignatoryWithMultipleVerificationImagesDocumentStatusEnum {
    Uploaded = 'UPLOADED',
    NotUploaded = 'NOT_UPLOADED',
    Invalid = 'INVALID',
}

export function SignatoryWithMultipleVerificationImagesFromJSON(json: any): SignatoryWithMultipleVerificationImages {
    return SignatoryWithMultipleVerificationImagesFromJSONTyped(json, false)
}

export function SignatoryWithMultipleVerificationImagesFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): SignatoryWithMultipleVerificationImages {
    if (json === undefined || json === null) {
        return json
    }
    return {
        signatoryCode: !exists(json, 'signatoryCode') ? undefined : json['signatoryCode'],
        firstName: json['firstName'],
        lastName: json['lastName'],
        email: json['email'],
        phoneNumber: !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        birthDate: new Date(json['birthDate']),
        nationality: !exists(json, 'nationality') ? undefined : json['nationality'],
        documentStatus: !exists(json, 'documentStatus') ? undefined : json['documentStatus'],
        address: AddressFromJSON(json['address']),
        images: !exists(json, 'images')
            ? undefined
            : (json['images'] as Array<any>).map(VerificationImageForIndividualFromJSON),
    }
}

export function SignatoryWithMultipleVerificationImagesToJSON(
    value?: SignatoryWithMultipleVerificationImages | null
): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        signatoryCode: value.signatoryCode,
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        phoneNumber: value.phoneNumber,
        birthDate: value.birthDate.toISOString().substr(0, 10),
        nationality: value.nationality,
        documentStatus: value.documentStatus,
        address: AddressToJSON(value.address),
        images:
            value.images === undefined
                ? undefined
                : (value.images as Array<any>).map(VerificationImageForIndividualToJSON),
    }
}
