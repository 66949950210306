import { useIntl } from 'react-intl'
import { Controller, useFormContext } from 'react-hook-form'
import { PCheckboxWrapper } from '@porsche-design-system/components-react'

import { DocumentType } from '@slmpserv/common-api-merchant'
import { descriptionMessages, documentManagementMessages } from '@slmpserv/common-translations'
import { CompletenessBadge, FormGroup, SpacedDivider } from '@slmpserv/common-components'

import { useDocumentProgress } from '../hooks/useDocumentProgress'
import { DocumentSelection } from '../components/DocumentSelection/DocumentSelection'
import { useFilterDisabledDocumentTypes } from '../hooks/useFilterDisabledDocumentTypes'
import { DocumentsFormState } from '../DocumentsForm/DocumentsFormTypesAndConsts'

import { StyledHeading, StyledText } from './shared.styles'

type CsvCarSectionProps = {
    language: string
}

export function CsvCarSection({ language }: CsvCarSectionProps) {
    const { formatMessage } = useIntl()
    const { watch, control } = useFormContext<DocumentsFormState>()
    const hasOffer = watch('offersCsvCars')

    const documentTypes = useFilterDisabledDocumentTypes([
        DocumentType.CSV_CAR_GENERAL,
        DocumentType.CSV_CAR_BEV,
        DocumentType.CSV_CAR_PHEV,
    ])
    const requiredDocuments = hasOffer ? documentTypes : []
    const { selectedDocumentCount, complete, total } = useDocumentProgress(language, requiredDocuments)

    return (
        <>
            <SpacedDivider />
            <FormGroup
                label={formatMessage(documentManagementMessages.csvCarsSection)}
                completedBadge={<CompletenessBadge done={selectedDocumentCount} total={total} complete={complete} />}
                labelColumnWidth={1}
                contentColumnWidth={4}
            >
                <PCheckboxWrapper label={formatMessage(documentManagementMessages.csvCarsOfferExists)}>
                    <Controller
                        control={control}
                        name={'offersCsvCars'}
                        render={({ field: { name, onChange, onBlur, value } }) => (
                            <input type={'checkbox'} name={name} onBlur={onBlur} onChange={onChange} checked={value} />
                        )}
                    />
                </PCheckboxWrapper>
                <SpacedDivider />

                <StyledHeading tag="h4" size="large">
                    {formatMessage(descriptionMessages.csvCarHeader)}
                </StyledHeading>
                <StyledText color={'neutral-contrast-high'}>
                    {formatMessage(descriptionMessages.csvCarDetail)}
                </StyledText>

                <DocumentSelection
                    documentType={DocumentType.CSV_CAR_GENERAL}
                    disabled={!hasOffer}
                    language={language}
                />

                <StyledHeading tag="h4" size="large">
                    {formatMessage(descriptionMessages.csvCarAdditionalDocumentsHeader)}
                </StyledHeading>
                <StyledText color={'neutral-contrast-high'}>
                    {formatMessage(descriptionMessages.csvCarAdditionalDocumentsDetail)}
                </StyledText>

                <DocumentSelection documentType={DocumentType.CSV_CAR_BEV} disabled={!hasOffer} language={language} />
                <DocumentSelection documentType={DocumentType.CSV_CAR_PHEV} disabled={!hasOffer} language={language} />
            </FormGroup>
        </>
    )
}
