import { Navigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { ReactNode } from 'react'

import { LoadingBoundary } from '@slmpserv/common-components-legacy'
import { useCurrentDealerContext } from '@slmpserv/self-service-current-dealer-context'
import { ApiError, isInsufficientRole, merchantsApi } from '@slmpserv/common-api-merchant'
import { useFetchMerchant } from '@slmpserv/common/api/use-query-wrapper'

import { ErrorRedirectBuilder } from '../../pages/Error/ErrorRedirectBuilder'

type CentralizedOnboardingRoutesProps = {
    dashboardRoutes: ReactNode
}

function CentralizedOnboardingRoutes({ dashboardRoutes }: CentralizedOnboardingRoutesProps) {
    const { partnerNumber } = useCurrentDealerContext()
    const {
        queryResult: { isLoading: isLoadingMerchant, isError: isErrorMerchant, error: errorMerchant },
    } = useFetchMerchant({ ppnId: partnerNumber })

    if (isLoadingMerchant) {
        return <LoadingBoundary isLoading={true} />
    }

    if (!isErrorMerchant) {
        return dashboardRoutes
    }

    if (isInsufficientRole(errorMerchant as unknown as ApiError)) {
        return <Navigate {...ErrorRedirectBuilder.insufficientRole()} />
    } else {
        return <Navigate {...ErrorRedirectBuilder.unknownError()} />
    }
}

type RouteSelectorProps = {
    sellerRoutes: ReactNode
    dashboardRoutes: ReactNode
}

export function RouteSelector({ sellerRoutes, dashboardRoutes }: RouteSelectorProps) {
    const { partnerNumber } = useCurrentDealerContext()
    const { data, isLoading } = useQuery({
        queryKey: ['isSellerOnly', partnerNumber],
        queryFn: () => merchantsApi.getIsSellerOnly({ ppnId: partnerNumber }),
    })

    if (isLoading) {
        return <LoadingBoundary isLoading={isLoading} />
    }
    // TODO remove after DiscoBoys move Inquiries out of merchant app
    if (data?.isSellerOnly) {
        return sellerRoutes
    }

    return <CentralizedOnboardingRoutes dashboardRoutes={dashboardRoutes} />
}
