/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { MerchantStatusData } from './MerchantStatusData'
import {
    MerchantStatusDataFromJSON,
    MerchantStatusDataFromJSONTyped,
    MerchantStatusDataToJSON,
} from './MerchantStatusData'
import type { MerchantStatusErrors } from './MerchantStatusErrors'
import {
    MerchantStatusErrorsFromJSON,
    MerchantStatusErrorsFromJSONTyped,
    MerchantStatusErrorsToJSON,
} from './MerchantStatusErrors'

/**
 *
 * @export
 * @interface MerchantStatus
 */
export interface MerchantStatus {
    /**
     *
     * @type {boolean}
     * @memberof MerchantStatus
     */
    dealershipContactIncomplete: boolean
    /**
     *
     * @type {boolean}
     * @memberof MerchantStatus
     */
    onlineOrderAndPaymentIncomplete: boolean
    /**
     *
     * @type {boolean}
     * @memberof MerchantStatus
     */
    legalDocumentsIncomplete: boolean
    /**
     *
     * @type {boolean}
     * @memberof MerchantStatus
     */
    legalReservationDocumentsIncomplete?: boolean
    /**
     *
     * @type {Array<MerchantStatusErrors>}
     * @memberof MerchantStatus
     */
    errors: Array<MerchantStatusErrors>
    /**
     *
     * @type {MerchantStatusData}
     * @memberof MerchantStatus
     */
    data: MerchantStatusData
}

/**
 * Check if a given object implements the MerchantStatus interface.
 */
export function instanceOfMerchantStatus(value: object): boolean {
    if (!('dealershipContactIncomplete' in value)) return false
    if (!('onlineOrderAndPaymentIncomplete' in value)) return false
    if (!('legalDocumentsIncomplete' in value)) return false
    if (!('errors' in value)) return false
    if (!('data' in value)) return false
    return true
}

export function MerchantStatusFromJSON(json: any): MerchantStatus {
    return MerchantStatusFromJSONTyped(json, false)
}

export function MerchantStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantStatus {
    if (json == null) {
        return json
    }
    return {
        dealershipContactIncomplete: json['dealershipContactIncomplete'],
        onlineOrderAndPaymentIncomplete: json['onlineOrderAndPaymentIncomplete'],
        legalDocumentsIncomplete: json['legalDocumentsIncomplete'],
        legalReservationDocumentsIncomplete:
            json['legalReservationDocumentsIncomplete'] == null
                ? undefined
                : json['legalReservationDocumentsIncomplete'],
        errors: (json['errors'] as Array<any>).map(MerchantStatusErrorsFromJSON),
        data: MerchantStatusDataFromJSON(json['data']),
    }
}

export function MerchantStatusToJSON(value?: MerchantStatus | null): any {
    if (value == null) {
        return value
    }
    return {
        dealershipContactIncomplete: value['dealershipContactIncomplete'],
        onlineOrderAndPaymentIncomplete: value['onlineOrderAndPaymentIncomplete'],
        legalDocumentsIncomplete: value['legalDocumentsIncomplete'],
        legalReservationDocumentsIncomplete: value['legalReservationDocumentsIncomplete'],
        errors: (value['errors'] as Array<any>).map(MerchantStatusErrorsToJSON),
        data: MerchantStatusDataToJSON(value['data']),
    }
}
