import * as runtime from './api/runtime'

export class MerchantApiClient extends runtime.BaseAPI {
    async downloadFile(path: string): Promise<Blob> {
        const blobResponse = await this.request({
            path,
            method: 'GET',
            headers: {},
        })

        return new runtime.BlobApiResponse(blobResponse).value()
    }
}
