import { useQuery } from '@tanstack/react-query'

import { Merchant } from '@slmpserv/common-api-merchant'

import { kycApi } from './kyc-api'

type UseFetchKycNotificationsEmailsProps = {
    ppnId: Merchant['ppnId']
}

export function useFetchKycEmails({ ppnId }: UseFetchKycNotificationsEmailsProps, enabled = true) {
    const queryKeyKYCEmails = ['kyc-emails', ppnId]

    const kycEmails = useQuery({
        queryKey: queryKeyKYCEmails,
        queryFn: () =>
            enabled && ppnId
                ? kycApi.kycEmailsGET({ ppnId }).then(result => {
                      return { statusEmails: result.addresses || [] }
                  })
                : null,
    })

    const isLoading = kycEmails.isLoading
    const isError = kycEmails.isError

    return { kycEmails, isLoading, isError }
}
