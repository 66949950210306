/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * The two-character country code of the address in ISO-3166-1 alpha-2 standard
     * @type {string}
     * @memberof Address
     */
    country: string
    /**
     * Two characters for an address in the USA or Canada, or a maximum of three characters for an address in all other countries. Required for an address in the USA or Canada.
     * @type {string}
     * @memberof Address
     */
    stateOrProvince?: string
    /**
     *
     * @type {string}
     * @memberof Address
     */
    city: string
    /**
     *
     * @type {string}
     * @memberof Address
     */
    postCode: string
    /**
     *
     * @type {string}
     * @memberof Address
     */
    street: string
    /**
     *
     * @type {string}
     * @memberof Address
     */
    streetNumber: string
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false)
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if (json === undefined || json === null) {
        return json
    }
    return {
        country: json['country'],
        stateOrProvince: !exists(json, 'stateOrProvince') ? undefined : json['stateOrProvince'],
        city: json['city'],
        postCode: json['postCode'],
        street: json['street'],
        streetNumber: json['streetNumber'],
    }
}

export function AddressToJSON(value?: Address | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        country: value.country,
        stateOrProvince: value.stateOrProvince,
        city: value.city,
        postCode: value.postCode,
        street: value.street,
        streetNumber: value.streetNumber,
    }
}
