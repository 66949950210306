import { ComponentProps, useCallback, useEffect, useState } from 'react'
import { PSwitch } from '@porsche-design-system/components-react'
import type { SwitchUpdateEvent } from '@porsche-design-system/components-react'
import { spacingFluid } from '@porsche-design-system/components-react/styles'
import { styled } from 'styled-components'
import { IntlProvider } from 'react-intl'

const TranslationToggles = styled.div`
    display: flex;
    justify-content: end;
    margin-top: ${spacingFluid.small};
    margin-right: ${spacingFluid.small};
    position: absolute;
    width: 205px;
    right: 0;
`

const StyledSwitchContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledSwitch = styled(PSwitch)`
    &:not(:first-of-type) {
        padding-top: ${spacingFluid.small};
    }
`

const translationModeProxy = new Proxy<Record<string, string>>(
    {},
    {
        get(_, messageId) {
            return messageId
        },
        has() {
            return true
        },
        getOwnPropertyDescriptor(_, key) {
            return {
                value: key,
                writable: true,
                enumerable: true,
                configurable: true,
            }
        },
    }
)

type UseTranslationModeProps = {
    isProd: boolean
    data: ComponentProps<typeof IntlProvider>['messages']
}

export function useTranslationMode({ isProd, data }: UseTranslationModeProps) {
    const [translationModeEnabled, setTranslationModeEnabled] = useState<boolean>(() =>
        isProd ? false : localStorage.getItem('translationMode') === 'true'
    )
    const [translationSuppressedWarnings, setTranslationSuppressedWarnings] = useState<boolean>(() =>
        isProd ? false : localStorage.getItem('translationSuppressWarnings') === 'true'
    )

    const onSwitchChange = useCallback((e: CustomEvent<SwitchUpdateEvent>) => {
        setTranslationModeEnabled(e.detail.checked)
    }, [])

    useEffect(() => {
        if (!isProd) {
            localStorage.setItem('translationMode', translationModeEnabled ? 'true' : 'false')
            localStorage.setItem('translationSuppressWarnings', translationSuppressedWarnings ? 'true' : 'false')
        }
    }, [isProd, translationModeEnabled, translationSuppressedWarnings])

    if (isProd) {
        return {
            messages: data,
            translationSuppressedWarnings: false,
            TranslationModeToggle: (): null => null,
        }
    }

    return {
        messages: translationModeEnabled ? translationModeProxy : data,
        translationSuppressedWarnings,
        TranslationModeToggle: () => (
            <TranslationToggles>
                <StyledSwitchContainer>
                    <StyledSwitch checked={translationModeEnabled} onUpdate={onSwitchChange}>
                        Translation Mode
                    </StyledSwitch>
                    <StyledSwitch
                        checked={translationSuppressedWarnings}
                        onUpdate={() => setTranslationSuppressedWarnings(current => !current)}
                    >
                        Suppressed Warnings
                    </StyledSwitch>
                </StyledSwitchContainer>
            </TranslationToggles>
        ),
    }
}
