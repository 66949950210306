import { defineMessages } from 'react-intl'

export const messages = defineMessages({
    purchaseRequestNotificationsTitle: {
        id: 'dealer.dashboard.purchaseRequestNotifications.title',
        defaultMessage: 'Purchase request notifications',
    },
    notificationsEnabledLabel: {
        id: 'dealer.purchaseRequestNotifications.notificationsEnabledLabel.label',
        defaultMessage: 'Receive notifications',
    },
    emailLabel: {
        id: 'dealer.purchaseRequestNotifications.email.label',
        defaultMessage: 'Email address',
    },
    additionalEmailLabel: {
        id: 'dealer.purchaseRequestNotifications.additionalEmail.label',
        defaultMessage: 'Additional Email address',
    },
    addAdditionalEmail: {
        id: 'dealer.purchaseRequestNotifications.addAdditionalEmail',
        defaultMessage: 'Add additional email address',
    },
    deleteAdditionalEmail: {
        id: 'dealer.purchaseRequestNotifications.deleteAdditionalEmail',
        defaultMessage: 'Remove email address',
    },
    toastSubmitSuccess: {
        id: 'dealer.purchaseRequestNotifications.toast.submit.success',
        defaultMessage: 'Saved',
    },
    toastSubmitError: {
        id: 'dealer.purchaseRequestNotifications.toast.submit.error',
        defaultMessage: 'Error while saving',
    },
})
