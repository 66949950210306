import { merchantsApi } from '../../merchant-api'
import { MerchantResponse } from '../models/MerchantResponse'

import { toApiError } from './ApiError'

export const merchantAdapter = {
    async getByPpnId(ppnId: string): Promise<MerchantResponse> {
        try {
            // eslint-disable-next-line testing-library/no-await-sync-queries
            return await merchantsApi.getByPpnId({ ppnId: ppnId })
        } catch (response) {
            throw await toApiError(response)
        }
    },
}
