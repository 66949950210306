import { useIntl } from 'react-intl'

import { useFetchMarketplace, useFetchMerchantStatus } from '@slmpserv/common/api/use-query-wrapper'
import { useMerchant } from '@slmpserv/common-contexts'
import { useMarketplaceFeatures } from '@slmpserv/hooks'
import { DownPaymentMarketplaceStatus, Merchant } from '@slmpserv/common-api-merchant'
import { ErrorInlineNotification, QueryBoundary } from '@slmpserv/common-components'
import { dashboardErrorMessages, errorsMessages } from '@slmpserv/common-translations'

import { DocumentsGroup } from './DocumentsGroup/DocumentsGroup'
import { DealershipContactGroup } from './DealershipContactGroup/DealershipContactGroup'
import { OnlineOrderGroup } from './OnlineOrderGroup/OnlineOrderGroup'

interface DashboardProps {
    ppnId: Merchant['ppnId']
}

export function Dashboard({ ppnId }: DashboardProps) {
    const merchant = useMerchant()
    const { merchantStatus, queryResult: merchantQuery } = useFetchMerchantStatus(ppnId)
    const { marketplace, queryResult: marketplaceQuery } = useFetchMarketplace({
        marketplaceKey: merchant.marketplaceKey,
    })
    const marketplaceFeatures = useMarketplaceFeatures({ marketplace })

    const { formatMessage } = useIntl()

    const showDealershipContactGroup = marketplaceFeatures.ONLINE_ORDER_CONFIG

    const showOnlineOrderGroup =
        marketplaceFeatures.ONLINE_ORDER_CONFIG &&
        marketplace.downPaymentStatus !== DownPaymentMarketplaceStatus.DISABLED

    const showDocumentGroup = marketplaceFeatures.DOCUMENT_MANAGEMENT

    return (
        <QueryBoundary
            queryResult={[merchantQuery, marketplaceQuery]}
            fallbackRender={() => (
                <ErrorInlineNotification
                    title={formatMessage(errorsMessages.justError)}
                    message={formatMessage(dashboardErrorMessages.onlineOrderGroupError)}
                />
            )}
        >
            {showDealershipContactGroup && (
                <DealershipContactGroup
                    merchant={merchant}
                    merchantStatus={merchantStatus}
                    showPurchaseRequestSettings={marketplaceFeatures.PURCHASE_REQUEST_SETTINGS}
                />
            )}
            {showOnlineOrderGroup && (
                <OnlineOrderGroup
                    merchant={merchant}
                    merchantStatus={merchantStatus}
                    showDownPaymentBox={showOnlineOrderGroup}
                    showPaymentServiceSettings={marketplaceFeatures.KYC_MANAGEMENT}
                    showPayoutsNotificationSettings={marketplaceFeatures.PAYOUT_SECTION}
                    currency={marketplace.currency}
                />
            )}
            {showDocumentGroup && (
                <DocumentsGroup marketplace={marketplace} merchant={merchant} merchantStatus={merchantStatus} />
            )}
        </QueryBoundary>
    )
}
