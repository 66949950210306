import { useToastManager } from '@porsche-design-system/components-react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useEffect } from 'react'
import { useMutation } from '@tanstack/react-query'
import { Flex } from '@slcheckout/ui-kit'

import { LoadingAndErrorWrapper } from '@slmpserv/self-service-shared'
import { Merchant } from '@slmpserv/common-api-merchant'
import { MultipleEmailsField } from '@slmpserv/common-components'
import { FormButtons } from '@slmpserv/common-components-legacy'
import { kycApi, useFetchKycEmails } from '@slmpserv/kyc-service-api'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'

import { messages } from './messages'

export type EmailsForKycNotificationsFormState = {
    addresses: Array<{ id: string; value: string }>
    type: string
}

type KycNotificationsConfigProps = {
    ppnId: Merchant['ppnId']
}

export function KycNotificationsConfig({ ppnId }: KycNotificationsConfigProps) {
    const { addMessage } = useToastManager()
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const {
        register,
        handleSubmit: useFormHandleSubmit,
        reset,
        formState,
        control,
    } = useForm<EmailsForKycNotificationsFormState>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: { addresses: [{ value: '' }] },
    })

    const {
        kycEmails: { data, isError },
        isLoading,
    } = useFetchKycEmails({ ppnId })

    useEffect(() => {
        if (!isLoading && !isError && data) {
            reset({
                addresses: data.statusEmails.length
                    ? data.statusEmails.map(email => ({ value: email }))
                    : [{ value: '' }],
            })
        }
    }, [data, isError, isLoading, reset])

    const { mutate, isPending: isSaving } = useMutation({
        mutationFn: (data: EmailsForKycNotificationsFormState) => {
            const payload = {
                ppnId,
                updateKycEmail: {
                    addresses: data.addresses.map(({ value }) => value),
                },
            }

            return kycApi.kycEmailsPUT(payload)
        },
        onSuccess: () =>
            addMessage({
                state: 'success',
                text: formatMessage(messages.toastSubmitSuccess),
            }),
        onError: error => {
            console.error(error)
            addMessage({
                state: 'neutral',
                text: formatMessage(messages.toastSubmitError),
            })
        },
    })

    const handleSubmit = (data: EmailsForKycNotificationsFormState) => {
        mutate(data)
    }

    const handleCancel = () => {
        navigate(StaticRoutes.DASHBOARD)
    }

    return (
        <LoadingAndErrorWrapper isLoading={isLoading} isError={isError}>
            <form onSubmit={useFormHandleSubmit(handleSubmit)}>
                <Flex direction="column">
                    <MultipleEmailsField
                        control={control}
                        register={register}
                        formState={formState}
                        fieldName={'addresses'}
                        messages={{
                            firstEntryLabel: messages.emailLabel,
                            nthEntryLabel: messages.additionalEmailLabel,
                        }}
                    />

                    <FormButtons isLoading={isSaving} onCancel={handleCancel} />
                </Flex>
            </form>
        </LoadingAndErrorWrapper>
    )
}
