/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service Dealer App Settings Api
 * Dealer settings API of Purchase Request Service, to store and retrieve settings in the dealer app settings microfrontend
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface DealershipSettingsDto
 */
export interface DealershipSettingsDto {
    /**
     * Enables purchase request email notifications
     * @type {boolean}
     * @memberof DealershipSettingsDto
     */
    notificationsEnabled: boolean
    /**
     * List of email addresses which should receive notifications about new purchase requests!
     * @type {Array<string>}
     * @memberof DealershipSettingsDto
     */
    contactEmailAddresses: Array<string>
}

/**
 * Check if a given object implements the DealershipSettingsDto interface.
 */
export function instanceOfDealershipSettingsDto(value: object): boolean {
    if (!('notificationsEnabled' in value)) return false
    if (!('contactEmailAddresses' in value)) return false
    return true
}

export function DealershipSettingsDtoFromJSON(json: any): DealershipSettingsDto {
    return DealershipSettingsDtoFromJSONTyped(json, false)
}

export function DealershipSettingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealershipSettingsDto {
    if (json == null) {
        return json
    }
    return {
        notificationsEnabled: json['notificationsEnabled'],
        contactEmailAddresses: json['contactEmailAddresses'],
    }
}

export function DealershipSettingsDtoToJSON(value?: DealershipSettingsDto | null): any {
    if (value == null) {
        return value
    }
    return {
        notificationsEnabled: value['notificationsEnabled'],
        contactEmailAddresses: value['contactEmailAddresses'],
    }
}
