/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SubmerchantVerificationCheck
 */
export interface SubmerchantVerificationCheck {
    /**
     * The status of the subject that is being verified
     * @type {string}
     * @memberof SubmerchantVerificationCheck
     */
    status: string
    /**
     * The type of verification check
     * @type {string}
     * @memberof SubmerchantVerificationCheck
     */
    type: string
    /**
     * Verification check code
     * @type {number}
     * @memberof SubmerchantVerificationCheck
     */
    code: number
    /**
     * A description of the verification check
     * @type {string}
     * @memberof SubmerchantVerificationCheck
     */
    description: string
}

export function SubmerchantVerificationCheckFromJSON(json: any): SubmerchantVerificationCheck {
    return SubmerchantVerificationCheckFromJSONTyped(json, false)
}

export function SubmerchantVerificationCheckFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): SubmerchantVerificationCheck {
    if (json === undefined || json === null) {
        return json
    }
    return {
        status: json['status'],
        type: json['type'],
        code: json['code'],
        description: json['description'],
    }
}

export function SubmerchantVerificationCheckToJSON(value?: SubmerchantVerificationCheck | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        status: value.status,
        type: value.type,
        code: value.code,
        description: value.description,
    }
}
