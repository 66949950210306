/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    BankAccountWithIban,
    BankAccountWithIbanFromJSON,
    BankAccountWithIbanFromJSONTyped,
    BankAccountWithIbanToJSON,
    BankAccountWithoutIban,
    BankAccountWithoutIbanFromJSON,
    BankAccountWithoutIbanFromJSONTyped,
    BankAccountWithoutIbanToJSON,
    ParentCompany,
    ParentCompanyFromJSON,
    ParentCompanyFromJSONTyped,
    ParentCompanyToJSON,
    Shareholder,
    ShareholderFromJSON,
    ShareholderFromJSONTyped,
    ShareholderToJSON,
    Signatory,
    SignatoryFromJSON,
    SignatoryFromJSONTyped,
    SignatoryToJSON,
} from './'

/**
 *
 * @export
 * @interface SubmerchantModel
 */
export interface SubmerchantModel {
    /**
     * Required only for POST request to create a submerchant. Cannot be updated in the future
     * @type {string}
     * @memberof SubmerchantModel
     */
    ppnId: string
    /**
     *
     * @type {string}
     * @memberof SubmerchantModel
     */
    legalBusinessName: string
    /**
     * non-formal name of the dealer
     * @type {string}
     * @memberof SubmerchantModel
     */
    doingBusinessAs: string
    /**
     *
     * @type {string}
     * @memberof SubmerchantModel
     */
    email: string
    /**
     * Full phone number with country prefix
     * @type {string}
     * @memberof SubmerchantModel
     */
    phoneNumber: string
    /**
     * in Germany Handelsregisternummer
     * @type {string}
     * @memberof SubmerchantModel
     */
    registrationNumber: string
    /**
     * Legal entity that is tied to submerchant, If none specified PorscheMP is used. Enums are case insensitive.
     * @type {string}
     * @memberof SubmerchantModel
     */
    legalEntity?: SubmerchantModelLegalEntityEnum
    /**
     * Tax ID of the company
     * @type {string}
     * @memberof SubmerchantModel
     */
    taxId: string
    /**
     *
     * @type {Address}
     * @memberof SubmerchantModel
     */
    address: Address
    /**
     *
     * @type {Address}
     * @memberof SubmerchantModel
     */
    principalBusinessAddress?: Address
    /**
     *
     * @type {BankAccountWithIban | BankAccountWithoutIban}
     * @memberof SubmerchantModel
     */
    bankAccount: BankAccountWithIban | BankAccountWithoutIban
    /**
     *
     * @type {Array<ParentCompany>}
     * @memberof SubmerchantModel
     */
    parentCompanies?: Array<ParentCompany>
    /**
     *
     * @type {Array<Shareholder>}
     * @memberof SubmerchantModel
     */
    shareholders: Array<Shareholder>
    /**
     *
     * @type {Array<Signatory>}
     * @memberof SubmerchantModel
     */
    signatories: Array<Signatory>
    /**
     * Processing tier of submerchant, ignored during update
     * @type {number}
     * @memberof SubmerchantModel
     */
    tier?: number
    /**
     * Adyen identifier of submerchant, ignored during update
     * @type {string}
     * @memberof SubmerchantModel
     */
    accountHolderCode?: string
    /**
     * Email used for KYC confirmation
     * @type {string}
     * @memberof SubmerchantModel
     */
    confirmationEmail?: string
    /**
     * Indicates whether the submerchant has been migrated to Adyen's Balance Platform
     * @type {boolean}
     * @memberof SubmerchantModel
     */
    migrated: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum SubmerchantModelLegalEntityEnum {
    PorscheMp = 'PorscheMP',
    PorscheMpUsa = 'PorscheMP_USA',
    PorscheMpCanada = 'PorscheMP_Canada',
    PorscheMpAu = 'PorscheMP_AU',
}

export function SubmerchantModelFromJSON(json: any): SubmerchantModel {
    return SubmerchantModelFromJSONTyped(json, false)
}

export function SubmerchantModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmerchantModel {
    if (json === undefined || json === null) {
        return json
    }
    return {
        ppnId: json['ppnId'],
        legalBusinessName: json['legalBusinessName'],
        doingBusinessAs: json['doingBusinessAs'],
        email: json['email'],
        phoneNumber: json['phoneNumber'],
        registrationNumber: json['registrationNumber'],
        legalEntity: !exists(json, 'legalEntity') ? undefined : json['legalEntity'],
        taxId: json['taxId'],
        address: AddressFromJSON(json['address']),
        principalBusinessAddress: !exists(json, 'principalBusinessAddress')
            ? undefined
            : AddressFromJSON(json['principalBusinessAddress']),
        bankAccount: json['bankAccount'],
        parentCompanies: !exists(json, 'parentCompanies')
            ? undefined
            : (json['parentCompanies'] as Array<any>).map(ParentCompanyFromJSON),
        shareholders: (json['shareholders'] as Array<any>).map(ShareholderFromJSON),
        signatories: (json['signatories'] as Array<any>).map(SignatoryFromJSON),
        tier: !exists(json, 'tier') ? undefined : json['tier'],
        accountHolderCode: !exists(json, 'accountHolderCode') ? undefined : json['accountHolderCode'],
        confirmationEmail: !exists(json, 'confirmationEmail') ? undefined : json['confirmationEmail'],
        migrated: json['migrated'],
    }
}

export function SubmerchantModelToJSON(value?: SubmerchantModel | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        ppnId: value.ppnId,
        legalBusinessName: value.legalBusinessName,
        doingBusinessAs: value.doingBusinessAs,
        email: value.email,
        phoneNumber: value.phoneNumber,
        registrationNumber: value.registrationNumber,
        legalEntity: value.legalEntity,
        taxId: value.taxId,
        address: AddressToJSON(value.address),
        principalBusinessAddress: AddressToJSON(value.principalBusinessAddress),
        bankAccount: value.bankAccount,
        parentCompanies:
            value.parentCompanies === undefined
                ? undefined
                : (value.parentCompanies as Array<any>).map(ParentCompanyToJSON),
        shareholders: (value.shareholders as Array<any>).map(ShareholderToJSON),
        signatories: (value.signatories as Array<any>).map(SignatoryToJSON),
        tier: value.tier,
        accountHolderCode: value.accountHolderCode,
        confirmationEmail: value.confirmationEmail,
        migrated: value.migrated,
    }
}
