import { PTabsItem } from '@porsche-design-system/components-react'
import { ReactNode } from 'react'

import { useLanguageTranslation } from '@slmpserv/hooks'

type LanguageProps = {
    language: string
    children: ReactNode
}

export function LanguageTabItem({ language, children }: LanguageProps) {
    const label = useLanguageTranslation(language)

    return <PTabsItem label={label}>{children}</PTabsItem>
}
