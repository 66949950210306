/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SubmerchantPayoutSchedule
 */
export interface SubmerchantPayoutSchedule {
    /**
     * Payout schedule of submerchant
     * @type {string}
     * @memberof SubmerchantPayoutSchedule
     */
    schedule: SubmerchantPayoutScheduleScheduleEnum
}

/**
 * @export
 * @enum {string}
 */
export enum SubmerchantPayoutScheduleScheduleEnum {
    Default = 'DEFAULT',
    Hold = 'HOLD',
    Daily = 'DAILY',
    Weekly = 'WEEKLY',
    Monthly = 'MONTHLY',
}

export function SubmerchantPayoutScheduleFromJSON(json: any): SubmerchantPayoutSchedule {
    return SubmerchantPayoutScheduleFromJSONTyped(json, false)
}

export function SubmerchantPayoutScheduleFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): SubmerchantPayoutSchedule {
    if (json === undefined || json === null) {
        return json
    }
    return {
        schedule: json['schedule'],
    }
}

export function SubmerchantPayoutScheduleToJSON(value?: SubmerchantPayoutSchedule | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        schedule: value.schedule,
    }
}
