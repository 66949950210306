import { PHeading, PRadioButtonWrapper } from '@porsche-design-system/components-react'
import { spacingFluid } from '@porsche-design-system/components-react/styles'
import { Control, Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { styled } from 'styled-components'

import { DownPaymentMarketplaceStatus } from '@slmpserv/common-api-merchant'

import { DownPaymentFormState } from '../types'

import { downPaymentConfigurationMessages } from './DownPayment.messages'
import { DownPaymentInfoText } from './DownPaymentInfoText'

const StyledRadioButtonWrapper = styled(PRadioButtonWrapper)`
    margin-bottom: ${spacingFluid.medium};
`

type DownPaymentActiveFieldProps = {
    control: Control<DownPaymentFormState, object>
    downPaymentMarketplaceStatus: DownPaymentMarketplaceStatus
}

export function DownPaymentActiveField({ control, downPaymentMarketplaceStatus }: DownPaymentActiveFieldProps) {
    const { formatMessage } = useIntl()

    return downPaymentMarketplaceStatus === DownPaymentMarketplaceStatus.OPTIONAL ? (
        <>
            <PHeading tag="h5" size="medium">
                {formatMessage(downPaymentConfigurationMessages.downPaymentInfoTextHeadline)}
            </PHeading>
            <DownPaymentInfoText />
            <Controller
                control={control}
                name="downPaymentActive"
                render={({ field: { onChange, value } }) => (
                    <>
                        <StyledRadioButtonWrapper
                            label={formatMessage(downPaymentConfigurationMessages.downPaymentDisable)}
                        >
                            <input type="radio" name={'no'} checked={value !== true} onChange={() => onChange(false)} />
                        </StyledRadioButtonWrapper>
                        <StyledRadioButtonWrapper
                            label={formatMessage(downPaymentConfigurationMessages.downPaymentEnable)}
                        >
                            <input type="radio" name={'yes'} checked={value === true} onChange={() => onChange(true)} />
                        </StyledRadioButtonWrapper>
                    </>
                )}
            />
        </>
    ) : (
        <DownPaymentInfoText />
    )
}
