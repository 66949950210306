/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface Imprint
 */
export interface Imprint {
    /**
     *
     * @type {string}
     * @memberof Imprint
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof Imprint
     */
    language: string
    /**
     *
     * @type {string}
     * @memberof Imprint
     */
    content?: string
}

/**
 * Check if a given object implements the Imprint interface.
 */
export function instanceOfImprint(value: object): boolean {
    if (!('language' in value)) return false
    return true
}

export function ImprintFromJSON(json: any): Imprint {
    return ImprintFromJSONTyped(json, false)
}

export function ImprintFromJSONTyped(json: any, ignoreDiscriminator: boolean): Imprint {
    if (json == null) {
        return json
    }
    return {
        id: json['id'] == null ? undefined : json['id'],
        language: json['language'],
        content: json['content'] == null ? undefined : json['content'],
    }
}

export function ImprintToJSON(value?: Imprint | null): any {
    if (value == null) {
        return value
    }
    return {
        id: value['id'],
        language: value['language'],
        content: value['content'],
    }
}
