import { createContext, ReactNode, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'

import { Marketplace } from '@slmpserv/common-api-merchant'
import { LoadingBoundary } from '@slmpserv/common-components-legacy'
import { useFetchMarketplace } from '@slmpserv/common/api/use-query-wrapper'
import { ErrorInlineNotification } from '@slmpserv/common-components'
import { errorsMessages } from '@slmpserv/common-translations'

import { marketplaceContextMessages } from './MarketplaceContext.messages'

export const MarketplaceContext = createContext<Marketplace | undefined>(undefined)

type MarketplaceContextProviderProps = {
    children: ReactNode
    marketplaceKey?: Marketplace['key']
}

export function MarketplaceContextProvider({ children, marketplaceKey }: MarketplaceContextProviderProps) {
    const { marketplaceKey: marketplaceKeyFromRoute } = useParams<'marketplaceKey'>()
    if (marketplaceKeyFromRoute) {
        marketplaceKey = marketplaceKeyFromRoute
    }
    const { formatMessage } = useIntl()

    const {
        marketplace,
        queryResult: { isError, isLoading },
    } = useFetchMarketplace({
        marketplaceKey: marketplaceKey,
    })

    return (
        <LoadingBoundary isLoading={isLoading}>
            {isError ? (
                <ErrorInlineNotification
                    title={formatMessage(errorsMessages.justError)}
                    message={formatMessage(marketplaceContextMessages.fetchFailed)}
                />
            ) : (
                <MarketplaceContext.Provider value={marketplace}>{children}</MarketplaceContext.Provider>
            )}
        </LoadingBoundary>
    )
}

export function useMarketplaceContext() {
    const context = useContext(MarketplaceContext)

    if (context === undefined) {
        throw new Error('useMarketplaceContext must be used within a MarketplaceContextProvider')
    }

    return context
}
