import { useIntl } from 'react-intl'
import { PPopover, PTag } from '@porsche-design-system/components-react'

import { useMatomoTrackTooltipClick } from '@slmpserv/common/analytics'
import { dashboardPopoverMessages, dashboardStatusMessages } from '@slmpserv/common-translations'

type PurchaseRequestSettingsStatusProps = {
    purchaseRequestConfig: {
        contactEmailAddresses: string[]
        notificationsEnabled: boolean
    }
}

export function PurchaseRequestSettingsStatus({ purchaseRequestConfig }: PurchaseRequestSettingsStatusProps) {
    const { formatMessage } = useIntl()
    const { trackEvent: trackOptionalTooltipEvent } = useMatomoTrackTooltipClick(
        `optional-tag: purchase-request-settings`
    )
    const { trackEvent: trackWarningTooltipEvent } = useMatomoTrackTooltipClick(
        `warning-tag: purchase-request-settings`
    )

    if (!purchaseRequestConfig.notificationsEnabled && purchaseRequestConfig.contactEmailAddresses.length === 0) {
        return (
            <div style={{ display: 'inline-flex' }}>
                <PTag color={'notification-info-soft'}>{formatMessage(dashboardStatusMessages.optional)}</PTag>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <PPopover onClick={trackOptionalTooltipEvent} direction="right">
                        {formatMessage(dashboardPopoverMessages.purchaseRequestNotificationsOptional)}
                    </PPopover>
                </span>
            </div>
        )
    }

    if (!purchaseRequestConfig.notificationsEnabled && purchaseRequestConfig.contactEmailAddresses.length > 0) {
        return (
            <div style={{ display: 'inline-flex' }}>
                <PTag color="notification-warning" icon="warning">
                    {formatMessage(dashboardStatusMessages.purchaseRequestNotificationsSwitchedOff)}
                </PTag>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <PPopover onClick={trackWarningTooltipEvent} direction="right">
                        {formatMessage(dashboardPopoverMessages.purchaseRequestNotificationsSwitchedOff)}
                    </PPopover>
                </span>
            </div>
        )
    }

    return null
}
