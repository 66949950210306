import { useMemo } from 'react'

import {
    DocumentType,
    MarketplaceDocumentConfig,
    MarketplaceDocumentConfigDocumentStatusEnum,
} from '@slmpserv/common-api-merchant'

export function useNeededTncDocuments(
    marketplaceDocumentConfig: MarketplaceDocumentConfig[],
    csvEnabled: boolean,
    offersNewCars: boolean,
    offersCsvCars: boolean,
    checkoutEnabled: boolean,
    reservationEnabled: boolean
): DocumentType[] {
    return useMemo(() => {
        const configMap = marketplaceDocumentConfig.reduce(
            (map, config) => ({ ...map, [config.documentType]: config }),
            {}
        )

        const includeNewCars = checkoutEnabled && offersNewCars
        const includeCsvCars = csvEnabled && offersCsvCars

        const newCarTypes = [DocumentType.NEW_CAR_BEV, DocumentType.NEW_CAR_GENERAL, DocumentType.NEW_CAR_PHEV]
        const usedCarTypes = [
            DocumentType.USED_CAR_APPROVED_BEV,
            DocumentType.USED_CAR_APPROVED_GENERAL,
            DocumentType.USED_CAR_APPROVED_PHEV,
            DocumentType.USED_CAR_BEV,
            DocumentType.USED_CAR_GENERAL,
            DocumentType.USED_CAR_PHEV,
        ]
        const csvCarTypes = [DocumentType.CSV_CAR_BEV, DocumentType.CSV_CAR_GENERAL, DocumentType.CSV_CAR_PHEV]

        const reservationTypes = [DocumentType.RESERVATION_GENERAL, DocumentType.RESERVATION_PRIVACY_POLICY]

        const allowedDocumentTypes = [
            ...(includeNewCars ? newCarTypes : []),
            ...(checkoutEnabled ? usedCarTypes : []),
            ...(includeCsvCars ? csvCarTypes : []),
            ...(reservationEnabled ? reservationTypes : []),
        ]

        return allowedDocumentTypes.filter(
            type => configMap[type]?.documentStatus !== MarketplaceDocumentConfigDocumentStatusEnum.DISABLED
        )
    }, [marketplaceDocumentConfig, checkoutEnabled, offersNewCars, csvEnabled, offersCsvCars, reservationEnabled])
}
