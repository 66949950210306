import { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import { UseFormHandleSubmit } from 'react-hook-form/dist/types/form'

type Handler = {
    name: string
    handler: ReturnType<UseFormHandleSubmit<any>>
}

interface Context {
    submitAll: () => Promise<void>
    addSubmitHandler: (handler: Handler) => void
    isSubmitting: boolean
}

const MultipleFormSubmitContext = createContext<Context | undefined>(undefined)

interface MultipleFormSubmitProviderProps {
    onSuccess?: () => void | Promise<void>
    onError?: () => void | Promise<void>
    children: ReactNode
}

export function MultipleFormSubmitProvider({ children, onSuccess, onError }: MultipleFormSubmitProviderProps) {
    const [handlers, setHandlers] = useState<Handler[]>([])
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const submitAll = useCallback(async () => {
        setIsSubmitting(true)
        try {
            for (const { handler } of handlers) {
                await handler()
            }
            if (onSuccess) {
                await onSuccess()
            }
        } catch (e) {
            if (onError) {
                await onError()
            }
        } finally {
            setIsSubmitting(false)
        }
    }, [handlers, onError, onSuccess])

    const addSubmitHandler = useCallback(({ name, handler }: Handler) => {
        setHandlers(cur => [...cur.filter(el => el.name !== name), { name, handler }])
    }, [])

    return (
        <MultipleFormSubmitContext.Provider value={{ submitAll, addSubmitHandler, isSubmitting }} children={children} />
    )
}

export function useMultipleFormSubmits() {
    const context = useContext(MultipleFormSubmitContext)

    if (context === undefined) {
        throw new Error('useMultipleFormSubmits must be used within an MultipleFormSubmitProvider')
    }

    return context
}
