import { useEffect } from 'react'
import { currentEnvironment } from '@finder/environment'

export function useMatomo(environment?: string) {
    useEffect(() => {
        const env = environment ?? currentEnvironment()
        const _paq = (window._paq = window._paq || [])

        _paq.push(['enableLinkTracking'])

        const matomoPorscheUrl = '//porsche.matomo.cloud/'
        _paq.push(['setTrackerUrl', matomoPorscheUrl + 'matomo.php'])
        env !== 'P' ? _paq.push(['setSiteId', '1']) : _paq.push(['setSiteId', '3'])

        const d = document
        const scriptElement = d.createElement('script')
        const script = d.getElementsByTagName('script')[0]

        scriptElement.type = 'text/javascript'
        scriptElement.async = true
        scriptElement.src = matomoPorscheUrl + 'matomo.js'

        if (script && script.parentNode) {
            script.parentNode.insertBefore(scriptElement, script)
        }
    }, [environment])
}
