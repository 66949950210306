import { PInlineNotificationProps } from '@porsche-design-system/components-react'
import { useIntl } from 'react-intl'

import { MerchantStatusErrors } from '@slmpserv/common-api-merchant'
import {
    dashboardNotificationByError,
    dashboardNotificationByErrorPaymentStatus,
    dashboardPaymentDeadlineDateResolver,
} from '@slmpserv/common-translations'
import { useFetchMerchantStatus, useOnlineOrderingStatus } from '@slmpserv/common/api/use-query-wrapper'
import { useMarketplaceContext } from '@slmpserv/common-contexts'
import { useMarketplaceFeatures } from '@slmpserv/hooks'
import { useFetchKycEmails } from '@slmpserv/kyc-service-api'

export type Notification = {
    title: string
    text: string
    link: string
    type?: PInlineNotificationProps['state']
}

type UseFetchNotificationsProps = {
    ppnId: string
}

const excludeWhenKycDisabledErrors: MerchantStatusErrors[] = [
    MerchantStatusErrors.RESERVATION_FEE,
    MerchantStatusErrors.PAYMENT_SERVICE,
]

const excludeWhenCheckoutDisabledErrors: MerchantStatusErrors[] = [
    MerchantStatusErrors.TNC,
    MerchantStatusErrors.PRIVACY_POLICY,
]

export function useFetchNotifications({ ppnId }: UseFetchNotificationsProps) {
    const { formatMessage } = useIntl()
    const marketplace = useMarketplaceContext()
    const marketplaceFeatures = useMarketplaceFeatures({ marketplace })
    const { merchantStatus, queryResult: merchantQueryResult } = useFetchMerchantStatus(ppnId)
    const { onlineOrderingStatus, queryResult: orderingStatusQueryResult } = useOnlineOrderingStatus({ ppnId })
    const { paymentStatus } = merchantStatus.data
    const {
        kycEmails: { data },
        isError,
    } = useFetchKycEmails({ ppnId }, marketplaceFeatures.KYC_MANAGEMENT)

    const errors = merchantStatus.errors
        .filter(error => {
            if (marketplace.kycEnabled) {
                // Do not filter any errors when KYC is enabled for the given marketplace
                return true
            } else {
                // Don't show any notifications related to the KYC process
                return !excludeWhenKycDisabledErrors.includes(error)
            }
        })
        .filter(error => {
            if (marketplace.checkoutEnabled) {
                // Do not filter any errors when checkout is enabled for the given marketplace
                return true
            } else {
                // Don't show any notifications related to the checkout process
                return !excludeWhenCheckoutDisabledErrors.includes(error)
            }
        })

    const notifications: Notification[] = errors
        .filter(errorKey => errorKey !== MerchantStatusErrors.PAYMENT_SERVICE)
        .map(errorKey => {
            return {
                title: formatMessage(dashboardNotificationByError[errorKey].title),
                text: formatMessage(dashboardNotificationByError[errorKey].text),
                link: formatMessage(dashboardNotificationByError[errorKey].link),
                type: 'warning',
            }
        })

    if (errors.includes(MerchantStatusErrors.PAYMENT_SERVICE)) {
        const { descriptor, days } = dashboardPaymentDeadlineDateResolver(onlineOrderingStatus)
        const paymentDeadlineToDateMessage: string =
            descriptor && days ? formatMessage(descriptor, { days: days }) : null

        notifications.push({
            title: formatMessage(dashboardNotificationByErrorPaymentStatus[paymentStatus].title),
            text: [
                formatMessage(dashboardNotificationByErrorPaymentStatus[paymentStatus].text),
                paymentDeadlineToDateMessage,
            ]
                .filter(Boolean)
                .join(' '),
            link: formatMessage(dashboardNotificationByErrorPaymentStatus[paymentStatus].link),
            type: 'warning',
        })
    }

    if ((data?.statusEmails && !data.statusEmails.length) || isError) {
        notifications.push({
            title: formatMessage(dashboardNotificationByError['paymentProcessingStatusNotificationIncomplete'].title),
            text: formatMessage(dashboardNotificationByError['paymentProcessingStatusNotificationIncomplete'].text),
            link: formatMessage(dashboardNotificationByError['paymentProcessingStatusNotificationIncomplete'].link),
            type: 'warning',
        })
    }

    return { notifications, queryResults: [merchantQueryResult, orderingStatusQueryResult] }
}
