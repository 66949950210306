/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum AppRole {
    MACHINE_USER = 'MACHINE_USER',
    GLOBAL_ADMIN = 'GLOBAL_ADMIN',
    GLOBAL_MARKETPLACE_ADMIN = 'GLOBAL_MARKETPLACE_ADMIN',
    MARKETPLACE_OPERATOR = 'MARKETPLACE_OPERATOR',
    MARKETPLACE_IMPORTER = 'MARKETPLACE_IMPORTER',
    MERCHANT_DATA_ADMIN = 'MERCHANT_DATA_ADMIN',
    MERCHANT_ADMIN = 'MERCHANT_ADMIN',
}

export function instanceOfAppRole(value: any): boolean {
    return Object.values(AppRole).includes(value)
}

export function AppRoleFromJSON(json: any): AppRole {
    return AppRoleFromJSONTyped(json, false)
}

export function AppRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppRole {
    return json as AppRole
}

export function AppRoleToJSON(value?: AppRole | null): any {
    return value as any
}
