/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface DownPaymentResponse
 */
export interface DownPaymentResponse {
    /**
     *
     * @type {boolean}
     * @memberof DownPaymentResponse
     */
    active: boolean
    /**
     *
     * @type {string}
     * @memberof DownPaymentResponse
     */
    currency: DownPaymentResponseCurrencyEnum
    /**
     *
     * @type {number}
     * @memberof DownPaymentResponse
     */
    value?: number
}

/**
 * @export
 * @enum {string}
 */
export enum DownPaymentResponseCurrencyEnum {
    AUD = 'AUD',
    AMD = 'AMD',
    AZN = 'AZN',
    BAM = 'BAM',
    BGN = 'BGN',
    BRL = 'BRL',
    BYN = 'BYN',
    CAD = 'CAD',
    CHF = 'CHF',
    CZK = 'CZK',
    EUR = 'EUR',
    GEL = 'GEL',
    GBP = 'GBP',
    HRK = 'HRK',
    HUF = 'HUF',
    ILS = 'ILS',
    KZT = 'KZT',
    JPY = 'JPY',
    KRW = 'KRW',
    MDL = 'MDL',
    MKD = 'MKD',
    MXN = 'MXN',
    NOK = 'NOK',
    PLN = 'PLN',
    RON = 'RON',
    SEK = 'SEK',
    SGD = 'SGD',
    RSD = 'RSD',
    TRY = 'TRY',
    TWD = 'TWD',
    UAH = 'UAH',
    USD = 'USD',
    UZS = 'UZS',
}

/**
 * Check if a given object implements the DownPaymentResponse interface.
 */
export function instanceOfDownPaymentResponse(value: object): boolean {
    if (!('active' in value)) return false
    if (!('currency' in value)) return false
    return true
}

export function DownPaymentResponseFromJSON(json: any): DownPaymentResponse {
    return DownPaymentResponseFromJSONTyped(json, false)
}

export function DownPaymentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DownPaymentResponse {
    if (json == null) {
        return json
    }
    return {
        active: json['active'],
        currency: json['currency'],
        value: json['value'] == null ? undefined : json['value'],
    }
}

export function DownPaymentResponseToJSON(value?: DownPaymentResponse | null): any {
    if (value == null) {
        return value
    }
    return {
        active: value['active'],
        currency: value['currency'],
        value: value['value'],
    }
}
