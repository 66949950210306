import { PHeading } from '@porsche-design-system/components-react'
import { useIntl } from 'react-intl'

import { useCurrentDealerContext } from '@slmpserv/self-service-current-dealer-context'
import { Dashboard } from '@slmpserv/self-service-dashboard'
import { PageLayout } from '@slmpserv/common-components-legacy'
import { routesMessages } from '@slmpserv/common-translations'
import { QueryBoundary } from '@slmpserv/common-components'

import { useFetchNotifications } from '../components/NotificationArea/useFetchNotifications'
import { NotificationArea } from '../components/NotificationArea/NotificationArea'

export function DashboardPage() {
    const { formatMessage } = useIntl()
    const { partnerNumber } = useCurrentDealerContext()
    const { notifications, queryResults } = useFetchNotifications({ ppnId: partnerNumber })

    return (
        <PageLayout
            header={
                <>
                    <PHeading tag="h3" size="large">
                        {formatMessage(routesMessages.dashboardHeadline)}
                    </PHeading>
                    <QueryBoundary queryResult={queryResults} renderChildrenOnlyOnSuccess={true}>
                        <NotificationArea notifications={notifications} />
                    </QueryBoundary>
                </>
            }
        >
            <Dashboard ppnId={partnerNumber} />
        </PageLayout>
    )
}
