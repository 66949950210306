import { defineMessages } from 'react-intl'

export const errorMessages = defineMessages({
    pageTitle: {
        id: 'dealer.error.pageTitle',
        defaultMessage: 'Error',
    },
    fallback: {
        id: 'dealer.error.text',
        defaultMessage: 'Something went wrong.',
    },
    featureNotEnabled: {
        id: 'dealer.error.featureNotEnabled',
        defaultMessage: 'Sorry this feature is not enabled.',
    },
    merchantNotFound: {
        id: 'dealer.error.merchantNotFound',
        defaultMessage: 'Could not find Merchant with PPN Id {ppnId}.',
    },
    insufficientRole: {
        id: 'dealer.error.insufficientRole',
        defaultMessage:
            'You need the correct permission to open that page. Please get in touch with your market contact.',
    },
    backToDashboard: {
        id: 'dealer.error.navigation.backToDashboard',
        defaultMessage: 'Back to Dashboard',
    },
})
