/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CarSalesConfiguration
 */
export interface CarSalesConfiguration {
    /**
     *
     * @type {boolean}
     * @memberof CarSalesConfiguration
     */
    offersNewCars: boolean
    /**
     *
     * @type {boolean}
     * @memberof CarSalesConfiguration
     */
    offersCsvCars: boolean
}

/**
 * Check if a given object implements the CarSalesConfiguration interface.
 */
export function instanceOfCarSalesConfiguration(value: object): boolean {
    if (!('offersNewCars' in value)) return false
    if (!('offersCsvCars' in value)) return false
    return true
}

export function CarSalesConfigurationFromJSON(json: any): CarSalesConfiguration {
    return CarSalesConfigurationFromJSONTyped(json, false)
}

export function CarSalesConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarSalesConfiguration {
    if (json == null) {
        return json
    }
    return {
        offersNewCars: json['offersNewCars'],
        offersCsvCars: json['offersCsvCars'],
    }
}

export function CarSalesConfigurationToJSON(value?: CarSalesConfiguration | null): any {
    if (value == null) {
        return value
    }
    return {
        offersNewCars: value['offersNewCars'],
        offersCsvCars: value['offersCsvCars'],
    }
}
