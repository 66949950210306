/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ErrorResponse, MarketplaceLegalNotice } from '../models/index'
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    MarketplaceLegalNoticeFromJSON,
    MarketplaceLegalNoticeToJSON,
} from '../models/index'

export interface AddUpdateLegalNoticeToMarketplaceRequest {
    marketplaceKey: string
    language: string
    marketplaceLegalNotice: MarketplaceLegalNotice
}

export interface GetMarketplaceLegalNoticeRequest {
    marketplaceKey: string
    language: string
}

export interface GetMarketplaceLegalNoticesRequest {
    marketplaceKey: string
}

/**
 *
 */
export class MarketplacesLegalNoticeApi extends runtime.BaseAPI {
    /**
     */
    async addUpdateLegalNoticeToMarketplaceRaw(
        requestParameters: AddUpdateLegalNoticeToMarketplaceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<MarketplaceLegalNotice>> {
        if (requestParameters['marketplaceKey'] == null) {
            throw new runtime.RequiredError(
                'marketplaceKey',
                'Required parameter "marketplaceKey" was null or undefined when calling addUpdateLegalNoticeToMarketplace().'
            )
        }

        if (requestParameters['language'] == null) {
            throw new runtime.RequiredError(
                'language',
                'Required parameter "language" was null or undefined when calling addUpdateLegalNoticeToMarketplace().'
            )
        }

        if (requestParameters['marketplaceLegalNotice'] == null) {
            throw new runtime.RequiredError(
                'marketplaceLegalNotice',
                'Required parameter "marketplaceLegalNotice" was null or undefined when calling addUpdateLegalNoticeToMarketplace().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request(
            {
                path: `/api/marketplaces/{marketplaceKey}/legalNotices/{language}`
                    .replace(`{${'marketplaceKey'}}`, encodeURIComponent(String(requestParameters['marketplaceKey'])))
                    .replace(`{${'language'}}`, encodeURIComponent(String(requestParameters['language']))),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: MarketplaceLegalNoticeToJSON(requestParameters['marketplaceLegalNotice']),
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => MarketplaceLegalNoticeFromJSON(jsonValue))
    }

    /**
     */
    async addUpdateLegalNoticeToMarketplace(
        requestParameters: AddUpdateLegalNoticeToMarketplaceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<MarketplaceLegalNotice> {
        const response = await this.addUpdateLegalNoticeToMarketplaceRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async getMarketplaceLegalNoticeRaw(
        requestParameters: GetMarketplaceLegalNoticeRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<MarketplaceLegalNotice>> {
        if (requestParameters['marketplaceKey'] == null) {
            throw new runtime.RequiredError(
                'marketplaceKey',
                'Required parameter "marketplaceKey" was null or undefined when calling getMarketplaceLegalNotice().'
            )
        }

        if (requestParameters['language'] == null) {
            throw new runtime.RequiredError(
                'language',
                'Required parameter "language" was null or undefined when calling getMarketplaceLegalNotice().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/marketplaces/{marketplaceKey}/legalNotices/{language}`
                    .replace(`{${'marketplaceKey'}}`, encodeURIComponent(String(requestParameters['marketplaceKey'])))
                    .replace(`{${'language'}}`, encodeURIComponent(String(requestParameters['language']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => MarketplaceLegalNoticeFromJSON(jsonValue))
    }

    /**
     */
    async getMarketplaceLegalNotice(
        requestParameters: GetMarketplaceLegalNoticeRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<MarketplaceLegalNotice> {
        const response = await this.getMarketplaceLegalNoticeRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async getMarketplaceLegalNoticesRaw(
        requestParameters: GetMarketplaceLegalNoticesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<Array<MarketplaceLegalNotice>>> {
        if (requestParameters['marketplaceKey'] == null) {
            throw new runtime.RequiredError(
                'marketplaceKey',
                'Required parameter "marketplaceKey" was null or undefined when calling getMarketplaceLegalNotices().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/marketplaces/{marketplaceKey}/legalNotices`.replace(
                    `{${'marketplaceKey'}}`,
                    encodeURIComponent(String(requestParameters['marketplaceKey']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(MarketplaceLegalNoticeFromJSON))
    }

    /**
     */
    async getMarketplaceLegalNotices(
        requestParameters: GetMarketplaceLegalNoticesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<Array<MarketplaceLegalNotice>> {
        const response = await this.getMarketplaceLegalNoticesRaw(requestParameters, initOverrides)
        return await response.value()
    }
}
