import { themeLight } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'

import {
    dashboardPopoverMessages,
    dashboardPreviewMessages,
    dashboardStatusMessages,
    dashboardStatusMessageTooltip,
    dashboardTitles,
} from '@slmpserv/common-translations'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { useMatomoTrackEditEvent } from '@slmpserv/common/analytics'
import { AccessSensitiveProps } from '@slmpserv/self-service-shared'

import { DashboardGroupItem, DashboardWarningTag } from '../DashboardGroupItem/DashboardGroupItem'
import { DashboardGroupItemPreview } from '../DashboardGroupItemPreview/DashboardGroupItemPreview'

type PaymentProviderNotificationsItemProps = AccessSensitiveProps & {
    contactEmailAddress?: string[]
    emailsMissing: boolean
}

export function PaymentProviderNotificationsItem({
    contactEmailAddress,
    emailsMissing,
    isAllowedToEdit,
}: PaymentProviderNotificationsItemProps) {
    const { formatMessage } = useIntl()
    const { trackEvent } = useMatomoTrackEditEvent('payment_provider_notifications', emailsMissing)

    return (
        <DashboardGroupItem
            hightlightColor={emailsMissing ? themeLight.notification.warning : null}
            title={formatMessage(dashboardTitles.paymentProcessingStatusNotifications)}
            tooltipText={formatMessage(dashboardPopoverMessages.paymentProcessingStatusNotifications)}
            preview={
                <DashboardGroupItemPreview
                    title={formatMessage(dashboardPreviewMessages.contactEmailAddresses)}
                    text={contactEmailAddress?.length === 0 ? '-' : contactEmailAddress}
                />
            }
            badge={
                emailsMissing && (
                    <DashboardWarningTag
                        popoverText={formatMessage(dashboardStatusMessageTooltip.paymentProcessingStatusNotifications)}
                        tagText={formatMessage(dashboardStatusMessages.addContact)}
                    />
                )
            }
            editLink={{
                isAllowedToEdit,
                props: {
                    to: StaticRoutes.KYC_NOTIFICATIONS,
                },
                dataTestId: 'payment-notifications-edit-link',
                matomoEvent: trackEvent,
            }}
        />
    )
}
