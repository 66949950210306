import { spacingFluid as spacing } from '@porsche-design-system/components-react/styles'
import { ReactNode } from 'react'
import { styled } from 'styled-components'

export type Space = keyof typeof spacing

export interface SpacingProps {
    marginLeft?: Space
    marginTop?: Space
    marginRight?: Space
    marginBottom?: Space
    margin?: Space
    paddingLeft?: Space
    paddingTop?: Space
    paddingRight?: Space
    paddingBottom?: Space
    padding?: Space
    nowrap?: boolean
    backgroundColor?: string
    children?: ReactNode | undefined
}

export const Spacing = styled.div<SpacingProps>`
    ${({ marginLeft }) => marginLeft && 'margin-left: ' + spacing[marginLeft] + ';'}
    ${({ marginRight }) => marginRight && 'margin-right: ' + spacing[marginRight] + ';'}
    ${({ marginTop }) => marginTop && 'margin-top: ' + spacing[marginTop] + ';'}
    ${({ marginBottom }) => marginBottom && 'margin-bottom: ' + spacing[marginBottom] + ';'}
    ${({ margin }) => margin && 'margin: ' + spacing[margin] + ';'}
    ${({ paddingLeft }) => paddingLeft && 'padding-left: ' + spacing[paddingLeft] + ';'}
    ${({ paddingRight }) => paddingRight && 'padding-right: ' + spacing[paddingRight] + ';'}
    ${({ paddingTop }) => paddingTop && 'padding-top: ' + spacing[paddingTop] + ';'}
    ${({ paddingBottom }) => paddingBottom && 'padding-bottom: ' + spacing[paddingBottom] + ';'}
    ${({ padding }) => padding && 'padding: ' + spacing[padding] + ';'}
    ${({ nowrap }) => nowrap && 'white-space: nowrap;'}
    ${({ backgroundColor }) => (backgroundColor ? 'background-color: ' + backgroundColor + ';' : '')}
`
