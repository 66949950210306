import { useMutation } from '@tanstack/react-query'

import { Merchant, merchantsApi } from '@slmpserv/common-api-merchant'

export function useSyncOnlineOrderingStatus(merchantId?: Merchant['id']) {
    const { mutateAsync } = useMutation({
        mutationFn: () => merchantsApi.syncOnlineOrderingStatus({ merchantId: merchantId }),
    })

    return mutateAsync
}
