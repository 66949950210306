/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CreatePaymentLinkRequest
 */
export interface CreatePaymentLinkRequest {
    /**
     *
     * @type {number}
     * @memberof CreatePaymentLinkRequest
     */
    amount: number
    /**
     *
     * @type {number}
     * @memberof CreatePaymentLinkRequest
     */
    validity: number
    /**
     *
     * @type {string}
     * @memberof CreatePaymentLinkRequest
     */
    marketplaceKey: string
    /**
     *
     * @type {string}
     * @memberof CreatePaymentLinkRequest
     */
    submerchantId: string
}

/**
 * Check if a given object implements the CreatePaymentLinkRequest interface.
 */
export function instanceOfCreatePaymentLinkRequest(value: object): boolean {
    if (!('amount' in value)) return false
    if (!('validity' in value)) return false
    if (!('marketplaceKey' in value)) return false
    if (!('submerchantId' in value)) return false
    return true
}

export function CreatePaymentLinkRequestFromJSON(json: any): CreatePaymentLinkRequest {
    return CreatePaymentLinkRequestFromJSONTyped(json, false)
}

export function CreatePaymentLinkRequestFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): CreatePaymentLinkRequest {
    if (json == null) {
        return json
    }
    return {
        amount: json['amount'],
        validity: json['validity'],
        marketplaceKey: json['marketplaceKey'],
        submerchantId: json['submerchantId'],
    }
}

export function CreatePaymentLinkRequestToJSON(value?: CreatePaymentLinkRequest | null): any {
    if (value == null) {
        return value
    }
    return {
        amount: value['amount'],
        validity: value['validity'],
        marketplaceKey: value['marketplaceKey'],
        submerchantId: value['submerchantId'],
    }
}
