import { ReactNode } from 'react'
import { themeLight, spacingFluid } from '@porsche-design-system/components-react/styles'
import { styled } from 'styled-components'
import { Flex } from '@slcheckout/ui-kit'

const StyledFileLayout = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${spacingFluid.small};
    padding: ${spacingFluid.small};
    background: ${themeLight.contrast.low};
`

type HasFileLayoutProps = {
    fileInfo: ReactNode
    updateFile: ReactNode
    disabled?: boolean
}

export function HasFileLayout({ fileInfo, updateFile }: HasFileLayoutProps) {
    return (
        <StyledFileLayout>
            <Flex direction="column">{fileInfo}</Flex>
            <Flex>{updateFile}</Flex>
        </StyledFileLayout>
    )
}
