/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Amount, AmountFromJSON, AmountFromJSONTyped, AmountToJSON } from './'

/**
 *
 * @export
 * @interface SubmerchantDebitRequestRestModel
 */
export interface SubmerchantDebitRequestRestModel {
    /**
     *
     * @type {Amount}
     * @memberof SubmerchantDebitRequestRestModel
     */
    amount?: Amount
    /**
     *
     * @type {string}
     * @memberof SubmerchantDebitRequestRestModel
     */
    merchantReference?: string
    /**
     * Description of request
     * @type {string}
     * @memberof SubmerchantDebitRequestRestModel
     */
    description?: string
}

export function SubmerchantDebitRequestRestModelFromJSON(json: any): SubmerchantDebitRequestRestModel {
    return SubmerchantDebitRequestRestModelFromJSONTyped(json, false)
}

export function SubmerchantDebitRequestRestModelFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): SubmerchantDebitRequestRestModel {
    if (json === undefined || json === null) {
        return json
    }
    return {
        amount: !exists(json, 'amount') ? undefined : AmountFromJSON(json['amount']),
        merchantReference: !exists(json, 'merchantReference') ? undefined : json['merchantReference'],
        description: !exists(json, 'description') ? undefined : json['description'],
    }
}

export function SubmerchantDebitRequestRestModelToJSON(value?: SubmerchantDebitRequestRestModel | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        amount: AmountToJSON(value.amount),
        merchantReference: value.merchantReference,
        description: value.description,
    }
}
