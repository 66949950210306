/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum MerchantStatusErrors {
    INQUIRES_EMAIL = 'INQUIRES_EMAIL',
    INQUIRES_PHONE = 'INQUIRES_PHONE',
    ONLINE_ORDER_EMAIL = 'ONLINE_ORDER_EMAIL',
    RESERVATION_FEE = 'RESERVATION_FEE',
    PAYMENT_SERVICE = 'PAYMENT_SERVICE',
    TNC = 'TNC',
    IMPRINT = 'IMPRINT',
    PRIVACY_POLICY = 'PRIVACY_POLICY',
    RESERVATION = 'RESERVATION',
}

export function instanceOfMerchantStatusErrors(value: any): boolean {
    return Object.values(MerchantStatusErrors).includes(value)
}

export function MerchantStatusErrorsFromJSON(json: any): MerchantStatusErrors {
    return MerchantStatusErrorsFromJSONTyped(json, false)
}

export function MerchantStatusErrorsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantStatusErrors {
    return json as MerchantStatusErrors
}

export function MerchantStatusErrorsToJSON(value?: MerchantStatusErrors | null): any {
    return value as any
}
