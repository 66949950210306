/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
    ErrorResponse,
    OnlineOrderConfigurationDownPaymentRequest,
    OnlineOrderConfigurationEmailsRequest,
    OnlineOrderConfigurationRequest,
    OnlineOrderConfigurationResponse,
    PayoutInformationEmailsRequest,
} from '../models/index'
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    OnlineOrderConfigurationDownPaymentRequestFromJSON,
    OnlineOrderConfigurationDownPaymentRequestToJSON,
    OnlineOrderConfigurationEmailsRequestFromJSON,
    OnlineOrderConfigurationEmailsRequestToJSON,
    OnlineOrderConfigurationRequestFromJSON,
    OnlineOrderConfigurationRequestToJSON,
    OnlineOrderConfigurationResponseFromJSON,
    OnlineOrderConfigurationResponseToJSON,
    PayoutInformationEmailsRequestFromJSON,
    PayoutInformationEmailsRequestToJSON,
} from '../models/index'

export interface GetOrderConfigurationRequest {
    ppnId: string
}

export interface UpdateOrderConfigurationRequest {
    ppnId: string
    onlineOrderConfigurationRequest: OnlineOrderConfigurationRequest
}

export interface UpdateOrderConfigurationDownPaymentRequest {
    ppnId: string
    onlineOrderConfigurationDownPaymentRequest: OnlineOrderConfigurationDownPaymentRequest
}

export interface UpdateOrderConfigurationEmailsRequest {
    ppnId: string
    onlineOrderConfigurationEmailsRequest: OnlineOrderConfigurationEmailsRequest
}

export interface UpdatePayoutInformationEmailsRequest {
    ppnId: string
    payoutInformationEmailsRequest: PayoutInformationEmailsRequest
}

/**
 *
 */
export class OnlineOrderConfigurationApi extends runtime.BaseAPI {
    /**
     */
    async getOrderConfigurationRaw(
        requestParameters: GetOrderConfigurationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<OnlineOrderConfigurationResponse>> {
        if (requestParameters['ppnId'] == null) {
            throw new runtime.RequiredError(
                'ppnId',
                'Required parameter "ppnId" was null or undefined when calling getOrderConfiguration().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/merchants/{ppnId}/onlineOrderConfiguration`.replace(
                    `{${'ppnId'}}`,
                    encodeURIComponent(String(requestParameters['ppnId']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => OnlineOrderConfigurationResponseFromJSON(jsonValue))
    }

    /**
     */
    async getOrderConfiguration(
        requestParameters: GetOrderConfigurationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<OnlineOrderConfigurationResponse> {
        const response = await this.getOrderConfigurationRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async updateOrderConfigurationRaw(
        requestParameters: UpdateOrderConfigurationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<OnlineOrderConfigurationResponse>> {
        if (requestParameters['ppnId'] == null) {
            throw new runtime.RequiredError(
                'ppnId',
                'Required parameter "ppnId" was null or undefined when calling updateOrderConfiguration().'
            )
        }

        if (requestParameters['onlineOrderConfigurationRequest'] == null) {
            throw new runtime.RequiredError(
                'onlineOrderConfigurationRequest',
                'Required parameter "onlineOrderConfigurationRequest" was null or undefined when calling updateOrderConfiguration().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request(
            {
                path: `/api/merchants/{ppnId}/onlineOrderConfiguration`.replace(
                    `{${'ppnId'}}`,
                    encodeURIComponent(String(requestParameters['ppnId']))
                ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: OnlineOrderConfigurationRequestToJSON(requestParameters['onlineOrderConfigurationRequest']),
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => OnlineOrderConfigurationResponseFromJSON(jsonValue))
    }

    /**
     */
    async updateOrderConfiguration(
        requestParameters: UpdateOrderConfigurationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<OnlineOrderConfigurationResponse> {
        const response = await this.updateOrderConfigurationRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async updateOrderConfigurationDownPaymentRaw(
        requestParameters: UpdateOrderConfigurationDownPaymentRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<OnlineOrderConfigurationResponse>> {
        if (requestParameters['ppnId'] == null) {
            throw new runtime.RequiredError(
                'ppnId',
                'Required parameter "ppnId" was null or undefined when calling updateOrderConfigurationDownPayment().'
            )
        }

        if (requestParameters['onlineOrderConfigurationDownPaymentRequest'] == null) {
            throw new runtime.RequiredError(
                'onlineOrderConfigurationDownPaymentRequest',
                'Required parameter "onlineOrderConfigurationDownPaymentRequest" was null or undefined when calling updateOrderConfigurationDownPayment().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request(
            {
                path: `/api/merchants/{ppnId}/onlineOrderConfiguration/downPayment`.replace(
                    `{${'ppnId'}}`,
                    encodeURIComponent(String(requestParameters['ppnId']))
                ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: OnlineOrderConfigurationDownPaymentRequestToJSON(
                    requestParameters['onlineOrderConfigurationDownPaymentRequest']
                ),
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => OnlineOrderConfigurationResponseFromJSON(jsonValue))
    }

    /**
     */
    async updateOrderConfigurationDownPayment(
        requestParameters: UpdateOrderConfigurationDownPaymentRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<OnlineOrderConfigurationResponse> {
        const response = await this.updateOrderConfigurationDownPaymentRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async updateOrderConfigurationEmailsRaw(
        requestParameters: UpdateOrderConfigurationEmailsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<OnlineOrderConfigurationResponse>> {
        if (requestParameters['ppnId'] == null) {
            throw new runtime.RequiredError(
                'ppnId',
                'Required parameter "ppnId" was null or undefined when calling updateOrderConfigurationEmails().'
            )
        }

        if (requestParameters['onlineOrderConfigurationEmailsRequest'] == null) {
            throw new runtime.RequiredError(
                'onlineOrderConfigurationEmailsRequest',
                'Required parameter "onlineOrderConfigurationEmailsRequest" was null or undefined when calling updateOrderConfigurationEmails().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request(
            {
                path: `/api/merchants/{ppnId}/onlineOrderConfiguration/orderConfigurationEmails`.replace(
                    `{${'ppnId'}}`,
                    encodeURIComponent(String(requestParameters['ppnId']))
                ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: OnlineOrderConfigurationEmailsRequestToJSON(
                    requestParameters['onlineOrderConfigurationEmailsRequest']
                ),
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => OnlineOrderConfigurationResponseFromJSON(jsonValue))
    }

    /**
     */
    async updateOrderConfigurationEmails(
        requestParameters: UpdateOrderConfigurationEmailsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<OnlineOrderConfigurationResponse> {
        const response = await this.updateOrderConfigurationEmailsRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async updatePayoutInformationEmailsRaw(
        requestParameters: UpdatePayoutInformationEmailsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<OnlineOrderConfigurationResponse>> {
        if (requestParameters['ppnId'] == null) {
            throw new runtime.RequiredError(
                'ppnId',
                'Required parameter "ppnId" was null or undefined when calling updatePayoutInformationEmails().'
            )
        }

        if (requestParameters['payoutInformationEmailsRequest'] == null) {
            throw new runtime.RequiredError(
                'payoutInformationEmailsRequest',
                'Required parameter "payoutInformationEmailsRequest" was null or undefined when calling updatePayoutInformationEmails().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request(
            {
                path: `/api/merchants/{ppnId}/onlineOrderConfiguration/payoutInformationEmails`.replace(
                    `{${'ppnId'}}`,
                    encodeURIComponent(String(requestParameters['ppnId']))
                ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: PayoutInformationEmailsRequestToJSON(requestParameters['payoutInformationEmailsRequest']),
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => OnlineOrderConfigurationResponseFromJSON(jsonValue))
    }

    /**
     */
    async updatePayoutInformationEmails(
        requestParameters: UpdatePayoutInformationEmailsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<OnlineOrderConfigurationResponse> {
        const response = await this.updatePayoutInformationEmailsRaw(requestParameters, initOverrides)
        return await response.value()
    }
}
