import { useQuery } from '@tanstack/react-query'

import { EditRights, rightsRestControllerApi } from '@slmpserv/common-api-merchant'

function useFetchRights(entryDn: string) {
    const queryResult = useQuery({
        queryKey: ['permissions', entryDn],
        queryFn: () => rightsRestControllerApi.getEditRights({ entryDn }),
        staleTime: 60 * 1000,
    })
    return queryResult.data || []
}

export function useIsAllowedToEditSeller(entryDn: string) {
    return useFetchRights(entryDn).includes(EditRights.SELLER)
}

export function useIsAllowedToEditMerchant(entryDn: string) {
    return useFetchRights(entryDn).includes(EditRights.MERCHANT)
}
