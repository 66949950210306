import { useEffect } from 'react'

import { basePathMiddleware } from '@slmpserv/purchase-request-settings-api'
import { Environment } from '@slmpserv/environment'

export const usePurchaseRequestSettingsApiBasePath = (environment: Environment): void => {
    useEffect(() => {
        basePathMiddleware.setEnv(environment)
    }, [environment])
}
