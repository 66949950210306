const devIndicator = 'dev.app.slmpserv.aws.porsche-preview.cloud'
const previewIndicator = 'app.slmpserv.aws.porsche-preview.cloud'
const prodIndicator = 'app.slmpserv.aws.porsche.cloud'

export enum Environment {
    PROD = 'PROD',
    PREVIEW = 'PREVIEW',
    DEV = 'DEV',
    LOCAL = 'LOCAL',
    E2E = 'E2E',
}

export const getEnvironment = (): Environment => {
    const hostname = window.location.hostname
    if (hostname.includes(devIndicator)) {
        return Environment.DEV
    } else if (hostname.includes(previewIndicator)) {
        return Environment.PREVIEW
    } else if (hostname.includes(prodIndicator)) {
        return Environment.PROD
    } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return typeof window['Cypress'] !== 'undefined' ? Environment.E2E : Environment.LOCAL
    }
}
