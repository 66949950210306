/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PagOidUpdateRequestDto
 */
export interface PagOidUpdateRequestDto {
    /**
     *
     * @type {string}
     * @memberof PagOidUpdateRequestDto
     */
    pagOid: string
}

/**
 * Check if a given object implements the PagOidUpdateRequestDto interface.
 */
export function instanceOfPagOidUpdateRequestDto(value: object): boolean {
    if (!('pagOid' in value)) return false
    return true
}

export function PagOidUpdateRequestDtoFromJSON(json: any): PagOidUpdateRequestDto {
    return PagOidUpdateRequestDtoFromJSONTyped(json, false)
}

export function PagOidUpdateRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagOidUpdateRequestDto {
    if (json == null) {
        return json
    }
    return {
        pagOid: json['pagOid'],
    }
}

export function PagOidUpdateRequestDtoToJSON(value?: PagOidUpdateRequestDto | null): any {
    if (value == null) {
        return value
    }
    return {
        pagOid: value['pagOid'],
    }
}
