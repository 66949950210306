/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { DocumentType } from './DocumentType'
import { DocumentTypeFromJSON, DocumentTypeFromJSONTyped, DocumentTypeToJSON } from './DocumentType'

/**
 *
 * @export
 * @interface MarketplaceDocumentConfig
 */
export interface MarketplaceDocumentConfig {
    /**
     *
     * @type {string}
     * @memberof MarketplaceDocumentConfig
     */
    id?: string
    /**
     *
     * @type {DocumentType}
     * @memberof MarketplaceDocumentConfig
     */
    documentType: DocumentType
    /**
     *
     * @type {DocumentType}
     * @memberof MarketplaceDocumentConfig
     */
    documentFallback?: DocumentType
    /**
     *
     * @type {string}
     * @memberof MarketplaceDocumentConfig
     */
    marketplaceKey: string
    /**
     *
     * @type {string}
     * @memberof MarketplaceDocumentConfig
     */
    documentStatus: MarketplaceDocumentConfigDocumentStatusEnum
}

/**
 * @export
 * @enum {string}
 */
export enum MarketplaceDocumentConfigDocumentStatusEnum {
    DISABLED = 'DISABLED',
    REQUIRED = 'REQUIRED',
    OPTIONAL = 'OPTIONAL',
}

/**
 * Check if a given object implements the MarketplaceDocumentConfig interface.
 */
export function instanceOfMarketplaceDocumentConfig(value: object): boolean {
    if (!('documentType' in value)) return false
    if (!('marketplaceKey' in value)) return false
    if (!('documentStatus' in value)) return false
    return true
}

export function MarketplaceDocumentConfigFromJSON(json: any): MarketplaceDocumentConfig {
    return MarketplaceDocumentConfigFromJSONTyped(json, false)
}

export function MarketplaceDocumentConfigFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): MarketplaceDocumentConfig {
    if (json == null) {
        return json
    }
    return {
        id: json['id'] == null ? undefined : json['id'],
        documentType: DocumentTypeFromJSON(json['documentType']),
        documentFallback: json['documentFallback'] == null ? undefined : DocumentTypeFromJSON(json['documentFallback']),
        marketplaceKey: json['marketplaceKey'],
        documentStatus: json['documentStatus'],
    }
}

export function MarketplaceDocumentConfigToJSON(value?: MarketplaceDocumentConfig | null): any {
    if (value == null) {
        return value
    }
    return {
        id: value['id'],
        documentType: DocumentTypeToJSON(value['documentType']),
        documentFallback: DocumentTypeToJSON(value['documentFallback']),
        marketplaceKey: value['marketplaceKey'],
        documentStatus: value['documentStatus'],
    }
}
