export function getDaysFromToday(futureDate?: Date) {
    if (!futureDate) {
        return 0
    }

    const MS_PER_DAY = 1000 * 60 * 60 * 24
    const today = new Date()

    // Discard the time and time-zone information.
    const utc1 = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())
    const utc2 = Date.UTC(futureDate.getFullYear(), futureDate.getMonth(), futureDate.getDate())
    return Math.floor((utc2 - utc1) / MS_PER_DAY)
}
