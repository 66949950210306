/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SellerContactEmail
 */
export interface SellerContactEmail {
    /**
     *
     * @type {string}
     * @memberof SellerContactEmail
     */
    entryDn: string
    /**
     *
     * @type {boolean}
     * @memberof SellerContactEmail
     */
    differNewPreowned: boolean
    /**
     *
     * @type {Array<string>}
     * @memberof SellerContactEmail
     */
    emailAddressesNew: Array<string>
    /**
     *
     * @type {Array<string>}
     * @memberof SellerContactEmail
     */
    emailAddressesPreowned: Array<string>
}

/**
 * Check if a given object implements the SellerContactEmail interface.
 */
export function instanceOfSellerContactEmail(value: object): boolean {
    if (!('entryDn' in value)) return false
    if (!('differNewPreowned' in value)) return false
    if (!('emailAddressesNew' in value)) return false
    if (!('emailAddressesPreowned' in value)) return false
    return true
}

export function SellerContactEmailFromJSON(json: any): SellerContactEmail {
    return SellerContactEmailFromJSONTyped(json, false)
}

export function SellerContactEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): SellerContactEmail {
    if (json == null) {
        return json
    }
    return {
        entryDn: json['entryDn'],
        differNewPreowned: json['differNewPreowned'],
        emailAddressesNew: json['emailAddressesNew'],
        emailAddressesPreowned: json['emailAddressesPreowned'],
    }
}

export function SellerContactEmailToJSON(value?: SellerContactEmail | null): any {
    if (value == null) {
        return value
    }
    return {
        entryDn: value['entryDn'],
        differNewPreowned: value['differNewPreowned'],
        emailAddressesNew: value['emailAddressesNew'],
        emailAddressesPreowned: value['emailAddressesPreowned'],
    }
}
