import { spacingFluid } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'
import { styled } from 'styled-components'

import { useMarketplaceContext } from '@slmpserv/common-contexts'
import { DownPaymentMarketplaceStatus, DownPaymentResponseCurrencyEnum } from '@slmpserv/common-api-merchant'
import { FormTextInput } from '@slmpserv/common-components'

import { downPaymentConfigurationMessages } from './DownPayment.messages'

const StyledDownPaymentAmountGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-bottom: ${spacingFluid.medium};
`

const StyledFormTextInput = styled(FormTextInput)`
    margin-bottom: ${spacingFluid.medium};
`

type DownpaymentAmountFieldProps = {
    downPaymentActive: boolean
    currency?: DownPaymentResponseCurrencyEnum
}

export function DownPaymentAmountField({ downPaymentActive, currency }: DownpaymentAmountFieldProps) {
    const { formatMessage } = useIntl()
    const marketplace = useMarketplaceContext()

    if (marketplace.downPaymentStatus === DownPaymentMarketplaceStatus.DISABLED) {
        return null
    }

    const downPaymentInputDisabled = !(
        marketplace.downPaymentStatus === DownPaymentMarketplaceStatus.REQUIRED || downPaymentActive
    )

    return (
        <StyledDownPaymentAmountGrid>
            <StyledFormTextInput
                label={formatMessage(downPaymentConfigurationMessages.downPaymentAmountLabel, { currency })}
                fieldName={'downPaymentValue'}
                registerOptions={{
                    valueAsNumber: true,
                    required: formatMessage(downPaymentConfigurationMessages.dealerDownPaymentEmpty),
                    min: {
                        value: 0,
                        message: formatMessage(downPaymentConfigurationMessages.dealerDownPaymentNegative),
                    },
                }}
                disabled={downPaymentInputDisabled}
                step={1}
                type={'number'}
                data-testid={'downPaymentValue'}
            />
        </StyledDownPaymentAmountGrid>
    )
}
