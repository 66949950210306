import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import { useToastManager } from '@porsche-design-system/components-react'

import { sellerContactApi, SellerContactEmail } from '@slmpserv/common-api-merchant'
import { useFetchSellerContactMailAddresses } from '@slmpserv/common/api/use-query-wrapper'

import { onlineOrderConfigurationMessages } from '../OnlineOrderConfiguration.messages'
import { UsedNewCarContact } from '../types'

export type SellerContactEmailFormState = {
    newCarEmails: Array<{ value: string }>
    usedCarEmails: Array<{ value: string }>
    newCarSameAsUsedCar: UsedNewCarContact
}

export function useSellerContactEmailsForm(entryDn: string) {
    const { addMessage } = useToastManager()
    const { formatMessage } = useIntl()

    const methods = useForm<SellerContactEmailFormState>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: { newCarEmails: [{ value: '' }], usedCarEmails: [{ value: '' }] },
    })

    const initForm = useCallback(
        (data: SellerContactEmail) => {
            const formValues: SellerContactEmailFormState = {
                newCarEmails: data.emailAddressesNew.map(email => ({ value: email })),
                usedCarEmails: data.emailAddressesPreowned.map(email => ({ value: email })),
                newCarSameAsUsedCar: data.differNewPreowned ? UsedNewCarContact.Different : UsedNewCarContact.Same,
            }

            formValues.newCarEmails = formValues.newCarEmails.length === 0 ? [{ value: '' }] : formValues.newCarEmails
            formValues.usedCarEmails =
                formValues.usedCarEmails.length === 0 ? [{ value: '' }] : formValues.usedCarEmails

            methods.reset(formValues)
        },
        [methods]
    )

    const { queryResult: fetchSellerContactQueryResult } = useFetchSellerContactMailAddresses({
        entryDn,
        queryOptions: {
            refetchOnWindowFocus: false,
        },
        onSuccess: initForm,
    })

    const saveEmailsQueryResult = useMutation({
        mutationFn: (formValues: SellerContactEmailFormState) => {
            const emailAddressesPreowned = formValues.usedCarEmails.map(entry => entry.value)
            const emailAddressesNew = formValues.newCarEmails.map(entry => entry.value)
            const differNewPreowned = formValues.newCarSameAsUsedCar === UsedNewCarContact.Different

            const sellerContactEmail = {
                entryDn,
                emailAddressesNew: differNewPreowned ? emailAddressesNew : emailAddressesPreowned,
                emailAddressesPreowned,
                differNewPreowned,
            }

            return sellerContactApi.createOrUpdateEmail({
                sellerContactEmail,
            })
        },
    })

    const submitCallback = async (data: SellerContactEmailFormState): Promise<void> => {
        try {
            await saveEmailsQueryResult.mutateAsync(data)
            addMessage({ state: 'success', text: formatMessage(onlineOrderConfigurationMessages.toastSubmitSuccess) })
        } catch (err) {
            console.error(err)
            addMessage({ state: 'neutral', text: formatMessage(onlineOrderConfigurationMessages.toastSubmitError) })
        }
    }

    return {
        methods,
        handleSubmit: methods.handleSubmit(submitCallback),
        fetchSellerContactQueryResult: fetchSellerContactQueryResult,
    }
}
