import { useIntl } from 'react-intl'

import { DocumentType } from '@slmpserv/common-api-merchant'
import { descriptionMessages, documentManagementMessages } from '@slmpserv/common-translations'
import { CompletenessBadge, FormGroup, SpacedDivider } from '@slmpserv/common-components'

import { useDocumentProgress } from '../hooks/useDocumentProgress'
import { DocumentSelection } from '../components/DocumentSelection/DocumentSelection'
import { useFilterDisabledDocumentTypes } from '../hooks/useFilterDisabledDocumentTypes'

import { StyledHeading, StyledText } from './shared.styles'

type ReservationSectionProps = {
    language: string
}

export function ReservationSection({ language }: ReservationSectionProps) {
    const { formatMessage } = useIntl()

    const documentTypes = useFilterDisabledDocumentTypes([
        DocumentType.RESERVATION_GENERAL,
        DocumentType.RESERVATION_PRIVACY_POLICY,
    ])
    const requiredDocuments = documentTypes ?? []
    const { selectedDocumentCount, complete, total } = useDocumentProgress(language, requiredDocuments)

    return (
        <>
            <SpacedDivider />
            <FormGroup
                label={formatMessage(documentManagementMessages.reservationDocuments)}
                completedBadge={<CompletenessBadge done={selectedDocumentCount} total={total} complete={complete} />}
                labelColumnWidth={1}
                contentColumnWidth={4}
            >
                <StyledHeading tag="h4" size="large">
                    {formatMessage(descriptionMessages.reservationHeader)}
                </StyledHeading>
                <StyledText color={'neutral-contrast-high'}>
                    {formatMessage(descriptionMessages.reservationDetail)}
                </StyledText>
                <DocumentSelection documentType={DocumentType.RESERVATION_GENERAL} language={language} />

                <SpacedDivider />

                <StyledHeading tag="h4" size="large">
                    {formatMessage(descriptionMessages.reservationPrivacyPolicyHeader)}
                </StyledHeading>
                <StyledText color={'neutral-contrast-high'}>
                    {formatMessage(descriptionMessages.reservationPrivacyPolicyDetail)}
                </StyledText>
                <DocumentSelection documentType={DocumentType.RESERVATION_PRIVACY_POLICY} language={language} />
            </FormGroup>
        </>
    )
}
