/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { EditRights, ErrorResponse } from '../models/index'
import { EditRightsFromJSON, EditRightsToJSON, ErrorResponseFromJSON, ErrorResponseToJSON } from '../models/index'

export interface GetEditRightsRequest {
    entryDn: string
}

/**
 *
 */
export class RightsRestControllerApi extends runtime.BaseAPI {
    /**
     */
    async getEditRightsRaw(
        requestParameters: GetEditRightsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<Array<EditRights>>> {
        if (requestParameters['entryDn'] == null) {
            throw new runtime.RequiredError(
                'entryDn',
                'Required parameter "entryDn" was null or undefined when calling getEditRights().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/rights/edit/{entryDn}`.replace(
                    `{${'entryDn'}}`,
                    encodeURIComponent(String(requestParameters['entryDn']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(EditRightsFromJSON))
    }

    /**
     */
    async getEditRights(
        requestParameters: GetEditRightsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<Array<EditRights>> {
        const response = await this.getEditRightsRaw(requestParameters, initOverrides)
        return await response.value()
    }
}
