/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SellerContactPhone
 */
export interface SellerContactPhone {
    /**
     *
     * @type {string}
     * @memberof SellerContactPhone
     */
    entryDn: string
    /**
     *
     * @type {boolean}
     * @memberof SellerContactPhone
     */
    differNewPreowned: boolean
    /**
     *
     * @type {string}
     * @memberof SellerContactPhone
     */
    phoneNumberNew: string
    /**
     *
     * @type {string}
     * @memberof SellerContactPhone
     */
    phoneNumberPreowned: string
}

/**
 * Check if a given object implements the SellerContactPhone interface.
 */
export function instanceOfSellerContactPhone(value: object): boolean {
    if (!('entryDn' in value)) return false
    if (!('differNewPreowned' in value)) return false
    if (!('phoneNumberNew' in value)) return false
    if (!('phoneNumberPreowned' in value)) return false
    return true
}

export function SellerContactPhoneFromJSON(json: any): SellerContactPhone {
    return SellerContactPhoneFromJSONTyped(json, false)
}

export function SellerContactPhoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): SellerContactPhone {
    if (json == null) {
        return json
    }
    return {
        entryDn: json['entryDn'],
        differNewPreowned: json['differNewPreowned'],
        phoneNumberNew: json['phoneNumberNew'],
        phoneNumberPreowned: json['phoneNumberPreowned'],
    }
}

export function SellerContactPhoneToJSON(value?: SellerContactPhone | null): any {
    if (value == null) {
        return value
    }
    return {
        entryDn: value['entryDn'],
        differNewPreowned: value['differNewPreowned'],
        phoneNumberNew: value['phoneNumberNew'],
        phoneNumberPreowned: value['phoneNumberPreowned'],
    }
}
