import { FormProvider, useForm } from 'react-hook-form'
import { ReactNode, useCallback, useEffect } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useMerchant, useMultipleFormSubmits } from '@slmpserv/common-contexts'
import { QueryBoundary } from '@slmpserv/common-components'
import {
    CreateOrUpdateDecisionRequest,
    merchantTermsAndConditionsApi,
    SelectedOption,
} from '@slmpserv/common-api-merchant'
import { DocumentType } from '@slmpserv/common-api-merchant/models'

import { useLoadInitialTncFormState } from '../hooks/useLoadStoredMerchantDocumentConfig'

import { DocumentsFormState } from './DocumentsFormTypesAndConsts'

type DocumentsFormProps = {
    marketplaceKey: string
    languages: string[]
    children: ReactNode
}

export function DocumentsForm({ children, marketplaceKey, languages }: DocumentsFormProps) {
    const queryClient = useQueryClient()
    const { id: merchantId } = useMerchant()
    const methods = useForm<DocumentsFormState>({ mode: 'onSubmit' })
    const { queryResults, reinizializeForm } = useLoadInitialTncFormState({
        marketplaceKey,
        merchantId,
        languages,
        reset: methods.reset,
    })

    const { mutateAsync: updateCarSalesConfiguration } = useMutation({
        mutationFn: ({ offersNewCars, offersCsvCars }: DocumentsFormState) =>
            merchantTermsAndConditionsApi.saveCarSalesConfiguration({
                merchantId,
                carSalesConfiguration: {
                    offersNewCars,
                    offersCsvCars,
                },
            }),
    })

    const { mutateAsync: updateDecision } = useMutation({
        mutationFn: (requestParameters: CreateOrUpdateDecisionRequest) =>
            merchantTermsAndConditionsApi.createOrUpdateDecision(requestParameters),
    })

    const updateDocuments = useCallback(
        async (documentsFormState: DocumentsFormState) => {
            await updateCarSalesConfiguration(documentsFormState)

            for await (const language of languages) {
                const { files, decisions } = methods.formState.dirtyFields.documents?.[language] || {}

                const dirtyFields = { ...files, ...decisions }

                for (const documentType in dirtyFields) {
                    const typedDocumentType = documentType as DocumentType
                    const hasFileUpdate =
                        (
                            methods.formState.dirtyFields.documents?.[language]?.files as Record<
                                string,
                                boolean | undefined
                            >
                        )?.[documentType] === true
                    const hasDecisionUpdate =
                        (
                            methods.formState.dirtyFields.documents?.[language]?.decisions as Record<
                                string,
                                boolean | undefined
                            >
                        )?.[documentType] === true

                    await updateDecision({
                        merchantId,
                        documentType: typedDocumentType,
                        language,
                        selectedOption: hasDecisionUpdate
                            ? (documentsFormState.documents[language].decisions[typedDocumentType] as SelectedOption)
                            : (documentsFormState.documents[language].storedDecisions[
                                  typedDocumentType
                              ] as SelectedOption),
                        file: hasFileUpdate
                            ? documentsFormState.documents[language].files[typedDocumentType]?.[0]
                            : undefined,
                    })
                }
            }

            // Fetch again from server
            await queryClient.invalidateQueries({
                queryKey: ['merchant-terms-and-conditions-configuration', merchantId],
            })
            reinizializeForm()
        },
        [
            languages,
            merchantId,
            methods.formState.dirtyFields.documents,
            queryClient,
            reinizializeForm,
            updateCarSalesConfiguration,
            updateDecision,
        ]
    )

    const { addSubmitHandler } = useMultipleFormSubmits()
    useEffect(() => {
        addSubmitHandler({ name: `terms-and-conditions`, handler: methods.handleSubmit(updateDocuments) })
    }, [addSubmitHandler, methods, updateDocuments])

    return (
        <FormProvider {...methods}>
            <QueryBoundary queryResult={queryResults}>{children}</QueryBoundary>
        </FormProvider>
    )
}
