import { defineMessages } from 'react-intl'

export const onlineOrderConfigurationMessages = defineMessages({
    toastSubmitSuccess: {
        id: 'dealer.onlineOrderingConfig.toast.submit.success',
        defaultMessage: 'Saved',
    },
    toastSubmitError: {
        id: 'dealer.onlineOrderingConfig.toast.submit.error',
        defaultMessage: 'Error while saving',
    },
})
