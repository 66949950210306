/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum DownPaymentMarketplaceStatus {
    DISABLED = 'DISABLED',
    OPTIONAL = 'OPTIONAL',
    REQUIRED = 'REQUIRED',
}

export function instanceOfDownPaymentMarketplaceStatus(value: any): boolean {
    return Object.values(DownPaymentMarketplaceStatus).includes(value)
}

export function DownPaymentMarketplaceStatusFromJSON(json: any): DownPaymentMarketplaceStatus {
    return DownPaymentMarketplaceStatusFromJSONTyped(json, false)
}

export function DownPaymentMarketplaceStatusFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): DownPaymentMarketplaceStatus {
    return json as DownPaymentMarketplaceStatus
}

export function DownPaymentMarketplaceStatusToJSON(value?: DownPaymentMarketplaceStatus | null): any {
    return value as any
}
