/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { DownPaymentResponse } from './DownPaymentResponse'
import {
    DownPaymentResponseFromJSON,
    DownPaymentResponseFromJSONTyped,
    DownPaymentResponseToJSON,
} from './DownPaymentResponse'

/**
 *
 * @export
 * @interface OnlineOrderConfigurationResponse
 */
export interface OnlineOrderConfigurationResponse {
    /**
     *
     * @type {DownPaymentResponse}
     * @memberof OnlineOrderConfigurationResponse
     */
    downPayment: DownPaymentResponse
    /**
     *
     * @type {Array<string>}
     * @memberof OnlineOrderConfigurationResponse
     */
    emailsForOrderConfirmation: Array<string>
    /**
     *
     * @type {boolean}
     * @memberof OnlineOrderConfigurationResponse
     */
    differentEmailCsvCars?: boolean
    /**
     *
     * @type {Array<string>}
     * @memberof OnlineOrderConfigurationResponse
     */
    emailsForCsvOrderConfirmation?: Array<string>
    /**
     *
     * @type {Array<string>}
     * @memberof OnlineOrderConfigurationResponse
     */
    emailsForPayoutInformation: Array<string>
}

/**
 * Check if a given object implements the OnlineOrderConfigurationResponse interface.
 */
export function instanceOfOnlineOrderConfigurationResponse(value: object): boolean {
    if (!('downPayment' in value)) return false
    if (!('emailsForOrderConfirmation' in value)) return false
    if (!('emailsForPayoutInformation' in value)) return false
    return true
}

export function OnlineOrderConfigurationResponseFromJSON(json: any): OnlineOrderConfigurationResponse {
    return OnlineOrderConfigurationResponseFromJSONTyped(json, false)
}

export function OnlineOrderConfigurationResponseFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): OnlineOrderConfigurationResponse {
    if (json == null) {
        return json
    }
    return {
        downPayment: DownPaymentResponseFromJSON(json['downPayment']),
        emailsForOrderConfirmation: json['emailsForOrderConfirmation'],
        differentEmailCsvCars: json['differentEmailCsvCars'] == null ? undefined : json['differentEmailCsvCars'],
        emailsForCsvOrderConfirmation:
            json['emailsForCsvOrderConfirmation'] == null ? undefined : json['emailsForCsvOrderConfirmation'],
        emailsForPayoutInformation: json['emailsForPayoutInformation'],
    }
}

export function OnlineOrderConfigurationResponseToJSON(value?: OnlineOrderConfigurationResponse | null): any {
    if (value == null) {
        return value
    }
    return {
        downPayment: DownPaymentResponseToJSON(value['downPayment']),
        emailsForOrderConfirmation: value['emailsForOrderConfirmation'],
        differentEmailCsvCars: value['differentEmailCsvCars'],
        emailsForCsvOrderConfirmation: value['emailsForCsvOrderConfirmation'],
        emailsForPayoutInformation: value['emailsForPayoutInformation'],
    }
}
