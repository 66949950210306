/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum LegalDocumentType {
    NEW_CAR_BEV_CONDITIONS = 'NEW_CAR_BEV_CONDITIONS',
    NEW_CAR_CONDITIONS = 'NEW_CAR_CONDITIONS',
    NEW_CAR_PHEV_CONDITIONS = 'NEW_CAR_PHEV_CONDITIONS',
    PRIVACY_POLICY = 'PRIVACY_POLICY',
    USED_CAR_BEV_CONDITIONS_WITHOUT_WARRANTY = 'USED_CAR_BEV_CONDITIONS_WITHOUT_WARRANTY',
    USED_CAR_BEV_CONDITIONS_WITH_WARRANTY = 'USED_CAR_BEV_CONDITIONS_WITH_WARRANTY',
    USED_CAR_CONDITIONS_WITHOUT_WARRANTY = 'USED_CAR_CONDITIONS_WITHOUT_WARRANTY',
    USED_CAR_CONDITIONS_WITH_WARRANTY = 'USED_CAR_CONDITIONS_WITH_WARRANTY',
}

export function instanceOfLegalDocumentType(value: any): boolean {
    return Object.values(LegalDocumentType).includes(value)
}

export function LegalDocumentTypeFromJSON(json: any): LegalDocumentType {
    return LegalDocumentTypeFromJSONTyped(json, false)
}

export function LegalDocumentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegalDocumentType {
    return json as LegalDocumentType
}

export function LegalDocumentTypeToJSON(value?: LegalDocumentType | null): any {
    return value as any
}
