/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     *
     * @type {Date}
     * @memberof ErrorResponse
     */
    timestamp: Date
    /**
     *
     * @type {Array<string>}
     * @memberof ErrorResponse
     */
    errors: Array<string>
    /**
     *
     * @type {string}
     * @memberof ErrorResponse
     */
    status: string
    /**
     *
     * @type {string}
     * @memberof ErrorResponse
     */
    reason?: string
    /**
     *
     * @type {string}
     * @memberof ErrorResponse
     */
    traceId?: string
    /**
     *
     * @type {string}
     * @memberof ErrorResponse
     */
    spanId?: string
    /**
     *
     * @type {string}
     * @memberof ErrorResponse
     */
    xTraceId?: string
    /**
     *
     * @type {string}
     * @memberof ErrorResponse
     */
    xtraceId?: string
}

/**
 * Check if a given object implements the ErrorResponse interface.
 */
export function instanceOfErrorResponse(value: object): boolean {
    if (!('timestamp' in value)) return false
    if (!('errors' in value)) return false
    if (!('status' in value)) return false
    return true
}

export function ErrorResponseFromJSON(json: any): ErrorResponse {
    return ErrorResponseFromJSONTyped(json, false)
}

export function ErrorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorResponse {
    if (json == null) {
        return json
    }
    return {
        timestamp: new Date(json['timestamp']),
        errors: json['errors'],
        status: json['status'],
        reason: json['reason'] == null ? undefined : json['reason'],
        traceId: json['traceId'] == null ? undefined : json['traceId'],
        spanId: json['spanId'] == null ? undefined : json['spanId'],
        xTraceId: json['xTraceId'] == null ? undefined : json['xTraceId'],
        xtraceId: json['xtraceId'] == null ? undefined : json['xtraceId'],
    }
}

export function ErrorResponseToJSON(value?: ErrorResponse | null): any {
    if (value == null) {
        return value
    }
    return {
        timestamp: value['timestamp'].toISOString(),
        errors: value['errors'],
        status: value['status'],
        reason: value['reason'],
        traceId: value['traceId'],
        spanId: value['spanId'],
        xTraceId: value['xTraceId'],
        xtraceId: value['xtraceId'],
    }
}
