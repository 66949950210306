/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
    SubmerchantVerificationCheck,
    SubmerchantVerificationCheckFromJSON,
    SubmerchantVerificationCheckFromJSONTyped,
    SubmerchantVerificationCheckToJSON,
} from './'

/**
 *
 * @export
 * @interface SubmerchantVerification
 */
export interface SubmerchantVerification {
    /**
     * The code of the subject that didn't pass the verification check e.g. code of a shareholder
     * @type {string}
     * @memberof SubmerchantVerification
     */
    verificationSubjectCode: string
    /**
     * The type of subject that didn't pass the verification check e.g. shareholder
     * @type {string}
     * @memberof SubmerchantVerification
     */
    verificationSubjectType: string
    /**
     * Verification check data
     * @type {Array<SubmerchantVerificationCheck>}
     * @memberof SubmerchantVerification
     */
    checks: Array<SubmerchantVerificationCheck>
}

export function SubmerchantVerificationFromJSON(json: any): SubmerchantVerification {
    return SubmerchantVerificationFromJSONTyped(json, false)
}

export function SubmerchantVerificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmerchantVerification {
    if (json === undefined || json === null) {
        return json
    }
    return {
        verificationSubjectCode: json['verificationSubjectCode'],
        verificationSubjectType: json['verificationSubjectType'],
        checks: (json['checks'] as Array<any>).map(SubmerchantVerificationCheckFromJSON),
    }
}

export function SubmerchantVerificationToJSON(value?: SubmerchantVerification | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        verificationSubjectCode: value.verificationSubjectCode,
        verificationSubjectType: value.verificationSubjectType,
        checks: (value.checks as Array<any>).map(SubmerchantVerificationCheckToJSON),
    }
}
