/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface HostedOnboardingRequestRestModel
 */
export interface HostedOnboardingRequestRestModel {
    /**
     *
     * @type {string}
     * @memberof HostedOnboardingRequestRestModel
     */
    locale?: string
    /**
     *
     * @type {string}
     * @memberof HostedOnboardingRequestRestModel
     */
    returnUrl?: string
}

export function HostedOnboardingRequestRestModelFromJSON(json: any): HostedOnboardingRequestRestModel {
    return HostedOnboardingRequestRestModelFromJSONTyped(json, false)
}

export function HostedOnboardingRequestRestModelFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): HostedOnboardingRequestRestModel {
    if (json === undefined || json === null) {
        return json
    }
    return {
        locale: !exists(json, 'locale') ? undefined : json['locale'],
        returnUrl: !exists(json, 'returnUrl') ? undefined : json['returnUrl'],
    }
}

export function HostedOnboardingRequestRestModelToJSON(value?: HostedOnboardingRequestRestModel | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        locale: value.locale,
        returnUrl: value.returnUrl,
    }
}
