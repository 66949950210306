/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum DocumentType {
    NEW_CAR_GENERAL = 'NEW_CAR_GENERAL',
    NEW_CAR_BEV = 'NEW_CAR_BEV',
    NEW_CAR_PHEV = 'NEW_CAR_PHEV',
    USED_CAR_GENERAL = 'USED_CAR_GENERAL',
    USED_CAR_BEV = 'USED_CAR_BEV',
    USED_CAR_PHEV = 'USED_CAR_PHEV',
    USED_CAR_APPROVED_GENERAL = 'USED_CAR_APPROVED_GENERAL',
    USED_CAR_APPROVED_BEV = 'USED_CAR_APPROVED_BEV',
    USED_CAR_APPROVED_PHEV = 'USED_CAR_APPROVED_PHEV',
    CSV_CAR_GENERAL = 'CSV_CAR_GENERAL',
    CSV_CAR_BEV = 'CSV_CAR_BEV',
    CSV_CAR_PHEV = 'CSV_CAR_PHEV',
    PRIVACY_POLICY = 'PRIVACY_POLICY',
    RESERVATION_GENERAL = 'RESERVATION_GENERAL',
    RESERVATION_PRIVACY_POLICY = 'RESERVATION_PRIVACY_POLICY',
}

export function instanceOfDocumentType(value: any): boolean {
    return Object.values(DocumentType).includes(value)
}

export function DocumentTypeFromJSON(json: any): DocumentType {
    return DocumentTypeFromJSONTyped(json, false)
}

export function DocumentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentType {
    return json as DocumentType
}

export function DocumentTypeToJSON(value?: DocumentType | null): any {
    return value as any
}
