import { useQuery } from '@tanstack/react-query'

import { GetAllImprintsRequest, merchantImprintApi } from '@slmpserv/common-api-merchant'

type UseFetchAllImprintsProps = {
    merchantId: GetAllImprintsRequest['merchantId']
}

export function useFetchAllImprints({ merchantId }: UseFetchAllImprintsProps) {
    const queryKey = ['document-management', merchantId, 'imprints']

    const queryResult = useQuery({
        queryKey,
        queryFn: () => merchantImprintApi.getAllImprints({ merchantId }),
    })

    return { imprints: queryResult.data || [], queryResult, queryKey } as const
}
