import { PHeading } from '@porsche-design-system/components-react'
import { spacingFluid, themeLight } from '@porsche-design-system/components-react/styles'
import { ReactNode } from 'react'
import { styled } from 'styled-components'

const StyledFormSectionContainer = styled.div`
    padding: ${spacingFluid.large};
    border: 1px ${themeLight.contrast.low} solid;
    margin-bottom: ${spacingFluid.small};
`

type FormSectionProps = { header: string; children: ReactNode }

export function FormSection({ header, children }: FormSectionProps) {
    return (
        <StyledFormSectionContainer>
            <PHeading tag="h2" size="x-large" id={'conditions'}>
                {header}
            </PHeading>
            {children}
        </StyledFormSectionContainer>
    )
}
