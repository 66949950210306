import { useIntl } from 'react-intl'

import { MerchantResponse, MerchantStatus, MerchantStatusErrors } from '@slmpserv/common-api-merchant'
import { dashboardTitles } from '@slmpserv/common-translations'
import {
    useApplicationFeature,
    useFetchMerchantOnlineOrderConfig,
    useFetchSellerContactMailAddresses,
    useFetchSellerContactPhoneNumber,
} from '@slmpserv/common/api/use-query-wrapper'
import { useCurrentDealerContext } from '@slmpserv/self-service-current-dealer-context'
import { useIsAllowedToEditMerchant, useIsAllowedToEditSeller } from '@slmpserv/common/api/use-query-wrapper'

import { DashboardGroup } from '../DashboardGroup/DashboardGroup'
import { DataCompletedTag, DataWriteAccessIsRestricted } from '../DashboardGroupItem/DashboardGroupItem'

import { EmailsItem } from './EmailsItem'
import { HomepageItem } from './HomepageItem'
import { OnlineOrderContactItem } from './OnlineOrderContactItem'
import { PhoneItem } from './PhoneItem'
import { PurchaseRequestContactItemContainer } from './PurchaseRequestContactItem'

type DealershipContactProps = {
    merchant: MerchantResponse
    merchantStatus: MerchantStatus
    showPurchaseRequestSettings: boolean
}

export function DealershipContactGroup({
    merchant,
    merchantStatus,
    showPurchaseRequestSettings,
}: DealershipContactProps) {
    const { dealershipId } = useCurrentDealerContext()

    const isAllowedToEditSeller = useIsAllowedToEditSeller(dealershipId)
    const isAllowedToEditMerchant = useIsAllowedToEditMerchant(dealershipId)

    const { onlineOrderConfig } = useFetchMerchantOnlineOrderConfig({
        ppnId: merchant.ppnId,
    })

    const { emailAddressesNew, emailAddressesPreowned } = useFetchSellerContactMailAddresses({
        entryDn: dealershipId,
        queryOptions: {
            refetchOnWindowFocus: false,
        },
    })

    const { phoneNumberNew, phoneNumberPreowned } = useFetchSellerContactPhoneNumber({
        entryDn: dealershipId,
        queryOptions: {
            refetchOnWindowFocus: false,
        },
    })

    const { formatMessage } = useIntl()
    const inquiryFormActive = useApplicationFeature('inquiry-form')

    const emailsMissing = merchantStatus.errors.includes(MerchantStatusErrors.ONLINE_ORDER_EMAIL)
    const inquiryEmailsMissing = merchantStatus.errors.includes(MerchantStatusErrors.INQUIRES_EMAIL)
    const inquiryPhoneMissing = merchantStatus.errors.includes(MerchantStatusErrors.INQUIRES_PHONE)

    const offersCsvCars = merchant.offersCsvCars
    const differentEmailCsvCars = onlineOrderConfig?.differentEmailCsvCars ?? false
    const csvEmailsMissing =
        offersCsvCars &&
        differentEmailCsvCars &&
        merchantStatus.errors.includes(MerchantStatusErrors.ONLINE_ORDER_EMAIL)

    return (
        <DashboardGroup
            boxTitle={formatMessage(dashboardTitles.dealershipInformationGroup)}
            badge={
                <>
                    <DataCompletedTag isDataComplete={!merchantStatus.dealershipContactIncomplete} />
                    <DataWriteAccessIsRestricted hasAllWriteAccess={isAllowedToEditSeller && isAllowedToEditMerchant} />
                </>
            }
        >
            {inquiryFormActive && (
                <>
                    <EmailsItem
                        newCarsEmails={emailAddressesNew}
                        usedCarsEmails={emailAddressesPreowned}
                        emailsMissing={inquiryEmailsMissing}
                        isAllowedToEdit={isAllowedToEditSeller}
                    />

                    <PhoneItem
                        newCarsPhoneNumber={phoneNumberNew}
                        usedCarsPhoneNumber={phoneNumberPreowned}
                        phoneNumberMissing={inquiryPhoneMissing}
                        isAllowedToEdit={isAllowedToEditSeller}
                    />
                </>
            )}
            <OnlineOrderContactItem
                emailAddresses={onlineOrderConfig?.emailsForOrderConfirmation}
                emailsMissing={emailsMissing}
                offersCsvCars={offersCsvCars}
                differentEmailCsvCars={differentEmailCsvCars}
                csvEmailAddresses={
                    offersCsvCars &&
                    (differentEmailCsvCars
                        ? onlineOrderConfig?.emailsForCsvOrderConfirmation
                        : onlineOrderConfig?.emailsForOrderConfirmation)
                }
                csvEmailsMissing={csvEmailsMissing}
                isAllowedToEdit={isAllowedToEditMerchant}
            />

            {showPurchaseRequestSettings && <PurchaseRequestContactItemContainer />}

            <HomepageItem />
        </DashboardGroup>
    )
}
