import { useIntl } from 'react-intl'
import { themeLight } from '@porsche-design-system/components-react/styles'

import { DocumentType } from '@slmpserv/common-api-merchant'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { translateLanguage } from '@slmpserv/hooks'
import { dashboardStatusMessages } from '@slmpserv/common-translations'
import { useMatomoTrackEditEvent } from '@slmpserv/common/analytics'
import { AccessSensitiveProps } from '@slmpserv/self-service-shared'

import { DashboardGroupItem, DashboardWarningTag } from '../DashboardGroupItem/DashboardGroupItem'
import { DashboardGroupItemPreview } from '../DashboardGroupItemPreview/DashboardGroupItemPreview'

type TncItemProps = AccessSensitiveProps & {
    title: string
    tooltipText: string
    popoverText: string
    languages: string[]
    neededDocumentTypes: DocumentType[]
    documentsMissing: boolean
    missingDocuments: { [language: string]: DocumentType[] }
    dataTestId: string
}

export function TncItem({
    title,
    tooltipText,
    popoverText,
    missingDocuments,
    languages,
    neededDocumentTypes,
    documentsMissing,
    isAllowedToEdit,
    dataTestId,
}: TncItemProps) {
    const { formatMessage, locale: browserLocale } = useIntl()
    const { trackEvent } = useMatomoTrackEditEvent('tnc_documents', documentsMissing)
    // the backend sends the missing documents per language
    // valid documents = (number of expected documents) - (number of missing documents)
    const getValidDocumentCount = (language: string) =>
        neededDocumentTypes.length - (missingDocuments[language]?.length || 0)

    return (
        <DashboardGroupItem
            hightlightColor={documentsMissing ? themeLight.notification.warning : null}
            title={title}
            tooltipText={tooltipText}
            preview={languages.map(language => (
                <DashboardGroupItemPreview
                    key={language}
                    title={translateLanguage(language, browserLocale)}
                    text={getValidDocumentCount(language) + '/' + neededDocumentTypes.length}
                />
            ))}
            badge={
                documentsMissing && (
                    <DashboardWarningTag
                        tagText={formatMessage(dashboardStatusMessages.uploadDocument)}
                        popoverText={popoverText}
                    />
                )
            }
            editLink={{
                isAllowedToEdit,
                props: {
                    to: StaticRoutes.DOCUMENT_MANAGEMENT_TERMS_AND_CONDITIONS,
                },
                dataTestId: dataTestId,
                matomoEvent: trackEvent,
            }}
        />
    )
}
