import { PText } from '@porsche-design-system/components-react'
import { spacingStatic } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'
import { styled } from 'styled-components'

import { downPaymentConfigurationMessages } from './DownPayment.messages'

const StyledText = styled(PText)`
    margin-bottom: ${spacingStatic.large};
`

export function DownPaymentInfoText() {
    const { formatMessage } = useIntl()

    return <StyledText>{formatMessage(downPaymentConfigurationMessages.downPaymentInfoText)}</StyledText>
}
