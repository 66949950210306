import { ReactNode } from 'react'
import { PHeading } from '@porsche-design-system/components-react'
import { spacingFluid, themeLight } from '@porsche-design-system/components-react/styles'
import { styled } from 'styled-components'

const StyledBoxGrid = styled.div<{ fullWidth?: boolean }>`
    display: grid;
    grid-template-columns: ${({ fullWidth }) => (fullWidth ? '1fr' : '1fr 3fr')};
    gap: ${spacingFluid.medium};
    background: ${themeLight.background.base};
    margin-top: ${spacingFluid.medium};
    padding: ${spacingFluid.large} ${spacingFluid.large};
    &.disableMargin {
        margin-top: 0;
    }
`

export type BoxLayoutProps = {
    title?: string
    disableFirstElementMargin?: boolean
    children?: ReactNode
}

export function BoxLayout({ title, children, disableFirstElementMargin = false }: BoxLayoutProps) {
    const hasTitle = Boolean(title)
    return (
        <StyledBoxGrid className={disableFirstElementMargin ? 'disableMargin' : ''} fullWidth={!hasTitle}>
            {hasTitle && (
                <PHeading tag="h3" size="large">
                    {title}
                </PHeading>
            )}
            {children}
        </StyledBoxGrid>
    )
}
