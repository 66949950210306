import { PButtonPure, PInlineNotification } from '@porsche-design-system/components-react'
import { useState } from 'react'
import { useIntl } from 'react-intl'

import { finderDealerWrapperMessages } from '@slmpserv/common-translations'
import { SpacedDivider } from '@slmpserv/common-components'

import { Notification } from './useFetchNotifications'

type NotificationAreaProps = {
    notifications: Notification[]
}

export function NotificationArea({ notifications }: NotificationAreaProps) {
    const { formatMessage } = useIntl()
    const [areaIsVisible, setAreaIsVisible] = useState(true)

    if (notifications.length <= 0) {
        return null
    }

    return (
        <>
            <SpacedDivider spacingTop="large" spacingBottom="large" />

            <PButtonPure icon={areaIsVisible ? 'view-off' : 'view'} onClick={() => setAreaIsVisible(!areaIsVisible)}>
                {areaIsVisible
                    ? formatMessage(finderDealerWrapperMessages.hideNotifications, { count: notifications.length })
                    : formatMessage(finderDealerWrapperMessages.showNotifications, { count: notifications.length })}
            </PButtonPure>

            {areaIsVisible &&
                notifications.map((notification, index) => (
                    <PInlineNotification
                        key={index}
                        persistent={true}
                        state={notification.type ?? 'warning'}
                        heading={notification.title}
                        description={notification.text}
                        style={{ marginTop: '8px' }}
                    />
                ))}
        </>
    )
}
