import { Navigate, Route, Routes } from 'react-router-dom'

import { useCurrentDealerContext } from '@slmpserv/self-service-current-dealer-context'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { useMarketplaceFeatures } from '@slmpserv/hooks'
import { useFetchMarketplace } from '@slmpserv/common/api/use-query-wrapper'

import { SellerContactEmailPage, SellerContactPhonePage } from '../../pages'
import { SellerDashboardPage } from '../../pages/SellerDashboardPage'
import { PurchaseRequestContactPage } from '../../pages/PurchaseRequestContactPage'
import { ErrorRedirectBuilder } from '../../pages/Error/ErrorRedirectBuilder'

export function SellerRoutes() {
    const { dealershipId, marketplaceKey } = useCurrentDealerContext()

    const { marketplace, queryResult: marketplaceQueryResult } = useFetchMarketplace({ marketplaceKey })
    const marketplaceFeatures = useMarketplaceFeatures({ marketplace })

    const showPurchaseRequestSettings = marketplaceQueryResult.isSuccess
        ? marketplaceFeatures.PURCHASE_REQUEST_SETTINGS
        : false

    return (
        <Routes>
            <Route
                path={StaticRoutes.SELLER_CONTACT_EMAIL}
                element={<SellerContactEmailPage entryDn={dealershipId} />}
            />
            <Route
                path={StaticRoutes.SELLER_CONTACT_PHONE}
                element={<SellerContactPhonePage entryDn={dealershipId} />}
            />
            <Route
                path={StaticRoutes.PURCHASE_REQUEST_CONTACT}
                element={
                    showPurchaseRequestSettings ? (
                        <PurchaseRequestContactPage />
                    ) : (
                        <Navigate {...ErrorRedirectBuilder.featureNotEnabled()} />
                    )
                }
            />
            <Route path={StaticRoutes.DASHBOARD} element={<SellerDashboardPage />} />
            <Route path={'*'} element={<Navigate to={StaticRoutes.DASHBOARD} />} />
        </Routes>
    )
}
