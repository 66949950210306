import { Controller, useFormContext } from 'react-hook-form'
import { useRef } from 'react'
import { useIntl } from 'react-intl'
import '@toast-ui/editor/dist/toastui-editor.css'
import { Editor } from '@toast-ui/react-editor'

import '@toast-ui/editor/dist/i18n/de-de'
import '@toast-ui/editor/dist/i18n/cs-cz'
import '@toast-ui/editor/dist/i18n/es-es'
import '@toast-ui/editor/dist/i18n/fi-fi'
import '@toast-ui/editor/dist/i18n/fr-fr'
import '@toast-ui/editor/dist/i18n/gl-es'
import '@toast-ui/editor/dist/i18n/it-it'
import '@toast-ui/editor/dist/i18n/ja-jp'
import '@toast-ui/editor/dist/i18n/ko-kr'
import '@toast-ui/editor/dist/i18n/nb-no'
import '@toast-ui/editor/dist/i18n/nl-nl'
import '@toast-ui/editor/dist/i18n/pl-pl'
import '@toast-ui/editor/dist/i18n/ru-ru'
import '@toast-ui/editor/dist/i18n/sv-se'
import '@toast-ui/editor/dist/i18n/tr-tr'
import '@toast-ui/editor/dist/i18n/uk-ua'
import '@toast-ui/editor/dist/i18n/zh-cn'
import '@toast-ui/editor/dist/i18n/zh-tw'

/**
 * Not all available items are used since not all of them are useful for the kind of documents merchants want to edit.
 * For example the 'code' and 'codeblock' items are removed.
 *
 * All available items:
 * 'heading', 'bold', 'italic', 'strike', 'divider', 'hr', 'quote', 'ul', 'ol', 'task','indent', 'outdent', 'table',
 * 'image', 'link', 'code', 'codeblock'
 */
const TOOLBAR_ITEMS = [
    ['heading', 'bold', 'italic', 'strike'],
    ['hr'],
    ['ul', 'ol', 'indent', 'outdent'],
    ['table', 'link'],
]

const browserLangToLanguageTag = (locale: string) => {
    return locale.toLowerCase().substring(0, 2)
}

export type RichEditorProps = {
    name: string
}

export function RichEditor({ name }: RichEditorProps) {
    const { locale } = useIntl()
    const language = browserLangToLanguageTag(locale)
    const { control } = useFormContext()
    const editorRef = useRef<Editor>(null)

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange } }) => (
                <Editor
                    autofocus={false}
                    usageStatistics={false}
                    language={language}
                    initialEditType={'wysiwyg'}
                    useCommandShortcut={true}
                    initialValue={value}
                    hideModeSwitch={false}
                    previewHighlight={false}
                    toolbarItems={TOOLBAR_ITEMS}
                    minHeight={'auto'}
                    height={'80vh'}
                    onChange={() => {
                        const editorMarkdown = editorRef.current?.getInstance()?.getMarkdown()
                        if (editorMarkdown) {
                            onChange(editorMarkdown)
                        }
                    }}
                    ref={editorRef}
                />
            )}
        />
    )
}
