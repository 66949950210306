import { defineMessages, MessageDescriptor } from 'react-intl'

import { DocumentType } from '@slmpserv/common-api-merchant'

import { legalNoticeSectionMessages } from '../merchantForm'

export const documentManagementMessages = defineMessages({
    headline: {
        id: 'merchant.document-management.headline',
        defaultMessage: 'Terms and Conditions',
    },
    newCarsOfferExists: {
        id: 'merchant.document-management.tnc.newCars.offerExists',
        defaultMessage: 'Required (New Cars offers)',
    },
    csvCarsOfferExists: {
        id: 'merchant.document-management.tnc.csvCars.offerExists',
        defaultMessage: 'Required (Csv Cars offers)',
    },
    newCarsSection: {
        id: 'merchant.document-management.tnc.section.newCars',
        defaultMessage: 'New Cars',
    },
    usedCarsSection: {
        id: 'merchant.document-management.tnc.section.usedCars',
        defaultMessage: 'Used Cars',
    },
    csvCarsSection: {
        id: 'merchant.document-management.tnc.section.csvCars',
        defaultMessage: 'CSV Cars',
    },
    privacyPolicySection: {
        id: 'merchant.document-management.tnc.section.privacyPolicy',
        defaultMessage: 'Privacy Policy',
    },
    otherDocuments: {
        id: 'merchant.document-management.tnc.section.otherDocuments',
        defaultMessage: 'Other',
    },
    reservationDocuments: {
        id: 'merchant.document-management.tnc.section.reservationDocuments',
        defaultMessage: 'Reservation Documents',
    },
})

export const mpConfigTableMessages = defineMessages({
    documentType: {
        id: 'merchant.document-management.config.documentType',
        defaultMessage: 'Document Type',
    },
    requiredDocumentType: {
        id: 'merchant.document-management.config.required',
        defaultMessage: 'Required',
    },
    optionalDocumentType: {
        id: 'merchant.document-management.config.optional',
        defaultMessage: 'Optional',
    },
    disabledDocumentType: {
        id: 'merchant.document-management.config.disabled',
        defaultMessage: 'Disabled',
    },
    fallback: {
        id: 'merchant.document-management.config.fallback',
        defaultMessage: 'Fallback',
    },
})

export const documentFileUploadMessages = defineMessages({
    pdfUpToSize: {
        id: 'merchant.document-management.pdfUpToSize',
        defaultMessage: 'Accepted: .pdf with up to {maxSize} MB.',
    },
    lastUpdatedOn: {
        id: 'merchant.document-management.lastUpdatedOn',
        defaultMessage: 'Last updated on {date}',
    },
    lastUpdatedRightNow: {
        id: 'merchant.document-management.lastUpdatedRightNow',
        defaultMessage: 'Updated just now.',
    },
    cancelFileUpload: {
        id: 'merchant.document-management.cancelFileUpload',
        defaultMessage: 'Cancel',
    },
    addNewFile: {
        id: 'merchant.document-management.addNewFile',
        defaultMessage: 'Add File',
    },
    fileSizeTooBig: {
        id: 'merchant.document-management.fileSizeToBig',
        defaultMessage: 'File size too big',
    },
    updateFile: {
        id: 'merchant.document-management.updateFile',
        defaultMessage: 'Update File',
    },
    youNeedToUploadAFile: {
        id: 'merchant.document-management.youNeedToUploadAFile',
        defaultMessage: 'You need to select a file',
    },
})

export const tncMessages = defineMessages({
    newCarsGeneral: {
        id: 'merchant.document-management.tnc.newCars.general',
        defaultMessage: 'Terms and Conditions for New Cars',
    },
    newCarsBev: {
        id: 'merchant.document-management.tnc.newCars.bev',
        defaultMessage: 'Terms and Conditions for New Cars (BEV)',
    },
    newCarsPhev: {
        id: 'merchant.document-management.tnc.newCars.phev',
        defaultMessage: 'Terms and Conditions for New Cars (PHEV)',
    },
    usedCarsGeneral: {
        id: 'merchant.document-management.tnc.usedCars.general',
        defaultMessage: 'Terms and Conditions for Used Cars',
    },
    usedCarsBev: {
        id: 'merchant.document-management.tnc.usedCars.bev',
        defaultMessage: 'Terms and Conditions for Used Cars (BEV)',
    },
    usedCarsPhev: {
        id: 'merchant.document-management.tnc.usedCars.phev',
        defaultMessage: 'Terms and Conditions for Used Cars (PHEV)',
    },
    usedCarsApprovedGeneral: {
        id: 'merchant.document-management.tnc.usedCars.approvedGeneral',
        defaultMessage: 'Terms and Conditions for Approved Used Cars',
    },
    usedCarsApprovedBev: {
        id: 'merchant.document-management.tnc.usedCars.approvedBev',
        defaultMessage: 'Terms and Conditions for Approved Used Cars (BEV)',
    },
    usedCarsApprovedPhev: {
        id: 'merchant.document-management.tnc.usedCars.approvedPhev',
        defaultMessage: 'Terms and Conditions for Approved Used Cars (PHEV)',
    },
    csvCarGeneral: {
        id: 'merchant.document-management.tnc.csv.general',
        defaultMessage: 'CSV Car General',
    },
    csvCarBev: {
        id: 'merchant.document-management.tnc.csv.bev',
        defaultMessage: 'CSV Car Bev',
    },
    csvCarPhev: {
        id: 'merchant.document-management.tnc.csv.phev',
        defaultMessage: 'CSV Car Phev',
    },
    privacyPolicy: { id: 'merchant.document-management.tnc.privacyPolicy', defaultMessage: 'Privacy Policy' },
    reservationGeneral: {
        id: 'merchant.document-management.tnc.reservation.general',
        defaultMessage: 'Terms and Conditions for vehicle reservations',
    },
    reservationPrivacyPolicy: {
        id: 'merchant.document-management.tnc.reservation.privacyPolicy',
        defaultMessage: 'Privacy Policy for vehicle reservations',
    },
})

export const descriptionMessages = defineMessages({
    newCarHeader: {
        id: 'merchant.document-management.tnc.desciptions.newCar.header',
        defaultMessage: 'Main T&C document for new stock vehicles',
    },
    newCarDetail: {
        id: 'merchant.document-management.tnc.desciptions.newCar.header.details',
        defaultMessage:
            'This is the main T&C document for the online sale of new stock vehicles. The upload of this document is mandatory. If you only upload the mandatory T&Cs for new stock vehicles, this document will be available for the customer during the checkout process, no matter what kind of vehicle was sold (e.g. BEV or PHEV vehicles).',
    },
    newCarAdditionalDocumentsHeader: {
        id: 'merchant.document-management.tnc.desciptions.newCar.additional.header',
        defaultMessage: 'Additional T&C documents for new stock vehicles',
    },
    newCarAdditionalDocumentsDetail: {
        id: 'merchant.document-management.tnc.desciptions.newCar.additional.detail',
        defaultMessage: `Select "Included in General Terms and Conditions for new stock vehicles" if the main T&C document for new stock vehicles includes the information for this specific vehicle type (e.g. BEV, PHEV).
Select "Upload separate document" if you want to upload a T&C document for a specific vehicle type (e.g. BEV, PHEV).
Select "Not required (no corresponding offers)" if you do not offer vehicles of the specific vehicle type. Please note that no OCS checkout will be possible for this vehicle type.`,
    },
    usedCarHeader: {
        id: 'merchant.document-management.tnc.desciptions.usedCar.header',
        defaultMessage: 'Main T&C document for used vehicles',
    },
    usedCarDetail: {
        id: 'merchant.document-management.tnc.desciptions.usedCar.header.details',
        defaultMessage:
            'This is the main T&C document for the online sale of used vehicles. The upload of this document is mandatory. If you only upload the mandatory T&Cs for used cars, this document will be available for the customer during the checkout process, no matter what kind of vehicle was sold (e.g. BEV or PHEV vehicles).',
    },
    usedCarAdditionalDocumentsHeader: {
        id: 'merchant.document-management.tnc.desciptions.usedCar.additional.header',
        defaultMessage: 'Additional T&C documents for used vehicles',
    },
    usedCarAdditionalDocumentsDetail: {
        id: 'merchant.document-management.tnc.desciptions.usedCar.additional.detail',
        defaultMessage: `Select "Included in General Terms and Conditions for used vehicles" if the main T&C document for used vehicles includes the information for this specific vehicle type (e.g. BEV, PHEV).
Select "Upload separate document" if you want to upload a T&C document for a specific vehicle type (e.g. BEV, PHEV).
Select "Not required (no corresponding offers)" if you do not offer vehicles of the specific vehicle type. Please note that no OCS checkout will be possible for this vehicle type.`,
    },
    csvCarHeader: {
        id: 'merchant.document-management.tnc.desciptions.csvCar.header',
        defaultMessage: 'Main T&C document for configured customer vehicles',
    },
    csvCarDetail: {
        id: 'merchant.document-management.tnc.desciptions.csvCar.header.details',
        defaultMessage:
            'This is the main T&C document for configured customer vehicles. The upload of this document is mandatory. If you only upload the mandatory T&Cs for configured customer vehicles, this document will be available for the customer during the checkout process, no matter what kind of vehicle was sold (e.g. BEV or PHEV vehicles).',
    },
    csvCarAdditionalDocumentsHeader: {
        id: 'merchant.document-management.tnc.desciptions.csvCar.additional.header',
        defaultMessage: 'Additional T&C documents for configured customer vehicles',
    },
    csvCarAdditionalDocumentsDetail: {
        id: 'merchant.document-management.tnc.desciptions.csvCar.additional.detail',
        defaultMessage: `Select "Included in General Terms and Conditions for online pre-order of configured customer vehicles" if the main T&C document for configured customer vehicles includes the information for this specific vehicle type (e.g. BEV, PHEV).
Select "Upload separate document" if you want to upload a T&C document for a specific vehicle type (e.g. BEV, PHEV).
Select "Not required (no corresponding offers)" if you do not offer vehicles of the specific vehicle type. Please note that no OCS checkout will be possible for this vehicle type.`,
    },
    reservationHeader: {
        id: 'merchant.document-management.tnc.desciptions.reservation.header',
        defaultMessage: 'Main T&C document for vehicle reservations',
    },
    reservationDetail: {
        id: 'merchant.document-management.tnc.desciptions.csvCar.reservation.details',
        defaultMessage:
            'This is the T&C document for vehicle reservations. The upload of this document is mandatory. If you only upload the mandatory T&Cs, this document will be available for the customer during the reservation process.',
    },
    reservationPrivacyPolicyHeader: {
        id: 'merchant.document-management.tnc.desciptions.reservationPrivacyPolicy.header',
        defaultMessage: 'Privacy Policy for vehicle Reservation',
    },
    reservationPrivacyPolicyDetail: {
        id: 'merchant.document-management.tnc.desciptions.reservationPrivacyPolicy.details',
        defaultMessage: `The Privacy Policy enables the customer to get informed about the processing of personal data and indicates whether data is passed on to third parties. The dealership is responsible for the completeness and accuracy of the information.

Various legal documents are required for the use of the Porsche Finder platform. As the provider of the offered vehicles in the Porsche Finder, the dealer is responsible for the content and Porsche-adequate quality (e.g. uniform layout) of these documents. Vehicles can only be activated for online reservation if all the required documents (Terms and Conditions, Privacy Policy, Imprint) have been uploaded.`,
    },
})

export const LabelForDocumentType: Record<DocumentType | 'IMPRINT', MessageDescriptor> = {
    IMPRINT: legalNoticeSectionMessages.imprintTitle,
    PRIVACY_POLICY: tncMessages.privacyPolicy,
    NEW_CAR_BEV: tncMessages.newCarsBev,
    NEW_CAR_GENERAL: tncMessages.newCarsGeneral,
    NEW_CAR_PHEV: tncMessages.newCarsPhev,
    USED_CAR_APPROVED_BEV: tncMessages.usedCarsApprovedBev,
    USED_CAR_APPROVED_GENERAL: tncMessages.usedCarsApprovedGeneral,
    USED_CAR_APPROVED_PHEV: tncMessages.usedCarsApprovedPhev,
    USED_CAR_BEV: tncMessages.usedCarsBev,
    USED_CAR_GENERAL: tncMessages.usedCarsGeneral,
    USED_CAR_PHEV: tncMessages.usedCarsPhev,
    CSV_CAR_GENERAL: tncMessages.csvCarGeneral,
    CSV_CAR_BEV: tncMessages.csvCarBev,
    CSV_CAR_PHEV: tncMessages.csvCarPhev,
    RESERVATION_GENERAL: tncMessages.reservationGeneral,
    RESERVATION_PRIVACY_POLICY: tncMessages.reservationPrivacyPolicy,
}
