/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
    CarSalesConfiguration,
    DocumentType,
    ErrorResponse,
    MerchantTermsAndConditionsResponse,
    SelectedOption,
} from '../models/index'
import {
    CarSalesConfigurationFromJSON,
    CarSalesConfigurationToJSON,
    DocumentTypeFromJSON,
    DocumentTypeToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    MerchantTermsAndConditionsResponseFromJSON,
    MerchantTermsAndConditionsResponseToJSON,
    SelectedOptionFromJSON,
    SelectedOptionToJSON,
} from '../models/index'

export interface CreateOrUpdateDecisionRequest {
    merchantId: string
    language: string
    documentType: DocumentType
    selectedOption: SelectedOption
    file?: Blob
}

export interface GetCurrentConfigRequest {
    merchantId: string
}

export interface GetMerchantFileRequest {
    merchantId: string
    fileId: string
}

export interface SaveCarSalesConfigurationRequest {
    merchantId: string
    carSalesConfiguration: CarSalesConfiguration
}

/**
 *
 */
export class MerchantTermsAndConditionsApi extends runtime.BaseAPI {
    /**
     */
    async createOrUpdateDecisionRaw(
        requestParameters: CreateOrUpdateDecisionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['merchantId'] == null) {
            throw new runtime.RequiredError(
                'merchantId',
                'Required parameter "merchantId" was null or undefined when calling createOrUpdateDecision().'
            )
        }

        if (requestParameters['language'] == null) {
            throw new runtime.RequiredError(
                'language',
                'Required parameter "language" was null or undefined when calling createOrUpdateDecision().'
            )
        }

        if (requestParameters['documentType'] == null) {
            throw new runtime.RequiredError(
                'documentType',
                'Required parameter "documentType" was null or undefined when calling createOrUpdateDecision().'
            )
        }

        if (requestParameters['selectedOption'] == null) {
            throw new runtime.RequiredError(
                'selectedOption',
                'Required parameter "selectedOption" was null or undefined when calling createOrUpdateDecision().'
            )
        }

        const queryParameters: any = {}

        if (requestParameters['language'] != null) {
            queryParameters['language'] = requestParameters['language']
        }

        if (requestParameters['documentType'] != null) {
            queryParameters['documentType'] = requestParameters['documentType']
        }

        if (requestParameters['selectedOption'] != null) {
            queryParameters['selectedOption'] = requestParameters['selectedOption']
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }]
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes)

        let formParams: { append(param: string, value: any): any }
        let useForm = false
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm
        if (useForm) {
            formParams = new FormData()
        } else {
            formParams = new URLSearchParams()
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any)
        }

        const response = await this.request(
            {
                path: `/api/v2/merchants/{merchantId}/termsAndConditions/updateDecision`.replace(
                    `{${'merchantId'}}`,
                    encodeURIComponent(String(requestParameters['merchantId']))
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: formParams,
            },
            initOverrides
        )

        return new runtime.VoidApiResponse(response)
    }

    /**
     */
    async createOrUpdateDecision(
        requestParameters: CreateOrUpdateDecisionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<void> {
        await this.createOrUpdateDecisionRaw(requestParameters, initOverrides)
    }

    /**
     */
    async getCurrentConfigRaw(
        requestParameters: GetCurrentConfigRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<MerchantTermsAndConditionsResponse>> {
        if (requestParameters['merchantId'] == null) {
            throw new runtime.RequiredError(
                'merchantId',
                'Required parameter "merchantId" was null or undefined when calling getCurrentConfig().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/v2/merchants/{merchantId}/termsAndConditions`.replace(
                    `{${'merchantId'}}`,
                    encodeURIComponent(String(requestParameters['merchantId']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => MerchantTermsAndConditionsResponseFromJSON(jsonValue))
    }

    /**
     */
    async getCurrentConfig(
        requestParameters: GetCurrentConfigRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<MerchantTermsAndConditionsResponse> {
        const response = await this.getCurrentConfigRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async getMerchantFileRaw(
        requestParameters: GetMerchantFileRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['merchantId'] == null) {
            throw new runtime.RequiredError(
                'merchantId',
                'Required parameter "merchantId" was null or undefined when calling getMerchantFile().'
            )
        }

        if (requestParameters['fileId'] == null) {
            throw new runtime.RequiredError(
                'fileId',
                'Required parameter "fileId" was null or undefined when calling getMerchantFile().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/v2/merchants/{merchantId}/termsAndConditions/file/{fileId}`
                    .replace(`{${'merchantId'}}`, encodeURIComponent(String(requestParameters['merchantId'])))
                    .replace(`{${'fileId'}}`, encodeURIComponent(String(requestParameters['fileId']))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response)
        } else {
            return new runtime.TextApiResponse(response) as any
        }
    }

    /**
     */
    async getMerchantFile(
        requestParameters: GetMerchantFileRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<string> {
        const response = await this.getMerchantFileRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async saveCarSalesConfigurationRaw(
        requestParameters: SaveCarSalesConfigurationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<CarSalesConfiguration>> {
        if (requestParameters['merchantId'] == null) {
            throw new runtime.RequiredError(
                'merchantId',
                'Required parameter "merchantId" was null or undefined when calling saveCarSalesConfiguration().'
            )
        }

        if (requestParameters['carSalesConfiguration'] == null) {
            throw new runtime.RequiredError(
                'carSalesConfiguration',
                'Required parameter "carSalesConfiguration" was null or undefined when calling saveCarSalesConfiguration().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request(
            {
                path: `/api/v2/merchants/{merchantId}/termsAndConditions/carSalesConfiguration`.replace(
                    `{${'merchantId'}}`,
                    encodeURIComponent(String(requestParameters['merchantId']))
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CarSalesConfigurationToJSON(requestParameters['carSalesConfiguration']),
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => CarSalesConfigurationFromJSON(jsonValue))
    }

    /**
     */
    async saveCarSalesConfiguration(
        requestParameters: SaveCarSalesConfigurationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<CarSalesConfiguration> {
        const response = await this.saveCarSalesConfigurationRaw(requestParameters, initOverrides)
        return await response.value()
    }
}
