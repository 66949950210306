/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
    SubmerchantError,
    SubmerchantErrorFromJSON,
    SubmerchantErrorFromJSONTyped,
    SubmerchantErrorToJSON,
    SubmerchantEvent,
    SubmerchantEventFromJSON,
    SubmerchantEventFromJSONTyped,
    SubmerchantEventToJSON,
    SubmerchantVerification,
    SubmerchantVerificationFromJSON,
    SubmerchantVerificationFromJSONTyped,
    SubmerchantVerificationToJSON,
} from './'

/**
 *
 * @export
 * @interface SubmerchantStatusDetails
 */
export interface SubmerchantStatusDetails {
    /**
     * Status of the account holder
     * @type {string}
     * @memberof SubmerchantStatusDetails
     */
    accountHolderStatus: string
    /**
     * Indicates if payouts are allowed for submerchant
     * @type {boolean}
     * @memberof SubmerchantStatusDetails
     */
    payoutAllowed?: boolean
    /**
     * Status of the account holder. An aggregate of the account holder status and payout allowed fields
     * @type {string}
     * @memberof SubmerchantStatusDetails
     */
    status: string
    /**
     * The errors produced by verification checks
     * @type {Array<SubmerchantError>}
     * @memberof SubmerchantStatusDetails
     */
    errors?: Array<SubmerchantError>
    /**
     * Scheduled events of upcoming deadlines
     * @type {Array<SubmerchantEvent>}
     * @memberof SubmerchantStatusDetails
     */
    events?: Array<SubmerchantEvent>
    /**
     * Information produced by verification checks
     * @type {Array<SubmerchantVerification>}
     * @memberof SubmerchantStatusDetails
     */
    verificationChecks?: Array<SubmerchantVerification>
}

export function SubmerchantStatusDetailsFromJSON(json: any): SubmerchantStatusDetails {
    return SubmerchantStatusDetailsFromJSONTyped(json, false)
}

export function SubmerchantStatusDetailsFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): SubmerchantStatusDetails {
    if (json === undefined || json === null) {
        return json
    }
    return {
        accountHolderStatus: json['accountHolderStatus'],
        payoutAllowed: !exists(json, 'payoutAllowed') ? undefined : json['payoutAllowed'],
        status: json['status'],
        errors: !exists(json, 'errors') ? undefined : (json['errors'] as Array<any>).map(SubmerchantErrorFromJSON),
        events: !exists(json, 'events') ? undefined : (json['events'] as Array<any>).map(SubmerchantEventFromJSON),
        verificationChecks: !exists(json, 'verificationChecks')
            ? undefined
            : (json['verificationChecks'] as Array<any>).map(SubmerchantVerificationFromJSON),
    }
}

export function SubmerchantStatusDetailsToJSON(value?: SubmerchantStatusDetails | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        accountHolderStatus: value.accountHolderStatus,
        payoutAllowed: value.payoutAllowed,
        status: value.status,
        errors: value.errors === undefined ? undefined : (value.errors as Array<any>).map(SubmerchantErrorToJSON),
        events: value.events === undefined ? undefined : (value.events as Array<any>).map(SubmerchantEventToJSON),
        verificationChecks:
            value.verificationChecks === undefined
                ? undefined
                : (value.verificationChecks as Array<any>).map(SubmerchantVerificationToJSON),
    }
}
