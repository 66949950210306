/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
    FaultResponse,
    FaultResponseFromJSON,
    FaultResponseToJSON,
    HostedOnboardingRequestRestModel,
    HostedOnboardingRequestRestModelFromJSON,
    HostedOnboardingRequestRestModelToJSON,
    HostedOnboardingResponseRestModel,
    HostedOnboardingResponseRestModelFromJSON,
    HostedOnboardingResponseRestModelToJSON,
} from '../models'

export interface AccountholdersIdOnboardinglinkPostRequest {
    id: number
    hostedOnboardingRequestRestModel?: HostedOnboardingRequestRestModel
}

/**
 *
 */
export class AccountHolderManagementApi extends runtime.BaseAPI {
    /**
     * Creates hosted onboarding link for account holder
     * Create hosted onboarding link
     */
    async accountholdersIdOnboardinglinkPostRaw(
        requestParameters: AccountholdersIdOnboardinglinkPostRequest
    ): Promise<runtime.ApiResponse<HostedOnboardingResponseRestModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter requestParameters.id was null or undefined when calling accountholdersIdOnboardinglinkPost.'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.apiKey) {
            headerParameters['Authorization'] = this.configuration.apiKey('Authorization') // apikey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('bearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/accountholders/{id}/onboardinglink`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(requestParameters.id))
            ),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HostedOnboardingRequestRestModelToJSON(requestParameters.hostedOnboardingRequestRestModel),
        })

        return new runtime.JSONApiResponse(response, jsonValue => HostedOnboardingResponseRestModelFromJSON(jsonValue))
    }

    /**
     * Creates hosted onboarding link for account holder
     * Create hosted onboarding link
     */
    async accountholdersIdOnboardinglinkPost(
        requestParameters: AccountholdersIdOnboardinglinkPostRequest
    ): Promise<HostedOnboardingResponseRestModel> {
        const response = await this.accountholdersIdOnboardinglinkPostRaw(requestParameters)
        return await response.value()
    }
}
