import { Fragment, useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { useFetchAllImprints } from '@slmpserv/common/api/use-query-wrapper'
import { Imprint } from '@slmpserv/common-api-merchant'
import {
    CompletenessBadge,
    FormGroup,
    LanguageTabs,
    QueryBoundary,
    RichEditor,
    SpacedDivider,
} from '@slmpserv/common-components'
import { useMarketplaceContext, useMerchant, useMultipleFormSubmits } from '@slmpserv/common-contexts'
import { imprintMessages } from '@slmpserv/common-translations'

import { useSaveImprints } from './useSaveImprints'

type ImprintFormState = Record<string, Imprint>

function imprintArrayToFormState(imprint: Imprint[]): ImprintFormState {
    return imprint.reduce(
        (acc, imprint) => ({
            ...acc,
            [imprint.language]: imprint,
        }),
        {}
    )
}

function imprintArrayToCompleteness(imprint: Imprint[]): Record<string, boolean> {
    return imprint.reduce(
        (acc, imprint) => ({
            ...acc,
            [imprint.language]: Boolean(imprint.content),
        }),
        {}
    )
}

function formStateToImprints(formState: ImprintFormState): Imprint[] {
    const imprints: Imprint[] = []
    for (const language in formState) {
        imprints.push({ language, content: formState[language].content, id: formState[language].id })
    }
    return imprints
}

export function ImprintForm() {
    const { id: merchantId } = useMerchant()
    const methods = useForm<ImprintFormState>({ mode: 'onSubmit' })
    const [formInitialized, setFormInitialized] = useState(false)
    const [storedImprints, setStoredImprints] = useState<{ [language: string]: boolean }>({})
    const { imprints, queryResult } = useFetchAllImprints({ merchantId })
    const { formatMessage } = useIntl()
    const { locales } = useMarketplaceContext()
    const { saveImprints } = useSaveImprints({ merchantId })

    const languagesFormMarketplaceLocales = locales.map(locale => locale.substring(0, 2))

    if (!formInitialized && queryResult.isSuccess) {
        setFormInitialized(true)

        const storedImprints = imprintArrayToCompleteness(imprints)
        const initialFormState = imprintArrayToFormState(imprints)

        setStoredImprints(storedImprints)
        methods.reset(initialFormState)
    }

    const updateDocuments = useCallback(
        async (formState: ImprintFormState) => {
            // refetch to get the newest text for the editor
            await queryResult.refetch()
            // Update decisions on backend
            const savedImprints = await saveImprints({ imprints: formStateToImprints(formState) })
            methods.reset(imprintArrayToFormState(savedImprints))
            setStoredImprints(imprintArrayToCompleteness(savedImprints))
        },
        [queryResult, saveImprints, methods]
    )

    const { addSubmitHandler } = useMultipleFormSubmits()

    useEffect(() => {
        addSubmitHandler({ name: `imprint`, handler: methods.handleSubmit(updateDocuments) })
    }, [addSubmitHandler, methods, updateDocuments])

    return (
        <QueryBoundary queryResult={queryResult}>
            <FormProvider {...methods}>
                <LanguageTabs languages={languagesFormMarketplaceLocales}>
                    {language => (
                        <Fragment key={language}>
                            <SpacedDivider spacingTop="large" spacingBottom="large" />
                            <FormGroup
                                completedBadge={<CompletenessBadge complete={storedImprints[language] === true} />}
                                label={formatMessage(imprintMessages.sectionLabel)}
                                labelColumnWidth={1}
                                contentColumnWidth={4}
                            >
                                <RichEditor name={`${language}.content`} />
                            </FormGroup>
                        </Fragment>
                    )}
                </LanguageTabs>
            </FormProvider>
        </QueryBoundary>
    )
}
