/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
    FaultResponse,
    FaultResponseFromJSON,
    FaultResponseToJSON,
    GetSubmerchantStatusResponse,
    GetSubmerchantStatusResponseFromJSON,
    GetSubmerchantStatusResponseToJSON,
    InlineObject,
    InlineObjectFromJSON,
    InlineObjectToJSON,
    SubmerchantDebitRequestRestModel,
    SubmerchantDebitRequestRestModelFromJSON,
    SubmerchantDebitRequestRestModelToJSON,
    SubmerchantDebitResponseRestModel,
    SubmerchantDebitResponseRestModelFromJSON,
    SubmerchantDebitResponseRestModelToJSON,
    SubmerchantId,
    SubmerchantIdFromJSON,
    SubmerchantIdToJSON,
    SubmerchantModel,
    SubmerchantModelFromJSON,
    SubmerchantModelToJSON,
    SubmerchantPayoutSchedule,
    SubmerchantPayoutScheduleFromJSON,
    SubmerchantPayoutScheduleToJSON,
    SubmerchantPayoutScheduleRequest,
    SubmerchantPayoutScheduleRequestFromJSON,
    SubmerchantPayoutScheduleRequestToJSON,
    SubmerchantStatus,
    SubmerchantStatusFromJSON,
    SubmerchantStatusToJSON,
} from '../models'

export interface ExchangeSubmerchantIdRequest {
    sourceId: number
    inlineObject: InlineObject
}

export interface PpnIdsubmerchantIdMappingGETRequest {
    ppnId: string
}

export interface SubmerchantDELETERequest {
    submerchantId: number
}

export interface SubmerchantGETRequest {
    submerchantId: number
}

export interface SubmerchantPOSTRequest {
    submerchantModel: SubmerchantModel
}

export interface SubmerchantPUTRequest {
    submerchantId: number
    submerchantModel: SubmerchantModel
}

export interface SubmerchantPayoutscheduleGETRequest {
    submerchantId: number
}

export interface SubmerchantPayoutschedulePUTRequest {
    submerchantId: number
    submerchantPayoutScheduleRequest: SubmerchantPayoutScheduleRequest
}

export interface SubmerchantPpnGETRequest {
    ppnId: string
}

export interface SubmerchantStatusGETRequest {
    submerchantId: number
}

export interface SubmerchantsDebitIdStatusGetRequest {
    id: number
}

export interface SubmerchantsDebitSubmerchantIdPostRequest {
    submerchantId: number
    submerchantDebitRequestRestModel: SubmerchantDebitRequestRestModel
}

export interface SubmerchantsStatusesGetRequest {
    id?: number
    country?: string
    page?: number
    size?: number
}

/**
 *
 */
export class SubmerchantManagementApi extends runtime.BaseAPI {
    /**
     * Can be used to exchange submerchant id on PP environment
     * Exchanges submerchant id on PP environment
     */
    async exchangeSubmerchantIdRaw(
        requestParameters: ExchangeSubmerchantIdRequest
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sourceId === null || requestParameters.sourceId === undefined) {
            throw new runtime.RequiredError(
                'sourceId',
                'Required parameter requestParameters.sourceId was null or undefined when calling exchangeSubmerchantId.'
            )
        }

        if (requestParameters.inlineObject === null || requestParameters.inlineObject === undefined) {
            throw new runtime.RequiredError(
                'inlineObject',
                'Required parameter requestParameters.inlineObject was null or undefined when calling exchangeSubmerchantId.'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.apiKey) {
            headerParameters['Authorization'] = this.configuration.apiKey('Authorization') // apikey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('bearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/submerchants/{sourceId}`.replace(
                `{${'sourceId'}}`,
                encodeURIComponent(String(requestParameters.sourceId))
            ),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObjectToJSON(requestParameters.inlineObject),
        })

        return new runtime.VoidApiResponse(response)
    }

    /**
     * Can be used to exchange submerchant id on PP environment
     * Exchanges submerchant id on PP environment
     */
    async exchangeSubmerchantId(requestParameters: ExchangeSubmerchantIdRequest): Promise<void> {
        await this.exchangeSubmerchantIdRaw(requestParameters)
    }

    /**
     * Provides submerchant ID based on provided PPN ID
     */
    async ppnIdsubmerchantIdMappingGETRaw(
        requestParameters: PpnIdsubmerchantIdMappingGETRequest
    ): Promise<runtime.ApiResponse<SubmerchantId>> {
        if (requestParameters.ppnId === null || requestParameters.ppnId === undefined) {
            throw new runtime.RequiredError(
                'ppnId',
                'Required parameter requestParameters.ppnId was null or undefined when calling ppnIdsubmerchantIdMappingGET.'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.apiKey) {
            headerParameters['Authorization'] = this.configuration.apiKey('Authorization') // apikey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('bearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/submerchants/{ppnId}/submerchantid`.replace(
                `{${'ppnId'}}`,
                encodeURIComponent(String(requestParameters.ppnId))
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => SubmerchantIdFromJSON(jsonValue))
    }

    /**
     * Provides submerchant ID based on provided PPN ID
     */
    async ppnIdsubmerchantIdMappingGET(requestParameters: PpnIdsubmerchantIdMappingGETRequest): Promise<SubmerchantId> {
        const response = await this.ppnIdsubmerchantIdMappingGETRaw(requestParameters)
        return await response.value()
    }

    /**
     * Close accounts on PSP side and remove submerchant data
     * Delete submerchant
     */
    async submerchantDELETERaw(requestParameters: SubmerchantDELETERequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.submerchantId === null || requestParameters.submerchantId === undefined) {
            throw new runtime.RequiredError(
                'submerchantId',
                'Required parameter requestParameters.submerchantId was null or undefined when calling submerchantDELETE.'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.apiKey) {
            headerParameters['Authorization'] = this.configuration.apiKey('Authorization') // apikey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('bearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/submerchants/{submerchantId}`.replace(
                `{${'submerchantId'}}`,
                encodeURIComponent(String(requestParameters.submerchantId))
            ),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.VoidApiResponse(response)
    }

    /**
     * Close accounts on PSP side and remove submerchant data
     * Delete submerchant
     */
    async submerchantDELETE(requestParameters: SubmerchantDELETERequest): Promise<void> {
        await this.submerchantDELETERaw(requestParameters)
    }

    /**
     * Provides almost all data of the submerchant. Verification images of shareholders are skipped
     * Provides submerchant data
     */
    async submerchantGETRaw(requestParameters: SubmerchantGETRequest): Promise<runtime.ApiResponse<SubmerchantModel>> {
        if (requestParameters.submerchantId === null || requestParameters.submerchantId === undefined) {
            throw new runtime.RequiredError(
                'submerchantId',
                'Required parameter requestParameters.submerchantId was null or undefined when calling submerchantGET.'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.apiKey) {
            headerParameters['Authorization'] = this.configuration.apiKey('Authorization') // apikey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('bearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/submerchants/{submerchantId}`.replace(
                `{${'submerchantId'}}`,
                encodeURIComponent(String(requestParameters.submerchantId))
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => SubmerchantModelFromJSON(jsonValue))
    }

    /**
     * Provides almost all data of the submerchant. Verification images of shareholders are skipped
     * Provides submerchant data
     */
    async submerchantGET(requestParameters: SubmerchantGETRequest): Promise<SubmerchantModel> {
        const response = await this.submerchantGETRaw(requestParameters)
        return await response.value()
    }

    /**
     * Endpoint is used to create a new submerchant instance in PSP and our DB.
     * Adds new submerchant
     */
    async submerchantPOSTRaw(requestParameters: SubmerchantPOSTRequest): Promise<runtime.ApiResponse<SubmerchantId>> {
        if (requestParameters.submerchantModel === null || requestParameters.submerchantModel === undefined) {
            throw new runtime.RequiredError(
                'submerchantModel',
                'Required parameter requestParameters.submerchantModel was null or undefined when calling submerchantPOST.'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.apiKey) {
            headerParameters['Authorization'] = this.configuration.apiKey('Authorization') // apikey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('bearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/submerchants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubmerchantModelToJSON(requestParameters.submerchantModel),
        })

        return new runtime.JSONApiResponse(response, jsonValue => SubmerchantIdFromJSON(jsonValue))
    }

    /**
     * Endpoint is used to create a new submerchant instance in PSP and our DB.
     * Adds new submerchant
     */
    async submerchantPOST(requestParameters: SubmerchantPOSTRequest): Promise<SubmerchantId> {
        const response = await this.submerchantPOSTRaw(requestParameters)
        return await response.value()
    }

    /**
     * Can be used to update submerchant data, eg. add shareholder image for verification, add/remove shareholders, change bank account data, change company address, etc.
     * Updates an existing submerchant
     */
    async submerchantPUTRaw(requestParameters: SubmerchantPUTRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.submerchantId === null || requestParameters.submerchantId === undefined) {
            throw new runtime.RequiredError(
                'submerchantId',
                'Required parameter requestParameters.submerchantId was null or undefined when calling submerchantPUT.'
            )
        }

        if (requestParameters.submerchantModel === null || requestParameters.submerchantModel === undefined) {
            throw new runtime.RequiredError(
                'submerchantModel',
                'Required parameter requestParameters.submerchantModel was null or undefined when calling submerchantPUT.'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.apiKey) {
            headerParameters['Authorization'] = this.configuration.apiKey('Authorization') // apikey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('bearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/submerchants/{submerchantId}`.replace(
                `{${'submerchantId'}}`,
                encodeURIComponent(String(requestParameters.submerchantId))
            ),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SubmerchantModelToJSON(requestParameters.submerchantModel),
        })

        return new runtime.VoidApiResponse(response)
    }

    /**
     * Can be used to update submerchant data, eg. add shareholder image for verification, add/remove shareholders, change bank account data, change company address, etc.
     * Updates an existing submerchant
     */
    async submerchantPUT(requestParameters: SubmerchantPUTRequest): Promise<void> {
        await this.submerchantPUTRaw(requestParameters)
    }

    /**
     * Provides only the payout schedule of the submerchant as a string.
     * Provides submerchant payout schedule data
     */
    async submerchantPayoutscheduleGETRaw(
        requestParameters: SubmerchantPayoutscheduleGETRequest
    ): Promise<runtime.ApiResponse<SubmerchantPayoutSchedule>> {
        if (requestParameters.submerchantId === null || requestParameters.submerchantId === undefined) {
            throw new runtime.RequiredError(
                'submerchantId',
                'Required parameter requestParameters.submerchantId was null or undefined when calling submerchantPayoutscheduleGET.'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.apiKey) {
            headerParameters['Authorization'] = this.configuration.apiKey('Authorization') // apikey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('bearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/submerchants/{submerchantId}/payoutschedule`.replace(
                `{${'submerchantId'}}`,
                encodeURIComponent(String(requestParameters.submerchantId))
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => SubmerchantPayoutScheduleFromJSON(jsonValue))
    }

    /**
     * Provides only the payout schedule of the submerchant as a string.
     * Provides submerchant payout schedule data
     */
    async submerchantPayoutscheduleGET(
        requestParameters: SubmerchantPayoutscheduleGETRequest
    ): Promise<SubmerchantPayoutSchedule> {
        const response = await this.submerchantPayoutscheduleGETRaw(requestParameters)
        return await response.value()
    }

    /**
     * Can be used to update submerchant payout schedule data.
     * Updates an existing submerchant payout schedule
     */
    async submerchantPayoutschedulePUTRaw(
        requestParameters: SubmerchantPayoutschedulePUTRequest
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.submerchantId === null || requestParameters.submerchantId === undefined) {
            throw new runtime.RequiredError(
                'submerchantId',
                'Required parameter requestParameters.submerchantId was null or undefined when calling submerchantPayoutschedulePUT.'
            )
        }

        if (
            requestParameters.submerchantPayoutScheduleRequest === null ||
            requestParameters.submerchantPayoutScheduleRequest === undefined
        ) {
            throw new runtime.RequiredError(
                'submerchantPayoutScheduleRequest',
                'Required parameter requestParameters.submerchantPayoutScheduleRequest was null or undefined when calling submerchantPayoutschedulePUT.'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.apiKey) {
            headerParameters['Authorization'] = this.configuration.apiKey('Authorization') // apikey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('bearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/submerchants/{submerchantId}/payoutschedule`.replace(
                `{${'submerchantId'}}`,
                encodeURIComponent(String(requestParameters.submerchantId))
            ),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SubmerchantPayoutScheduleRequestToJSON(requestParameters.submerchantPayoutScheduleRequest),
        })

        return new runtime.VoidApiResponse(response)
    }

    /**
     * Can be used to update submerchant payout schedule data.
     * Updates an existing submerchant payout schedule
     */
    async submerchantPayoutschedulePUT(requestParameters: SubmerchantPayoutschedulePUTRequest): Promise<void> {
        await this.submerchantPayoutschedulePUTRaw(requestParameters)
    }

    /**
     * Provides initial submerchant informations from Porsche Partner Network
     * Provides initial submerchant informations
     */
    async submerchantPpnGETRaw(
        requestParameters: SubmerchantPpnGETRequest
    ): Promise<runtime.ApiResponse<SubmerchantModel>> {
        if (requestParameters.ppnId === null || requestParameters.ppnId === undefined) {
            throw new runtime.RequiredError(
                'ppnId',
                'Required parameter requestParameters.ppnId was null or undefined when calling submerchantPpnGET.'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.apiKey) {
            headerParameters['Authorization'] = this.configuration.apiKey('Authorization') // apikey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('bearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/submerchants/{ppnId}/initialinfo`.replace(
                `{${'ppnId'}}`,
                encodeURIComponent(String(requestParameters.ppnId))
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => SubmerchantModelFromJSON(jsonValue))
    }

    /**
     * Provides initial submerchant informations from Porsche Partner Network
     * Provides initial submerchant informations
     */
    async submerchantPpnGET(requestParameters: SubmerchantPpnGETRequest): Promise<SubmerchantModel> {
        const response = await this.submerchantPpnGETRaw(requestParameters)
        return await response.value()
    }

    /**
     * This only the status string
     * Provides submerchant status
     */
    async submerchantStatusGETRaw(
        requestParameters: SubmerchantStatusGETRequest
    ): Promise<runtime.ApiResponse<SubmerchantStatus>> {
        if (requestParameters.submerchantId === null || requestParameters.submerchantId === undefined) {
            throw new runtime.RequiredError(
                'submerchantId',
                'Required parameter requestParameters.submerchantId was null or undefined when calling submerchantStatusGET.'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.apiKey) {
            headerParameters['Authorization'] = this.configuration.apiKey('Authorization') // apikey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('bearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/submerchants/{submerchantId}/status`.replace(
                `{${'submerchantId'}}`,
                encodeURIComponent(String(requestParameters.submerchantId))
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => SubmerchantStatusFromJSON(jsonValue))
    }

    /**
     * This only the status string
     * Provides submerchant status
     */
    async submerchantStatusGET(requestParameters: SubmerchantStatusGETRequest): Promise<SubmerchantStatus> {
        const response = await this.submerchantStatusGETRaw(requestParameters)
        return await response.value()
    }

    /**
     * Can be used to top get request status
     * Returns status of submerchant direct debit request
     */
    async submerchantsDebitIdStatusGetRaw(
        requestParameters: SubmerchantsDebitIdStatusGetRequest
    ): Promise<runtime.ApiResponse<SubmerchantDebitResponseRestModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter requestParameters.id was null or undefined when calling submerchantsDebitIdStatusGet.'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.apiKey) {
            headerParameters['Authorization'] = this.configuration.apiKey('Authorization') // apikey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('bearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/submerchants/debit/{id}/status`.replace(
                `{${'id'}}`,
                encodeURIComponent(String(requestParameters.id))
            ),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => SubmerchantDebitResponseRestModelFromJSON(jsonValue))
    }

    /**
     * Can be used to top get request status
     * Returns status of submerchant direct debit request
     */
    async submerchantsDebitIdStatusGet(
        requestParameters: SubmerchantsDebitIdStatusGetRequest
    ): Promise<SubmerchantDebitResponseRestModel> {
        const response = await this.submerchantsDebitIdStatusGetRaw(requestParameters)
        return await response.value()
    }

    /**
     * Can be used to top up submerchant account
     * Creates direct debit request for submerchant
     */
    async submerchantsDebitSubmerchantIdPostRaw(
        requestParameters: SubmerchantsDebitSubmerchantIdPostRequest
    ): Promise<runtime.ApiResponse<SubmerchantDebitResponseRestModel>> {
        if (requestParameters.submerchantId === null || requestParameters.submerchantId === undefined) {
            throw new runtime.RequiredError(
                'submerchantId',
                'Required parameter requestParameters.submerchantId was null or undefined when calling submerchantsDebitSubmerchantIdPost.'
            )
        }

        if (
            requestParameters.submerchantDebitRequestRestModel === null ||
            requestParameters.submerchantDebitRequestRestModel === undefined
        ) {
            throw new runtime.RequiredError(
                'submerchantDebitRequestRestModel',
                'Required parameter requestParameters.submerchantDebitRequestRestModel was null or undefined when calling submerchantsDebitSubmerchantIdPost.'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        if (this.configuration && this.configuration.apiKey) {
            headerParameters['Authorization'] = this.configuration.apiKey('Authorization') // apikey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('bearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/submerchants/debit/{submerchantId}`.replace(
                `{${'submerchantId'}}`,
                encodeURIComponent(String(requestParameters.submerchantId))
            ),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubmerchantDebitRequestRestModelToJSON(requestParameters.submerchantDebitRequestRestModel),
        })

        return new runtime.JSONApiResponse(response, jsonValue => SubmerchantDebitResponseRestModelFromJSON(jsonValue))
    }

    /**
     * Can be used to top up submerchant account
     * Creates direct debit request for submerchant
     */
    async submerchantsDebitSubmerchantIdPost(
        requestParameters: SubmerchantsDebitSubmerchantIdPostRequest
    ): Promise<SubmerchantDebitResponseRestModel> {
        const response = await this.submerchantsDebitSubmerchantIdPostRaw(requestParameters)
        return await response.value()
    }

    /**
     * This only the status string
     * Provides statuses of submerchants filtered by id, country with paging option
     */
    async submerchantsStatusesGetRaw(
        requestParameters: SubmerchantsStatusesGetRequest
    ): Promise<runtime.ApiResponse<Array<GetSubmerchantStatusResponse>>> {
        const queryParameters: any = {}

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id
        }

        if (requestParameters.country !== undefined) {
            queryParameters['country'] = requestParameters.country
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size
        }

        const headerParameters: runtime.HTTPHeaders = {}

        if (this.configuration && this.configuration.apiKey) {
            headerParameters['Authorization'] = this.configuration.apiKey('Authorization') // apikey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken
            const tokenString = typeof token === 'function' ? token('bearer', []) : token

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`
            }
        }
        const response = await this.request({
            path: `/submerchants/statuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        })

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(GetSubmerchantStatusResponseFromJSON))
    }

    /**
     * This only the status string
     * Provides statuses of submerchants filtered by id, country with paging option
     */
    async submerchantsStatusesGet(
        requestParameters: SubmerchantsStatusesGetRequest
    ): Promise<Array<GetSubmerchantStatusResponse>> {
        const response = await this.submerchantsStatusesGetRaw(requestParameters)
        return await response.value()
    }
}
