import { DocumentType, MarketplaceDocumentConfig, MerchantDocumentMetadata } from '@slmpserv/common-api-merchant'

import { DocumentSelectionDefaultOptions } from '../components/DocumentSelection/DocumentSelection'

type FromEnum<T extends string> = { [P in T]: `${P}` }[T]

export const GeneralDocumentTypes = [
    DocumentType.USED_CAR_GENERAL,
    DocumentType.NEW_CAR_GENERAL,
    DocumentType.CSV_CAR_GENERAL,
]

export type DocumentDecisions = Record<DocumentType, DocumentSelectionDefaultOptions>

export type DocumentFiles = Record<FromEnum<DocumentType>, FileList | undefined>

export type MerchantTncFormData = {
    language: string
    decisions: DocumentDecisions
    files: DocumentFiles
    storedDecisions: DocumentDecisions
    storedFiles: Partial<Record<FromEnum<DocumentType>, MerchantDocumentMetadata | undefined>>
}

export type MarketplaceDocumentConfigMap = Partial<
    Record<FromEnum<DocumentType>, MarketplaceDocumentConfig | undefined>
>

export type DocumentsFormState = {
    offersNewCars: boolean
    offersCsvCars: boolean
    reservationEnabled: boolean
    documentConfig: MarketplaceDocumentConfigMap
    documents: {
        [language: string]: MerchantTncFormData
    }
}
