import { PTag } from '@porsche-design-system/components-react'

type CompletenessBadgeProps = {
    done?: number
    total?: number
    complete: boolean
}

export function CompletenessBadge({ done, total, complete }: CompletenessBadgeProps) {
    const showProgress = done !== undefined && total !== undefined

    return (
        <PTag
            icon={complete ? 'check' : 'exclamation'}
            color={complete ? 'notification-success-soft' : 'notification-error-soft'}
        >
            {showProgress && `${done}/${total}`}
        </PTag>
    )
}
