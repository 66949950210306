import { useIntl } from 'react-intl'

import {
    dashboardErrorMessages,
    dashboardPopoverMessages,
    dashboardPreviewMessages,
    dashboardTitles,
    errorsMessages,
} from '@slmpserv/common-translations'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { useMatomoTrackEditEvent } from '@slmpserv/common/analytics'
import { useFetchPurchaseRequestSettings } from '@slmpserv/purchase-request-settings-api'
import { useCurrentDealerContext } from '@slmpserv/self-service-current-dealer-context'
import { ErrorInlineNotification, QueryBoundary } from '@slmpserv/common-components'
import { PurchaseRequestSettingsStatus } from '@slmpserv/self-service/purchase-request-notifications'
import { AccessSensitiveProps } from '@slmpserv/self-service-shared'
import { useIsAllowedToEditMerchant, useIsAllowedToEditSeller } from '@slmpserv/common/api/use-query-wrapper'

import { DashboardGroupItemPreview } from '../DashboardGroupItemPreview/DashboardGroupItemPreview'
import { DashboardGroupItem } from '../DashboardGroupItem/DashboardGroupItem'

export function PurchaseRequestContactItemContainer() {
    const { dealershipId, marketplaceKey } = useCurrentDealerContext()
    const { formatMessage } = useIntl()

    const isAllowedToEditMerchant = useIsAllowedToEditMerchant(dealershipId)
    const isAllowedToEditSeller = useIsAllowedToEditSeller(dealershipId)

    const isAllowedToEdit = isAllowedToEditMerchant || isAllowedToEditSeller

    const { queryResult } = useFetchPurchaseRequestSettings({
        dealerEntryDn: dealershipId,
        marketplace: marketplaceKey,
        queryOptions: {
            refetchOnWindowFocus: false,
        },
    })

    return (
        <QueryBoundary
            queryResult={queryResult}
            fallbackRender={() => (
                <ErrorInlineNotification
                    title={formatMessage(errorsMessages.justError)}
                    message={formatMessage(dashboardErrorMessages.purchaseRequestConfigError)}
                />
            )}
        >
            <PurchaseRequestContactItem purchaseRequestConfig={queryResult.data} isAllowedToEdit={isAllowedToEdit} />
        </QueryBoundary>
    )
}

type PurchaseRequestContactItemProps = AccessSensitiveProps & {
    purchaseRequestConfig: {
        contactEmailAddresses: string[]
        notificationsEnabled: boolean
    }
}

function PurchaseRequestContactItem({ purchaseRequestConfig, isAllowedToEdit }: PurchaseRequestContactItemProps) {
    const { formatMessage } = useIntl()
    const { trackEvent } = useMatomoTrackEditEvent('purchase_request_contact', false)

    return (
        <DashboardGroupItem
            hightlightColor={null}
            title={formatMessage(dashboardTitles.purchaseRequestNotifications)}
            tooltipText={formatMessage(dashboardPopoverMessages.purchaseRequestNotifications)}
            preview={
                <DashboardGroupItemPreview
                    title={formatMessage(dashboardPreviewMessages.contactEmailAddresses)}
                    text={
                        purchaseRequestConfig.contactEmailAddresses.length > 0
                            ? purchaseRequestConfig.contactEmailAddresses
                            : '-'
                    }
                />
            }
            badge={<PurchaseRequestSettingsStatus purchaseRequestConfig={purchaseRequestConfig} />}
            editLink={{
                isAllowedToEdit,
                props: { to: StaticRoutes.PURCHASE_REQUEST_CONTACT },
                dataTestId: 'purchase-request-edit-link',
                matomoEvent: trackEvent,
            }}
        />
    )
}
