import { AuthorizationHeader } from '@finder/cognito-react'

import { Environment, getEnvironment } from '@slmpserv/environment'

import {
    AccountHolderManagementApi,
    Configuration,
    KYCEmailsApi,
    Middleware,
    RequestContext,
    SubmerchantManagementApi,
} from '../../api/index'

class PpnMiddleware implements Middleware {
    private tokenFunction?: () => Promise<AuthorizationHeader | undefined>
    private env: Environment = Environment.LOCAL

    public setEnv = (env: Environment) => (this.env = env)
    public setTokenFunction(tokenFunction: () => Promise<AuthorizationHeader | undefined>) {
        this.tokenFunction = tokenFunction
    }

    pre = async ({ url, init }: RequestContext) => {
        if (this.tokenFunction) {
            const existingHeaders = init.headers || {}
            const headersWithAuth = {
                ...existingHeaders,
                ...(await this.tokenFunction()),
                Apikey: this.env === Environment.PROD ? apiKeys[Environment.PROD] : apiKeys[Environment.PREVIEW],
                'Auth-mode': 'ppn',
            }
            const initWithAuth = { ...init, headers: headersWithAuth }
            return { init: initWithAuth, url }
        }
        return { url, init }
    }
}

class BasePathMiddleware implements Middleware {
    private env: Environment = Environment.LOCAL
    public setEnv = (env: Environment) => (this.env = env)
    pre = async ({ url, init }: RequestContext) => {
        const backendUrl = new URL(kycBackendUrls[this.env])
        const requestUrl = new URL(url)
        requestUrl.hostname = backendUrl.hostname
        requestUrl.protocol = backendUrl.protocol
        requestUrl.port = backendUrl.port
        return { init, url: requestUrl.toString() }
    }
}

export const apiMiddleware = new PpnMiddleware()
export const basePathMiddleware = new BasePathMiddleware()

export const apiKeys = {
    [Environment.PROD]: 'vZUABlrXzPNWc0KOVFW32SodatPgMZIt',
    [Environment.PREVIEW]: 'ql3HA7msQo2BjOfk5rVtUMTLzXdFw4hb',
}

export const kycBackendUrls: Record<Environment, string> = {
    DEV: 'https://api.porsche-preview.com/kyc/v1',
    PREVIEW: 'https://api.porsche-preview.com/kyc/v1',
    PROD: 'https://api.porsche.com/kyc/v1',
    LOCAL: 'https://api.porsche-preview.com/kyc/v1',
    E2E: 'https://api.porsche-preview.com/kyc/v1',
}

const kycConfig = new Configuration({
    basePath: kycBackendUrls[getEnvironment()],
    middleware: [apiMiddleware, basePathMiddleware],
})

export const kycApi = new KYCEmailsApi(kycConfig)
export const submerchantApi = new SubmerchantManagementApi(kycConfig)
export const accountHolderApi = new AccountHolderManagementApi(kycConfig)
