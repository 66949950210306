/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * amount and currency
 * @export
 * @interface Amount
 */
export interface Amount {
    /**
     * The three-character ISO currency code.
     * @type {string}
     * @memberof Amount
     */
    currency?: string
    /**
     * The payable amount that can be charged for the transaction. The transaction amount needs to be represented in minor units according to the following [table](https://docs.adyen.com/developers/development-resources/currency-codes)
     * @type {number}
     * @memberof Amount
     */
    value?: number
}

export function AmountFromJSON(json: any): Amount {
    return AmountFromJSONTyped(json, false)
}

export function AmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): Amount {
    if (json === undefined || json === null) {
        return json
    }
    return {
        currency: !exists(json, 'currency') ? undefined : json['currency'],
        value: !exists(json, 'value') ? undefined : json['value'],
    }
}

export function AmountToJSON(value?: Amount | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        currency: value.currency,
        value: value.value,
    }
}
