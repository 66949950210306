import { useIntl } from 'react-intl'

import { ErrorInlineNotification, QueryBoundary } from '@slmpserv/common-components'
import { dashboardErrorMessages, dashboardTitles, errorsMessages } from '@slmpserv/common-translations'
import {
    useFetchSellerContactMailAddresses,
    useFetchSellerContactPhoneNumber,
    useIsAllowedToEditMerchant,
    useIsAllowedToEditSeller,
} from '@slmpserv/common/api/use-query-wrapper'

import { DashboardGroup } from '../DashboardGroup/DashboardGroup'
import { DataWriteAccessIsRestricted } from '../DashboardGroupItem/DashboardGroupItem'

import { EmailsItem } from './EmailsItem'
import { HomepageItem } from './HomepageItem'
import { PhoneItem } from './PhoneItem'
import { PurchaseRequestContactItemContainer } from './PurchaseRequestContactItem'

type DealershipContactProps = {
    entryDn: string
    showPurchaseRequestSettings: boolean
}

export function SellerDealershipContactGroup({ entryDn, showPurchaseRequestSettings }: DealershipContactProps) {
    const { formatMessage } = useIntl()

    const isAllowedToEditSeller = useIsAllowedToEditSeller(entryDn)
    const isAllowedToEditMerchant = useIsAllowedToEditMerchant(entryDn)

    const {
        emailAddressesNew,
        emailAddressesPreowned,
        queryResult: fetchEmailsQuery,
    } = useFetchSellerContactMailAddresses({
        entryDn: entryDn,
        queryOptions: {
            refetchOnWindowFocus: false,
        },
    })

    const {
        phoneNumberNew,
        phoneNumberPreowned,
        queryResult: fetchPhoneNumberQuery,
    } = useFetchSellerContactPhoneNumber({
        entryDn: entryDn,
        queryOptions: {
            refetchOnWindowFocus: false,
        },
    })

    const hasAllWriteAccess = isAllowedToEditSeller || isAllowedToEditMerchant

    return (
        <DashboardGroup
            boxTitle={formatMessage(dashboardTitles.dealershipInformationGroup)}
            badge={<DataWriteAccessIsRestricted hasAllWriteAccess={hasAllWriteAccess} />}
        >
            <>
                <QueryBoundary
                    queryResult={fetchEmailsQuery}
                    fallbackRender={() => (
                        <ErrorInlineNotification
                            title={formatMessage(errorsMessages.justError)}
                            message={formatMessage(dashboardErrorMessages.sellerDealershipContactEmailAddressMissing)}
                        />
                    )}
                >
                    <EmailsItem
                        newCarsEmails={emailAddressesNew}
                        usedCarsEmails={emailAddressesPreowned}
                        emailsMissing={false}
                        isAllowedToEdit={isAllowedToEditSeller}
                    />
                </QueryBoundary>
                <QueryBoundary
                    queryResult={fetchPhoneNumberQuery}
                    fallbackRender={() => (
                        <ErrorInlineNotification
                            title={formatMessage(errorsMessages.justError)}
                            message={formatMessage(dashboardErrorMessages.sellerDealershipContactPhoneNumberMissing)}
                        />
                    )}
                >
                    <PhoneItem
                        newCarsPhoneNumber={phoneNumberNew}
                        usedCarsPhoneNumber={phoneNumberPreowned}
                        phoneNumberMissing={false}
                        isAllowedToEdit={isAllowedToEditSeller}
                    />
                </QueryBoundary>

                {showPurchaseRequestSettings && <PurchaseRequestContactItemContainer />}

                <HomepageItem />
            </>
        </DashboardGroup>
    )
}
