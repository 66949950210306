import { ReactNode } from 'react'
import { PSpinner } from '@porsche-design-system/components-react'
import { Flex } from '@slcheckout/ui-kit'
import { styled } from 'styled-components'

import { FullScreenCss } from './LoadingBoundary.styles'

const StyledFlex = styled(Flex)<{ $fullscreen?: boolean }>`
    ${({ $fullscreen }) => $fullscreen && FullScreenCss}
`

interface LoadingBoundaryProps {
    children?: ReactNode
    isLoading: boolean
    fullscreen?: boolean
}

export function LoadingBoundary({ isLoading, fullscreen = false, children }: LoadingBoundaryProps) {
    return isLoading ? (
        <StyledFlex justifyContent={'center'} alignItems={'center'} $fullscreen={fullscreen}>
            <PSpinner size={'medium'} />
        </StyledFlex>
    ) : (
        children
    )
}
