import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useMemo } from 'react'

import { marketplacesDocumentConfigurationApi, MarketplaceDocumentConfig } from '@slmpserv/common-api-merchant'

type UseFetchMerchantDocumentConfigurationProps = {
    marketplaceKey: string
}

type QueryOptions = Omit<
    UseQueryOptions<MarketplaceDocumentConfig[], Error, MarketplaceDocumentConfig[], string[]>,
    'queryKey' | 'queryFn'
>

export function useFetchMarketplaceDocumentConfiguration(
    { marketplaceKey }: UseFetchMerchantDocumentConfigurationProps,
    options: QueryOptions = {}
) {
    const queryKey = useMemo(() => ['marketplace-document-configuration', marketplaceKey], [marketplaceKey])

    const queryResult = useQuery({
        queryKey,
        queryFn: () => marketplacesDocumentConfigurationApi.getDocumentsConfigurationsByMarketplace({ marketplaceKey }),
        enabled: Boolean(marketplaceKey),
        ...options,
    })

    return { marketplaceDocumentConfig: queryResult.data || [], queryResult, queryKey }
}
