import { useMutation, useQuery } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useToastManager } from '@porsche-design-system/components-react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { styled } from 'styled-components'

import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { FormButtons } from '@slmpserv/common-components-legacy'
import { LoadingAndErrorWrapper } from '@slmpserv/self-service-shared'
import { Merchant, onlineOrderConfigurationApi } from '@slmpserv/common-api-merchant'
import { MultipleEmailsField } from '@slmpserv/common-components'

import { payoutContactConfigurationMessages } from './PayoutContactConfiguration.messages'
import { EmailsForPayoutReportState } from './types'

const StyledEmailFields = styled.div`
    display: grid;
    grid-template-columns: 1fr;
`

type PayoutContactConfigurationProps = {
    ppnId: Merchant['ppnId']
}

export function PayoutContactConfiguration({ ppnId }: PayoutContactConfigurationProps) {
    const { addMessage } = useToastManager()
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const {
        register,
        handleSubmit: useFormHandleSubmit,
        reset,
        formState,
        control,
    } = useForm<EmailsForPayoutReportState>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: { emailsForPayoutReport: [{ value: '' }] },
    })

    const queryKey = ['online-order-config', ppnId]
    const { isLoading, isError, data } = useQuery(
        // queryKey,
        // () => onlineOrderConfigurationApi.getOrderConfiguration({ ppnId }),
        {
            queryKey,
            queryFn: () => onlineOrderConfigurationApi.getOrderConfiguration({ ppnId }),
            refetchOnWindowFocus: false,
        }
    )

    useEffect(() => {
        if (!isLoading && !isError && data) {
            reset({
                emailsForPayoutReport:
                    data.emailsForPayoutInformation.length > 0
                        ? data.emailsForPayoutInformation.map(email => ({ value: email }))
                        : [{ value: '' }],
            })
        }
    }, [data, isError, isLoading, reset])

    const { mutate, isPending: isSaving } = useMutation({
        mutationFn: (data: EmailsForPayoutReportState) => {
            const emailsForPayoutInformation = data.emailsForPayoutReport.map(({ value }) => value)
            return onlineOrderConfigurationApi.updatePayoutInformationEmails({
                ppnId,
                payoutInformationEmailsRequest: { emailsForPayoutInformation },
            })
        },
        onSuccess: () =>
            addMessage({
                state: 'success',
                text: formatMessage(payoutContactConfigurationMessages.toastSubmitSuccess),
            }),
        onError: error => {
            console.error(error)
            addMessage({
                state: 'neutral',
                text: formatMessage(payoutContactConfigurationMessages.toastSubmitError),
            })
        },
    })

    const handleSubmit = (data: EmailsForPayoutReportState) => {
        mutate(data)
    }

    const handleCancel = () => {
        navigate(StaticRoutes.DASHBOARD)
    }

    return (
        <LoadingAndErrorWrapper isLoading={isLoading} isError={isError}>
            <form onSubmit={useFormHandleSubmit(handleSubmit)}>
                <StyledEmailFields>
                    <MultipleEmailsField
                        control={control}
                        register={register}
                        formState={formState}
                        fieldName={'emailsForPayoutReport'}
                        messages={{
                            firstEntryLabel: payoutContactConfigurationMessages.payoutReportEmailLabel,
                            nthEntryLabel: payoutContactConfigurationMessages.payoutReportAdditionalEmailLabel,
                        }}
                    />
                    <FormButtons isLoading={isSaving} onCancel={handleCancel} />
                </StyledEmailFields>
            </form>
        </LoadingAndErrorWrapper>
    )
}
