import { useIntl } from 'react-intl'

import { dashboardPopoverMessages, dashboardPreviewMessages, dashboardTitles } from '@slmpserv/common-translations'

import { DashboardGroupItem } from '../DashboardGroupItem/DashboardGroupItem'
import { DashboardGroupItemPreview } from '../DashboardGroupItemPreview/DashboardGroupItemPreview'

export function HomepageItem() {
    const { formatMessage } = useIntl()

    return (
        <DashboardGroupItem
            title={formatMessage(dashboardTitles.homepage)}
            tooltipText={formatMessage(dashboardPopoverMessages.homepage)}
            preview={
                <DashboardGroupItemPreview
                    title={formatMessage(dashboardPreviewMessages.homepageUrlsDeprecatedTitle)}
                    text={formatMessage(dashboardPreviewMessages.homepageUrlsDeprecatedText)}
                />
            }
        />
    )
}
