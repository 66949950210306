/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface Merchant
 */
export interface Merchant {
    /**
     *
     * @type {string}
     * @memberof Merchant
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof Merchant
     */
    ppnId: string
    /**
     *
     * @type {string}
     * @memberof Merchant
     */
    entryDn?: string
    /**
     *
     * @type {string}
     * @memberof Merchant
     */
    businessName: string
    /**
     *
     * @type {string}
     * @memberof Merchant
     */
    legalBusinessName: string
    /**
     *
     * @type {string}
     * @memberof Merchant
     */
    marketplaceKey: string
    /**
     *
     * @type {string}
     * @memberof Merchant
     */
    debtorNumber?: string
}

/**
 * Check if a given object implements the Merchant interface.
 */
export function instanceOfMerchant(value: object): boolean {
    if (!('ppnId' in value)) return false
    if (!('businessName' in value)) return false
    if (!('legalBusinessName' in value)) return false
    if (!('marketplaceKey' in value)) return false
    return true
}

export function MerchantFromJSON(json: any): Merchant {
    return MerchantFromJSONTyped(json, false)
}

export function MerchantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Merchant {
    if (json == null) {
        return json
    }
    return {
        id: json['id'] == null ? undefined : json['id'],
        ppnId: json['ppnId'],
        entryDn: json['entryDn'] == null ? undefined : json['entryDn'],
        businessName: json['businessName'],
        legalBusinessName: json['legalBusinessName'],
        marketplaceKey: json['marketplaceKey'],
        debtorNumber: json['debtorNumber'] == null ? undefined : json['debtorNumber'],
    }
}

export function MerchantToJSON(value?: Merchant | null): any {
    if (value == null) {
        return value
    }
    return {
        id: value['id'],
        ppnId: value['ppnId'],
        entryDn: value['entryDn'],
        businessName: value['businessName'],
        legalBusinessName: value['legalBusinessName'],
        marketplaceKey: value['marketplaceKey'],
        debtorNumber: value['debtorNumber'],
    }
}
