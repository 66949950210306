import { PButton } from '@porsche-design-system/components-react'
import { useIntl } from 'react-intl'

import { documentFileUploadMessages } from '@slmpserv/common-translations'

type ButtonUpdateFileProps = {
    onClick: () => void
    disabled: boolean
}

export function ButtonUpdateFile({ onClick, disabled }: ButtonUpdateFileProps) {
    const { formatMessage } = useIntl()

    return (
        <PButton onClick={onClick} icon={'upload'} type={'button'} disabled={disabled}>
            {formatMessage(documentFileUploadMessages.updateFile)}
        </PButton>
    )
}
