import 'systemjs-webpack-interop/auto-public-path'
import ReactDOM from 'react-dom'

import { MerchantAccountSelfServiceProps, MerchantAccountSelfService } from './app/pages/MerchantAccountSelfService'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function exportForFinder(container: any) {
    return {
        mount(props: MerchantAccountSelfServiceProps) {
            ReactDOM.render(<MerchantAccountSelfService {...props} />, container)
        },
        unmount() {
            ReactDOM.unmountComponentAtNode(container)
        },
    }
}

export default exportForFinder
