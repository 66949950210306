// TODO: move to lib, this is used also in UnifiedFilePicker
export function openBlobInNewTab(blob?: Blob, name?: string) {
    if (!blob || !name) {
        console.error('can not open file because either name or blob is undefined')
        return
    } else {
        // chrome & ff
        window.open(window.URL.createObjectURL(blob))
    }
}
