import { defineMessages } from 'react-intl'

export const finderDealerWrapperMessages = defineMessages({
    hideNotifications: {
        id: 'dealer.dashboard.notificationArea.hide',
        defaultMessage: 'Hide Notifications ({count})',
    },
    showNotifications: {
        id: 'dealer.dashboard.notificationArea.show',
        defaultMessage: 'Show Notifications ({count})',
    },
})
