import { ReactNode } from 'react'
import { PHeading, PLinkPure } from '@porsche-design-system/components-react'
import { spacingStatic } from '@porsche-design-system/components-react/styles'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'

const StyledBackLink = styled(PLinkPure)`
    margin-bottom: ${spacingStatic.large};
`

type WithTitle = {
    title: string
    children?: never
    backButton?: { label: string; to: string }
}
type WithChildren = {
    title?: never
    children: ReactNode
    backButton?: never
}
type PageHeaderProps = WithTitle | WithChildren

export function PageHeader({ title, children, backButton }: PageHeaderProps) {
    return title ? (
        <>
            {backButton ? (
                <Link to={backButton.to} data-testid={'back-to-dashboard-link'}>
                    <StyledBackLink icon={'arrow-head-left'}>{backButton.label}</StyledBackLink>
                </Link>
            ) : null}
            <PHeading tag="h3" size="large">
                {title}
            </PHeading>
        </>
    ) : (
        children
    )
}
