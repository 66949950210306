import { PHeading, PText } from '@porsche-design-system/components-react'

type DashboardGroupItemPreviewProps = {
    title: string
    text: string | string[]
}

export function DashboardGroupItemPreview({ title, text }: DashboardGroupItemPreviewProps) {
    const textLines = Array.isArray(text) ? text : [text]

    return (
        <>
            <PHeading tag="h5" size="small">
                {title}
            </PHeading>
            {textLines.map((line, index) => (
                <PText key={index} color={'neutral-contrast-medium'}>
                    {line}
                </PText>
            ))}
        </>
    )
}
