/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateKycEmail
 */
export interface UpdateKycEmail {
    /**
     * The complete list of email addresses that should be stored
     * @type {Array<string>}
     * @memberof UpdateKycEmail
     */
    addresses: Array<string>
}

export function UpdateKycEmailFromJSON(json: any): UpdateKycEmail {
    return UpdateKycEmailFromJSONTyped(json, false)
}

export function UpdateKycEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateKycEmail {
    if (json === undefined || json === null) {
        return json
    }
    return {
        addresses: json['addresses'],
    }
}

export function UpdateKycEmailToJSON(value?: UpdateKycEmail | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        addresses: value.addresses,
    }
}
