import { PBanner, useToastManager } from '@porsche-design-system/components-react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useMutation, useQuery } from '@tanstack/react-query'
import { CSSProperties, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import {
    DownPaymentMarketplaceStatus,
    onlineOrderConfigurationApi,
    OnlineOrderConfigurationDownPaymentRequest,
    PaymentStatusResponse,
} from '@slmpserv/common-api-merchant'
import { FormButtons } from '@slmpserv/common-components-legacy'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { useMarketplaceContext, useMerchant } from '@slmpserv/common-contexts'
import { useOnlineOrderingStatus, useSyncOnlineOrderingStatus } from '@slmpserv/common/api/use-query-wrapper'
import { LoadingAndErrorWrapper } from '@slmpserv/self-service-shared'

import { DownPaymentFormState } from '../types'
import { onlineOrderConfigurationMessages } from '../OnlineOrderConfiguration.messages'

import { downPaymentConfigurationMessages } from './DownPayment.messages'
import { DownPaymentAmountField } from './DownPaymentAmountField'
import { DownPaymentActiveField } from './DownPaymentActiveField'

interface DownPaymentConfigurationProps {
    ppnId: string
    onSuccessSaveCallback: (arg0: boolean) => boolean
}

export function DownPaymentConfiguration({ ppnId, onSuccessSaveCallback }: DownPaymentConfigurationProps) {
    const { addMessage } = useToastManager()
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const marketplace = useMarketplaceContext()
    const {
        onlineOrderingStatus,
        queryResult: { isLoading: isOnlineOrderingLoading },
    } = useOnlineOrderingStatus({ ppnId })

    const methods = useForm<DownPaymentFormState>({
        mode: 'all',
        reValidateMode: 'onChange',
    })

    const { data, isLoading, isError } = useQuery({
        queryKey: ['down-payment-config', ppnId],
        queryFn: () => onlineOrderConfigurationApi.getOrderConfiguration({ ppnId }),
        refetchOnWindowFocus: false,
    })

    const merchant = useMerchant()

    useEffect(() => {
        if (!isLoading && !isError && data) {
            methods.reset({
                downPaymentValue: data.downPayment.value || 0,
                downPaymentActive: data.downPayment.active,
            })
        }
    }, [data, isError, isLoading, methods])

    const { mutateAsync: updateDownpayment, isPending: isSaving } = useMutation({
        mutationFn: (formValues: DownPaymentFormState) => {
            const requestData: OnlineOrderConfigurationDownPaymentRequest = {
                downPaymentActive: formValues.downPaymentActive,
                downPaymentValue: formValues.downPaymentValue,
            }
            return onlineOrderConfigurationApi.updateOrderConfigurationDownPayment({
                ppnId,
                onlineOrderConfigurationDownPaymentRequest: requestData,
            })
        },
    })
    const syncOnlineOrderingStatus = useSyncOnlineOrderingStatus(merchant.id)

    const handleSubmit = async (data: DownPaymentFormState) => {
        try {
            await updateDownpayment(data)
            await syncOnlineOrderingStatus()
            addMessage({ state: 'success', text: formatMessage(onlineOrderConfigurationMessages.toastSubmitSuccess) })
            onSuccessSaveCallback(true)
        } catch (err) {
            console.error(err)
            addMessage({ state: 'neutral', text: formatMessage(onlineOrderConfigurationMessages.toastSubmitError) })
        }
    }

    const handleCancel = () => {
        navigate(StaticRoutes.DASHBOARD)
    }

    const style = {
        '--p-banner-position-type': 'relative',
        '--p-banner-position-top': 'none',
        '--p-banner-position-bottom': 'none',
    } as CSSProperties

    const showKycBanner =
        (onlineOrderingStatus?.paymentStatus === PaymentStatusResponse.INACTIVE ||
            onlineOrderingStatus?.paymentStatus === PaymentStatusResponse.UNREGISTERED) &&
        marketplace.downPaymentStatus === DownPaymentMarketplaceStatus.OPTIONAL

    const downPaymentActive = methods.watch('downPaymentActive')

    return (
        <LoadingAndErrorWrapper isLoading={isLoading || isOnlineOrderingLoading} isError={isError}>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                    <DownPaymentActiveField
                        control={methods.control}
                        downPaymentMarketplaceStatus={marketplace.downPaymentStatus}
                    />
                    <DownPaymentAmountField
                        downPaymentActive={downPaymentActive}
                        currency={data?.downPayment.currency}
                    />
                    {showKycBanner && (
                        <PBanner open={showKycBanner} style={style}>
                            <span slot="description">
                                {formatMessage(downPaymentConfigurationMessages.downPaymentKycInfoText)}
                            </span>
                        </PBanner>
                    )}
                    <FormButtons isLoading={isSaving} onCancel={handleCancel} />
                </form>
            </FormProvider>
        </LoadingAndErrorWrapper>
    )
}
