/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PayoutInformationEmailsRequest
 */
export interface PayoutInformationEmailsRequest {
    /**
     *
     * @type {Array<string>}
     * @memberof PayoutInformationEmailsRequest
     */
    emailsForPayoutInformation: Array<string>
}

/**
 * Check if a given object implements the PayoutInformationEmailsRequest interface.
 */
export function instanceOfPayoutInformationEmailsRequest(value: object): boolean {
    if (!('emailsForPayoutInformation' in value)) return false
    return true
}

export function PayoutInformationEmailsRequestFromJSON(json: any): PayoutInformationEmailsRequest {
    return PayoutInformationEmailsRequestFromJSONTyped(json, false)
}

export function PayoutInformationEmailsRequestFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): PayoutInformationEmailsRequest {
    if (json == null) {
        return json
    }
    return {
        emailsForPayoutInformation: json['emailsForPayoutInformation'],
    }
}

export function PayoutInformationEmailsRequestToJSON(value?: PayoutInformationEmailsRequest | null): any {
    if (value == null) {
        return value
    }
    return {
        emailsForPayoutInformation: value['emailsForPayoutInformation'],
    }
}
