import { useQuery } from '@tanstack/react-query'

import { merchantsApi, Merchant, MerchantStatus } from '@slmpserv/common-api-merchant'

export function useFetchMerchantStatus(ppnId: Merchant['ppnId']) {
    const queryKey = ['merchant', ppnId, 'merchantStatus']
    const queryResult = useQuery({
        queryKey,
        queryFn: () => merchantsApi.getMerchantStatus({ ppnId }),
    })
    const data = (queryResult?.data || {
        data: {
            missingImprints: [],
            missingPrivacyPolicies: [],
            missingTncDocuments: {},
        },
        errors: [],
    }) as MerchantStatus

    return { merchantStatus: data, queryResult }
}
