import { useQuery } from '@tanstack/react-query'

import { ApiError, Marketplace, marketplaceAdapter } from '@slmpserv/common-api-merchant'

import { shouldRetry } from './shouldRetry'

export type UseFetchMarketplaceProps = {
    marketplaceKey?: Marketplace['key']
}

export function useFetchMarketplace({ marketplaceKey }: UseFetchMarketplaceProps) {
    const queryKey = ['marketplace', marketplaceKey]

    const queryResult = useQuery({
        queryKey,
        queryFn: () => marketplaceAdapter.getMarketplace(marketplaceKey ?? ''),
        enabled: Boolean(marketplaceKey),
        retry: (failureCount, error) => {
            return shouldRetry(error as unknown as ApiError, failureCount)
        },
    })

    return { marketplace: queryResult.data, queryResult, queryKey } as const
}
