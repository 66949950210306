import { FC } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { DealerContextProps } from '@slfinrtl/context'

import {
    useApiBasePath,
    useApiTokenFn,
    useKycApiBasePath,
    usePurchaseRequestSettingsApiBasePath,
} from '@slmpserv/hooks'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { CurrentDealerContextProvider } from '@slmpserv/self-service-current-dealer-context'

import { AppWrapper } from '../components/AppWrapper'
import { getCurrentEnvironment } from '../utils/envResolver'
import { DashboardRoutes, RouteSelector } from '../components/Routes'
import { MatomoDisclaimer } from '../components/MatomoDisclaimer'
import { SellerRoutes } from '../components/Routes/SellerRoutes'

import { Error } from './Error/Error'

export interface MerchantAccountSelfServiceProps {
    context: DealerContextProps
    basePath: string
}

export const MerchantAccountSelfService: FC<MerchantAccountSelfServiceProps> = ({
    basePath,
    context: dealerContext,
}) => {
    useApiTokenFn(dealerContext.getAuthorizationHeader)
    useApiBasePath(getCurrentEnvironment(dealerContext))
    useKycApiBasePath(getCurrentEnvironment(dealerContext))
    usePurchaseRequestSettingsApiBasePath(getCurrentEnvironment(dealerContext))

    return (
        <CurrentDealerContextProvider dealerContext={dealerContext}>
            <AppWrapper locale={dealerContext.locale} environment={dealerContext.environment}>
                <Router basename={basePath}>
                    <Routes>
                        <Route path={StaticRoutes.ERROR} element={<Error />} />
                        <Route
                            path={'*'}
                            element={
                                <RouteSelector dashboardRoutes={<DashboardRoutes />} sellerRoutes={<SellerRoutes />} />
                            }
                        />
                    </Routes>
                </Router>
                <MatomoDisclaimer environment={dealerContext.environment} />
            </AppWrapper>
        </CurrentDealerContextProvider>
    )
}
