import { Fragment, ReactNode } from 'react'
import { PSpinner, PText } from '@porsche-design-system/components-react'
import { useIntl } from 'react-intl'

import { loadingAndErrorMessages } from './LoadingAndErrorWrapper.messages'

type LoadingAndErrorWrapperProps = {
    isLoading: boolean
    isError: boolean
    children: ReactNode
}

export function LoadingAndErrorWrapper({ isLoading, isError, children }: LoadingAndErrorWrapperProps) {
    const { formatMessage } = useIntl()
    if (isLoading) {
        return (
            <PSpinner
                size={{ base: 'small', l: 'medium' }}
                aria-label={formatMessage(loadingAndErrorMessages.isLoading)}
            />
        )
    } else if (isError) {
        return <PText>{formatMessage(loadingAndErrorMessages.isError)}</PText>
    } else {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <Fragment>{children}</Fragment>
    }
}
