import { useIntl } from 'react-intl'
import React from 'react'
import { Link } from 'react-router-dom'
import { PHeading, PLinkPure, PText } from '@porsche-design-system/components-react'
import { spacingStatic } from '@porsche-design-system/components-react/styles'
import { Flex, FlexItem } from '@slcheckout/ui-kit'

import { routesMessages } from '@slmpserv/common-translations'
import { BoxLayout, PageLayout } from '@slmpserv/common-components-legacy'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { useCurrentDealerContext } from '@slmpserv/self-service-current-dealer-context'
import { PurchaseRequestNotificationsConfig } from '@slmpserv/self-service/purchase-request-notifications'

export function PurchaseRequestContactPage() {
    const { formatMessage } = useIntl()
    const { dealershipId } = useCurrentDealerContext()
    return (
        <PageLayout
            header={
                <>
                    <Link to={StaticRoutes.DASHBOARD}>
                        <PLinkPure icon={'arrow-head-left'} style={{ marginBottom: spacingStatic.large }}>
                            {formatMessage(routesMessages.backToDashboard)}
                        </PLinkPure>
                    </Link>
                    <Flex direction="column">
                        <FlexItem>
                            <PHeading tag="h3" size="large">
                                {formatMessage(routesMessages.purchaseRequestContactHeadline)}
                            </PHeading>
                        </FlexItem>
                        <FlexItem>
                            <PText color={'neutral-contrast-medium'}>
                                {formatMessage(routesMessages.purchaseRequestContactSubHeadline)}
                            </PText>
                        </FlexItem>
                    </Flex>
                </>
            }
        >
            <BoxLayout>
                <PurchaseRequestNotificationsConfig entryDn={dealershipId} />
            </BoxLayout>
        </PageLayout>
    )
}
