import { Control, FormState, useFieldArray, UseFormRegister } from 'react-hook-form'
import { MessageDescriptor, useIntl } from 'react-intl'
import { PButtonPure, PTextFieldWrapper } from '@porsche-design-system/components-react'
import { spacingFluid, spacingStatic } from '@porsche-design-system/components-react/styles'
import { styled } from 'styled-components'
import { Flex, FlexItem } from '@slcheckout/ui-kit'

import { emailFieldMessages } from '@slmpserv/common-translations'

import { MailRegexp } from './MailRegexp/MailRegexp'

const StyledEmailFieldGrid = styled.div<{ isFirst: boolean }>`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${spacingFluid.medium};
    margin-top: ${({ isFirst }) => (isFirst ? 0 : spacingFluid.medium)};
    margin-bottom: ${spacingFluid.small};
`

const StyledAddButton = styled(PButtonPure)`
    margin-bottom: ${spacingStatic.medium};
`

const StyledDeleteButton = styled(Flex)`
    margin-top: ${spacingStatic.large};
`

type EmailFieldProps = {
    register: UseFormRegister<any>
    formState: FormState<any>
    control: Control<any>
    fieldName: string
    messages: {
        firstEntryLabel: MessageDescriptor
        nthEntryLabel: MessageDescriptor
    }
}

export function MultipleEmailsField({ register, formState, control, fieldName, messages }: EmailFieldProps) {
    const { formatMessage } = useIntl()
    const { fields, append, remove } = useFieldArray({
        control,
        name: fieldName,
    })
    const { firstEntryLabel, nthEntryLabel } = messages

    return (
        <>
            {fields.map((field, index) => (
                <StyledEmailFieldGrid key={field.id} isFirst={index === 0}>
                    <PTextFieldWrapper
                        label={index === 0 ? formatMessage(firstEntryLabel) : formatMessage(nthEntryLabel)}
                        message={
                            formState.errors[fieldName]
                                ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore because this is a generic component the path cannot be types
                                  formState.errors[fieldName]?.[index]?.value?.message
                                : undefined
                        }
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore because this is a generic component the path cannot be types
                        state={formState.errors[fieldName]?.[index] ? 'error' : 'none'}
                    >
                        <input
                            placeholder={formatMessage(emailFieldMessages.placeholder)}
                            {...register(`${fieldName}.${index}.value`, {
                                required: formatMessage(emailFieldMessages.emailRequired),
                                pattern: {
                                    message: formatMessage(emailFieldMessages.emailInvalid),
                                    value: MailRegexp,
                                },
                            })}
                            type={'email'}
                        />
                    </PTextFieldWrapper>
                    <StyledDeleteButton>
                        <FlexItem>
                            {fields.length === 1 ? null : (
                                <StyledAddButton type={'button'} icon={'subtract'} onClick={() => remove(index)}>
                                    {formatMessage(emailFieldMessages.deleteAdditionalEmail)}
                                </StyledAddButton>
                            )}
                        </FlexItem>
                    </StyledDeleteButton>
                </StyledEmailFieldGrid>
            ))}
            <StyledAddButton type={'button'} icon={'add'} onClick={() => append({ value: '' })}>
                {formatMessage(emailFieldMessages.addAdditionalEmail)}
            </StyledAddButton>
        </>
    )
}
