/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PaymentStatusResponse {
    UNREGISTERED = 'UNREGISTERED',
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE',
    PAYOUT_BLOCKED = 'PAYOUT_BLOCKED',
    SUSPENDED = 'SUSPENDED',
    CLOSED = 'CLOSED',
}

export function instanceOfPaymentStatusResponse(value: any): boolean {
    return Object.values(PaymentStatusResponse).includes(value)
}

export function PaymentStatusResponseFromJSON(json: any): PaymentStatusResponse {
    return PaymentStatusResponseFromJSONTyped(json, false)
}

export function PaymentStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentStatusResponse {
    return json as PaymentStatusResponse
}

export function PaymentStatusResponseToJSON(value?: PaymentStatusResponse | null): any {
    return value as any
}
