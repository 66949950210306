import { useCallback, useEffect, useState } from 'react'
import { PCheckboxWrapper, PText } from '@porsche-design-system/components-react'
import { spacingFluid, themeLight } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'
import { Flex, FlexItem, Pds3Grid, Pds3GridItem } from '@slcheckout/ui-kit'
import { styled } from 'styled-components'

import { changeEnableTracking, useMatomo } from '@slmpserv/common/analytics'
import { matomoMessages } from '@slmpserv/common-translations'

const MatomoDisclaimerContainer = styled(Pds3Grid).attrs({
    type: 'wide',
})`
    background-color: ${themeLight.background.surface};
    padding-top: ${spacingFluid.medium};
    padding-bottom: ${spacingFluid.medium};
`

const StyledMatomoDisclaimerGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: ${spacingFluid.medium};
    background-color: ${themeLight.background.base};
    padding: ${spacingFluid.large};
`

type MatomoDisclaimerProps = {
    environment?: string
}

/* eslint-disable @typescript-eslint/ban-ts-comment */
export function MatomoDisclaimer({ environment }: MatomoDisclaimerProps) {
    // useMatomo is needed here because it needs to be initialized at later stage
    useMatomo(environment)
    const [trackingActive, setTrackingActive] = useState(false)
    const { formatMessage } = useIntl()

    const refreshTrackingActiveState = useCallback(() => {
        if (!window?._paq) {
            return
        }
        // this is done here because extracting it in a function did result in unexpected results
        window._paq.push([
            //@ts-ignore
            function () {
                //@ts-ignore
                setTrackingActive(!this.isUserOptedOut())
            },
        ])
    }, [setTrackingActive])

    useEffect(() => {
        refreshTrackingActiveState()
    }, [refreshTrackingActiveState])

    const handleOnChange = e => {
        changeEnableTracking(e.target.checked)
        refreshTrackingActiveState()
    }

    return (
        <MatomoDisclaimerContainer>
            <Pds3GridItem column={{ base: ['start', 'end'] }}>
                <StyledMatomoDisclaimerGrid>
                    <PText weight={'bold'}>{formatMessage(matomoMessages.dataUsage)}</PText>
                    <Flex>
                        <FlexItem>
                            <PCheckboxWrapper hideLabel={true} style={{ display: 'contents' }}>
                                <input type={'checkbox'} checked={trackingActive} onChange={handleOnChange} />
                            </PCheckboxWrapper>
                        </FlexItem>
                        <FlexItem>
                            <Flex direction={'column'} style={{ marginLeft: spacingFluid.small }}>
                                <FlexItem>
                                    <PText weight={'bold'}>{formatMessage(matomoMessages.optOutLabel)}</PText>
                                </FlexItem>
                                <FlexItem style={{ marginTop: spacingFluid.small }}>
                                    <PText color={'neutral-contrast-medium'}>
                                        {formatMessage(matomoMessages.optOutDescription)}
                                    </PText>
                                </FlexItem>
                            </Flex>
                        </FlexItem>
                    </Flex>
                </StyledMatomoDisclaimerGrid>
            </Pds3GridItem>
        </MatomoDisclaimerContainer>
    )
}
