import { useIntl } from 'react-intl'
import { PHeading, PText } from '@porsche-design-system/components-react'
import { spacingFluid } from '@porsche-design-system/components-react/styles'
import { styled } from 'styled-components'

import { documentManagementMessages, routesMessages } from '@slmpserv/common-translations'
import { CompletenessBadge, FormGroup, SpacedDivider } from '@slmpserv/common-components'
import { DocumentType } from '@slmpserv/common-api-merchant'

import { DocumentSelection } from '../components/DocumentSelection/DocumentSelection'
import { useDocumentProgress } from '../hooks/useDocumentProgress'

type PrivacyPolicySectionProps = {
    language: string
}

/**
 * This component is used in the Self Service and in the Operator UI (see below)
 *
 * @param language The language of the document
 */
export function PrivacyPolicySection({ language }: PrivacyPolicySectionProps) {
    const { formatMessage } = useIntl()
    const { selectedDocumentCount, complete, total } = useDocumentProgress(language, [DocumentType.PRIVACY_POLICY])

    return (
        <>
            <SpacedDivider />
            <FormGroup
                label={formatMessage(documentManagementMessages.privacyPolicySection)}
                completedBadge={<CompletenessBadge done={selectedDocumentCount} total={total} complete={complete} />}
                labelColumnWidth={1}
                contentColumnWidth={4}
            >
                <DocumentSelection documentType={DocumentType.PRIVACY_POLICY} language={language} />
            </FormGroup>
        </>
    )
}

type PrivacyPolicyProps = {
    language: string
}

const StyledPrivacyPolicyGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: ${spacingFluid.medium};
    margin-top: ${spacingFluid.medium};
    margin-bottom: ${spacingFluid.medium};
`

/**
 * Usually we only use a section (see above) but in the Operator UI we have an additional explaination
 *
 * @param language The language of the document
 */
export function PrivacyPolicy({ language }: PrivacyPolicyProps) {
    const { formatMessage } = useIntl()

    return (
        <>
            <SpacedDivider />
            <StyledPrivacyPolicyGrid>
                <PHeading tag="h3" size="large">
                    {formatMessage(routesMessages.privacyPolicyHeadline)}
                </PHeading>

                <PText color={'neutral-contrast-medium'} style={{ whiteSpace: 'break-spaces' }}>
                    {formatMessage(routesMessages.privacyPolicySubHeadline)}
                </PText>
            </StyledPrivacyPolicyGrid>
            <PrivacyPolicySection language={language} />
        </>
    )
}
