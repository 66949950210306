import { PTag } from '@porsche-design-system/components-react'
import { useIntl } from 'react-intl'

import {
    dashboardPopoverMessages,
    dashboardPreviewMessages,
    dashboardStatusMessages,
    dashboardTitles,
} from '@slmpserv/common-translations'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { useMatomoTrackEditEvent } from '@slmpserv/common/analytics'
import { AccessSensitiveProps } from '@slmpserv/self-service-shared'

import { DashboardGroupItem } from '../DashboardGroupItem/DashboardGroupItem'
import { DashboardGroupItemPreview } from '../DashboardGroupItemPreview/DashboardGroupItemPreview'

type PayoutsNotificationItemProps = AccessSensitiveProps & {
    contactEmailAddress?: string[]
}

export function PayoutsNotificationItem({ contactEmailAddress, isAllowedToEdit }: PayoutsNotificationItemProps) {
    const { formatMessage } = useIntl()
    const { trackEvent } = useMatomoTrackEditEvent('payouts_notification', contactEmailAddress?.length === 0)

    return (
        <DashboardGroupItem
            hightlightColor={null}
            title={formatMessage(dashboardTitles.payoutNotifications)}
            tooltipText={formatMessage(dashboardPopoverMessages.payoutNotifications)}
            preview={
                <DashboardGroupItemPreview
                    title={formatMessage(dashboardPreviewMessages.contactEmailAddresses)}
                    text={contactEmailAddress?.length === 0 ? '-' : contactEmailAddress}
                />
            }
            badge={<PTag>{formatMessage(dashboardStatusMessages.optional)}</PTag>}
            editLink={{
                isAllowedToEdit,
                props: {
                    to: StaticRoutes.PAYOUT_CONTACT,
                },
                dataTestId: 'payout-notification-edit-link',
                matomoEvent: trackEvent,
            }}
        />
    )
}
