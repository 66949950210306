import { PDivider } from '@porsche-design-system/components-react'
import { spacingFluid } from '@porsche-design-system/components-react/styles'
import { styled } from 'styled-components'

import { FormButtons } from '@slmpserv/common-components-legacy'

export const StyledFormGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
`

export const StyledDivider = styled(PDivider)`
    grid-column: 1/3;
    margin-top: ${spacingFluid.medium};
    margin-bottom: ${spacingFluid.medium};
`

export const StyledInputWrapper = styled.div`
    grid-column: 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
`

export const StyledFormButtons = styled(FormButtons)`
    grid-column: 2;
`
