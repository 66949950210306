import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { type DealershipSettingsDto, GetDealershipSettingsRequest } from '../../api'

import { purchaseRequestSettingsApi } from './purchase-request-settings-api'

export type UseFetchPurchaseRequestConfigProps = GetDealershipSettingsRequest & {
    queryOptions: Omit<
        UseQueryOptions<DealershipSettingsDto, Error, DealershipSettingsDto, string[]>,
        'queryKey' | 'queryFn'
    >
}

export function useFetchPurchaseRequestSettings({
    dealerEntryDn,
    marketplace,
    queryOptions,
}: UseFetchPurchaseRequestConfigProps) {
    const queryKey = ['dealer', dealerEntryDn, 'purchaseRequestConfig']
    const queryResult = useQuery({
        queryKey,
        queryFn: () =>
            purchaseRequestSettingsApi.getDealershipSettings({
                dealerEntryDn,
                marketplace,
            }),
        ...queryOptions,
    })

    return {
        queryResult,
        queryKey,
    }
}
