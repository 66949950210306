/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PaymentInformation
 */
export interface PaymentInformation {
    /**
     *
     * @type {string}
     * @memberof PaymentInformation
     */
    submerchantId?: string
    /**
     *
     * @type {string}
     * @memberof PaymentInformation
     */
    accountHolderCode?: string
    /**
     *
     * @type {string}
     * @memberof PaymentInformation
     */
    paymentStatus: PaymentInformationPaymentStatusEnum
    /**
     *
     * @type {boolean}
     * @memberof PaymentInformation
     */
    verified: boolean
    /**
     *
     * @type {boolean}
     * @memberof PaymentInformation
     */
    payoutAllowed: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum PaymentInformationPaymentStatusEnum {
    NONE = 'NONE',
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE',
    PAYOUT_BLOCKED = 'PAYOUT_BLOCKED',
    SUSPENDED = 'SUSPENDED',
    CLOSED = 'CLOSED',
}

/**
 * Check if a given object implements the PaymentInformation interface.
 */
export function instanceOfPaymentInformation(value: object): boolean {
    if (!('paymentStatus' in value)) return false
    if (!('verified' in value)) return false
    if (!('payoutAllowed' in value)) return false
    return true
}

export function PaymentInformationFromJSON(json: any): PaymentInformation {
    return PaymentInformationFromJSONTyped(json, false)
}

export function PaymentInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentInformation {
    if (json == null) {
        return json
    }
    return {
        submerchantId: json['submerchantId'] == null ? undefined : json['submerchantId'],
        accountHolderCode: json['accountHolderCode'] == null ? undefined : json['accountHolderCode'],
        paymentStatus: json['paymentStatus'],
        verified: json['verified'],
        payoutAllowed: json['payoutAllowed'],
    }
}

export function PaymentInformationToJSON(value?: PaymentInformation | null): any {
    if (value == null) {
        return value
    }
    return {
        submerchantId: value['submerchantId'],
        accountHolderCode: value['accountHolderCode'],
        paymentStatus: value['paymentStatus'],
        verified: value['verified'],
        payoutAllowed: value['payoutAllowed'],
    }
}
