import { useIntl } from 'react-intl'
import { themeLight } from '@porsche-design-system/components-react/styles'

import {
    dashboardPopoverMessages,
    dashboardPreviewMessages,
    dashboardStatusMessages,
    dashboardStatusMessageTooltip,
    dashboardTitles,
} from '@slmpserv/common-translations'
import { useMatomoTrackEditEvent } from '@slmpserv/common/analytics'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { AccessSensitiveProps } from '@slmpserv/self-service-shared'

import { DashboardGroupItem, DashboardWarningTag } from '../DashboardGroupItem/DashboardGroupItem'
import { DashboardGroupItemPreview } from '../DashboardGroupItemPreview/DashboardGroupItemPreview'

type EmailsItemProps = AccessSensitiveProps & {
    usedCarsEmails: string[]
    newCarsEmails: string[]
    emailsMissing: boolean
}

export function EmailsItem({ usedCarsEmails, newCarsEmails, emailsMissing, isAllowedToEdit }: EmailsItemProps) {
    const { formatMessage } = useIntl()
    const { trackEvent } = useMatomoTrackEditEvent('online_order_emails', emailsMissing)

    return (
        <DashboardGroupItem
            hightlightColor={emailsMissing ? themeLight.notification.warning : undefined}
            title={formatMessage(dashboardTitles.inquiriesViaMail)}
            tooltipText={formatMessage(dashboardPopoverMessages.inquiriesViaMail)}
            preview={
                <>
                    <DashboardGroupItemPreview
                        title={formatMessage(dashboardPreviewMessages.emailContactUsedCar)}
                        text={usedCarsEmails.length > 0 ? usedCarsEmails : '-'}
                    />
                    <DashboardGroupItemPreview
                        title={formatMessage(dashboardPreviewMessages.emailContactNewCar)}
                        text={newCarsEmails.length > 0 ? newCarsEmails : '-'}
                    />
                </>
            }
            badge={
                emailsMissing && (
                    <DashboardWarningTag
                        tagText={formatMessage(dashboardStatusMessages.addContact)}
                        popoverText={formatMessage(dashboardStatusMessageTooltip.inquiriesViaMail)}
                    />
                )
            }
            editLink={{
                isAllowedToEdit,
                props: {
                    to: StaticRoutes.SELLER_CONTACT_EMAIL,
                },
                dataTestId: 'email-edit-link',
                matomoEvent: trackEvent,
            }}
        />
    )
}
