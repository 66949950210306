import { defineMessages } from 'react-intl'

export const routesMessages = defineMessages({
    dashboardHeadline: {
        id: 'dealer.dashboard.page.headline',
        defaultMessage: 'Online Ordering',
    },
    payoutHeadline: {
        id: 'dealer.payout.page.headline',
        defaultMessage: 'Payout',
    },
    errorHeadline: {
        id: 'dealer.error.page.headline',
        defaultMessage: 'Error',
    },
    kycNotificationsHeadline: {
        id: 'dealer.page.kycNotifications.headline',
        defaultMessage: 'KYC Notifications',
    },
    kycManagementHeadline: {
        id: 'dealer.page.kycManagement.headline',
        defaultMessage: 'Payment Processing Services & KYC',
    },
    documentManagementHeadline: {
        id: 'dealer.page.documentManagement.headline',
        defaultMessage: 'Document Management',
    },
    documentManagementSubHeadline: {
        id: 'dealer.page.documentManagement.subHeadline',
        defaultMessage: `Terms and Conditions represent contractual conditions of purchase contracts. The dealership is responsible for the completeness and accuracy of the information.

Various legal documents are required for the use of the Porsche Finder platform. As the provider of the offered vehicles in the Porsche Finder, the dealer is responsible for the content and Porsche-adequate quality (e.g. uniform layout) of these documents. Vehicles can only be activated for online ordering if all the required documents (Terms and Conditions, Privacy Policy, Imprint) have been uploaded.`,
    },
    imprintHeadline: {
        id: 'dealer.page.imprint.headline',
        defaultMessage: 'Imprint',
    },
    imprintSubHeadline: {
        id: 'dealer.page.imprint.subHeadline',
        defaultMessage: `An imprint includes general legal information about the company. The dealership is responsible for the completeness and accuracy of the information.

Various legal documents are required for the use of the Porsche Finder platform. As the provider of the offered vehicles in the Porsche Finder, the dealer is responsible for the content and Porsche-adequate quality (e.g. uniform layout) of these documents. Vehicles can only be activated for online ordering if all the required documents (Terms and Conditions, Privacy Policy, Imprint) have been uploaded.`,
    },
    onlineOrderConfigurationHeadline: {
        id: 'dealer.page.onlineOrderConfiguration.headline',
        defaultMessage: 'Online Order Configuration',
    },
    downPaymentConfigurationHeadline: {
        id: 'dealer.page.downPaymentConfiguration.headline',
        defaultMessage: 'Reservation Fee',
    },
    contactConfigurationHeadline: {
        id: 'dealer.page.contactConfiguration.headline',
        defaultMessage: 'Contact',
    },
    backToDashboard: {
        id: 'dealer.navigation.backToDashboard',
        defaultMessage: 'Back to Dashboard',
    },
    inquiryEmailHeadline: {
        id: 'dealer.page.inquiryEmail.headline',
        defaultMessage: 'Inquiries by E-Mail',
    },
    inquiryEmailSubHeadline: {
        id: 'dealer.page.inquiryEmail.subHeadline',
        defaultMessage: 'E-Mail address(es) used for incoming customer inquiries',
    },
    inquiryPhoneHeadline: {
        id: 'dealer.page.inquiryPhone.headline',
        defaultMessage: 'Inquiries by Phone',
    },
    inquiryPhoneSubHeadline: {
        id: 'dealer.page.inquiryPhone.subHeadline',
        defaultMessage: 'Phone number(s) used for incoming customer inquiries',
    },
    purchaseRequestContactHeadline: {
        id: 'dealer.page.purchaseRequestContact.headline',
        defaultMessage: 'Purchase request notifications by E-Mail',
    },
    purchaseRequestContactSubHeadline: {
        id: 'dealer.page.purchaseRequestContact.subHeadline',
        defaultMessage: 'Email address(es) used to notify about incoming purchase requests',
    },
    homepageHeadline: {
        id: 'dealer.page.homepage.headline',
        defaultMessage: 'Homepage',
    },
    homepageSubHeadline: {
        id: 'dealer.page.homepage.subHeadline',
        defaultMessage: 'URLs to your dealer homepage',
    },
    privacyPolicyHeadline: {
        id: 'dealer.page.privacyPolicy.headline',
        defaultMessage: 'Privacy Policy',
    },
    privacyPolicySubHeadline: {
        id: 'dealer.page.privacyPolicy.subHeadline',
        defaultMessage: `The Privacy Policy enables the customer to get informed about the processing of personal data and indicates whether data is passed on to third parties. The dealership is responsible for the completeness and accuracy of the information.

Various legal documents are required for the use of the Porsche Finder platform. As the provider of the offered vehicles in the Porsche Finder, the dealer is responsible for the content and Porsche-adequate quality (e.g. uniform layout) of these documents. Vehicles can only be activated for online ordering if all the required documents (Terms and Conditions, Privacy Policy, Imprint) have been uploaded.`,
    },
})
