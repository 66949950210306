/* tslint:disable */
/* eslint-disable */
/**
 * Purchase Request Service Dealer App Settings Api
 * Dealer settings API of Purchase Request Service, to store and retrieve settings in the dealer app settings microfrontend
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { DealershipSettingsDto } from '../models/index'
import { DealershipSettingsDtoFromJSON, DealershipSettingsDtoToJSON } from '../models/index'

export interface GetDealershipSettingsRequest {
    dealerEntryDn: string
    marketplace: string
}

export interface StoreDealershipSettingsRequest {
    dealerEntryDn: string
    marketplace: string
    dealershipSettingsDto: DealershipSettingsDto
}

/**
 *
 */
export class PurchaseRequestSettingsApi extends runtime.BaseAPI {
    /**
     * Get settings by dealership entryDn
     */
    async getDealershipSettingsRaw(
        requestParameters: GetDealershipSettingsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<DealershipSettingsDto>> {
        if (requestParameters['dealerEntryDn'] == null) {
            throw new runtime.RequiredError(
                'dealerEntryDn',
                'Required parameter "dealerEntryDn" was null or undefined when calling getDealershipSettings().'
            )
        }

        if (requestParameters['marketplace'] == null) {
            throw new runtime.RequiredError(
                'marketplace',
                'Required parameter "marketplace" was null or undefined when calling getDealershipSettings().'
            )
        }

        const queryParameters: any = {}

        if (requestParameters['marketplace'] != null) {
            queryParameters['marketplace'] = requestParameters['marketplace']
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/v1/dealer-settings/{dealerEntryDn}`.replace(
                    `{${'dealerEntryDn'}}`,
                    encodeURIComponent(String(requestParameters['dealerEntryDn']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => DealershipSettingsDtoFromJSON(jsonValue))
    }

    /**
     * Get settings by dealership entryDn
     */
    async getDealershipSettings(
        requestParameters: GetDealershipSettingsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<DealershipSettingsDto> {
        const response = await this.getDealershipSettingsRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     * Stores settings for specified dealership
     */
    async storeDealershipSettingsRaw(
        requestParameters: StoreDealershipSettingsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['dealerEntryDn'] == null) {
            throw new runtime.RequiredError(
                'dealerEntryDn',
                'Required parameter "dealerEntryDn" was null or undefined when calling storeDealershipSettings().'
            )
        }

        if (requestParameters['marketplace'] == null) {
            throw new runtime.RequiredError(
                'marketplace',
                'Required parameter "marketplace" was null or undefined when calling storeDealershipSettings().'
            )
        }

        if (requestParameters['dealershipSettingsDto'] == null) {
            throw new runtime.RequiredError(
                'dealershipSettingsDto',
                'Required parameter "dealershipSettingsDto" was null or undefined when calling storeDealershipSettings().'
            )
        }

        const queryParameters: any = {}

        if (requestParameters['marketplace'] != null) {
            queryParameters['marketplace'] = requestParameters['marketplace']
        }

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request(
            {
                path: `/api/v1/dealer-settings/{dealerEntryDn}`.replace(
                    `{${'dealerEntryDn'}}`,
                    encodeURIComponent(String(requestParameters['dealerEntryDn']))
                ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: DealershipSettingsDtoToJSON(requestParameters['dealershipSettingsDto']),
            },
            initOverrides
        )

        return new runtime.VoidApiResponse(response)
    }

    /**
     * Stores settings for specified dealership
     */
    async storeDealershipSettings(
        requestParameters: StoreDealershipSettingsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<void> {
        await this.storeDealershipSettingsRaw(requestParameters, initOverrides)
    }
}
