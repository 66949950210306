/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { MerchantList } from './MerchantList'
import { MerchantListFromJSON, MerchantListFromJSONTyped, MerchantListToJSON } from './MerchantList'
import type { PageableObject } from './PageableObject'
import { PageableObjectFromJSON, PageableObjectFromJSONTyped, PageableObjectToJSON } from './PageableObject'
import type { SortObject } from './SortObject'
import { SortObjectFromJSON, SortObjectFromJSONTyped, SortObjectToJSON } from './SortObject'

/**
 *
 * @export
 * @interface PageMerchantList
 */
export interface PageMerchantList {
    /**
     *
     * @type {number}
     * @memberof PageMerchantList
     */
    totalElements?: number
    /**
     *
     * @type {number}
     * @memberof PageMerchantList
     */
    totalPages?: number
    /**
     *
     * @type {boolean}
     * @memberof PageMerchantList
     */
    first?: boolean
    /**
     *
     * @type {boolean}
     * @memberof PageMerchantList
     */
    last?: boolean
    /**
     *
     * @type {number}
     * @memberof PageMerchantList
     */
    size?: number
    /**
     *
     * @type {Array<MerchantList>}
     * @memberof PageMerchantList
     */
    content?: Array<MerchantList>
    /**
     *
     * @type {number}
     * @memberof PageMerchantList
     */
    number?: number
    /**
     *
     * @type {SortObject}
     * @memberof PageMerchantList
     */
    sort?: SortObject
    /**
     *
     * @type {number}
     * @memberof PageMerchantList
     */
    numberOfElements?: number
    /**
     *
     * @type {PageableObject}
     * @memberof PageMerchantList
     */
    pageable?: PageableObject
    /**
     *
     * @type {boolean}
     * @memberof PageMerchantList
     */
    empty?: boolean
}

/**
 * Check if a given object implements the PageMerchantList interface.
 */
export function instanceOfPageMerchantList(value: object): boolean {
    return true
}

export function PageMerchantListFromJSON(json: any): PageMerchantList {
    return PageMerchantListFromJSONTyped(json, false)
}

export function PageMerchantListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageMerchantList {
    if (json == null) {
        return json
    }
    return {
        totalElements: json['totalElements'] == null ? undefined : json['totalElements'],
        totalPages: json['totalPages'] == null ? undefined : json['totalPages'],
        first: json['first'] == null ? undefined : json['first'],
        last: json['last'] == null ? undefined : json['last'],
        size: json['size'] == null ? undefined : json['size'],
        content: json['content'] == null ? undefined : (json['content'] as Array<any>).map(MerchantListFromJSON),
        number: json['number'] == null ? undefined : json['number'],
        sort: json['sort'] == null ? undefined : SortObjectFromJSON(json['sort']),
        numberOfElements: json['numberOfElements'] == null ? undefined : json['numberOfElements'],
        pageable: json['pageable'] == null ? undefined : PageableObjectFromJSON(json['pageable']),
        empty: json['empty'] == null ? undefined : json['empty'],
    }
}

export function PageMerchantListToJSON(value?: PageMerchantList | null): any {
    if (value == null) {
        return value
    }
    return {
        totalElements: value['totalElements'],
        totalPages: value['totalPages'],
        first: value['first'],
        last: value['last'],
        size: value['size'],
        content: value['content'] == null ? undefined : (value['content'] as Array<any>).map(MerchantListToJSON),
        number: value['number'],
        sort: SortObjectToJSON(value['sort']),
        numberOfElements: value['numberOfElements'],
        pageable: PageableObjectToJSON(value['pageable']),
        empty: value['empty'],
    }
}
