/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
    EntryDnUpdateRequestDto,
    ErrorResponse,
    IsSellerOnlyResponse,
    Merchant,
    MerchantResponse,
    MerchantStatus,
    OnlineOrderingStatusDto,
    PagOidUpdateRequestDto,
    PageMerchantList,
    PaymentSecurityTokenResponse,
    PaymentStatusResponse,
} from '../models/index'
import {
    EntryDnUpdateRequestDtoFromJSON,
    EntryDnUpdateRequestDtoToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    IsSellerOnlyResponseFromJSON,
    IsSellerOnlyResponseToJSON,
    MerchantFromJSON,
    MerchantToJSON,
    MerchantResponseFromJSON,
    MerchantResponseToJSON,
    MerchantStatusFromJSON,
    MerchantStatusToJSON,
    OnlineOrderingStatusDtoFromJSON,
    OnlineOrderingStatusDtoToJSON,
    PagOidUpdateRequestDtoFromJSON,
    PagOidUpdateRequestDtoToJSON,
    PageMerchantListFromJSON,
    PageMerchantListToJSON,
    PaymentSecurityTokenResponseFromJSON,
    PaymentSecurityTokenResponseToJSON,
    PaymentStatusResponseFromJSON,
    PaymentStatusResponseToJSON,
} from '../models/index'

export interface DeleteRequest {
    merchantId: string
}

export interface CreateRequest {
    merchant: Merchant
}

export interface GetRequest {
    merchantId: string
}

export interface GetAllRequest {
    marketplaceKey?: string
    ppnId?: string
    searchTerm?: string
    paymentStatus?: Array<PaymentStatusResponse>
    deleted?: boolean
    page?: number
    size?: number
    sort?: Array<string>
}

export interface GetByPpnIdRequest {
    ppnId: string
}

export interface GetIsSellerOnlyRequest {
    ppnId: string
}

export interface GetKycAuthorizationTokenRequest {
    merchantId: string
}

export interface GetMerchantStatusRequest {
    ppnId: string
}

export interface GetOnlineOrderingStatusDashboardRequest {
    ppnId: string
}

export interface PermanentlyDeleteMerchantAndDependentEntitiesRequest {
    merchantId: string
}

export interface SyncOnlineOrderingStatusRequest {
    merchantId: string
}

export interface UpdateRequest {
    merchantId: string
    merchant: Merchant
}

export interface UpdateEntryDnRequest {
    merchantId: string
    entryDnUpdateRequestDto: EntryDnUpdateRequestDto
}

export interface UpdatePagOidRequest {
    merchantId: string
    pagOidUpdateRequestDto: PagOidUpdateRequestDto
}

/**
 *
 */
export class MerchantsApi extends runtime.BaseAPI {
    /**
     */
    async _deleteRaw(
        requestParameters: DeleteRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['merchantId'] == null) {
            throw new runtime.RequiredError(
                'merchantId',
                'Required parameter "merchantId" was null or undefined when calling _delete().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/merchants/{merchantId}`.replace(
                    `{${'merchantId'}}`,
                    encodeURIComponent(String(requestParameters['merchantId']))
                ),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.VoidApiResponse(response)
    }

    /**
     */
    async _delete(
        requestParameters: DeleteRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<void> {
        await this._deleteRaw(requestParameters, initOverrides)
    }

    /**
     */
    async createRaw(
        requestParameters: CreateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<MerchantResponse>> {
        if (requestParameters['merchant'] == null) {
            throw new runtime.RequiredError(
                'merchant',
                'Required parameter "merchant" was null or undefined when calling create().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request(
            {
                path: `/api/merchants`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: MerchantToJSON(requestParameters['merchant']),
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => MerchantResponseFromJSON(jsonValue))
    }

    /**
     */
    async create(
        requestParameters: CreateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<MerchantResponse> {
        const response = await this.createRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async getRaw(
        requestParameters: GetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<MerchantResponse>> {
        if (requestParameters['merchantId'] == null) {
            throw new runtime.RequiredError(
                'merchantId',
                'Required parameter "merchantId" was null or undefined when calling get().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/merchants/{merchantId}`.replace(
                    `{${'merchantId'}}`,
                    encodeURIComponent(String(requestParameters['merchantId']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => MerchantResponseFromJSON(jsonValue))
    }

    /**
     */
    async get(
        requestParameters: GetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<MerchantResponse> {
        const response = await this.getRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async getAllRaw(
        requestParameters: GetAllRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<PageMerchantList>> {
        const queryParameters: any = {}

        if (requestParameters['marketplaceKey'] != null) {
            queryParameters['marketplaceKey'] = requestParameters['marketplaceKey']
        }

        if (requestParameters['ppnId'] != null) {
            queryParameters['ppnId'] = requestParameters['ppnId']
        }

        if (requestParameters['searchTerm'] != null) {
            queryParameters['searchTerm'] = requestParameters['searchTerm']
        }

        if (requestParameters['paymentStatus'] != null) {
            queryParameters['paymentStatus'] = requestParameters['paymentStatus']
        }

        if (requestParameters['deleted'] != null) {
            queryParameters['deleted'] = requestParameters['deleted']
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page']
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size']
        }

        if (requestParameters['sort'] != null) {
            queryParameters['sort'] = requestParameters['sort']
        }

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/merchants`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => PageMerchantListFromJSON(jsonValue))
    }

    /**
     */
    async getAll(
        requestParameters: GetAllRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<PageMerchantList> {
        const response = await this.getAllRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async getByPpnIdRaw(
        requestParameters: GetByPpnIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<MerchantResponse>> {
        if (requestParameters['ppnId'] == null) {
            throw new runtime.RequiredError(
                'ppnId',
                'Required parameter "ppnId" was null or undefined when calling getByPpnId().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/merchants/ppn/{ppnId}`.replace(
                    `{${'ppnId'}}`,
                    encodeURIComponent(String(requestParameters['ppnId']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => MerchantResponseFromJSON(jsonValue))
    }

    /**
     */
    async getByPpnId(
        requestParameters: GetByPpnIdRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<MerchantResponse> {
        const response = await this.getByPpnIdRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async getIsSellerOnlyRaw(
        requestParameters: GetIsSellerOnlyRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<IsSellerOnlyResponse>> {
        if (requestParameters['ppnId'] == null) {
            throw new runtime.RequiredError(
                'ppnId',
                'Required parameter "ppnId" was null or undefined when calling getIsSellerOnly().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/merchants/ppn/{ppnId}/getIsSellerOnly`.replace(
                    `{${'ppnId'}}`,
                    encodeURIComponent(String(requestParameters['ppnId']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => IsSellerOnlyResponseFromJSON(jsonValue))
    }

    /**
     */
    async getIsSellerOnly(
        requestParameters: GetIsSellerOnlyRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<IsSellerOnlyResponse> {
        const response = await this.getIsSellerOnlyRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async getKycAuthorizationTokenRaw(
        requestParameters: GetKycAuthorizationTokenRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<PaymentSecurityTokenResponse>> {
        if (requestParameters['merchantId'] == null) {
            throw new runtime.RequiredError(
                'merchantId',
                'Required parameter "merchantId" was null or undefined when calling getKycAuthorizationToken().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/merchants/{merchantId}/kycAuthorization`.replace(
                    `{${'merchantId'}}`,
                    encodeURIComponent(String(requestParameters['merchantId']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => PaymentSecurityTokenResponseFromJSON(jsonValue))
    }

    /**
     */
    async getKycAuthorizationToken(
        requestParameters: GetKycAuthorizationTokenRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<PaymentSecurityTokenResponse> {
        const response = await this.getKycAuthorizationTokenRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async getMerchantStatusRaw(
        requestParameters: GetMerchantStatusRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<MerchantStatus>> {
        if (requestParameters['ppnId'] == null) {
            throw new runtime.RequiredError(
                'ppnId',
                'Required parameter "ppnId" was null or undefined when calling getMerchantStatus().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/merchants/ppn/{ppnId}/merchantStatus`.replace(
                    `{${'ppnId'}}`,
                    encodeURIComponent(String(requestParameters['ppnId']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => MerchantStatusFromJSON(jsonValue))
    }

    /**
     */
    async getMerchantStatus(
        requestParameters: GetMerchantStatusRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<MerchantStatus> {
        const response = await this.getMerchantStatusRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async getOnlineOrderingStatusDashboardRaw(
        requestParameters: GetOnlineOrderingStatusDashboardRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<OnlineOrderingStatusDto>> {
        if (requestParameters['ppnId'] == null) {
            throw new runtime.RequiredError(
                'ppnId',
                'Required parameter "ppnId" was null or undefined when calling getOnlineOrderingStatusDashboard().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/merchants/ppn/{ppnId}/onlineOrderingStatus`.replace(
                    `{${'ppnId'}}`,
                    encodeURIComponent(String(requestParameters['ppnId']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => OnlineOrderingStatusDtoFromJSON(jsonValue))
    }

    /**
     */
    async getOnlineOrderingStatusDashboard(
        requestParameters: GetOnlineOrderingStatusDashboardRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<OnlineOrderingStatusDto> {
        const response = await this.getOnlineOrderingStatusDashboardRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async permanentlyDeleteMerchantAndDependentEntitiesRaw(
        requestParameters: PermanentlyDeleteMerchantAndDependentEntitiesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['merchantId'] == null) {
            throw new runtime.RequiredError(
                'merchantId',
                'Required parameter "merchantId" was null or undefined when calling permanentlyDeleteMerchantAndDependentEntities().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/merchants/{merchantId}/deletePermanently`.replace(
                    `{${'merchantId'}}`,
                    encodeURIComponent(String(requestParameters['merchantId']))
                ),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.VoidApiResponse(response)
    }

    /**
     */
    async permanentlyDeleteMerchantAndDependentEntities(
        requestParameters: PermanentlyDeleteMerchantAndDependentEntitiesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<void> {
        await this.permanentlyDeleteMerchantAndDependentEntitiesRaw(requestParameters, initOverrides)
    }

    /**
     */
    async syncOnlineOrderingStatusRaw(
        requestParameters: SyncOnlineOrderingStatusRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['merchantId'] == null) {
            throw new runtime.RequiredError(
                'merchantId',
                'Required parameter "merchantId" was null or undefined when calling syncOnlineOrderingStatus().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/merchants/{merchantId}/syncOnlineOrderingStatus`.replace(
                    `{${'merchantId'}}`,
                    encodeURIComponent(String(requestParameters['merchantId']))
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.VoidApiResponse(response)
    }

    /**
     */
    async syncOnlineOrderingStatus(
        requestParameters: SyncOnlineOrderingStatusRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<void> {
        await this.syncOnlineOrderingStatusRaw(requestParameters, initOverrides)
    }

    /**
     */
    async updateRaw(
        requestParameters: UpdateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<MerchantResponse>> {
        if (requestParameters['merchantId'] == null) {
            throw new runtime.RequiredError(
                'merchantId',
                'Required parameter "merchantId" was null or undefined when calling update().'
            )
        }

        if (requestParameters['merchant'] == null) {
            throw new runtime.RequiredError(
                'merchant',
                'Required parameter "merchant" was null or undefined when calling update().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request(
            {
                path: `/api/merchants/{merchantId}`.replace(
                    `{${'merchantId'}}`,
                    encodeURIComponent(String(requestParameters['merchantId']))
                ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: MerchantToJSON(requestParameters['merchant']),
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => MerchantResponseFromJSON(jsonValue))
    }

    /**
     */
    async update(
        requestParameters: UpdateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<MerchantResponse> {
        const response = await this.updateRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async updateEntryDnRaw(
        requestParameters: UpdateEntryDnRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['merchantId'] == null) {
            throw new runtime.RequiredError(
                'merchantId',
                'Required parameter "merchantId" was null or undefined when calling updateEntryDn().'
            )
        }

        if (requestParameters['entryDnUpdateRequestDto'] == null) {
            throw new runtime.RequiredError(
                'entryDnUpdateRequestDto',
                'Required parameter "entryDnUpdateRequestDto" was null or undefined when calling updateEntryDn().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request(
            {
                path: `/api/merchants/{merchantId}/entrydn`.replace(
                    `{${'merchantId'}}`,
                    encodeURIComponent(String(requestParameters['merchantId']))
                ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: EntryDnUpdateRequestDtoToJSON(requestParameters['entryDnUpdateRequestDto']),
            },
            initOverrides
        )

        return new runtime.VoidApiResponse(response)
    }

    /**
     */
    async updateEntryDn(
        requestParameters: UpdateEntryDnRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<void> {
        await this.updateEntryDnRaw(requestParameters, initOverrides)
    }

    /**
     */
    async updatePagOidRaw(
        requestParameters: UpdatePagOidRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['merchantId'] == null) {
            throw new runtime.RequiredError(
                'merchantId',
                'Required parameter "merchantId" was null or undefined when calling updatePagOid().'
            )
        }

        if (requestParameters['pagOidUpdateRequestDto'] == null) {
            throw new runtime.RequiredError(
                'pagOidUpdateRequestDto',
                'Required parameter "pagOidUpdateRequestDto" was null or undefined when calling updatePagOid().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request(
            {
                path: `/api/merchants/{merchantId}/pagoid`.replace(
                    `{${'merchantId'}}`,
                    encodeURIComponent(String(requestParameters['merchantId']))
                ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: PagOidUpdateRequestDtoToJSON(requestParameters['pagOidUpdateRequestDto']),
            },
            initOverrides
        )

        return new runtime.VoidApiResponse(response)
    }

    /**
     */
    async updatePagOid(
        requestParameters: UpdatePagOidRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<void> {
        await this.updatePagOidRaw(requestParameters, initOverrides)
    }
}
