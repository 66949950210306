/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * Contains an identifier of a submerchant
 * @export
 * @interface SubmerchantId
 */
export interface SubmerchantId {
    /**
     *
     * @type {number}
     * @memberof SubmerchantId
     */
    submerchantId: number
}

export function SubmerchantIdFromJSON(json: any): SubmerchantId {
    return SubmerchantIdFromJSONTyped(json, false)
}

export function SubmerchantIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmerchantId {
    if (json === undefined || json === null) {
        return json
    }
    return {
        submerchantId: json['submerchantId'],
    }
}

export function SubmerchantIdToJSON(value?: SubmerchantId | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        submerchantId: value.submerchantId,
    }
}
