/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface MarketplaceLegalNotice
 */
export interface MarketplaceLegalNotice {
    /**
     *
     * @type {string}
     * @memberof MarketplaceLegalNotice
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MarketplaceLegalNotice
     */
    language: string
    /**
     *
     * @type {string}
     * @memberof MarketplaceLegalNotice
     */
    imprint?: string
}

/**
 * Check if a given object implements the MarketplaceLegalNotice interface.
 */
export function instanceOfMarketplaceLegalNotice(value: object): boolean {
    if (!('language' in value)) return false
    return true
}

export function MarketplaceLegalNoticeFromJSON(json: any): MarketplaceLegalNotice {
    return MarketplaceLegalNoticeFromJSONTyped(json, false)
}

export function MarketplaceLegalNoticeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketplaceLegalNotice {
    if (json == null) {
        return json
    }
    return {
        id: json['id'] == null ? undefined : json['id'],
        language: json['language'],
        imprint: json['imprint'] == null ? undefined : json['imprint'],
    }
}

export function MarketplaceLegalNoticeToJSON(value?: MarketplaceLegalNotice | null): any {
    if (value == null) {
        return value
    }
    return {
        id: value['id'],
        language: value['language'],
        imprint: value['imprint'],
    }
}
