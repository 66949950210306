import { PText } from '@porsche-design-system/components-react'
import { spacingStatic } from '@porsche-design-system/components-react/styles'
import { styled } from 'styled-components'

const StyledText = styled(PText)`
    margin-bottom: ${spacingStatic.small};
`

type FileUploadLabelProps = { label: string }

export function FileUploadLabel({ label }: FileUploadLabelProps) {
    return <StyledText>{label}</StyledText>
}
