/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface MerchantDocumentMetadata
 */
export interface MerchantDocumentMetadata {
    /**
     *
     * @type {string}
     * @memberof MerchantDocumentMetadata
     */
    url: string
    /**
     *
     * @type {Date}
     * @memberof MerchantDocumentMetadata
     */
    updated: Date
    /**
     *
     * @type {string}
     * @memberof MerchantDocumentMetadata
     */
    name: string
}

/**
 * Check if a given object implements the MerchantDocumentMetadata interface.
 */
export function instanceOfMerchantDocumentMetadata(value: object): boolean {
    if (!('url' in value)) return false
    if (!('updated' in value)) return false
    if (!('name' in value)) return false
    return true
}

export function MerchantDocumentMetadataFromJSON(json: any): MerchantDocumentMetadata {
    return MerchantDocumentMetadataFromJSONTyped(json, false)
}

export function MerchantDocumentMetadataFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): MerchantDocumentMetadata {
    if (json == null) {
        return json
    }
    return {
        url: json['url'],
        updated: new Date(json['updated']),
        name: json['name'],
    }
}

export function MerchantDocumentMetadataToJSON(value?: MerchantDocumentMetadata | null): any {
    if (value == null) {
        return value
    }
    return {
        url: value['url'],
        updated: value['updated'].toISOString(),
        name: value['name'],
    }
}
