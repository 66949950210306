import intersection from 'lodash/intersection'
import { useFormContext } from 'react-hook-form'

import { DocumentType, SelectedOption } from '@slmpserv/common-api-merchant'

import { DocumentsFormState, GeneralDocumentTypes } from '../DocumentsForm/DocumentsFormTypesAndConsts'

export function useDocumentProgress(language: string, targetDocuments: DocumentType[]) {
    const { watch } = useFormContext<DocumentsFormState>()
    const formStateDecisions = watch(`documents.${language}.decisions`)
    const formStateFiles = watch(`documents.${language}.files`)

    const storedDecisions = watch(`documents.${language}.storedDecisions`)
    const storedFiles = watch(`documents.${language}.storedFiles`)

    const fileForDocTypeExists = (type: DocumentType) =>
        formStateFiles[type] !== undefined || storedFiles[type] !== undefined

    const madeDecisions = Object.keys(formStateDecisions || []).filter(type => {
        const docType = type as DocumentType
        const decision = formStateDecisions[docType]

        if (decision === SelectedOption.DOCUMENT_SPECIFIED) {
            return fileForDocTypeExists(docType)
        } else if (decision === SelectedOption.NO_CORRESPONDING_OFFER) {
            return true
        } else if (decision === SelectedOption.HANDLED_BY_FALLBACK) {
            return true
        } else {
            return false
        }
    })

    const selectedGeneralFiles = Object.keys(formStateFiles || [])
        .filter(type => GeneralDocumentTypes.includes(type as DocumentType))
        .filter(type => fileForDocTypeExists(type as DocumentType))

    const savedDecisions = Object.keys(storedDecisions || {})
        .filter(type => targetDocuments.includes(type as DocumentType))
        .filter(type => {
            const docType = type as DocumentType
            const decision = formStateDecisions[docType]

            if (decision === SelectedOption.DOCUMENT_SPECIFIED) {
                return storedFiles[docType] !== undefined
            } else if (decision === SelectedOption.NO_CORRESPONDING_OFFER) {
                return true
            } else if (decision === SelectedOption.HANDLED_BY_FALLBACK) {
                return true
            } else {
                return false
            }
        })

    const selectedDocuments = [...madeDecisions, ...selectedGeneralFiles]

    const complete = savedDecisions.length === targetDocuments.length

    const selectedDocumentCount = intersection(targetDocuments, selectedDocuments).length

    return { selectedDocumentCount, complete, total: targetDocuments.length }
}
