import { useEffect } from 'react'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { sellerContactApi, SellerContactEmail } from '@slmpserv/common-api-merchant'

export type UseFetchSellerContactMailAddressesProps = {
    entryDn: string
    queryOptions: Omit<UseQueryOptions<SellerContactEmail, Error, SellerContactEmail, string[]>, 'queryKey' | 'queryFn'>
}

export function useFetchSellerContactMailAddresses({
    entryDn,
    queryOptions,
    onSuccess,
}: UseFetchSellerContactMailAddressesProps & { onSuccess?: (data: SellerContactEmail) => void }) {
    const queryKey = ['dealer', entryDn, 'seller', 'emails']
    const queryResult = useQuery({
        queryKey,
        queryFn: () => sellerContactApi.getEmailByEntryDn({ entryDn }),
        ...queryOptions,
    })

    useEffect(() => {
        if (queryResult.isSuccess && onSuccess) {
            onSuccess(queryResult.data)
        }
    }, [onSuccess, queryResult.data, queryResult.isSuccess])

    return {
        emailAddressesNew: queryResult.data?.emailAddressesNew || [],
        emailAddressesPreowned: queryResult.data?.emailAddressesPreowned || [],
        queryResult,
        queryKey,
    } as const
}
