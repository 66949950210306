/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum MarketplaceFeatures {
    CONTRACT_SECTION = 'CONTRACT_SECTION',
    DOCUMENT_MANAGEMENT = 'DOCUMENT_MANAGEMENT',
    ONLINE_ORDER_CONFIG = 'ONLINE_ORDER_CONFIG',
    KYC_MANAGEMENT = 'KYC_MANAGEMENT',
    PAYOUT_SECTION = 'PAYOUT_SECTION',
    PURCHASE_REQUEST_SETTINGS = 'PURCHASE_REQUEST_SETTINGS',
}

export function instanceOfMarketplaceFeatures(value: any): boolean {
    return Object.values(MarketplaceFeatures).includes(value)
}

export function MarketplaceFeaturesFromJSON(json: any): MarketplaceFeatures {
    return MarketplaceFeaturesFromJSONTyped(json, false)
}

export function MarketplaceFeaturesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketplaceFeatures {
    return json as MarketplaceFeatures
}

export function MarketplaceFeaturesToJSON(value?: MarketplaceFeatures | null): any {
    return value as any
}
