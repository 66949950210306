import { useIntl } from 'react-intl'

import { useCurrentDealerContext } from '@slmpserv/self-service-current-dealer-context'
import { BoxLayout, PageHeader, PageLayout } from '@slmpserv/common-components-legacy'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { PayoutContactConfiguration } from '@slmpserv/self-service-payout'
import { routesMessages } from '@slmpserv/common-translations'

export function PayoutContactPage() {
    const { formatMessage } = useIntl()
    const { partnerNumber } = useCurrentDealerContext()

    return (
        <PageLayout
            header={
                <PageHeader
                    title={formatMessage(routesMessages.contactConfigurationHeadline)}
                    backButton={{
                        label: formatMessage(routesMessages.backToDashboard),
                        to: StaticRoutes.DASHBOARD,
                    }}
                />
            }
        >
            <BoxLayout>
                <PayoutContactConfiguration ppnId={partnerNumber} />
            </BoxLayout>
        </PageLayout>
    )
}
