import { marketplaces } from '@finder/marketplace-configuration'
import { themeLight } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'

import {
    dashboardPopoverMessages,
    dashboardPreviewMessages,
    dashboardStatusMessages,
    dashboardStatusMessageTooltip,
    dashboardTitles,
} from '@slmpserv/common-translations'
import { useMatomoTrackEditEvent } from '@slmpserv/common/analytics'
import { useCurrentDealerContext } from '@slmpserv/self-service-current-dealer-context'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { AccessSensitiveProps } from '@slmpserv/self-service-shared'

import { DashboardGroupItem, DashboardWarningTag } from '../DashboardGroupItem/DashboardGroupItem'
import { DashboardGroupItemPreview } from '../DashboardGroupItemPreview/DashboardGroupItemPreview'

type OnlineOrderContactItemProps = AccessSensitiveProps & {
    emailAddresses: string[]
    emailsMissing: boolean
    offersCsvCars?: boolean
    differentEmailCsvCars?: boolean
    csvEmailAddresses?: string[]
    csvEmailsMissing?: boolean
}

export function OnlineOrderContactItem({
    emailAddresses,
    emailsMissing,
    offersCsvCars,
    differentEmailCsvCars,
    csvEmailAddresses,
    csvEmailsMissing,
    isAllowedToEdit,
}: OnlineOrderContactItemProps) {
    const { formatMessage } = useIntl()

    const { trackEvent } = useMatomoTrackEditEvent('online_order_contact', emailsMissing)

    const { marketplaceKey } = useCurrentDealerContext()
    const isEmailRequired = (emailsMissing || csvEmailsMissing) && marketplaces[marketplaceKey] !== 'us'

    const showCSVEmailPreview = offersCsvCars && differentEmailCsvCars
    const titleStockEmailPreview = showCSVEmailPreview
        ? dashboardPreviewMessages.contactEmailAddressesForStock
        : dashboardPreviewMessages.contactEmailAddresses

    return (
        <DashboardGroupItem
            hightlightColor={isEmailRequired ? themeLight.notification.warning : undefined}
            title={formatMessage(dashboardTitles.onlineOrderIntake)}
            tooltipText={formatMessage(dashboardPopoverMessages.onlineOrderIntake)}
            preview={
                <>
                    <DashboardGroupItemPreview title={formatMessage(titleStockEmailPreview)} text={emailAddresses} />
                    {showCSVEmailPreview && (
                        <DashboardGroupItemPreview
                            title={formatMessage(dashboardPreviewMessages.contactEmailAddressesForCsv)}
                            text={csvEmailAddresses}
                        />
                    )}
                </>
            }
            badge={
                isEmailRequired && (
                    <DashboardWarningTag
                        tagText={formatMessage(dashboardStatusMessages.addContact)}
                        popoverText={formatMessage(dashboardStatusMessageTooltip.onlineOrderIntake)}
                    />
                )
            }
            editLink={{
                isAllowedToEdit,
                props: {
                    to: StaticRoutes.CONTACT,
                },
                dataTestId: 'online-order-edit-link',
                matomoEvent: trackEvent,
            }}
        />
    )
}
