/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
    SubmerchantStatusDetails,
    SubmerchantStatusDetailsFromJSON,
    SubmerchantStatusDetailsFromJSONTyped,
    SubmerchantStatusDetailsToJSON,
} from './'

/**
 *
 * @export
 * @interface SubmerchantStatus
 */
export interface SubmerchantStatus {
    /**
     *
     * @type {SubmerchantStatusDetails}
     * @memberof SubmerchantStatus
     */
    statusDetails?: SubmerchantStatusDetails
    /**
     * Adyen identifier of submerchant, ignored during update
     * @type {string}
     * @memberof SubmerchantStatus
     */
    accountHolderCode: string
    /**
     * Determines whether the submerchant is active based on the account holder status
     * @type {boolean}
     * @memberof SubmerchantStatus
     */
    active: boolean
}

export function SubmerchantStatusFromJSON(json: any): SubmerchantStatus {
    return SubmerchantStatusFromJSONTyped(json, false)
}

export function SubmerchantStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmerchantStatus {
    if (json === undefined || json === null) {
        return json
    }
    return {
        statusDetails: !exists(json, 'statusDetails')
            ? undefined
            : SubmerchantStatusDetailsFromJSON(json['statusDetails']),
        accountHolderCode: json['accountHolderCode'],
        active: json['active'],
    }
}

export function SubmerchantStatusToJSON(value?: SubmerchantStatus | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        statusDetails: SubmerchantStatusDetailsToJSON(value.statusDetails),
        accountHolderCode: value.accountHolderCode,
        active: value.active,
    }
}
