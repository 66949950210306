/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * Verification image is not necessary to create an account but may be necessary to enable payouts
 * @export
 * @interface VerificationImageForIndividual
 */
export interface VerificationImageForIndividual {
    /**
     * base64 image, between 100 kB and 4 MB
     * @type {string}
     * @memberof VerificationImageForIndividual
     */
    data: string
    /**
     * supported file types jpeg, jpg, pdf, png
     * @type {string}
     * @memberof VerificationImageForIndividual
     */
    filename: string
    /**
     *
     * @type {string}
     * @memberof VerificationImageForIndividual
     */
    type: VerificationImageForIndividualTypeEnum
    /**
     *
     * @type {string}
     * @memberof VerificationImageForIndividual
     */
    documentNumber?: string
}

/**
 * @export
 * @enum {string}
 */
export enum VerificationImageForIndividualTypeEnum {
    IdCardFront = 'ID_CARD_FRONT',
    IdCardBack = 'ID_CARD_BACK',
    DrivingLicenceBack = 'DRIVING_LICENCE_BACK',
    DrivingLicenceFront = 'DRIVING_LICENCE_FRONT',
    Passport = 'PASSPORT',
}

export function VerificationImageForIndividualFromJSON(json: any): VerificationImageForIndividual {
    return VerificationImageForIndividualFromJSONTyped(json, false)
}

export function VerificationImageForIndividualFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): VerificationImageForIndividual {
    if (json === undefined || json === null) {
        return json
    }
    return {
        data: json['data'],
        filename: json['filename'],
        type: json['type'],
        documentNumber: !exists(json, 'documentNumber') ? undefined : json['documentNumber'],
    }
}

export function VerificationImageForIndividualToJSON(value?: VerificationImageForIndividual | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        data: value.data,
        filename: value.filename,
        type: value.type,
        documentNumber: value.documentNumber,
    }
}
