import { PDivider } from '@porsche-design-system/components-react'
import { spacingStatic } from '@porsche-design-system/components-react/styles'

type SpacedDividerProps = {
    spacingTop?: keyof typeof spacingStatic | undefined
    spacingBottom?: keyof typeof spacingStatic | undefined
}

export function SpacedDivider({ spacingTop = 'large', spacingBottom = 'medium' }: SpacedDividerProps) {
    return <PDivider style={{ marginTop: spacingStatic[spacingTop], marginBottom: spacingStatic[spacingBottom] }} />
}
