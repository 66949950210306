/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PaymentLinkEnabledResponse
 */
export interface PaymentLinkEnabledResponse {
    /**
     *
     * @type {boolean}
     * @memberof PaymentLinkEnabledResponse
     */
    paymentLinkEnabled: boolean
}

/**
 * Check if a given object implements the PaymentLinkEnabledResponse interface.
 */
export function instanceOfPaymentLinkEnabledResponse(value: object): boolean {
    if (!('paymentLinkEnabled' in value)) return false
    return true
}

export function PaymentLinkEnabledResponseFromJSON(json: any): PaymentLinkEnabledResponse {
    return PaymentLinkEnabledResponseFromJSONTyped(json, false)
}

export function PaymentLinkEnabledResponseFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): PaymentLinkEnabledResponse {
    if (json == null) {
        return json
    }
    return {
        paymentLinkEnabled: json['paymentLinkEnabled'],
    }
}

export function PaymentLinkEnabledResponseToJSON(value?: PaymentLinkEnabledResponse | null): any {
    if (value == null) {
        return value
    }
    return {
        paymentLinkEnabled: value['paymentLinkEnabled'],
    }
}
