/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ShareholderWithMultipleVerificationImages,
    ShareholderWithSingleVerificationImage,
    ShareholderWithMultipleVerificationImagesFromJSONTyped,
    ShareholderWithMultipleVerificationImagesToJSON,
    ShareholderWithSingleVerificationImageFromJSONTyped,
    ShareholderWithSingleVerificationImageToJSON,
} from './'

/**
 * @type Shareholder
 *
 * @export
 */
export type Shareholder = ShareholderWithMultipleVerificationImages | ShareholderWithSingleVerificationImage

export function ShareholderFromJSON(json: any): Shareholder {
    return ShareholderFromJSONTyped(json, false)
}

export function ShareholderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Shareholder {
    if (json === undefined || json === null) {
        return json
    }
    return { ...json }
}

export function ShareholderToJSON(value?: Shareholder | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return { ...value }
}
