/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ErrorResponse, Imprint } from '../models/index'
import { ErrorResponseFromJSON, ErrorResponseToJSON, ImprintFromJSON, ImprintToJSON } from '../models/index'

export interface AddUpdateImprintsRequest {
    merchantId: string
    imprint: Array<Imprint>
}

export interface GetAllImprintsRequest {
    merchantId: string
}

/**
 *
 */
export class MerchantImprintApi extends runtime.BaseAPI {
    /**
     */
    async addUpdateImprintsRaw(
        requestParameters: AddUpdateImprintsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<Array<Imprint>>> {
        if (requestParameters['merchantId'] == null) {
            throw new runtime.RequiredError(
                'merchantId',
                'Required parameter "merchantId" was null or undefined when calling addUpdateImprints().'
            )
        }

        if (requestParameters['imprint'] == null) {
            throw new runtime.RequiredError(
                'imprint',
                'Required parameter "imprint" was null or undefined when calling addUpdateImprints().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request(
            {
                path: `/api/merchants/{merchantId}/imprint`.replace(
                    `{${'merchantId'}}`,
                    encodeURIComponent(String(requestParameters['merchantId']))
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters['imprint']!.map(ImprintToJSON),
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ImprintFromJSON))
    }

    /**
     */
    async addUpdateImprints(
        requestParameters: AddUpdateImprintsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<Array<Imprint>> {
        const response = await this.addUpdateImprintsRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async getAllImprintsRaw(
        requestParameters: GetAllImprintsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<Array<Imprint>>> {
        if (requestParameters['merchantId'] == null) {
            throw new runtime.RequiredError(
                'merchantId',
                'Required parameter "merchantId" was null or undefined when calling getAllImprints().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/merchants/{merchantId}/imprint`.replace(
                    `{${'merchantId'}}`,
                    encodeURIComponent(String(requestParameters['merchantId']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ImprintFromJSON))
    }

    /**
     */
    async getAllImprints(
        requestParameters: GetAllImprintsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<Array<Imprint>> {
        const response = await this.getAllImprintsRaw(requestParameters, initOverrides)
        return await response.value()
    }
}
