import { PHeading, PSelectWrapper, PTag } from '@porsche-design-system/components-react'
import { spacingFluid } from '@porsche-design-system/components-react/styles'
import { FormProvider, useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Flex, FlexItem } from '@slcheckout/ui-kit'
import { styled } from 'styled-components'

import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { MultipleEmailsField } from '@slmpserv/common-components'
import { sellerContactEmailsMessages } from '@slmpserv/common-translations'

import { UsedNewCarContact } from '../types'

import { SellerContactEmailFormState, useSellerContactEmailsForm } from './useSellerContactEmailsForm'
import { StyledDivider, StyledFormButtons, StyledFormGrid, StyledInputWrapper } from './sharedStyledComponents'

const StyledMultipleEmailsFieldWrapper = styled.div`
    grid-column: 2;
`

const StyledFlex = styled(Flex)`
    grid-column: 2;
`

type SellerContactEmailsProps = {
    entryDn: string
}

export function SellerContactEmails({ entryDn }: SellerContactEmailsProps) {
    const navigate = useNavigate()
    const { methods, handleSubmit } = useSellerContactEmailsForm(entryDn)

    const handleCancel = () => {
        navigate(StaticRoutes.DASHBOARD)
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit}>
                <StyledFormGrid>
                    <UsedCarEmails />
                    <StyledDivider />
                    <NewCarEmails />
                    <StyledFormButtons onCancel={handleCancel} />
                </StyledFormGrid>
            </form>
        </FormProvider>
    )
}

function UsedCarEmails() {
    const { formatMessage } = useIntl()
    const { register, control, formState } = useFormContext<SellerContactEmailFormState>()

    return (
        <>
            <PHeading tag="h4" size="medium">
                {formatMessage(sellerContactEmailsMessages.usedCarsTitle)}
            </PHeading>

            <StyledMultipleEmailsFieldWrapper>
                <MultipleEmailsField
                    control={control}
                    register={register}
                    formState={formState}
                    fieldName={'usedCarEmails'}
                    messages={{
                        firstEntryLabel: sellerContactEmailsMessages.emailLabel,
                        nthEntryLabel: sellerContactEmailsMessages.emailLabel,
                    }}
                />
            </StyledMultipleEmailsFieldWrapper>
        </>
    )
}

function NewCarEmails() {
    const { formatMessage } = useIntl()
    const { register, watch, control, formState } = useFormContext<SellerContactEmailFormState>()
    const showNewCarEmailsInput = watch('newCarSameAsUsedCar') === UsedNewCarContact.Different
    const usedCarEmails = watch('usedCarEmails') || []

    return (
        <>
            <PHeading tag="h4" size="medium">
                {formatMessage(sellerContactEmailsMessages.newCarsTitle)}
            </PHeading>

            <StyledInputWrapper>
                <PSelectWrapper>
                    <select
                        {...register('newCarSameAsUsedCar')}
                        defaultValue={UsedNewCarContact.Same}
                        data-testid={'use-same-email-select'}
                    >
                        <option value={UsedNewCarContact.Same}>
                            {formatMessage(sellerContactEmailsMessages.useSameEmailsSelectOption)}
                        </option>
                        <option value={UsedNewCarContact.Different}>
                            {formatMessage(sellerContactEmailsMessages.useDifferentEmailsSelectOption)}
                        </option>
                    </select>
                </PSelectWrapper>
            </StyledInputWrapper>

            {showNewCarEmailsInput ? (
                <>
                    <StyledDivider />
                    <StyledMultipleEmailsFieldWrapper>
                        <MultipleEmailsField
                            control={control}
                            register={register}
                            formState={formState}
                            fieldName={'newCarEmails'}
                            messages={{
                                firstEntryLabel: sellerContactEmailsMessages.emailLabel,
                                nthEntryLabel: sellerContactEmailsMessages.emailLabel,
                            }}
                        />
                    </StyledMultipleEmailsFieldWrapper>
                </>
            ) : (
                <StyledFlex direction="column">
                    {usedCarEmails
                        .filter(email => email.value !== '')
                        .map((email, index) => (
                            <FlexItem key={index}>
                                <PTag style={{ marginTop: spacingFluid.small, marginLeft: spacingFluid.small }}>
                                    {email.value}
                                </PTag>
                            </FlexItem>
                        ))}
                </StyledFlex>
            )}
        </>
    )
}
