import { ReactNode } from 'react'
import { PButtonPure, PHeading, PPopover, PTag } from '@porsche-design-system/components-react'
import { spacingFluid, themeLight } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'
import { styled } from 'styled-components'
import * as React from 'react'
import { LinkProps, useNavigate } from 'react-router-dom'

import { dashboardStatusMessages, sharedMerchantMessages } from '@slmpserv/common-translations'
import { useMatomoTrackTooltipClick } from '@slmpserv/common/analytics'
import { AccessSensitiveProps } from '@slmpserv/self-service-shared'

const StyledDashboardContainer = styled.div<{ hightlightColor?: string }>`
    border-left: ${spacingFluid.xSmall} solid ${({ hightlightColor }) => hightlightColor ?? 'transparent'};
    background: ${themeLight.background.base};
    margin-top: 2px;
    padding: ${spacingFluid.small} ${spacingFluid.medium};
`

const StyledDashboardGrid = styled.div`
    display: grid;
    grid-template-columns: 4fr 4fr 2fr 2fr;
    gap: ${spacingFluid.medium};
    margin-top: ${spacingFluid.small};
    margin-bottom: ${spacingFluid.small};
`

const StyledGridItem = styled.div`
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: start;

    &.alignItems {
        align-items: end;
    }
`

const StyledPopover = styled(PPopover)`
    margin-left: ${spacingFluid.small};
`

type DashboardGroupItemProps = {
    title: string
    hightlightColor?: string
    tooltipText?: string
    badge?: ReactNode
    preview?: ReactNode
    editLink?: EditLinkItemProps
}

type EditLinkItemProps = AccessSensitiveProps & {
    props?: LinkProps & React.RefAttributes<HTMLAnchorElement>
    text?: string
    dataTestId?: string
    matomoEvent?: () => void
}

export function DashboardWarningTag({ tagText, popoverText }: { tagText: string; popoverText: string }) {
    const { trackEvent } = useMatomoTrackTooltipClick(`warning-tag: ${tagText}`)

    return (
        <div style={{ display: 'inline-flex' }}>
            <PTag color="notification-warning" icon="warning">
                {tagText}
            </PTag>
            {popoverText && (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <PPopover onClick={trackEvent} direction="right">
                        {popoverText}
                    </PPopover>
                </span>
            )}
        </div>
    )
}

export function DataCompletedTag({ isDataComplete }: { isDataComplete: boolean }) {
    const { formatMessage } = useIntl()
    return isDataComplete ? (
        <PTag color="notification-success" icon="check">
            {formatMessage(dashboardStatusMessages.dataComplete)}
        </PTag>
    ) : (
        <PTag color="notification-warning" icon="warning">
            {formatMessage(dashboardStatusMessages.dataIncomplete)}
        </PTag>
    )
}

export function DataWriteAccessIsRestricted({ hasAllWriteAccess }: { hasAllWriteAccess: boolean }) {
    const { formatMessage } = useIntl()
    return (
        !hasAllWriteAccess && (
            <PTag color="notification-info-soft" icon="exclamation" style={{ marginLeft: '12px' }}>
                {formatMessage(dashboardStatusMessages.writeAccessIsRestricted)}
            </PTag>
        )
    )
}

function DefaultEditActionLink({
    isAllowedToEdit,
    props,
    text,
    dataTestId,
    matomoEvent,
}: EditLinkItemProps | undefined) {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    if (!props) return null

    const linkText = text ?? formatMessage(sharedMerchantMessages.edit)

    const handleClick = () => {
        matomoEvent()
        navigate(props.to)
    }

    return (
        <PButtonPure
            disabled={!isAllowedToEdit}
            data-test-id={dataTestId ?? 'editButton'}
            icon={'edit'}
            aria-label={linkText}
            onClick={handleClick}
        >
            {linkText}
        </PButtonPure>
    )
}

export function DashboardGroupItem({
    title,
    hightlightColor,
    tooltipText,
    badge,
    preview,
    editLink,
}: DashboardGroupItemProps) {
    const { trackEvent } = useMatomoTrackTooltipClick(`dashboard-item: ${title}`)

    return (
        <StyledDashboardContainer hightlightColor={hightlightColor}>
            <StyledDashboardGrid>
                <StyledGridItem>
                    <PHeading id={encodeURI(title)} tag="h5" size="small">
                        {title}
                        {tooltipText && <StyledPopover onClick={trackEvent}>{tooltipText}</StyledPopover>}
                    </PHeading>
                </StyledGridItem>
                <StyledGridItem>{preview}</StyledGridItem>
                <StyledGridItem className={`alignItems`}>{badge}</StyledGridItem>
                <StyledGridItem className={`alignItems`}>
                    <DefaultEditActionLink {...editLink}></DefaultEditActionLink>
                </StyledGridItem>
            </StyledDashboardGrid>
        </StyledDashboardContainer>
    )
}
