/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SubmerchantError
 */
export interface SubmerchantError {
    /**
     * Error code
     * @type {number}
     * @memberof SubmerchantError
     */
    errorCode: number
    /**
     * A description of the error produced by the verification check
     * @type {string}
     * @memberof SubmerchantError
     */
    errorDescription: string
    /**
     * The type of verification check that produced the error
     * @type {string}
     * @memberof SubmerchantError
     */
    checkType: string
    /**
     * The status of the subject that didn't pass the verification check
     * @type {string}
     * @memberof SubmerchantError
     */
    status: string
    /**
     * The code of the subject that didn't pass the verification check e.g. code of a shareholder
     * @type {string}
     * @memberof SubmerchantError
     */
    verificationSubjectCode?: string
    /**
     * The type of subject that didn't pass the verification check e.g. shareholder
     * @type {string}
     * @memberof SubmerchantError
     */
    verificationSubjectType?: string
}

export function SubmerchantErrorFromJSON(json: any): SubmerchantError {
    return SubmerchantErrorFromJSONTyped(json, false)
}

export function SubmerchantErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmerchantError {
    if (json === undefined || json === null) {
        return json
    }
    return {
        errorCode: json['errorCode'],
        errorDescription: json['errorDescription'],
        checkType: json['checkType'],
        status: json['status'],
        verificationSubjectCode: !exists(json, 'verificationSubjectCode') ? undefined : json['verificationSubjectCode'],
        verificationSubjectType: !exists(json, 'verificationSubjectType') ? undefined : json['verificationSubjectType'],
    }
}

export function SubmerchantErrorToJSON(value?: SubmerchantError | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        errorCode: value.errorCode,
        errorDescription: value.errorDescription,
        checkType: value.checkType,
        status: value.status,
        verificationSubjectCode: value.verificationSubjectCode,
        verificationSubjectType: value.verificationSubjectType,
    }
}
