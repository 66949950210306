import { defineMessages } from 'react-intl'

export const emailConfigurationMessages = defineMessages({
    dealerEmailLabel: {
        id: 'dealer.onlineOrderingConfig.label.email',
        defaultMessage: 'Email address for online orders',
    },
    dealerAdditionalEmailLabel: {
        id: 'dealer.onlineOrderingConfig.label.additionalEmail',
        defaultMessage: 'Additional Email address for online orders',
    },
    dealerEmailStockTitle: {
        id: 'dealer.onlineOrderingConfig.email.stockTitle',
        defaultMessage: 'Email address(es) for STOCK online orders',
    },
    dealerEmailCsvTitle: {
        id: 'dealer.onlineOrderingConfig.email.csvTitle',
        defaultMessage: 'Email address(es) for CSV online orders',
    },
    useSameEmailsSelectOption: {
        id: 'dealer.onlineOrderingConfig.useSameEmail.option',
        defaultMessage: 'Same as for stock vehicles',
    },
    useDifferentEmailsSelectOption: {
        id: 'dealer.onlineOrderingConfig.useDifferentEmail.option',
        defaultMessage: 'Provide specific contact details',
    },
})
