import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useMemo } from 'react'

import {
    MerchantResponse,
    merchantTermsAndConditionsApi,
    MerchantTermsAndConditionsResponse,
} from '@slmpserv/common-api-merchant'

type QueryOptions = Omit<
    UseQueryOptions<MerchantTermsAndConditionsResponse, Error, MerchantTermsAndConditionsResponse, string[]>,
    'queryKey' | 'queryFn'
>

export function useFetchMerchantDocumentConfiguration(merchantId: MerchantResponse['id'], options: QueryOptions = {}) {
    const queryKey = useMemo(() => ['merchant-terms-and-conditions-configuration', merchantId], [merchantId])

    const queryResult = useQuery({
        queryKey,
        queryFn: () => merchantTermsAndConditionsApi.getCurrentConfig({ merchantId }),
        ...options,
    })

    return { merchantDocumentConfig: queryResult.data, queryResult, queryKey }
}
