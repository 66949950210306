/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { DocumentType } from './DocumentType'
import { DocumentTypeFromJSON, DocumentTypeFromJSONTyped, DocumentTypeToJSON } from './DocumentType'
import type { PaymentStatusResponse } from './PaymentStatusResponse'
import {
    PaymentStatusResponseFromJSON,
    PaymentStatusResponseFromJSONTyped,
    PaymentStatusResponseToJSON,
} from './PaymentStatusResponse'

/**
 *
 * @export
 * @interface MerchantList
 */
export interface MerchantList {
    /**
     *
     * @type {string}
     * @memberof MerchantList
     */
    id?: string
    /**
     *
     * @type {string}
     * @memberof MerchantList
     */
    submerchantId?: string
    /**
     *
     * @type {string}
     * @memberof MerchantList
     */
    accountHolderCode?: string
    /**
     *
     * @type {string}
     * @memberof MerchantList
     */
    ppnId: string
    /**
     *
     * @type {string}
     * @memberof MerchantList
     */
    entryDn: string
    /**
     *
     * @type {string}
     * @memberof MerchantList
     */
    pagOid: string
    /**
     *
     * @type {PaymentStatusResponse}
     * @memberof MerchantList
     */
    paymentStatus: PaymentStatusResponse
    /**
     *
     * @type {Date}
     * @memberof MerchantList
     */
    inactivationDate?: Date
    /**
     *
     * @type {Date}
     * @memberof MerchantList
     */
    suspensionDate?: Date
    /**
     *
     * @type {string}
     * @memberof MerchantList
     */
    businessName: string
    /**
     *
     * @type {string}
     * @memberof MerchantList
     */
    legalBusinessName: string
    /**
     *
     * @type {{ [key: string]: Array<DocumentType>; }}
     * @memberof MerchantList
     */
    missingDocuments: { [key: string]: Array<DocumentType> }
    /**
     *
     * @type {{ [key: string]: Array<DocumentType>; }}
     * @memberof MerchantList
     */
    missingReservationDocuments: { [key: string]: Array<DocumentType> }
    /**
     *
     * @type {Array<string>}
     * @memberof MerchantList
     */
    missingImprints: Array<string>
    /**
     *
     * @type {boolean}
     * @memberof MerchantList
     */
    readyForOnlineOrdering: boolean
    /**
     *
     * @type {boolean}
     * @memberof MerchantList
     */
    readyForOnlineReservation?: boolean
    /**
     *
     * @type {Date}
     * @memberof MerchantList
     */
    deleteDate?: Date
}

/**
 * Check if a given object implements the MerchantList interface.
 */
export function instanceOfMerchantList(value: object): boolean {
    if (!('ppnId' in value)) return false
    if (!('entryDn' in value)) return false
    if (!('pagOid' in value)) return false
    if (!('paymentStatus' in value)) return false
    if (!('businessName' in value)) return false
    if (!('legalBusinessName' in value)) return false
    if (!('missingDocuments' in value)) return false
    if (!('missingReservationDocuments' in value)) return false
    if (!('missingImprints' in value)) return false
    if (!('readyForOnlineOrdering' in value)) return false
    return true
}

export function MerchantListFromJSON(json: any): MerchantList {
    return MerchantListFromJSONTyped(json, false)
}

export function MerchantListFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantList {
    if (json == null) {
        return json
    }
    return {
        id: json['id'] == null ? undefined : json['id'],
        submerchantId: json['submerchantId'] == null ? undefined : json['submerchantId'],
        accountHolderCode: json['accountHolderCode'] == null ? undefined : json['accountHolderCode'],
        ppnId: json['ppnId'],
        entryDn: json['entryDn'],
        pagOid: json['pagOid'],
        paymentStatus: PaymentStatusResponseFromJSON(json['paymentStatus']),
        inactivationDate: json['inactivationDate'] == null ? undefined : new Date(json['inactivationDate']),
        suspensionDate: json['suspensionDate'] == null ? undefined : new Date(json['suspensionDate']),
        businessName: json['businessName'],
        legalBusinessName: json['legalBusinessName'],
        missingDocuments: json['missingDocuments'],
        missingReservationDocuments: json['missingReservationDocuments'],
        missingImprints: json['missingImprints'],
        readyForOnlineOrdering: json['readyForOnlineOrdering'],
        readyForOnlineReservation:
            json['readyForOnlineReservation'] == null ? undefined : json['readyForOnlineReservation'],
        deleteDate: json['deleteDate'] == null ? undefined : new Date(json['deleteDate']),
    }
}

export function MerchantListToJSON(value?: MerchantList | null): any {
    if (value == null) {
        return value
    }
    return {
        id: value['id'],
        submerchantId: value['submerchantId'],
        accountHolderCode: value['accountHolderCode'],
        ppnId: value['ppnId'],
        entryDn: value['entryDn'],
        pagOid: value['pagOid'],
        paymentStatus: PaymentStatusResponseToJSON(value['paymentStatus']),
        inactivationDate: value['inactivationDate'] == null ? undefined : value['inactivationDate'].toISOString(),
        suspensionDate: value['suspensionDate'] == null ? undefined : value['suspensionDate'].toISOString(),
        businessName: value['businessName'],
        legalBusinessName: value['legalBusinessName'],
        missingDocuments: value['missingDocuments'],
        missingReservationDocuments: value['missingReservationDocuments'],
        missingImprints: value['missingImprints'],
        readyForOnlineOrdering: value['readyForOnlineOrdering'],
        readyForOnlineReservation: value['readyForOnlineReservation'],
        deleteDate: value['deleteDate'] == null ? undefined : value['deleteDate'].toISOString(),
    }
}
