import { ReactNode } from 'react'
import { IntlProvider } from 'react-intl'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PorscheDesignSystemProvider, PToast } from '@porsche-design-system/components-react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { currentEnvironment } from '@finder/environment'

import { useTranslationMode, useTranslations } from '@slmpserv/hooks'

import { createAppConfig } from '../config'

const queryClientDefault = new QueryClient({
    defaultOptions: {
        queries: { staleTime: 2_000, refetchOnWindowFocus: false },
    },
})

type IntlQueryWrapperProps = {
    locale: string
    children: ReactNode
    environment?: string
}

export function IntlQueryWrapper({ locale, children, environment }: IntlQueryWrapperProps) {
    const env = environment ?? currentEnvironment()
    const appConfig = createAppConfig(env)

    const [{ messages: data }] = useTranslations({
        cdnUrl: appConfig.cdnUrl,
        locale,
        defaultLocale: appConfig.i18n.defaultLocale,
    })

    const { messages, TranslationModeToggle, translationSuppressedWarnings } = useTranslationMode({
        data,
        isProd: env === 'P',
    })

    return (
        <IntlProvider
            messages={messages}
            locale={locale} // Here, a correct locale must be written (regardless which), NOT "default"
            onError={err => {
                if (translationSuppressedWarnings && env === null) {
                    return
                }
                if (err.code === 'MISSING_TRANSLATION') {
                    console.warn('Missing translation', err.message)
                    return
                }
                throw err
            }}
        >
            <TranslationModeToggle />
            {children}
        </IntlProvider>
    )
}

type AppWrapperProps = {
    locale: string
    queryClient?: QueryClient
    children: ReactNode
    environment?: string
}

export const PDS_PREFIX_DEALER_WRAPPER = 'dealer-wrapper'

export function AppWrapper({
    locale = 'en-GB',
    queryClient = queryClientDefault,
    children,
    environment,
}: AppWrapperProps) {
    return (
        <PorscheDesignSystemProvider prefix={PDS_PREFIX_DEALER_WRAPPER}>
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools />
                <IntlQueryWrapper locale={locale} environment={environment}>
                    {children}
                </IntlQueryWrapper>
                <PToast />
            </QueryClientProvider>
        </PorscheDesignSystemProvider>
    )
}
