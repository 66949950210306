/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetSubmerchantStatusResponse
 */
export interface GetSubmerchantStatusResponse {
    /**
     * Status of submerchant
     * @type {string}
     * @memberof GetSubmerchantStatusResponse
     */
    status?: GetSubmerchantStatusResponseStatusEnum
    /**
     * PPN id of submerchant
     * @type {string}
     * @memberof GetSubmerchantStatusResponse
     */
    ppnId?: string
    /**
     * Id of submerchant
     * @type {number}
     * @memberof GetSubmerchantStatusResponse
     */
    submerchantId?: number
    /**
     *
     * @type {string}
     * @memberof GetSubmerchantStatusResponse
     */
    businessName?: string
    /**
     * non-formal name of the dealer
     * @type {string}
     * @memberof GetSubmerchantStatusResponse
     */
    doingBusinessAs?: string
}

/**
 * @export
 * @enum {string}
 */
export enum GetSubmerchantStatusResponseStatusEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    Suspended = 'SUSPENDED',
    Closed = 'CLOSED',
    PayoutBlocked = 'PAYOUT_BLOCKED',
}

export function GetSubmerchantStatusResponseFromJSON(json: any): GetSubmerchantStatusResponse {
    return GetSubmerchantStatusResponseFromJSONTyped(json, false)
}

export function GetSubmerchantStatusResponseFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): GetSubmerchantStatusResponse {
    if (json === undefined || json === null) {
        return json
    }
    return {
        status: !exists(json, 'status') ? undefined : json['status'],
        ppnId: !exists(json, 'ppnId') ? undefined : json['ppnId'],
        submerchantId: !exists(json, 'submerchantId') ? undefined : json['submerchantId'],
        businessName: !exists(json, 'businessName') ? undefined : json['businessName'],
        doingBusinessAs: !exists(json, 'doingBusinessAs') ? undefined : json['doingBusinessAs'],
    }
}

export function GetSubmerchantStatusResponseToJSON(value?: GetSubmerchantStatusResponse | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        status: value.status,
        ppnId: value.ppnId,
        submerchantId: value.submerchantId,
        businessName: value.businessName,
        doingBusinessAs: value.doingBusinessAs,
    }
}
