import { useQuery } from '@tanstack/react-query'

import { merchantsApi } from '@slmpserv/common-api-merchant'

export function useFetchKycAuthToken(merchantId: string) {
    const queryKey = ['KycAuthorizationToken', merchantId]
    const queryResult = useQuery({
        queryKey,
        queryFn: () => merchantsApi.getKycAuthorizationToken({ merchantId }),
        enabled: Boolean(merchantId),
    })

    return { queryResult, token: queryResult.data?.tokenValue }
}
