/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    SignatoryWithMultipleVerificationImages,
    SignatoryWithSingleVerificationImage,
    SignatoryWithMultipleVerificationImagesFromJSONTyped,
    SignatoryWithMultipleVerificationImagesToJSON,
    SignatoryWithSingleVerificationImageFromJSONTyped,
    SignatoryWithSingleVerificationImageToJSON,
} from './'

/**
 * @type Signatory
 *
 * @export
 */
export type Signatory = SignatoryWithMultipleVerificationImages | SignatoryWithSingleVerificationImage

export function SignatoryFromJSON(json: any): Signatory {
    return SignatoryFromJSONTyped(json, false)
}

export function SignatoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Signatory {
    if (json === undefined || json === null) {
        return json
    }
    return {
        ...SignatoryWithMultipleVerificationImagesFromJSONTyped(json, true),
        ...SignatoryWithSingleVerificationImageFromJSONTyped(json, true),
    }
}

export function SignatoryToJSON(value?: Signatory | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return { ...value }
}
