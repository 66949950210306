/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SellerContact
 */
export interface SellerContact {
    /**
     *
     * @type {string}
     * @memberof SellerContact
     */
    entryDn: string
    /**
     *
     * @type {boolean}
     * @memberof SellerContact
     */
    differPhoneNewPreowned: boolean
    /**
     *
     * @type {string}
     * @memberof SellerContact
     */
    phoneNumberNew: string
    /**
     *
     * @type {string}
     * @memberof SellerContact
     */
    phoneNumberPreowned: string
    /**
     *
     * @type {boolean}
     * @memberof SellerContact
     */
    differEmailNewPreowned: boolean
    /**
     *
     * @type {Array<string>}
     * @memberof SellerContact
     */
    emailAddressesNew: Array<string>
    /**
     *
     * @type {Array<string>}
     * @memberof SellerContact
     */
    emailAddressesPreowned: Array<string>
}

/**
 * Check if a given object implements the SellerContact interface.
 */
export function instanceOfSellerContact(value: object): boolean {
    if (!('entryDn' in value)) return false
    if (!('differPhoneNewPreowned' in value)) return false
    if (!('phoneNumberNew' in value)) return false
    if (!('phoneNumberPreowned' in value)) return false
    if (!('differEmailNewPreowned' in value)) return false
    if (!('emailAddressesNew' in value)) return false
    if (!('emailAddressesPreowned' in value)) return false
    return true
}

export function SellerContactFromJSON(json: any): SellerContact {
    return SellerContactFromJSONTyped(json, false)
}

export function SellerContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): SellerContact {
    if (json == null) {
        return json
    }
    return {
        entryDn: json['entryDn'],
        differPhoneNewPreowned: json['differPhoneNewPreowned'],
        phoneNumberNew: json['phoneNumberNew'],
        phoneNumberPreowned: json['phoneNumberPreowned'],
        differEmailNewPreowned: json['differEmailNewPreowned'],
        emailAddressesNew: json['emailAddressesNew'],
        emailAddressesPreowned: json['emailAddressesPreowned'],
    }
}

export function SellerContactToJSON(value?: SellerContact | null): any {
    if (value == null) {
        return value
    }
    return {
        entryDn: value['entryDn'],
        differPhoneNewPreowned: value['differPhoneNewPreowned'],
        phoneNumberNew: value['phoneNumberNew'],
        phoneNumberPreowned: value['phoneNumberPreowned'],
        differEmailNewPreowned: value['differEmailNewPreowned'],
        emailAddressesNew: value['emailAddressesNew'],
        emailAddressesPreowned: value['emailAddressesPreowned'],
    }
}
