import { PHeading, PText } from '@porsche-design-system/components-react'
import { spacingStatic } from '@porsche-design-system/components-react/styles'
import { styled } from 'styled-components'

export const StyledHeading = styled(PHeading)`
    margin-bottom: ${spacingStatic.small};
`

export const StyledText = styled(PText)`
    margin-bottom: ${spacingStatic.large};
`
