import { useEffect } from 'react'

import { basePathMiddleware } from '@slmpserv/common-api-merchant'
import { Environment } from '@slmpserv/environment'

export const useApiBasePath = (environment: Environment): void => {
    useEffect(() => {
        basePathMiddleware.setEnv(environment)
    }, [environment])
}
