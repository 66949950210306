import { PSpinner } from '@porsche-design-system/components-react'
import { Component, FunctionComponent, ReactElement, ReactNode } from 'react'
import { UseQueryResult } from '@tanstack/react-query'
import { ErrorBoundary, FallbackProps, useErrorBoundary } from 'react-error-boundary'
import { useIntl } from 'react-intl'
import { Flex } from '@slcheckout/ui-kit'

import { errorsMessages } from '@slmpserv/common-translations'

import { ErrorInlineNotification } from './Error/ErrorInlineNotification'

type PartialQueryResult = Pick<UseQueryResult, 'isLoading' | 'isError' | 'error' | 'isSuccess'>

type QueryBoundaryProps = {
    children: ReactNode
    queryResult: PartialQueryResult | PartialQueryResult[]
    renderChildrenOnlyOnSuccess?: boolean
}

type FallbackRender = (
    props: FallbackProps
) => ReactElement<unknown, string | typeof Component | FunctionComponent<Record<string, unknown>>> | null

function isResponse(value: unknown): value is Response {
    return value instanceof Response ? true : false
}

function QueryBoundaryRaw({ children, queryResult, renderChildrenOnlyOnSuccess = false }: QueryBoundaryProps) {
    const { showBoundary } = useErrorBoundary()
    const { formatMessage } = useIntl()

    const getErrorMessage = (error: unknown) => {
        if (isResponse(error)) {
            return `${formatMessage(errorsMessages.unableToFetchContent)} ${(error as Response).url}`
        }
        return formatMessage(errorsMessages.unknownError)
    }

    const { isLoading, isError, error, isSuccess } = Array.isArray(queryResult)
        ? {
              isLoading: queryResult.every(x => x.isLoading),
              isError: queryResult.some(x => x.isError),
              error: queryResult.reduce((prev: unknown[], cur) => (cur.isError ? [...prev, cur.error] : prev), []),
              isSuccess: queryResult.every(x => x.isSuccess),
          }
        : queryResult

    if (isLoading) {
        return (
            <Flex justifyContent={'center'} alignItems={'center'}>
                <PSpinner size={'medium'} />
            </Flex>
        )
    }

    if (isError) {
        Array.isArray(error)
            ? error.map(singleError => showBoundary(Error(getErrorMessage(singleError))))
            : showBoundary(Error(getErrorMessage(error))) // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>
    }
    if (!renderChildrenOnlyOnSuccess || (renderChildrenOnlyOnSuccess && isSuccess)) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{children}</>
    }
    return null
}

export function QueryBoundary({
    children,
    fallbackRender,
    ...rest
}: QueryBoundaryProps & { fallbackRender?: FallbackRender }) {
    const { formatMessage } = useIntl()

    if (!fallbackRender) {
        fallbackRender = ({ error }) => {
            return (
                <ErrorInlineNotification
                    title={formatMessage(errorsMessages.justError)}
                    message={error?.message || formatMessage(errorsMessages.genericError)}
                />
            )
        }
    }
    return (
        <ErrorBoundary fallbackRender={fallbackRender}>
            <QueryBoundaryRaw {...rest}>{children}</QueryBoundaryRaw>
        </ErrorBoundary>
    )
}
