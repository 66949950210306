import { ApiError } from './api'

const INSUFFICIENT_ROLE = 'INSUFFICIENT_ROLE'

export function isInsufficientRole(apiError: ApiError): boolean {
    if (apiError?.apiResponse.status == 403) {
        return apiError.errorResponse?.reason === INSUFFICIENT_ROLE
    }
    return false
}
