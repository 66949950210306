import { defineMessages } from 'react-intl'

export const downPaymentConfigurationMessages = defineMessages({
    downPaymentInfoTextHeadline: {
        id: 'dealer.onlineOrderingConfig.downPayment.infoTextHeadline',
        defaultMessage: 'Do you want to charge a refundable reservation fee for online ordering?',
    },
    downPaymentInfoText: {
        id: 'dealer.onlineOrderingConfig.downPayment.infoText',
        defaultMessage: 'Customers will be charged when placing an online order.',
    },
    downPaymentKycInfoText: {
        id: 'dealer.onlineOrderingConfig.downPayment.kycInfoText',
        defaultMessage:
            'Please authenticate yourself via KYC to receive the reservation fees. You can start the process from the overview page.',
    },
    downPaymentDisable: {
        id: 'dealer.onlineOrderingConfig.label.downPayment.disableText',
        defaultMessage: 'No',
    },
    downPaymentEnable: {
        id: 'dealer.onlineOrderingConfig.label.downPayment.enableText',
        defaultMessage: 'Yes',
    },
    downPaymentAmountLabel: {
        id: 'dealer.onlineOrderingConfig.label.downPaymentAmount',
        defaultMessage: 'Reservation fee ({currency})',
    },
    dealerDownPaymentEmpty: {
        id: 'dealer.onlineOrderingConfig.validation.downPaymentEmpty',
        defaultMessage: 'A reservation fee is required',
    },
    dealerDownPaymentNegative: {
        id: 'dealer.onlineOrderingConfig.validation.downPaymentNegative',
        defaultMessage: 'A reservation fee must be greater or equal zero.',
    },
})
