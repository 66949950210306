import { DocumentType, MerchantDocumentConfigResponse, SelectedOption } from '@slmpserv/common-api-merchant'

export function localeToLanguage(languageCandidate: string) {
    if (languageCandidate.length > 2) {
        return languageCandidate.substring(0, 2)
    }

    return languageCandidate
}

export function findConfigByType(
    type: DocumentType,
    language: string,
    configs: MerchantDocumentConfigResponse[]
): MerchantDocumentConfigResponse | undefined {
    return configs.find(config => config.documentType === type && config.locale === language)
}

function isDocumentValid(config: MerchantDocumentConfigResponse | undefined) {
    if (!config) {
        return false
    }

    if (config.selectedOption === SelectedOption.HANDLED_BY_FALLBACK) {
        return true
    }

    if (config.selectedOption === SelectedOption.NO_CORRESPONDING_OFFER) {
        return true
    }

    if (config.selectedOption === SelectedOption.DOCUMENT_SPECIFIED && config.documentMetadata?.url?.length > 0) {
        return true
    }

    return false
}

export function getValidDocumentsPerLanguageCount(
    configs: MerchantDocumentConfigResponse[],
    neededDocumentTypes: DocumentType[],
    language
) {
    return neededDocumentTypes
        .map(type => findConfigByType(type, language, configs))
        .map(config => isDocumentValid(config))
        .filter(isValid => isValid === true).length
}
