import { useQuery } from '@tanstack/react-query'

import { Merchant, merchantsApi } from '@slmpserv/common-api-merchant'

export type OnlineOrderingStatusProps = {
    ppnId: Merchant['ppnId']
}

export function useOnlineOrderingStatus({ ppnId }: OnlineOrderingStatusProps) {
    const queryKey = ['online-ordering-status', ppnId]

    const queryResult = useQuery({
        queryKey,
        queryFn: () => merchantsApi.getOnlineOrderingStatusDashboard({ ppnId }),
    })

    return { onlineOrderingStatus: queryResult.data, queryResult, queryKey } as const
}
