import { defineMessages } from 'react-intl'

// TODO: rename key from inquiry to sellerContact
export const sellerContactEmailsMessages = defineMessages({
    emailLabel: {
        id: 'dealer.inquiry.label.email',
        defaultMessage: 'E-Mail address',
    },
    newCarsTitle: {
        id: 'dealer.inquiry.email.newCars.title',
        defaultMessage: 'New vehicles',
    },
    usedCarsTitle: {
        id: 'dealer.inquiry.email.usedCars.title',
        defaultMessage: 'Used vehicles',
    },
    useSameEmailsSelectOption: {
        id: 'dealer.inquiry.useSameEmail.option',
        defaultMessage: 'Same as for used vehicles',
    },
    useDifferentEmailsSelectOption: {
        id: 'dealer.inquiry.useDifferentEmail.option',
        defaultMessage: 'Provide specific contact details',
    },
})

// TODO: rename key from inquiry to sellerContact
export const sellerContactPhoneMessages = defineMessages({
    phoneNumberLabel: {
        id: 'dealer.inquiry.label.phoneNumber',
        defaultMessage: 'Phone number',
    },
    phoneNumberPlaceholder: {
        id: 'dealer.inquiry.phoneNumber.placeholder',
        defaultMessage: '123456789',
    },
    phoneNumberRequired: {
        id: 'dealer.inquiry.error.phoneNumber.required',
        defaultMessage: 'Phone number required',
    },
    newCarsTitle: {
        id: 'dealer.inquiry.phoneNumber.newCars.title',
        defaultMessage: 'New vehicles',
    },
    usedCarsTitle: {
        id: 'dealer.inquiry.phoneNumber.usedCars.title',
        defaultMessage: 'Used vehicles',
    },
    useSamePhoneNumberSelectOption: {
        id: 'dealer.inquiry.useSamePhoneNumber.option',
        defaultMessage: 'Same as for used vehicles',
    },
    useDifferentPhoneNumberSelectOption: {
        id: 'dealer.inquiry.useDifferentPhoneNumber.option',
        defaultMessage: 'Provide specific contact details',
    },
})
