import { useEffect } from 'react'
import { AuthorizationHeader } from '@finder/cognito-react'
import { PSpinner } from '@porsche-design-system/components-react'
import { useIntl } from 'react-intl'
import { Flex } from '@slcheckout/ui-kit'

import { useBoolean } from '@slmpserv/hooks'
import { useMarketplaceContext } from '@slmpserv/common-contexts'

// needed in order to have correct types for the web component
// eslint-disable-next-line
import { KycWidgetProps } from './types'
import { ErrorInlineNotification } from '@slmpserv/common-components'
import { errorsMessages } from '@slmpserv/common-translations'
import { Environment } from '@slmpserv/environment'
import { apiKeys } from '@slmpserv/kyc-service-api'

type KycWidgetWrapperProps = {
    country: string
    ppnId: string
    isProd: boolean
    oauthTokenCallback: () => Promise<AuthorizationHeader>
    locale: string
}

export function KycWidget({ ppnId, isProd, locale, oauthTokenCallback, country }: KycWidgetWrapperProps) {
    const { formatMessage } = useIntl()
    const { 0: isLoading, 2: setIsLoadingDone } = useBoolean(true)
    const { 0: isError, 1: setError } = useBoolean()
    const { operator } = useMarketplaceContext()
    const tokenCallBackFunctionName = 'tokenCallBackFunction'
    const localeParts = locale.split(/-|_/)
    const localePhraseFormat =
        localeParts.length === 2 ? `${localeParts[0].toLocaleLowerCase()}-${localeParts[1].toUpperCase()}` : 'en-GB'

    useEffect(() => {
        const tokenCallbackPromise = oauthTokenCallback().then(header => header.Authorization)
        window[tokenCallBackFunctionName] = () => tokenCallbackPromise

        // TODO: only add script if not yet in dom
        const script = document.createElement('script')
        script.src = isProd
            ? 'https://pay.porsche.com/kyc-widget/widget.js'
            : 'https://stg.pay.porsche-preview.com/kyc-widget/widget.js'
        script.onload = setIsLoadingDone
        script.onerror = setError
        const styles = document.createElement('link')
        styles.rel = 'stylesheet'
        styles.href = isProd
            ? 'https://pay.porsche.com/kyc-widget/widget.css'
            : 'https://stg.pay.porsche-preview.com/kyc-widget/widget.css'

        document.head.append(script)
        document.head.append(styles)

        return () => {
            document.head.removeChild(script)
            document.head.removeChild(styles)
            delete window[tokenCallBackFunctionName]
        }
    }, [isProd, setError, setIsLoadingDone, tokenCallBackFunctionName, oauthTokenCallback])

    if (isLoading) {
        return (
            <Flex justifyContent={'center'} alignItems={'center'}>
                <PSpinner size={'medium'} />
            </Flex>
        )
    }

    if (isError) {
        return (
            <ErrorInlineNotification
                title={formatMessage(errorsMessages.justError)}
                message={formatMessage(errorsMessages.genericError)}
            />
        )
    }

    return (
        <kyc-ui-widget
            environment={isProd ? 'production' : 'preproduction'}
            apikey={isProd ? apiKeys[Environment.PROD] : apiKeys[Environment.PREVIEW]}
            locale={localePhraseFormat}
            country={country}
            originUrl={window.location.href}
            ppnid={ppnId}
            legalentityname={operator}
            authtokencallback={tokenCallBackFunctionName}
        />
    )
}
