/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface OnlineOrderConfigurationEmailsRequest
 */
export interface OnlineOrderConfigurationEmailsRequest {
    /**
     *
     * @type {Array<string>}
     * @memberof OnlineOrderConfigurationEmailsRequest
     */
    emailsForOrderConfirmation: Array<string>
    /**
     *
     * @type {boolean}
     * @memberof OnlineOrderConfigurationEmailsRequest
     */
    differentEmailCsvCars?: boolean
    /**
     *
     * @type {Array<string>}
     * @memberof OnlineOrderConfigurationEmailsRequest
     */
    emailsForCsvOrderConfirmation?: Array<string>
}

/**
 * Check if a given object implements the OnlineOrderConfigurationEmailsRequest interface.
 */
export function instanceOfOnlineOrderConfigurationEmailsRequest(value: object): boolean {
    if (!('emailsForOrderConfirmation' in value)) return false
    return true
}

export function OnlineOrderConfigurationEmailsRequestFromJSON(json: any): OnlineOrderConfigurationEmailsRequest {
    return OnlineOrderConfigurationEmailsRequestFromJSONTyped(json, false)
}

export function OnlineOrderConfigurationEmailsRequestFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): OnlineOrderConfigurationEmailsRequest {
    if (json == null) {
        return json
    }
    return {
        emailsForOrderConfirmation: json['emailsForOrderConfirmation'],
        differentEmailCsvCars: json['differentEmailCsvCars'] == null ? undefined : json['differentEmailCsvCars'],
        emailsForCsvOrderConfirmation:
            json['emailsForCsvOrderConfirmation'] == null ? undefined : json['emailsForCsvOrderConfirmation'],
    }
}

export function OnlineOrderConfigurationEmailsRequestToJSON(value?: OnlineOrderConfigurationEmailsRequest | null): any {
    if (value == null) {
        return value
    }
    return {
        emailsForOrderConfirmation: value['emailsForOrderConfirmation'],
        differentEmailCsvCars: value['differentEmailCsvCars'],
        emailsForCsvOrderConfirmation: value['emailsForCsvOrderConfirmation'],
    }
}
