/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SubmerchantDebitResponseRestModel
 */
export interface SubmerchantDebitResponseRestModel {
    /**
     * id of submerchant direct debit request
     * @type {number}
     * @memberof SubmerchantDebitResponseRestModel
     */
    id?: number
    /**
     *
     * @type {string}
     * @memberof SubmerchantDebitResponseRestModel
     */
    merchantReference?: string
    /**
     * status of request
     * @type {string}
     * @memberof SubmerchantDebitResponseRestModel
     */
    status?: string
    /**
     * id of submerchant
     * @type {number}
     * @memberof SubmerchantDebitResponseRestModel
     */
    submerchantId?: number
}

export function SubmerchantDebitResponseRestModelFromJSON(json: any): SubmerchantDebitResponseRestModel {
    return SubmerchantDebitResponseRestModelFromJSONTyped(json, false)
}

export function SubmerchantDebitResponseRestModelFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): SubmerchantDebitResponseRestModel {
    if (json === undefined || json === null) {
        return json
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        merchantReference: !exists(json, 'merchantReference') ? undefined : json['merchantReference'],
        status: !exists(json, 'status') ? undefined : json['status'],
        submerchantId: !exists(json, 'submerchantId') ? undefined : json['submerchantId'],
    }
}

export function SubmerchantDebitResponseRestModelToJSON(value?: SubmerchantDebitResponseRestModel | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        id: value.id,
        merchantReference: value.merchantReference,
        status: value.status,
        submerchantId: value.submerchantId,
    }
}
