import { useQuery } from '@tanstack/react-query'

import { Merchant, onlineOrderConfigurationApi } from '@slmpserv/common-api-merchant'

export type UseFetchMerchantOnlineOrderConfigProps = {
    ppnId: Merchant['ppnId']
}

export function useFetchMerchantOnlineOrderConfig({ ppnId }: UseFetchMerchantOnlineOrderConfigProps) {
    const queryKey = ['online-order-config', ppnId]
    const queryResult = useQuery({
        queryKey,
        queryFn: () => onlineOrderConfigurationApi.getOrderConfiguration({ ppnId }),
    })

    return { onlineOrderConfig: queryResult.data, queryResult, queryKey } as const
}
