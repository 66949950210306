/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ErrorResponse, MarketplaceDocumentConfig } from '../models/index'
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    MarketplaceDocumentConfigFromJSON,
    MarketplaceDocumentConfigToJSON,
} from '../models/index'

export interface GetDocumentsConfigurationsByMarketplaceRequest {
    marketplaceKey: string
}

export interface SaveDocumentsConfigurationsForMarketplaceRequest {
    marketplaceKey: string
    marketplaceDocumentConfig: MarketplaceDocumentConfig
}

/**
 *
 */
export class MarketplacesDocumentConfigurationApi extends runtime.BaseAPI {
    /**
     */
    async getDocumentsConfigurationsByMarketplaceRaw(
        requestParameters: GetDocumentsConfigurationsByMarketplaceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<Array<MarketplaceDocumentConfig>>> {
        if (requestParameters['marketplaceKey'] == null) {
            throw new runtime.RequiredError(
                'marketplaceKey',
                'Required parameter "marketplaceKey" was null or undefined when calling getDocumentsConfigurationsByMarketplace().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/marketplaces/{marketplaceKey}/documentConfigurations`.replace(
                    `{${'marketplaceKey'}}`,
                    encodeURIComponent(String(requestParameters['marketplaceKey']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(MarketplaceDocumentConfigFromJSON))
    }

    /**
     */
    async getDocumentsConfigurationsByMarketplace(
        requestParameters: GetDocumentsConfigurationsByMarketplaceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<Array<MarketplaceDocumentConfig>> {
        const response = await this.getDocumentsConfigurationsByMarketplaceRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async saveDocumentsConfigurationsForMarketplaceRaw(
        requestParameters: SaveDocumentsConfigurationsForMarketplaceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<MarketplaceDocumentConfig>> {
        if (requestParameters['marketplaceKey'] == null) {
            throw new runtime.RequiredError(
                'marketplaceKey',
                'Required parameter "marketplaceKey" was null or undefined when calling saveDocumentsConfigurationsForMarketplace().'
            )
        }

        if (requestParameters['marketplaceDocumentConfig'] == null) {
            throw new runtime.RequiredError(
                'marketplaceDocumentConfig',
                'Required parameter "marketplaceDocumentConfig" was null or undefined when calling saveDocumentsConfigurationsForMarketplace().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request(
            {
                path: `/api/marketplaces/{marketplaceKey}/documentConfigurations`.replace(
                    `{${'marketplaceKey'}}`,
                    encodeURIComponent(String(requestParameters['marketplaceKey']))
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: MarketplaceDocumentConfigToJSON(requestParameters['marketplaceDocumentConfig']),
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => MarketplaceDocumentConfigFromJSON(jsonValue))
    }

    /**
     */
    async saveDocumentsConfigurationsForMarketplace(
        requestParameters: SaveDocumentsConfigurationsForMarketplaceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<MarketplaceDocumentConfig> {
        const response = await this.saveDocumentsConfigurationsForMarketplaceRaw(requestParameters, initOverrides)
        return await response.value()
    }
}
