import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'

import { applicationFeatureApi } from '@slmpserv/common-api-merchant'

type ClientFeatureList = 'inquiry-form' //  Use [] if no feature flag exists

export function useApplicationFeature(featureName: ClientFeatureList): boolean | undefined {
    const [searchParams] = useSearchParams()
    const response = useQuery({
        queryKey: ['applicationFeatures'],
        queryFn: () => applicationFeatureApi.getFeatures(),
        refetchOnWindowFocus: false,
    })

    const isFeatureActiveFromUrl = searchParams.get(featureName)

    if (isFeatureActiveFromUrl === 'true') {
        return true
    }

    return response.data?.[featureName]
}
