/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
    CreatePaymentLinkRequest,
    CreatePaymentLinkResponseRestModel,
    ErrorResponse,
    PaymentLinkEnabledResponse,
} from '../models/index'
import {
    CreatePaymentLinkRequestFromJSON,
    CreatePaymentLinkRequestToJSON,
    CreatePaymentLinkResponseRestModelFromJSON,
    CreatePaymentLinkResponseRestModelToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PaymentLinkEnabledResponseFromJSON,
    PaymentLinkEnabledResponseToJSON,
} from '../models/index'

export interface CreatePaymentLinkOperationRequest {
    createPaymentLinkRequest: CreatePaymentLinkRequest
}

export interface GetPaymentLinkEnabledRequest {
    marketplaceKey: string
}

/**
 *
 */
export class PaymentApi extends runtime.BaseAPI {
    /**
     */
    async createPaymentLinkRaw(
        requestParameters: CreatePaymentLinkOperationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<CreatePaymentLinkResponseRestModel>> {
        if (requestParameters['createPaymentLinkRequest'] == null) {
            throw new runtime.RequiredError(
                'createPaymentLinkRequest',
                'Required parameter "createPaymentLinkRequest" was null or undefined when calling createPaymentLink().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request(
            {
                path: `/api/paymentLink/create`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreatePaymentLinkRequestToJSON(requestParameters['createPaymentLinkRequest']),
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => CreatePaymentLinkResponseRestModelFromJSON(jsonValue))
    }

    /**
     */
    async createPaymentLink(
        requestParameters: CreatePaymentLinkOperationRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<CreatePaymentLinkResponseRestModel> {
        const response = await this.createPaymentLinkRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async getPaymentLinkEnabledRaw(
        requestParameters: GetPaymentLinkEnabledRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<PaymentLinkEnabledResponse>> {
        if (requestParameters['marketplaceKey'] == null) {
            throw new runtime.RequiredError(
                'marketplaceKey',
                'Required parameter "marketplaceKey" was null or undefined when calling getPaymentLinkEnabled().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/paymentLink/paymentLinkEnabled/{marketplaceKey}`.replace(
                    `{${'marketplaceKey'}}`,
                    encodeURIComponent(String(requestParameters['marketplaceKey']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => PaymentLinkEnabledResponseFromJSON(jsonValue))
    }

    /**
     */
    async getPaymentLinkEnabled(
        requestParameters: GetPaymentLinkEnabledRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<PaymentLinkEnabledResponse> {
        const response = await this.getPaymentLinkEnabledRaw(requestParameters, initOverrides)
        return await response.value()
    }
}
