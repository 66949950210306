import { themeLight } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'

import {
    dashboardPaymentDeadlineDateResolver,
    dashboardPopoverMessages,
    dashboardPreviewMessages,
    dashboardStatusMessages,
    dashboardStatusMessageTooltip,
    dashboardTitles,
} from '@slmpserv/common-translations'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { MerchantResponse, OnlineOrderingStatusDto, PaymentStatusResponse } from '@slmpserv/common-api-merchant'
import { useMatomoTrackEditEvent } from '@slmpserv/common/analytics'
import { useFetchRedirectUrl } from '@slmpserv/kyc-service-api'
import { useCurrentDealerContext } from '@slmpserv/self-service-current-dealer-context'
import { AccessSensitiveProps } from '@slmpserv/self-service-shared'

import { DashboardGroupItem, DashboardWarningTag } from '../DashboardGroupItem/DashboardGroupItem'
import { DashboardGroupItemPreview } from '../DashboardGroupItemPreview/DashboardGroupItemPreview'

function getWarningStateMessages(kycStatus: PaymentStatusResponse) {
    switch (kycStatus) {
        case PaymentStatusResponse.UNREGISTERED: {
            return {
                tagText: dashboardStatusMessages.paymentProcessingNoAccount,
                popoverText: dashboardStatusMessageTooltip.paymentProcessingNoAccount,
            }
        }
        case PaymentStatusResponse.SUSPENDED: {
            return {
                tagText: dashboardStatusMessages.paymentProcessingAccountSuspended,
                popoverText: dashboardStatusMessageTooltip.paymentProcessingAccountSuspended,
            }
        }
        case PaymentStatusResponse.CLOSED: {
            return {
                tagText: dashboardStatusMessages.paymentProcessingAccountClosed,
                popoverText: dashboardStatusMessageTooltip.paymentProcessingAccountClosed,
            }
        }
        case PaymentStatusResponse.PAYOUT_BLOCKED: {
            return {
                tagText: dashboardStatusMessages.paymentProcessingPayoutBlocked,
                popoverText: dashboardStatusMessageTooltip.paymentProcessingPayoutBlocked,
            }
        }
        case PaymentStatusResponse.INACTIVE: {
            return {
                tagText: dashboardStatusMessages.paymentProcessingAccountInactive,
                popoverText: dashboardStatusMessageTooltip.paymentProcessingAccountInactive,
            }
        }
        default: {
            return {
                tagText: dashboardPreviewMessages.undefinedMessage,
                popoverText: dashboardPreviewMessages.undefinedMessage,
            }
        }
    }
}

type PaymentProcessingItemProps = AccessSensitiveProps & {
    merchant: MerchantResponse
    onlineOrderingStatus: OnlineOrderingStatusDto
    isInWarningState: boolean
}

export function PaymentProcessingItem({
    merchant,
    onlineOrderingStatus,
    isInWarningState,
    isAllowedToEdit,
}: PaymentProcessingItemProps) {
    const { formatMessage } = useIntl()
    const statusMessage = getWarningStateMessages(onlineOrderingStatus?.paymentStatus)

    const { descriptor, days } = dashboardPaymentDeadlineDateResolver(onlineOrderingStatus)

    const { locale } = useCurrentDealerContext()

    const { redirectUrl, isLoading } = useFetchRedirectUrl({
        ppnId: merchant.ppnId,
        locale: locale,
        returnUrl: window.location.href,
    })

    const paymentDeadlineToDateMessage: string = descriptor && days ? formatMessage(descriptor, { days: days }) : null

    const popoverMessage = [formatMessage(statusMessage.popoverText), paymentDeadlineToDateMessage]
        .filter(Boolean)
        .join(' ')

    const previewText = isInWarningState
        ? formatMessage(statusMessage.tagText)
        : formatMessage(dashboardPreviewMessages.paymentServicePayoutEnabled)

    const { trackEvent } = useMatomoTrackEditEvent('payment_processing', isInWarningState)

    return (
        <DashboardGroupItem
            hightlightColor={isInWarningState ? themeLight.notification.warning : null}
            title={formatMessage(dashboardTitles.paymentProcessing)}
            tooltipText={formatMessage(dashboardPopoverMessages.paymentProcessing)}
            preview={
                <DashboardGroupItemPreview title={formatMessage(dashboardPreviewMessages.status)} text={previewText} />
            }
            badge={
                isInWarningState && (
                    <DashboardWarningTag popoverText={popoverMessage} tagText={formatMessage(statusMessage.tagText)} />
                )
            }
            editLink={
                !isLoading && {
                    isAllowedToEdit,
                    props: {
                        to: redirectUrl || StaticRoutes.KYC_MANAGEMENT,
                        target: redirectUrl ? '_blank' : '',
                    },
                    matomoEvent: trackEvent,
                }
            }
        />
    )
}
