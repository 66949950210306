/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ErrorResponse, SellerContact, SellerContactEmail, SellerContactPhone } from '../models/index'
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    SellerContactFromJSON,
    SellerContactToJSON,
    SellerContactEmailFromJSON,
    SellerContactEmailToJSON,
    SellerContactPhoneFromJSON,
    SellerContactPhoneToJSON,
} from '../models/index'

export interface CreateOrUpdateEmailRequest {
    sellerContactEmail: SellerContactEmail
}

export interface CreateOrUpdatePhoneRequest {
    sellerContactPhone: SellerContactPhone
}

export interface GetByEntryDnRequest {
    entryDn: string
}

export interface GetEmailByEntryDnRequest {
    entryDn: string
}

export interface GetPhoneByEntryDnRequest {
    entryDn: string
}

/**
 *
 */
export class SellerContactApi extends runtime.BaseAPI {
    /**
     */
    async createOrUpdateEmailRaw(
        requestParameters: CreateOrUpdateEmailRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['sellerContactEmail'] == null) {
            throw new runtime.RequiredError(
                'sellerContactEmail',
                'Required parameter "sellerContactEmail" was null or undefined when calling createOrUpdateEmail().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request(
            {
                path: `/api/sellers/contact/email`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SellerContactEmailToJSON(requestParameters['sellerContactEmail']),
            },
            initOverrides
        )

        return new runtime.VoidApiResponse(response)
    }

    /**
     */
    async createOrUpdateEmail(
        requestParameters: CreateOrUpdateEmailRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<void> {
        await this.createOrUpdateEmailRaw(requestParameters, initOverrides)
    }

    /**
     */
    async createOrUpdatePhoneRaw(
        requestParameters: CreateOrUpdatePhoneRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['sellerContactPhone'] == null) {
            throw new runtime.RequiredError(
                'sellerContactPhone',
                'Required parameter "sellerContactPhone" was null or undefined when calling createOrUpdatePhone().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request(
            {
                path: `/api/sellers/contact/phone`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SellerContactPhoneToJSON(requestParameters['sellerContactPhone']),
            },
            initOverrides
        )

        return new runtime.VoidApiResponse(response)
    }

    /**
     */
    async createOrUpdatePhone(
        requestParameters: CreateOrUpdatePhoneRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<void> {
        await this.createOrUpdatePhoneRaw(requestParameters, initOverrides)
    }

    /**
     */
    async getByEntryDnRaw(
        requestParameters: GetByEntryDnRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<SellerContact>> {
        if (requestParameters['entryDn'] == null) {
            throw new runtime.RequiredError(
                'entryDn',
                'Required parameter "entryDn" was null or undefined when calling getByEntryDn().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/sellers/contact/{entryDn}`.replace(
                    `{${'entryDn'}}`,
                    encodeURIComponent(String(requestParameters['entryDn']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => SellerContactFromJSON(jsonValue))
    }

    /**
     */
    async getByEntryDn(
        requestParameters: GetByEntryDnRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<SellerContact> {
        const response = await this.getByEntryDnRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async getEmailByEntryDnRaw(
        requestParameters: GetEmailByEntryDnRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<SellerContactEmail>> {
        if (requestParameters['entryDn'] == null) {
            throw new runtime.RequiredError(
                'entryDn',
                'Required parameter "entryDn" was null or undefined when calling getEmailByEntryDn().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/sellers/contact/{entryDn}/email`.replace(
                    `{${'entryDn'}}`,
                    encodeURIComponent(String(requestParameters['entryDn']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => SellerContactEmailFromJSON(jsonValue))
    }

    /**
     */
    async getEmailByEntryDn(
        requestParameters: GetEmailByEntryDnRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<SellerContactEmail> {
        const response = await this.getEmailByEntryDnRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async getPhoneByEntryDnRaw(
        requestParameters: GetPhoneByEntryDnRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<SellerContactPhone>> {
        if (requestParameters['entryDn'] == null) {
            throw new runtime.RequiredError(
                'entryDn',
                'Required parameter "entryDn" was null or undefined when calling getPhoneByEntryDn().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/sellers/contact/{entryDn}/phone`.replace(
                    `{${'entryDn'}}`,
                    encodeURIComponent(String(requestParameters['entryDn']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => SellerContactPhoneFromJSON(jsonValue))
    }

    /**
     */
    async getPhoneByEntryDn(
        requestParameters: GetPhoneByEntryDnRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<SellerContactPhone> {
        const response = await this.getPhoneByEntryDnRaw(requestParameters, initOverrides)
        return await response.value()
    }
}
