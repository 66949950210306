import { defineMessages } from 'react-intl'

export const sharedMerchantMessages = defineMessages({
    ok: {
        id: 'merchant.common.ok',
        defaultMessage: 'Ok',
    },
    cancel: {
        id: 'merchant.common.cancel',
        defaultMessage: 'Cancel',
    },
    save: {
        id: 'merchant.common.save',
        defaultMessage: 'Save',
    },
    edit: {
        id: 'merchant.common.edit',
        defaultMessage: 'Edit',
    },
    add: {
        id: 'merchant.common.add',
        defaultMessage: 'Add',
    },
    actions: {
        id: 'merchant.common.actions',
        defaultMessage: 'Actions',
    },
    details: {
        id: 'merchant.common.details',
        defaultMessage: 'Details',
    },
})
