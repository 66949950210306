import { spacingStatic } from '@porsche-design-system/components-react/styles'
import { useFormContext } from 'react-hook-form'
import { useRef } from 'react'
import { useIntl } from 'react-intl'
import { styled } from 'styled-components'

import { documentFileUploadMessages, LabelForDocumentType } from '@slmpserv/common-translations'
import { DocumentType } from '@slmpserv/common-api-merchant'

import { DocumentsFormState } from '../../DocumentsForm/DocumentsFormTypesAndConsts'

import { FileJustSelected } from './FileJustSelected'
import { FileUploadLabel } from './FileUploadLabel'
import { ButtonUpdateFile } from './ButtonUpdateFile'
import { ButtonAddFile } from './ButtonAddFile'
import { AcceptedFilesInfo } from './AcceptedFilesInfo'
import { HasFileLayout } from './HasFileLayout'
import { FileUploadError } from './FileUploadError'
import { FileStoredInBackend } from './FileStoredInBackend'

const MAX_FILE_SIZE_DEFAULT = 5

const StyledFileUploadContainer = styled.div`
    margin-top: ${spacingStatic.small};
`

type FileUploadProps = {
    language: string
    showLabel?: boolean
    documentType: DocumentType
    disabled?: boolean
    maxFileSizeInMB?: number
}

export function FileUpload({
    language,
    documentType,
    disabled = false,
    showLabel,
    maxFileSizeInMB = MAX_FILE_SIZE_DEFAULT,
}: FileUploadProps) {
    const { formatMessage } = useIntl()
    const inputRef = useRef<HTMLInputElement>(null)

    const { watch, setValue, trigger, setError, clearErrors } = useFormContext<DocumentsFormState>()

    const existingDocument = watch(`documents.${language}.storedFiles.${documentType}`)

    const hasNewFile = watch(`documents.${language}.files.${documentType}`) !== undefined
    const hasOldFile = existingDocument !== undefined
    const hasFile = hasNewFile || hasOldFile

    const fileInfo = hasNewFile ? (
        <FileJustSelected documentType={documentType} inputRef={inputRef} disabled={disabled} language={language} />
    ) : hasOldFile ? (
        <FileStoredInBackend existingDocument={existingDocument} disabled={disabled} />
    ) : null

    const handleSelectNewFile = () => inputRef.current?.click()

    return (
        <StyledFileUploadContainer>
            <input
                accept={'application/pdf'}
                type={'file'}
                ref={inputRef}
                style={{ display: 'none' }}
                onChange={async event => {
                    clearErrors(`documents.${language}.files.${documentType}`)
                    if ((event.currentTarget.files as FileList)[0].size > maxFileSizeInMB * 1024 * 1024) {
                        setError(`documents.${language}.files.${documentType}`, {
                            message: formatMessage(documentFileUploadMessages.fileSizeTooBig),
                        })
                    }
                    setValue(`documents.${language}.files.${documentType}`, event.currentTarget.files || undefined, {
                        shouldDirty: true,
                    })
                    await trigger(`documents.${language}.decisions.${documentType}`)
                }}
                disabled={disabled}
            />
            {showLabel ? <FileUploadLabel label={formatMessage(LabelForDocumentType[documentType])} /> : null}
            {hasFile ? (
                <HasFileLayout
                    fileInfo={fileInfo}
                    updateFile={<ButtonUpdateFile onClick={handleSelectNewFile} disabled={disabled} />}
                    disabled={disabled}
                />
            ) : (
                <ButtonAddFile onClick={handleSelectNewFile} disabled={disabled} />
            )}

            <FileUploadError documentType={documentType} language={language} />

            <AcceptedFilesInfo maxFileSizeInMB={maxFileSizeInMB} />
        </StyledFileUploadContainer>
    )
}
