/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface GetKycEmail
 */
export interface GetKycEmail {
    /**
     * A list of email addresses that should be returned
     * @type {Array<string>}
     * @memberof GetKycEmail
     */
    addresses: Array<string>
}

export function GetKycEmailFromJSON(json: any): GetKycEmail {
    return GetKycEmailFromJSONTyped(json, false)
}

export function GetKycEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetKycEmail {
    if (json === undefined || json === null) {
        return json
    }
    return {
        addresses: json['addresses'],
    }
}

export function GetKycEmailToJSON(value?: GetKycEmail | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        addresses: value.addresses,
    }
}
