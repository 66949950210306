import { NavigateProps } from 'react-router-dom'

import { ApiError, isInsufficientRole, Merchant } from '@slmpserv/common-api-merchant'

export enum RouteErrors {
    FEATURE_NOT_ENABLED,
    MERCHANT_NOT_FOUND,
    UNKNOWN_ERROR,
    INSUFFICIENT_ROLE,
}

type MerchantNotFoundError = {
    error: {
        type: RouteErrors.MERCHANT_NOT_FOUND
        ppnId: Merchant['ppnId']
    }
}

type FeatureNotEnabledError = {
    error: {
        type: RouteErrors.FEATURE_NOT_ENABLED
    }
}

type InsufficientRoleError = {
    error: {
        type: RouteErrors.INSUFFICIENT_ROLE
    }
}

type UnknownError = {
    error: never
}

export type ErrorRouteState = FeatureNotEnabledError | MerchantNotFoundError | InsufficientRoleError | UnknownError

type ErrorRedirect = Pick<NavigateProps, 'to' | 'state'>

const ERROR_PAGE_PATH = '/error'

export const ErrorRedirectBuilder = {
    handleMerchantError: (ppnId: Merchant['ppnId'], apiError: ApiError): ErrorRedirect => {
        if (isInsufficientRole(apiError)) {
            return ErrorRedirectBuilder.insufficientRole()
        } else if (apiError?.apiResponse?.status === 404) {
            return ErrorRedirectBuilder.merchantNotFound(ppnId)
        } else {
            return ErrorRedirectBuilder.unknownError()
        }
    },
    handleMarketplaceError: (apiError: ApiError): ErrorRedirect => {
        if (isInsufficientRole(apiError)) {
            return ErrorRedirectBuilder.insufficientRole()
        } else {
            return ErrorRedirectBuilder.unknownError()
        }
    },
    featureNotEnabled: (): ErrorRedirect => ({
        to: ERROR_PAGE_PATH,
        state: {
            error: {
                type: RouteErrors.FEATURE_NOT_ENABLED,
            },
        },
    }),
    merchantNotFound: (ppnId: Merchant['ppnId']): ErrorRedirect => ({
        to: ERROR_PAGE_PATH,
        state: {
            error: {
                type: RouteErrors.MERCHANT_NOT_FOUND,
                ppnId,
            },
        },
    }),
    unknownError: (): ErrorRedirect => ({
        to: ERROR_PAGE_PATH,
        state: { error: { type: RouteErrors.UNKNOWN_ERROR } },
    }),
    insufficientRole: (): ErrorRedirect => ({
        to: ERROR_PAGE_PATH,
        state: { error: { type: RouteErrors.INSUFFICIENT_ROLE } },
    }),
}
