import { defineMessages } from 'react-intl'

export const formButtonsMessages = defineMessages({
    submit: {
        id: 'dealer.form.button.submit',
        defaultMessage: 'Save',
    },
    cancel: {
        id: 'dealer.form.button.cancel',
        defaultMessage: 'Cancel',
    },
    toastSubmitSuccess: {
        id: 'dealer.form.toast.submit.success',
        defaultMessage: 'Saved',
    },
    toastSubmitError: {
        id: 'dealer.form.toast.submit.error',
        defaultMessage: 'Error while saving',
    },
})
