/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum EditRights {
    SELLER = 'SELLER',
    MERCHANT = 'MERCHANT',
}

export function instanceOfEditRights(value: any): boolean {
    return Object.values(EditRights).includes(value)
}

export function EditRightsFromJSON(json: any): EditRights {
    return EditRightsFromJSONTyped(json, false)
}

export function EditRightsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditRights {
    return json as EditRights
}

export function EditRightsToJSON(value?: EditRights | null): any {
    return value as any
}
