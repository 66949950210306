import { useQuery } from '@tanstack/react-query'

import { Merchant } from '@slmpserv/common-api-merchant'

import { submerchantApi, accountHolderApi } from './kyc-api'

type UseFetchRedirectUrlProps = {
    ppnId: Merchant['ppnId']
    locale: string
    returnUrl: string
}

export function useFetchRedirectUrl({ ppnId, locale, returnUrl }: UseFetchRedirectUrlProps) {
    const queryKeyRedirectUrl = ['redirectUrl', ppnId]

    const redirectUrl = useQuery({
        queryKey: queryKeyRedirectUrl,
        queryFn: async () => {
            const submerchantId = (await submerchantApi.ppnIdsubmerchantIdMappingGET({ ppnId: ppnId })).submerchantId

            if (!submerchantId) {
                return { redirectUrl: null }
            }

            const migrated = (await submerchantApi.submerchantGET({ submerchantId: submerchantId })).migrated

            if (migrated === false) {
                return { redirectUrl: null }
            }

            const onboardingLink = (
                await accountHolderApi.accountholdersIdOnboardinglinkPost({
                    id: submerchantId,
                    hostedOnboardingRequestRestModel: { locale, returnUrl },
                })
            ).onboardingLink

            if (!onboardingLink) {
                return { redirectUrl: null }
            }

            return { redirectUrl: onboardingLink }
        },
        retry: false,
    })

    const isLoading = redirectUrl.isFetching

    return { redirectUrl: redirectUrl.data?.redirectUrl, isLoading }
}
