export type DownPaymentFormState = {
    downPaymentValue: number
    downPaymentActive: boolean
}

export type EmailFormState = {
    emailsForOrderConfirmation: Array<{ id: string; value: string }>
    emailsForCsvOrderConfirmation: Array<{ id: string; value: string }>
    differentEmailCsvCars: UsedNewCarContact
}

export enum UsedNewCarContact {
    Same = 'USE_SAME',
    Different = 'USE_DIFFERENT',
}
