/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SelectedOption {
    NO_CORRESPONDING_OFFER = 'NO_CORRESPONDING_OFFER',
    HANDLED_BY_FALLBACK = 'HANDLED_BY_FALLBACK',
    DOCUMENT_SPECIFIED = 'DOCUMENT_SPECIFIED',
}

export function instanceOfSelectedOption(value: any): boolean {
    return Object.values(SelectedOption).includes(value)
}

export function SelectedOptionFromJSON(json: any): SelectedOption {
    return SelectedOptionFromJSONTyped(json, false)
}

export function SelectedOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectedOption {
    return json as SelectedOption
}

export function SelectedOptionToJSON(value?: SelectedOption | null): any {
    return value as any
}
