import { useIntl, MessageDescriptor } from 'react-intl'
import { Tooltip } from '@slcheckout/ui-kit'
import { PText, PTag, PTagProps } from '@porsche-design-system/components-react'

import { PaymentStatusResponse } from '@slmpserv/common-api-merchant'
import { getDaysFromToday } from '@slmpserv/common-translations'

import { statusMessages } from './KycStatus.message'

const colors: { [key in PaymentStatusResponse]: PTagProps['color'] } = {
    [PaymentStatusResponse.ACTIVE]: 'notification-success',
    [PaymentStatusResponse.CLOSED]: 'notification-error',
    [PaymentStatusResponse.INACTIVE]: 'notification-error',
    [PaymentStatusResponse.PAYOUT_BLOCKED]: 'notification-warning',
    [PaymentStatusResponse.SUSPENDED]: 'notification-error',
    [PaymentStatusResponse.UNREGISTERED]: 'notification-error',
}

const icons: { [key in PaymentStatusResponse]: PTagProps['icon'] } = {
    [PaymentStatusResponse.ACTIVE]: 'check',
    [PaymentStatusResponse.CLOSED]: 'exclamation',
    [PaymentStatusResponse.INACTIVE]: 'exclamation',
    [PaymentStatusResponse.PAYOUT_BLOCKED]: 'warning',
    [PaymentStatusResponse.SUSPENDED]: 'exclamation',
    [PaymentStatusResponse.UNREGISTERED]: 'disable',
}

const translations: { [key in PaymentStatusResponse]: MessageDescriptor } = {
    [PaymentStatusResponse.ACTIVE]: statusMessages.active,
    [PaymentStatusResponse.CLOSED]: statusMessages.closed,
    [PaymentStatusResponse.INACTIVE]: statusMessages.inactive,
    [PaymentStatusResponse.PAYOUT_BLOCKED]: statusMessages.payoutBlocked,
    [PaymentStatusResponse.SUSPENDED]: statusMessages.suspended,
    [PaymentStatusResponse.UNREGISTERED]: statusMessages.notRegistered,
}

const tooltips: { [key in PaymentStatusResponse]: MessageDescriptor } = {
    [PaymentStatusResponse.ACTIVE]: statusMessages.activeTooltip,
    [PaymentStatusResponse.CLOSED]: statusMessages.closedTooltip,
    [PaymentStatusResponse.INACTIVE]: statusMessages.inactiveTooltip,
    [PaymentStatusResponse.PAYOUT_BLOCKED]: statusMessages.payoutBlockedTooltip,
    [PaymentStatusResponse.SUSPENDED]: statusMessages.suspendedTooltip,
    [PaymentStatusResponse.UNREGISTERED]: statusMessages.notRegisteredTooltip,
}

export function KycStatusBadge({
    status,
    inactivationDate,
    suspensionDate,
}: {
    status: PaymentStatusResponse
    inactivationDate?: Date
    suspensionDate?: Date
}) {
    const color = colors[status]
    const icon = icons[status]
    const translation = translations[status]
    const tooltip = tooltips[status]

    const { formatMessage } = useIntl()

    const daysToInactivation =
        inactivationDate && getDaysFromToday(inactivationDate) > 0 ? getDaysFromToday(inactivationDate) : null
    const daysToSuspension =
        suspensionDate && getDaysFromToday(suspensionDate) > 0 ? getDaysFromToday(suspensionDate) : null
    const inactivationMessage = daysToInactivation
        ? formatMessage(statusMessages.inactivationDateTooltip, { daysToInactivation })
        : null
    const suspensionMessage = daysToSuspension
        ? formatMessage(statusMessages.suspensionDateTooltip, { daysToSuspension })
        : null

    return (
        <Tooltip
            content={
                <PText>
                    {formatMessage(tooltip)} {inactivationMessage || suspensionMessage}
                </PText>
            }
        >
            <PTag color={color} icon={icon}>
                {formatMessage(translation)}
            </PTag>
        </Tooltip>
    )
}
