import { PTabs } from '@porsche-design-system/components-react'
import { ReactNode } from 'react'

import { LanguageTabItem } from './LanguageTabItem'

type LanguageTabsProps = {
    languages: string[]
    children: (language: string) => ReactNode
}

export function LanguageTabs({ languages, children: renderChildren }: LanguageTabsProps) {
    return (
        <PTabs>
            {languages.map(language => (
                <LanguageTabItem key={language} language={language}>
                    {renderChildren(language)}
                </LanguageTabItem>
            ))}
        </PTabs>
    )
}
