/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Address, AddressFromJSON, AddressFromJSONTyped, AddressToJSON } from './'

/**
 *
 * @export
 * @interface ParentCompany
 */
export interface ParentCompany {
    /**
     * This value is returned on GET request and has to be provided to update an existing Parent Company. It cannot be defined when creating a new one.
     * @type {string}
     * @memberof ParentCompany
     */
    parentCompanyCode?: string
    /**
     *
     * @type {string}
     * @memberof ParentCompany
     */
    legalBusinessName: string
    /**
     *
     * @type {string}
     * @memberof ParentCompany
     */
    registrationNumber: string
    /**
     *
     * @type {string}
     * @memberof ParentCompany
     */
    stockExchange: string
    /**
     *
     * @type {string}
     * @memberof ParentCompany
     */
    stockTicker?: string
    /**
     *
     * @type {string}
     * @memberof ParentCompany
     */
    stockNumber?: string
    /**
     *
     * @type {Address}
     * @memberof ParentCompany
     */
    address: Address
}

export function ParentCompanyFromJSON(json: any): ParentCompany {
    return ParentCompanyFromJSONTyped(json, false)
}

export function ParentCompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParentCompany {
    if (json === undefined || json === null) {
        return json
    }
    return {
        parentCompanyCode: !exists(json, 'parentCompanyCode') ? undefined : json['parentCompanyCode'],
        legalBusinessName: json['legalBusinessName'],
        registrationNumber: json['registrationNumber'],
        stockExchange: json['stockExchange'],
        stockTicker: !exists(json, 'stockTicker') ? undefined : json['stockTicker'],
        stockNumber: !exists(json, 'stockNumber') ? undefined : json['stockNumber'],
        address: AddressFromJSON(json['address']),
    }
}

export function ParentCompanyToJSON(value?: ParentCompany | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        parentCompanyCode: value.parentCompanyCode,
        legalBusinessName: value.legalBusinessName,
        registrationNumber: value.registrationNumber,
        stockExchange: value.stockExchange,
        stockTicker: value.stockTicker,
        stockNumber: value.stockNumber,
        address: AddressToJSON(value.address),
    }
}
