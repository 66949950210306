import { PButton } from '@porsche-design-system/components-react'
import { useIntl } from 'react-intl'

import { documentFileUploadMessages } from '@slmpserv/common-translations'

type ButtonAddFileProps = {
    onClick: () => void
    disabled?: boolean
}

export function ButtonAddFile({ onClick, disabled }: ButtonAddFileProps) {
    const { formatMessage } = useIntl()

    return (
        <PButton onClick={onClick} icon={'plus'} type={'button'} disabled={disabled}>
            {formatMessage(documentFileUploadMessages.addNewFile)}
        </PButton>
    )
}
