import { PSelectWrapper, PText } from '@porsche-design-system/components-react'
import { spacingFluid } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'
import { FieldPath } from 'react-hook-form/dist/types/path'
import { styled } from 'styled-components'

import {
    DocumentType,
    MarketplaceDocumentConfigDocumentStatusEnum,
    SelectedOption,
} from '@slmpserv/common-api-merchant'
import {
    documentFileUploadMessages,
    documentSelectionMessages,
    LabelForDocumentType,
} from '@slmpserv/common-translations'
import { SpacedDivider } from '@slmpserv/common-components'

import { DocumentsFormState } from '../../DocumentsForm/DocumentsFormTypesAndConsts'
import { FileUpload } from '../FileUpload/FileUpload'

export const SelectedOptionDefault = 'Default'

export type DocumentSelectionDefaultOptions =
    | typeof SelectedOptionDefault
    | SelectedOption.DOCUMENT_SPECIFIED
    | SelectedOption.NO_CORRESPONDING_OFFER
    | SelectedOption.HANDLED_BY_FALLBACK

type DocumentSelectionProps = {
    documentType: DocumentType
    language: string
    dataTestId?: string
    disabled?: boolean
}

function isUploadDocument(
    documentStatus?: MarketplaceDocumentConfigDocumentStatusEnum | undefined,
    selectOption: SelectedOption = SelectedOption.NO_CORRESPONDING_OFFER
) {
    if (!documentStatus) {
        return false
    }

    return (
        documentStatus === MarketplaceDocumentConfigDocumentStatusEnum.REQUIRED ||
        (documentStatus === MarketplaceDocumentConfigDocumentStatusEnum.OPTIONAL &&
            selectOption === SelectedOption.DOCUMENT_SPECIFIED)
    )
}

const StyledDocumentSelectionGrid = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: ${spacingFluid.medium};
`

export function DocumentSelection({ dataTestId, documentType, language, disabled }: DocumentSelectionProps) {
    const { formatMessage } = useIntl()
    const { register, watch, getValues } = useFormContext<DocumentsFormState>()

    const fieldPath = `documents.${language}.decisions.${documentType}` as FieldPath<DocumentsFormState>
    const marketplaceDocumentConfig = getValues(`documentConfig`)
    const fallbackDocument = marketplaceDocumentConfig && marketplaceDocumentConfig[documentType]?.documentFallback
    const documentConfig = marketplaceDocumentConfig && marketplaceDocumentConfig[documentType]
    const selectOption = watch(fieldPath)
    const canSelectOption = documentConfig?.documentStatus === MarketplaceDocumentConfigDocumentStatusEnum.OPTIONAL
    const isDocumentStatusDisabled =
        documentConfig?.documentStatus === MarketplaceDocumentConfigDocumentStatusEnum.DISABLED
    if (isDocumentStatusDisabled) {
        return null
    }

    return (
        <>
            <SpacedDivider />
            <StyledDocumentSelectionGrid>
                <div>
                    <PText color={disabled ? 'neutral-contrast-medium' : 'neutral-contrast-high'}>
                        {formatMessage(LabelForDocumentType[documentType])}
                    </PText>
                    {documentConfig === undefined && (
                        <PText color={'notification-error'}>
                            {formatMessage(documentSelectionMessages.missingDocumentConfiguration)}
                        </PText>
                    )}
                    {canSelectOption && (
                        <PSelectWrapper>
                            <select
                                data-testid={dataTestId}
                                {...register(fieldPath, {
                                    validate: value => {
                                        const values = getValues()
                                        if (
                                            value === SelectedOption.DOCUMENT_SPECIFIED &&
                                            values.documents?.[language]?.files?.[documentType] === undefined &&
                                            values.documents?.[language]?.storedFiles?.[documentType] === undefined
                                        ) {
                                            return formatMessage(documentFileUploadMessages.youNeedToUploadAFile)
                                        }
                                        return true
                                    },
                                })}
                                defaultValue={SelectedOptionDefault}
                                disabled={disabled}
                            >
                                <option value={SelectedOptionDefault} disabled={true}>
                                    {formatMessage(documentSelectionMessages.default)}
                                </option>
                                {fallbackDocument ? (
                                    <option value={SelectedOption.HANDLED_BY_FALLBACK}>
                                        {formatMessage(documentSelectionMessages.includedInFallback, {
                                            fallbackDocument: formatMessage(LabelForDocumentType[fallbackDocument]),
                                        })}
                                    </option>
                                ) : null}
                                <option value={SelectedOption.DOCUMENT_SPECIFIED}>
                                    {formatMessage(documentSelectionMessages.uploadDocument)}
                                </option>
                                <option value={SelectedOption.NO_CORRESPONDING_OFFER}>
                                    {formatMessage(documentSelectionMessages.notRequired)}
                                </option>
                            </select>
                        </PSelectWrapper>
                    )}
                    {isUploadDocument(documentConfig?.documentStatus, selectOption as SelectedOption) ? (
                        <FileUpload documentType={documentType} disabled={disabled} language={language} />
                    ) : null}
                </div>
            </StyledDocumentSelectionGrid>
        </>
    )
}
