import { defineMessages } from 'react-intl'

export const emailFieldMessages = defineMessages({
    placeholder: {
        id: 'dealer.onlineOrderingConfig.placeholder.email',
        defaultMessage: 'Please enter your email address',
    },
    emailInvalid: {
        id: 'dealer.onlineOrderingConfig.validation.invalidMail',
        defaultMessage: 'Please enter a valid email address',
    },
    emailRequired: {
        id: 'dealer.onlineOrderingConfig.validation.emptyMail',
        defaultMessage: 'A email address is required',
    },
    addAdditionalEmail: {
        id: 'dealer.onlineOrderingConfig.addAdditionalEmail',
        defaultMessage: 'Add additional e-mail address',
    },
    deleteAdditionalEmail: {
        id: 'dealer.onlineOrderingConfig.deleteAdditionalEmail',
        defaultMessage: 'Remove email address',
    },
})
