import { useIntl } from 'react-intl'
import languages from '@cospired/i18n-iso-languages'

export function translateLanguage(languageCode: string, locale: string): string {
    let displayLanguage = locale.substring(0, 2)
    try {
        languages.registerLocale(
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            require(`@cospired/i18n-iso-languages/langs/${displayLanguage}.json`)
        )
    } catch {
        displayLanguage = 'en'
        languages.registerLocale(
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            require(`@cospired/i18n-iso-languages/langs/en.json`)
        )
    }
    return languages.getName(languageCode, displayLanguage) as string
}

export function useLanguageTranslation(languageCode: string): string {
    const { locale } = useIntl()

    return translateLanguage(languageCode, locale)
}
