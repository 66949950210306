import { createContext, Fragment, ReactNode, useContext } from 'react'

import { Merchant, MerchantResponse } from '@slmpserv/common-api-merchant'
import { useFetchMerchant } from '@slmpserv/common/api/use-query-wrapper'
import { LoadingBoundary } from '@slmpserv/common-components-legacy'

export const MerchantContext = createContext<MerchantResponse | undefined>(undefined)

interface MerchantContextProps {
    children: ReactNode
    errorFallback?: ReactNode
    ppnId: Merchant['ppnId']
}

export function MerchantContextProvider({ children, errorFallback = null, ppnId }: MerchantContextProps) {
    const {
        merchant,
        queryResult: { isLoading, isError },
    } = useFetchMerchant({
        ppnId,
    })

    if (isError) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <Fragment>{errorFallback}</Fragment>
    }

    return (
        <MerchantContext.Provider value={merchant}>
            <LoadingBoundary isLoading={isLoading}>{children}</LoadingBoundary>
        </MerchantContext.Provider>
    )
}

export function useMerchant() {
    const context = useContext(MerchantContext)

    if (context === undefined) {
        throw new Error('useMerchant must be used within a MerchantContextProvider')
    }

    return context
}
