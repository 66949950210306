import { useIntl } from 'react-intl'
import { useEffect } from 'react'

import { useCurrentDealerContext } from '@slmpserv/self-service-current-dealer-context'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { KycWidget } from '@slmpserv/common/kyc-widget'
import { BoxLayout, PageHeader, PageLayout } from '@slmpserv/common-components-legacy'
import { useMarketplaceContext, useMerchant } from '@slmpserv/common-contexts'
import { routesMessages } from '@slmpserv/common-translations'
import { QueryBoundary } from '@slmpserv/common-components'
import { useFetchKycAuthToken } from '@slmpserv/common/api/use-query-wrapper'

import { isProd } from '../utils/envResolver'

export function KycPage() {
    const { formatMessage } = useIntl()
    const { partnerNumber, locale, getAuthorizationHeader } = useCurrentDealerContext()
    const marketplace = useMarketplaceContext()
    const { id: merchantId } = useMerchant()
    useEffect(() => {
        getAuthorizationHeader().then(header => header.Authorization)
    }, [getAuthorizationHeader])

    const { queryResult: authorizationTokenQueryResult, token: kycAuthToken } = useFetchKycAuthToken(merchantId)
    const kycWidgetVisible = Boolean(kycAuthToken)

    return (
        <PageLayout
            header={
                <PageHeader
                    title={formatMessage(routesMessages.kycManagementHeadline)}
                    backButton={{
                        label: formatMessage(routesMessages.backToDashboard),
                        to: StaticRoutes.DASHBOARD,
                    }}
                />
            }
        >
            <BoxLayout>
                <QueryBoundary queryResult={authorizationTokenQueryResult}>
                    {kycWidgetVisible && (
                        <KycWidget
                            ppnId={partnerNumber}
                            oauthTokenCallback={getAuthorizationHeader}
                            country={marketplace.key}
                            locale={locale}
                            isProd={isProd()}
                        />
                    )}
                </QueryBoundary>
            </BoxLayout>
        </PageLayout>
    )
}
