import { PDivider, PHeading, PSelectWrapper, PTag, useToastManager } from '@porsche-design-system/components-react'
import { spacingFluid } from '@porsche-design-system/components-react/styles'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { MessageDescriptor, useIntl } from 'react-intl'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { Flex, FlexItem } from '@slcheckout/ui-kit'
import { styled } from 'styled-components'

import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { FormButtons } from '@slmpserv/common-components-legacy'
import { LoadingAndErrorWrapper } from '@slmpserv/self-service-shared'
import {
    Merchant,
    onlineOrderConfigurationApi,
    OnlineOrderConfigurationEmailsRequest,
} from '@slmpserv/common-api-merchant'
import { useMerchant } from '@slmpserv/common-contexts'
import { MultipleEmailsField } from '@slmpserv/common-components'
import { useSyncOnlineOrderingStatus } from '@slmpserv/common/api/use-query-wrapper'

import { onlineOrderConfigurationMessages } from '../OnlineOrderConfiguration.messages'
import { EmailFormState, UsedNewCarContact } from '../types'

import { emailConfigurationMessages } from './Email.messages'

const StyledEmailConfigurationGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: ${spacingFluid.medium};
`

const StyledDivider = styled(PDivider)`
    grid-column: 1/3;
    margin-top: ${spacingFluid.medium};
    margin-bottom: ${spacingFluid.medium};
`

const StyledMultipleEmailsFieldWrapper = styled.div`
    grid-column: 2;
`

const StyledFormButtons = styled(FormButtons)`
    grid-column: 2;
`

const StyledEmailsForStockOrder = styled(Flex)`
    grid-column: 2;
`

interface EmailConfigurationProps {
    ppnId: Merchant['ppnId']
}

interface StockOrdersEmailsProps {
    descriptor: MessageDescriptor
}

function StockOrdersEmails({ descriptor }: StockOrdersEmailsProps) {
    const { formatMessage } = useIntl()
    const { register, control, formState } = useFormContext<EmailFormState>()

    return (
        <>
            <PHeading tag="h4" size="medium">
                {formatMessage(descriptor)}
            </PHeading>
            <StyledMultipleEmailsFieldWrapper>
                <MultipleEmailsField
                    control={control}
                    register={register}
                    formState={formState}
                    fieldName={'emailsForOrderConfirmation'}
                    messages={{
                        firstEntryLabel: emailConfigurationMessages.dealerEmailLabel,
                        nthEntryLabel: emailConfigurationMessages.dealerAdditionalEmailLabel,
                    }}
                />
            </StyledMultipleEmailsFieldWrapper>
        </>
    )
}

const StyledCsvOrderEmailsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${spacingFluid.medium};
`

function CsvOrdersEmails() {
    const { formatMessage } = useIntl()
    const { register, watch, control, formState } = useFormContext<EmailFormState>()

    const emailsForStockOrderConfirmation = watch('emailsForOrderConfirmation') || []
    const showCsvEmailInput = watch('differentEmailCsvCars') === UsedNewCarContact.Different

    return (
        <>
            <PHeading tag="h4" size="medium">
                {formatMessage(emailConfigurationMessages.dealerEmailCsvTitle)}
            </PHeading>
            <StyledCsvOrderEmailsGrid>
                <PSelectWrapper>
                    <select
                        {...register('differentEmailCsvCars')}
                        defaultValue={UsedNewCarContact.Same}
                        data-testid={'csv-use-same-email-select'}
                    >
                        <option value={UsedNewCarContact.Same}>
                            {formatMessage(emailConfigurationMessages.useSameEmailsSelectOption)}
                        </option>
                        <option value={UsedNewCarContact.Different}>
                            {formatMessage(emailConfigurationMessages.useDifferentEmailsSelectOption)}
                        </option>
                    </select>
                </PSelectWrapper>
            </StyledCsvOrderEmailsGrid>
            {showCsvEmailInput ? (
                <>
                    <StyledDivider />
                    <StyledMultipleEmailsFieldWrapper>
                        <MultipleEmailsField
                            control={control}
                            register={register}
                            formState={formState}
                            fieldName={'emailsForCsvOrderConfirmation'}
                            messages={{
                                firstEntryLabel: emailConfigurationMessages.dealerEmailLabel,
                                nthEntryLabel: emailConfigurationMessages.dealerAdditionalEmailLabel,
                            }}
                        />
                    </StyledMultipleEmailsFieldWrapper>
                </>
            ) : (
                <StyledEmailsForStockOrder>
                    {emailsForStockOrderConfirmation
                        .filter(email => email.value !== '')
                        .map((email, index) => (
                            <FlexItem key={index}>
                                <PTag style={{ marginTop: spacingFluid.medium, marginLeft: spacingFluid.small }}>
                                    {email.value}
                                </PTag>
                            </FlexItem>
                        ))}
                </StyledEmailsForStockOrder>
            )}
        </>
    )
}

export function EmailConfiguration({ ppnId }: EmailConfigurationProps) {
    const { addMessage } = useToastManager()
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const methods = useForm<EmailFormState>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            emailsForOrderConfirmation: [{ value: '' }],
            emailsForCsvOrderConfirmation: [{ value: '' }],
            differentEmailCsvCars: UsedNewCarContact.Same,
        },
    })
    const { reset, handleSubmit } = methods

    const merchant = useMerchant()

    const offersCsvCars = merchant.offersCsvCars

    const { isLoading, isError, data } = useQuery({
        queryKey: ['online-order-config', ppnId],
        queryFn: () => onlineOrderConfigurationApi.getOrderConfiguration({ ppnId }),
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        if (!isLoading && !isError && data) {
            reset({
                emailsForOrderConfirmation:
                    data.emailsForOrderConfirmation.length > 0
                        ? data.emailsForOrderConfirmation.map(email => ({ value: email }))
                        : [{ value: '' }],
                emailsForCsvOrderConfirmation:
                    data.emailsForCsvOrderConfirmation && data.emailsForCsvOrderConfirmation.length > 0
                        ? data.emailsForCsvOrderConfirmation.map(email => ({ value: email }))
                        : [{ value: '' }],
                differentEmailCsvCars: data.differentEmailCsvCars
                    ? UsedNewCarContact.Different
                    : UsedNewCarContact.Same,
            })
        }
    }, [data, isError, isLoading, reset])

    const { mutateAsync: updateEmails, isPending: isSaving } = useMutation({
        mutationFn: (data: EmailFormState) => {
            const emailsForOrderConfirmation = data.emailsForOrderConfirmation.map(({ value }) => value)
            const emailsForCsvOrderConfirmation =
                offersCsvCars && data.differentEmailCsvCars === UsedNewCarContact.Different
                    ? data.emailsForCsvOrderConfirmation.map(({ value }) => value)
                    : undefined
            const differentEmailCsvCars = offersCsvCars
                ? data.differentEmailCsvCars === UsedNewCarContact.Different
                : false

            const requestData: OnlineOrderConfigurationEmailsRequest = emailsForCsvOrderConfirmation
                ? {
                      emailsForOrderConfirmation: emailsForOrderConfirmation,
                      emailsForCsvOrderConfirmation: emailsForCsvOrderConfirmation,
                      differentEmailCsvCars: differentEmailCsvCars,
                  }
                : {
                      emailsForOrderConfirmation: emailsForOrderConfirmation,
                      differentEmailCsvCars: false,
                  }
            return onlineOrderConfigurationApi.updateOrderConfigurationEmails({
                ppnId,
                onlineOrderConfigurationEmailsRequest: requestData,
            })
        },
    })

    const syncOnlineOrderingStatus = useSyncOnlineOrderingStatus(merchant.id)

    const submitCallback = async (data: EmailFormState) => {
        try {
            await updateEmails(data)
            await syncOnlineOrderingStatus()
            addMessage({ state: 'success', text: formatMessage(onlineOrderConfigurationMessages.toastSubmitSuccess) })
        } catch (err) {
            console.error(err)
            addMessage({ state: 'neutral', text: formatMessage(onlineOrderConfigurationMessages.toastSubmitError) })
        }
    }

    const handleCancel = () => {
        navigate(StaticRoutes.DASHBOARD)
    }

    return (
        <LoadingAndErrorWrapper isLoading={isLoading} isError={isError}>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(submitCallback)}>
                    <StyledEmailConfigurationGrid>
                        <StockOrdersEmails
                            descriptor={
                                offersCsvCars
                                    ? emailConfigurationMessages.dealerEmailStockTitle
                                    : emailConfigurationMessages.dealerEmailLabel
                            }
                        />
                        {offersCsvCars && (
                            <>
                                <StyledDivider />

                                <CsvOrdersEmails />
                            </>
                        )}
                        <StyledFormButtons isLoading={isSaving} onCancel={handleCancel} />
                    </StyledEmailConfigurationGrid>
                </form>
            </FormProvider>
        </LoadingAndErrorWrapper>
    )
}
