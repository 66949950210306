import { defineMessages } from 'react-intl'

export const statusMessages = defineMessages({
    active: {
        id: 'dealer.kyc.status.active.label',
        defaultMessage: 'Active',
    },
    inactive: {
        id: 'dealer.kyc.status.complete.label',
        defaultMessage: 'Inactive',
    },
    notRegistered: {
        id: 'dealer.kyc.status.notRegistered.label',
        defaultMessage: 'Not registered',
    },
    payoutBlocked: {
        id: 'dealer.kyc.status.payoutBlocked.label',
        defaultMessage: 'Payouts blocked',
    },
    suspended: {
        id: 'dealer.kyc.status.suspended.label',
        defaultMessage: 'Suspended',
    },
    closed: {
        id: 'dealer.kyc.status.closed.label',
        defaultMessage: 'Closed',
    },
    activeTooltip: {
        id: 'dealer.kyc.status.active.tooltip',
        defaultMessage: 'The account is active and reservation fee payouts are enabled. No actions required.',
    },
    inactiveTooltip: {
        id: 'dealer.kyc.status.complete.tooltip',
        defaultMessage: 'Account is inactive.',
    },
    notRegisteredTooltip: {
        id: 'dealer.kyc.status.notRegistered.tooltip',
        defaultMessage:
            'The dealership is not registered as a marketplace seller at Adyen. Please conduct the Payment Services Onboarding to create an account.',
    },
    payoutBlockedTooltip: {
        id: 'dealer.kyc.status.payoutBlocked.tooltip',
        defaultMessage: 'The payouts are blocked.',
    },
    suspendedTooltip: {
        id: 'dealer.kyc.status.suspended.tooltip',
        defaultMessage: 'The account is suspended.',
    },
    closedTooltip: {
        id: 'dealer.kyc.status.closed.tooltip',
        defaultMessage: 'The account is closed.',
    },
    suspensionDateTooltip: {
        id: 'dealer.kyc.status.suspensionDate.tooltip',
        defaultMessage: 'This account will be suspended in {daysToSuspension} days.',
    },
    inactivationDateTooltip: {
        id: 'dealer.kyc.status.inactivationDate.tooltip',
        defaultMessage: 'This account will be deactivated in {daysToInactivation} days.',
    },
})
