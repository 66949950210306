import { DealerContextProps } from '@slfinrtl/context'
import { createContext, Fragment, ReactNode, useContext } from 'react'

interface FinderDealerCurrentDealerContextProps {
    children: ReactNode
    dealerContext: DealerContextProps
}

export type CurrentDealerContextValue = DealerContextProps

const CurrentDealerContext = createContext<CurrentDealerContextValue | undefined>(undefined)

export function CurrentDealerContextProvider({ children, dealerContext }: FinderDealerCurrentDealerContextProps) {
    return (
        <CurrentDealerContext.Provider value={{ ...dealerContext }}>
            {dealerContext ? (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <Fragment>{children}</Fragment>
            ) : null}
        </CurrentDealerContext.Provider>
    )
}

export function useCurrentDealerContext() {
    const context = useContext(CurrentDealerContext)

    if (context === undefined) {
        throw new Error('useCurrentDealerContext must be used within a CurrentDealerContextProvider')
    }

    return context
}
