import { defineMessages, MessageDescriptor } from 'react-intl'

import { MerchantStatusErrors, OnlineOrderingStatusDto, PaymentStatusResponse } from '@slmpserv/common-api-merchant'

import { getDaysFromToday } from '../shared/getDaysFromToday'

export const dashboardTitles = defineMessages({
    dealershipInformationGroup: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.group.title',
        defaultMessage: 'Dealership Contact Information',
    },
    inquiriesViaMail: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.inquiriesViaMail.title',
        defaultMessage: 'Inquiries by E-Mail',
    },
    inquiriesViaPhone: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.inquiriesViaPhone.title',
        defaultMessage: 'Inquiries by Phone',
    },
    purchaseRequestNotifications: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.purchaseRequestNotifications.title',
        defaultMessage: 'Purchase Request Notifications by E-Mail',
    },
    homepage: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.homepage.title',
        defaultMessage: 'Homepage',
    },
    onlineOrderIntake: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.onlineOrderIntake.title',
        defaultMessage: 'Online Order Intake',
    },
    onlineOrderingGroup: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.group.title',
        defaultMessage: 'Online order & payment settings',
    },
    reservationFee: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.reservationFee.title',
        defaultMessage: 'Reservation Fee',
    },
    paymentProcessing: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.paymentProcessing.title',
        defaultMessage: 'Payment Processing Services (Adyen)',
    },
    directDebitMandate: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.directDebitMandate.title',
        defaultMessage: 'Direct Debit Mandate (Adyen)',
    },
    paymentProcessingStatusNotifications: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.paymentProcessingStatusNotifications.title',
        defaultMessage: 'Status Notifications from Payment Processing Service',
    },
    payoutNotifications: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.payoutNotifications.title',
        defaultMessage: 'Payout Notifications',
    },
    documentGroup: {
        id: 'merchant.selfservice.dashboard.documents.group.title',
        defaultMessage: 'Dealership Legal Documents',
    },
    tnc: {
        id: 'merchant.selfservice.dashboard.documents.tnc.title',
        defaultMessage: 'Terms & Conditions',
    },
    reservationFlow: {
        id: 'merchant.selfservice.dashboard.documents.reservation.title',
        defaultMessage: 'Reservation Documents (T&C and Privacy Policy)',
    },
    privacyPolicy: {
        id: 'merchant.selfservice.dashboard.documents.privacyPolicy.title',
        defaultMessage: 'Privacy Policy',
    },
    imprint: {
        id: 'merchant.selfservice.dashboard.documents.imprint.title',
        defaultMessage: 'Imprint',
    },
})

export const dashboardPreviewMessages = defineMessages({
    emailContactUsedCar: {
        id: 'merchant.selfservice.dashboard.dealershipContactInfo.emailAdressesUsedCar',
        defaultMessage: 'E-Mail address(es) for used vehicle inquiries',
    },
    emailContactNewCar: {
        id: 'merchant.selfservice.dashboard.dealershipContactInfo.emailAdressesNewCar',
        defaultMessage: 'E-Mail address(es) for new vehicle inquiries',
    },
    phoneNumberUsedCar: {
        id: 'merchant.selfservice.dashboard.dealershipContactInfo.phoneNumbersUsedCar',
        defaultMessage: 'Phone number for used vehicle inquiries',
    },
    phoneNumberNewCar: {
        id: 'merchant.selfservice.dashboard.dealershipContactInfo.phoneNumbersNewCar',
        defaultMessage: 'Phone number for new vehicle inquiries',
    },
    amount: {
        id: 'merchant.selfservice.dashboard.amount',
        defaultMessage: 'Amount',
    },
    status: {
        id: 'merchant.selfservice.dashboard.status',
        defaultMessage: 'Status',
    },
    contactEmailAddresses: {
        id: 'merchant.selfservice.dashboard.contactEmailAddresses',
        defaultMessage: 'Contact E-Mail address(es)',
    },
    contactEmailAddressesForStock: {
        id: 'merchant.selfservice.dashboard.contactEmailAddressesForStock',
        defaultMessage: 'Contact E-Mail address(es) for STOCK vehicles',
    },
    contactEmailAddressesForCsv: {
        id: 'merchant.selfservice.dashboard.contactEmailAddressesForCsv',
        defaultMessage: 'Contact E-Mail address(es) for CSV vehicles',
    },
    homepageUrlsDeprecatedTitle: {
        id: 'merchant.selfservice.dashboard.homepageUrls.deprecated.title',
        defaultMessage: 'This information is no longer maintained here.',
    },
    homepageUrlsDeprecatedText: {
        id: 'merchant.selfservice.dashboard.homepageUrls.deprecated.text',
        defaultMessage: 'From now on, the Finder will use the dealer website you have entered in PPN.',
    },
    undefinedMessage: {
        id: 'merchant.selfservice.dashboard.undefined',
        defaultMessage: 'undefined',
    },
    paymentServicePayoutEnabled: {
        id: 'merchant.selfservice.dashboard.paymentService.payoutsEnabled',
        defaultMessage: 'Payouts enabled',
    },
})

export const dashboardPopoverMessages = defineMessages({
    inquiriesViaMail: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.inquiriesViaMail.popover',
        defaultMessage: 'E-Mail address(es) to receive online inquires (via contact form)',
    },
    inquiriesViaPhone: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.inquiriesViaPhone.popover',
        defaultMessage: 'Phone number which customers can contact in case of an inquiry',
    },
    onlineOrderIntake: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.onlineOrderIntake.popover',
        defaultMessage: 'E-Mail address to receive notifications about incoming online orders',
    },
    purchaseRequestNotifications: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.purchaseRequestNotifications.popover',
        defaultMessage:
            'E-Mail address(es) for notification of purchase enquiry activities: I.e. incoming purchase requests and accepted offers by customers.',
    },
    purchaseRequestNotificationsOptional: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.purchaseRequestNotificationsOptional.popover',
        defaultMessage:
            'If you want to be informed about new purchase requests by email, you must enter at least one email address.',
    },
    purchaseRequestNotificationsSwitchedOff: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.purchaseRequestNotificationsSwitchedOff.popover',
        defaultMessage: 'No notifications are sent by email. You can change this setting in edit mode.',
    },
    reservationFee: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.reservationFee.popover',
        defaultMessage:
            "Customer reservation fee (online payment) for Online Car Sales checkout. Note: please ensure that the same amount is reflected in your Porsche Center's Online Car Sales T&C documents.",
    },
    paymentProcessing: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.paymentProcessing.popover',
        defaultMessage:
            "In order to offer vehicles for Online Car Sales and to receive online payments (reservation fees) for customer orders, every participating dealership must register with Porsche's payment service provider Adyen. This process is called 'Know Your Customer' (KYC). Please note that reservation fees for online orders will only be paid out to your organization if your account has passed all verification checks. Please note that access is limited. Get in touch with your market contact in order to find out who has access.",
    },
    directDebitMandate: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.directDebitMandate.popover',
        defaultMessage: '',
    },
    paymentProcessingStatusNotifications: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.paymentProcessingStatusNotifications.popover',
        defaultMessage:
            'E-mail address(es) to receive notifications regarding the status of your Adyen account. Please update your data in the KYC process if you receive notification that the verification checks for your account have failed.',
    },
    payoutNotifications: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.payoutNotifications.popover',
        defaultMessage:
            "Receive notifications when reservation fees for online orders have been paid out to your organization. Note: It is recommended to include your accounting / finance department's e-mail address.",
    },
    tnc: {
        id: 'merchant.selfservice.dashboard.documents.tnc.popover',
        defaultMessage:
            "Your organization's Terms & Conditions documents for Online Car Sales. The documents will be available for customers in the Online Car Sales checkout. Please note that your organization is responsible for the correctness and legal compliance of the content.",
    },
    reservationFlow: {
        id: 'merchant.selfservice.dashboard.documents.reservation.popover',
        defaultMessage:
            "Your organization's Reservation documents. The documents will be available for customers when reserving a vehicle in Online Car Sales. Please note that your organization is responsible for the correctness and legal compliance of the content.",
    },
    privacyPolicy: {
        id: 'merchant.selfservice.dashboard.documents.privacyPolicy.popover',
        defaultMessage:
            "Your organization's Privacy Policy document for Online Car Sales. The document will be available for customers in the Online Car Sales checkout. Please note that your organization is responsible for the correctness and legal compliance of the content.",
    },
    imprint: {
        id: 'merchant.selfservice.dashboard.documents.imprint.popover',
        defaultMessage:
            "Your organization's imprint for Online Car Sales. Please note that your organization is responsible for the correctness and legal compliance of the content.",
    },
    homepage: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.homepage.popover',
        defaultMessage: 'URL of your dealer website to create a link to your Porsche showroom',
    },
})

export const dashboardStatusMessages = defineMessages({
    dataComplete: {
        id: 'merchant.selfservice.dashboard.dataComplete.success',
        defaultMessage: 'Data Complete',
    },
    dataIncomplete: {
        id: 'merchant.selfservice.dashboard.dataIncomplete.warning',
        defaultMessage: 'Data Incomplete',
    },
    addContact: {
        id: 'merchant.selfservice.dashboard.addContact.warning',
        defaultMessage: 'Add Contact',
    },
    addHomepage: {
        id: 'merchant.selfservice.dashboard.addHomepage.warning',
        defaultMessage: 'Add Homepage',
    },
    missingReservationFee: {
        id: 'merchant.selfservice.dashboard.defineFee.warning',
        defaultMessage: 'Define Fee',
    },
    paymentProcessingNoAccount: {
        id: 'merchant.selfservice.dashboard.paymentProcessingNoAccount.warning',
        defaultMessage: 'Complete Onboarding',
    },
    paymentProcessingAccountInactive: {
        id: 'merchant.selfservice.dashboard.paymentProcessingAccountInactive.warning',
        defaultMessage: 'Account inactive',
    },
    paymentProcessingAccountSuspended: {
        id: 'merchant.selfservice.dashboard.paymentProcessingAccountSuspended.warning',
        defaultMessage: 'Account suspended',
    },
    paymentProcessingAccountClosed: {
        id: 'merchant.selfservice.dashboard.paymentProcessingAccountClosed.warning',
        defaultMessage: 'Account closed',
    },
    paymentProcessingPayoutBlocked: {
        id: 'merchant.selfservice.dashboard.paymentProcessingPayoutBlocked.warning',
        defaultMessage: 'Payouts blocked',
    },
    purchaseRequestNotificationsSwitchedOff: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.purchaseRequestNotificationsSwitchedOff.warning',
        defaultMessage: 'Notifications are switched off',
    },
    uploadDocument: {
        id: 'merchant.selfservice.dashboard.uploadDocument.warning',
        defaultMessage: 'Upload document',
    },
    editImprint: {
        id: 'merchant.selfservice.dashboard.imprint.warning',
        defaultMessage: 'Edit Imprint',
    },
    optional: {
        id: 'merchant.selfservice.dashboard.optional.warning',
        defaultMessage: 'Optional',
    },
    writeAccessIsRestricted: {
        id: 'merchant.selfservice.dashboard.write.access.is.restricted',
        defaultMessage: 'The write access is restricted',
    },
})

export const dashboardStatusMessageTooltip = defineMessages({
    inquiriesViaMail: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.inquiriesViaMail.addContact.tooltip',
        defaultMessage: 'Please add at least one e-mail address to receive online inquires (via contact form).',
    },
    inquiriesViaPhone: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.inquiriesViaPhone.addContact.tooltip',
        defaultMessage: 'Please add a phone number for customer inquiries.',
    },
    homepage: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.addHomepage.tooltip',
        defaultMessage: 'Please add a dealer homepage.',
    },
    onlineOrderIntake: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.onlineOrderIntake.addContact.tooltip',
        defaultMessage: 'Please add at least one e-mail address to receive notifications about incoming online orders.',
    },
    reservationFee: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.reservationFee.defineFee.tooltip',
        defaultMessage: 'The reservation fee amount needs to match the amount stated in the Terms & Conditions.',
    },
    paymentProcessingNoAccount: {
        id: 'merchant.selfservice.dashboard.paymentProcessingNoAccount.warning.tooltip',
        defaultMessage:
            'No account is registered for your dealership. Please complete the onboarding (KYC - Know Your Customer) in order to use the Payment Processing Service (Adyen).',
    },
    paymentProcessingAccountInactive: {
        id: 'merchant.selfservice.dashboard.paymentProcessingAccountInactive.warning.tooltip',
        defaultMessage:
            "Your organization's account is inactive. The online order functionalities have been disabled for your organization (vehicles are still visible on Porsche Finder, but not online orderable). Payouts are currently disabled. Please start the Payment Service Onboarding and update the requested data in order to reactivate the account. Any accrued reservation fee will be paid out afterwards. Please note that if the account is not reactivated within 42 days after the inactivation, the account will be suspended and accrued reservation fees will be refunded to customers.",
    },
    paymentProcessingAccountSuspended: {
        id: 'merchant.selfservice.dashboard.paymentProcessingAccountSuspended.warning.tooltip',
        defaultMessage:
            "Your organization's account is suspended. All payments that have not been paid out to your bank account will be refunded to customers. The online order functionalities have been disabled for your organization (vehicles are still visible on Porsche Finder, but not online orderable). Please start the Payment Service Onboarding and update the requested data in order to reactivate the account.",
    },
    paymentProcessingAccountClosed: {
        id: 'merchant.selfservice.dashboard.paymentProcessingAccountClosed.warning.tooltip',
        defaultMessage:
            "Your organization's account is closed. All payments that have not been paid out to your bank account will be refunded to customers. The online order functionalities have been disabled for your organization (vehicles are still visible on Porsche Finder, but not online orderable). Please start the Payment Service Onboarding.",
    },
    paymentProcessingPayoutBlocked: {
        id: 'merchant.selfservice.dashboard.paymentProcessingPayoutBlocked.warning.tooltip',
        defaultMessage:
            'Payouts for reservation fees to your organization are currently disabled (online reservation fees will not be paid out to your bank account). Please start the Payment Service Onboarding and update the requested data in order to reactivate payouts. Any accrued reservation fee will be paid out afterwards.',
    },
    paymentProcessingStatusNotifications: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.paymentProcessingStatusNotifications.addContact.tooltip',
        defaultMessage:
            'Please add at least one e-mail address to receive notifications regarding the status of your Payment Service Onboarding (Adyen account).',
    },
    tnc: {
        id: 'merchant.selfservice.dashboard.uploadTnc.warning.tooltip',
        defaultMessage: 'Please upload all legally required Terms & Conditions. Please note the document standards.',
    },
    reservationFlow: {
        id: 'merchant.selfservice.dashboard.uploadReservationDocuments.warning.tooltip',
        defaultMessage: 'Please upload all legally required Reservation Documents. Please note the document standards.',
    },
    privacyPolicy: {
        id: 'merchant.selfservice.dashboard.uploadPrivacyPolicy.privacyPolicy.warning.tooltip',
        defaultMessage: 'Please upload the legally required Privacy Policy. Please note the document standards.',
    },
    uploadImprint: {
        id: 'merchant.selfservice.dashboard.uploadImprint.warning.tooltip',
        defaultMessage: 'Please add the legally required imprint text in the text editor.',
    },
})

const dashboardNotificationTitles = defineMessages({
    inquiriesViaMail: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.inquiriesViaMail.notification.title',
        defaultMessage: 'E-Mail address for inquiries is required',
    },
    inquiriesViaPhone: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.inquiriesViaPhone.notification.title',
        defaultMessage: 'Phone number for inquiries is required',
    },
    onlineOrderIntake: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.onlineOrderIntake.notification.title',
        defaultMessage: 'E-Mail address for Online Order Intake is required',
    },
    reservationFee: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.reservationFee.notification.title',
        defaultMessage: 'A reservation fee amount is required',
    },
    paymentProcessingInactive: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.paymentProcessingInactive.notification.title',
        defaultMessage: 'The account is inactive',
    },
    paymentProcessingNoAccount: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.paymentProcessingNoAccount.notification.title',
        defaultMessage: 'The Payment Processing Service onboarding needs to be completed',
    },
    paymentProcessingAccountSuspended: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.paymentProcessingAccountSuspended.notification.title',
        defaultMessage: 'Account suspended',
    },
    paymentProcessingAccountClosed: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.paymentProcessingAccountClosed.notification.title',
        defaultMessage: 'Account closed',
    },
    paymentProcessingPayoutsBlocked: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.paymentProcessingPayoutsBlocked.notification.title',
        defaultMessage: 'Payouts blocked',
    },
    paymentProcessingStatusNotifications: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.paymentProcessingStatusNotifications.notification.title',
        defaultMessage: 'E-Mail address for Status Notifications from Payment Processing Service is required',
    },
    tnc: {
        id: 'merchant.selfservice.dashboard.documents.tnc.notification.title',
        defaultMessage: 'Terms & Conditions documents are required',
    },
    reservationFlow: {
        id: 'merchant.selfservice.dashboard.documents.reservation.notification.title',
        defaultMessage: 'Reservation Documents are required',
    },
    privacyPolicy: {
        id: 'merchant.selfservice.dashboard.documents.privacyPolicy.notification.title',
        defaultMessage: 'Privacy Policy document is required',
    },
    imprint: {
        id: 'merchant.selfservice.dashboard.documents.imprint.notification.title',
        defaultMessage: 'Imprint text is required',
    },
    homepage: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.homepage.notification.title',
        defaultMessage: 'homepage is required',
    },
})

const dashboardNotificationText = defineMessages({
    inquiriesViaMail: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.inquiriesViaMail.notification.text',
        defaultMessage: 'Please add at least one e-mail address to receive online inquires (via contact form).',
    },
    inquiriesViaPhone: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.inquiriesViaPhone.notification.text',
        defaultMessage: 'Please add a phone number for customer inquiries.',
    },
    onlineOrderIntake: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.onlineOrderIntake.notification.text',
        defaultMessage: 'Please add at least one e-mail address to receive notifications about incoming online orders.',
    },
    reservationFee: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.reservationFee.notification.text',
        defaultMessage: 'The reservation fee amount needs to match the amount stated in the Terms & Conditions.',
    },
    paymentProcessingInactive: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.paymentProcessingInactive.notification.text',
        defaultMessage:
            "Your organization's account is inactive. The online order functionalities have been disabled for your organization (vehicles are still visible on Porsche Finder, but not online orderable). Payouts are currently disabled. Please start the Payment Service Onboarding and update the requested data in order to reactivate the account. Any accrued reservation fee will be paid out afterwards.",
    },
    paymentProcessingNoAccount: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.paymentProcessingNoAccount.notification.text',
        defaultMessage:
            'No account is registered for your organization. Please complete the onboarding (KYC - Know Your Customer) in order to use the Payment Processing Service (Adyen).',
    },
    paymentProcessingSuspended: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.paymentProcessingSuspended.notification.text',
        defaultMessage:
            "Your organization's account is suspended. All payments that have not been paid out to your bank account will be refunded to customers. The online order functionalities have been disabled for your organization (vehicles are still visible on Porsche Finder, but not online orderable). Please start the Payment Service Onboarding and update the requested data in order to reactivate the account.",
    },
    paymentProcessingAccountClosed: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.paymentProcessingAccountClosed.notification.text',
        defaultMessage:
            "Your organization's account is closed. All payments that have not been paid out to your bank account will be refunded to customers. The online order functionalities have been disabled for your organization (vehicles are still visible on Porsche Finder, but not online orderable). Please start the Payment Service Onboarding.",
    },
    paymentProcessingPayoutsBlocked: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.paymentProcessingPayoutsBlocked.notification.text',
        defaultMessage:
            'Payouts for reservation fees to your organization are currently disabled (online reservation fees will not be paid out to your bank account). Please start the Payment Service Onboarding and update the requested data in order to reactivate payouts. Any accrued reservation fee will be paid out afterwards.',
    },
    paymentProcessingStatusNotifications: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.paymentProcessingStatusNotifications.notification.text',
        defaultMessage:
            'Please add at least one e-mail address to receive notifications regarding the status of your Adyen account.',
    },
    tnc: {
        id: 'merchant.selfservice.dashboard.documents.tnc.notification.text',
        defaultMessage: 'Please upload the required Terms & Conditions documents',
    },
    privacyPolicy: {
        id: 'merchant.selfservice.dashboard.documents.privacyPolicy.notification.text',
        defaultMessage: 'Please upload the required Privacy Policy document',
    },
    reservationFlow: {
        id: 'merchant.selfservice.dashboard.documents.reservation.notification.text',
        defaultMessage: 'Please upload the required documents for reservation',
    },
    imprint: {
        id: 'merchant.selfservice.dashboard.documents.imprint.notification.text',
        defaultMessage: 'Please provide an Imprint text',
    },
    homepage: {
        id: 'merchant.selfservice.dashboard.dealershipInformation.homepage.notification.text',
        defaultMessage: 'Please provide a homepage',
    },
})

export const dashboardErrorMessages = defineMessages({
    sellerDealershipContactEmailAddressMissing: {
        id: 'merchant.selfservice.dashboard.seller.sellerDealershipContact.emailAddressMissing.error',
        defaultMessage: 'Error fetching email address(es).',
    },
    sellerDealershipContactPhoneNumberMissing: {
        id: 'merchant.selfservice.dashboard.seller.sellerDealershipContact.phoneNumberMissing.error',
        defaultMessage: 'Error fetching phone number(s).',
    },
    onlineOrderGroupError: {
        id: 'merchant.selfservice.dashboard.onlineOrderGroupInfo.error',
        defaultMessage: 'Error fetching online order group information.',
    },
    purchaseRequestConfigError: {
        id: 'merchant.selfservice.dashboard.purchaseRequestConfig.error',
        defaultMessage: 'Error fetching purchase request config.',
    },
})

export const dashboardPaymentDeadlineDateToMessages = defineMessages({
    paymentProcessingInactiveRemainingDaysToSuspension: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.paymentProcessingInactiveRemainingDaysToSuspension',
        defaultMessage: 'The account will be suspended in {days} days.',
    },
    paymentProcessingPayoutsBlockedDaysToInactivation: {
        id: 'merchant.selfservice.dashboard.onlineOrdering.paymentProcessingPayoutsBlockedDaysToInactivation',
        defaultMessage: 'The account will be inactivated in {days} days.',
    },
})

export function dashboardPaymentDeadlineDateResolver(onlineOrderingStatus: OnlineOrderingStatusDto): {
    descriptor: MessageDescriptor | undefined
    days: number | null
} {
    const paymentStatus = onlineOrderingStatus?.paymentStatus

    const inactivationDate = onlineOrderingStatus?.inactivationDate
    const suspensionDate = onlineOrderingStatus?.suspensionDate

    const daysToInactivation =
        inactivationDate && getDaysFromToday(inactivationDate) > 0 ? getDaysFromToday(inactivationDate) : null
    const daysToSuspension =
        suspensionDate && getDaysFromToday(suspensionDate) > 0 ? getDaysFromToday(suspensionDate) : null

    if (paymentStatus == PaymentStatusResponse.PAYOUT_BLOCKED && daysToInactivation) {
        return {
            descriptor: dashboardPaymentDeadlineDateToMessages.paymentProcessingPayoutsBlockedDaysToInactivation,
            days: daysToInactivation,
        }
    } else if (paymentStatus == PaymentStatusResponse.INACTIVE && daysToSuspension) {
        return {
            descriptor: dashboardPaymentDeadlineDateToMessages.paymentProcessingInactiveRemainingDaysToSuspension,
            days: daysToSuspension,
        }
    }

    return { descriptor: undefined, days: null }
}

export const dashboardNotificationByErrorPaymentStatus: Record<
    Exclude<PaymentStatusResponse, PaymentStatusResponse.ACTIVE>,
    { title: MessageDescriptor; text: MessageDescriptor; link: MessageDescriptor }
> = {
    PAYOUT_BLOCKED: {
        title: dashboardNotificationTitles.paymentProcessingPayoutsBlocked,
        text: dashboardNotificationText.paymentProcessingPayoutsBlocked,
        link: dashboardTitles.paymentProcessing,
    },
    INACTIVE: {
        title: dashboardNotificationTitles.paymentProcessingInactive,
        text: dashboardNotificationText.paymentProcessingInactive,
        link: dashboardTitles.paymentProcessing,
    },
    SUSPENDED: {
        title: dashboardNotificationTitles.paymentProcessingAccountSuspended,
        text: dashboardNotificationText.paymentProcessingSuspended,
        link: dashboardTitles.paymentProcessing,
    },
    CLOSED: {
        title: dashboardNotificationTitles.paymentProcessingAccountClosed,
        text: dashboardNotificationText.paymentProcessingAccountClosed,
        link: dashboardTitles.paymentProcessing,
    },
    UNREGISTERED: {
        title: dashboardNotificationTitles.paymentProcessingNoAccount,
        text: dashboardNotificationText.paymentProcessingNoAccount,
        link: dashboardTitles.paymentProcessing,
    },
}

export const dashboardNotificationByError: Record<
    | Exclude<MerchantStatusErrors, MerchantStatusErrors.PAYMENT_SERVICE> // Payment service is handled in dashboardNotificationByErrorPaymentStatus
    | 'dealershipContactIncomplete'
    | 'legalDocumentsIncomplete'
    | 'onlineOrderAndPaymentIncomplete'
    | 'legalReservationDocumentsIncomplete'
    | 'paymentProcessingStatusNotificationIncomplete',
    { title: MessageDescriptor; text: MessageDescriptor; link: MessageDescriptor }
> = {
    dealershipContactIncomplete: {
        title: dashboardStatusMessages.dataIncomplete,
        text: dashboardStatusMessages.dataIncomplete,
        link: dashboardTitles.dealershipInformationGroup,
    },
    legalDocumentsIncomplete: {
        title: dashboardStatusMessages.dataIncomplete,
        text: dashboardStatusMessages.dataIncomplete,
        link: dashboardTitles.documentGroup,
    },
    legalReservationDocumentsIncomplete: {
        title: dashboardStatusMessages.dataIncomplete,
        text: dashboardStatusMessages.dataIncomplete,
        link: dashboardTitles.documentGroup,
    },
    onlineOrderAndPaymentIncomplete: {
        title: dashboardStatusMessages.dataIncomplete,
        text: dashboardStatusMessages.dataIncomplete,
        link: dashboardTitles.onlineOrderingGroup,
    },
    paymentProcessingStatusNotificationIncomplete: {
        title: dashboardNotificationTitles.paymentProcessingStatusNotifications,
        text: dashboardNotificationText.paymentProcessingStatusNotifications,
        link: dashboardTitles.paymentProcessingStatusNotifications,
    },
    [MerchantStatusErrors.INQUIRES_EMAIL]: {
        title: dashboardNotificationTitles.inquiriesViaMail,
        text: dashboardNotificationText.inquiriesViaMail,
        link: dashboardTitles.inquiriesViaMail,
    },
    [MerchantStatusErrors.INQUIRES_PHONE]: {
        title: dashboardNotificationTitles.inquiriesViaPhone,
        text: dashboardNotificationText.inquiriesViaPhone,
        link: dashboardTitles.inquiriesViaPhone,
    },
    [MerchantStatusErrors.ONLINE_ORDER_EMAIL]: {
        title: dashboardNotificationTitles.onlineOrderIntake,
        text: dashboardNotificationText.onlineOrderIntake,
        link: dashboardTitles.onlineOrderIntake,
    },
    [MerchantStatusErrors.RESERVATION_FEE]: {
        title: dashboardNotificationTitles.reservationFee,
        text: dashboardNotificationText.reservationFee,
        link: dashboardTitles.reservationFee,
    },
    [MerchantStatusErrors.TNC]: {
        title: dashboardNotificationTitles.tnc,
        text: dashboardNotificationText.tnc,
        link: dashboardTitles.tnc,
    },
    [MerchantStatusErrors.PRIVACY_POLICY]: {
        title: dashboardNotificationTitles.privacyPolicy,
        text: dashboardNotificationText.privacyPolicy,
        link: dashboardTitles.privacyPolicy,
    },
    [MerchantStatusErrors.IMPRINT]: {
        title: dashboardNotificationTitles.imprint,
        text: dashboardNotificationText.imprint,
        link: dashboardTitles.imprint,
    },
    [MerchantStatusErrors.RESERVATION]: {
        title: dashboardNotificationTitles.reservationFlow,
        text: dashboardNotificationText.reservationFlow,
        link: dashboardTitles.reservationFlow,
    },
}
