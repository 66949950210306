/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { MerchantDocumentConfigResponse } from './MerchantDocumentConfigResponse'
import {
    MerchantDocumentConfigResponseFromJSON,
    MerchantDocumentConfigResponseFromJSONTyped,
    MerchantDocumentConfigResponseToJSON,
} from './MerchantDocumentConfigResponse'

/**
 *
 * @export
 * @interface MerchantTermsAndConditionsResponse
 */
export interface MerchantTermsAndConditionsResponse {
    /**
     *
     * @type {boolean}
     * @memberof MerchantTermsAndConditionsResponse
     */
    offersNewCars: boolean
    /**
     *
     * @type {boolean}
     * @memberof MerchantTermsAndConditionsResponse
     */
    offersCsvCars: boolean
    /**
     *
     * @type {boolean}
     * @memberof MerchantTermsAndConditionsResponse
     */
    reservationEnabled: boolean
    /**
     *
     * @type {Array<MerchantDocumentConfigResponse>}
     * @memberof MerchantTermsAndConditionsResponse
     */
    documentConfigurations: Array<MerchantDocumentConfigResponse>
}

/**
 * Check if a given object implements the MerchantTermsAndConditionsResponse interface.
 */
export function instanceOfMerchantTermsAndConditionsResponse(value: object): boolean {
    if (!('offersNewCars' in value)) return false
    if (!('offersCsvCars' in value)) return false
    if (!('reservationEnabled' in value)) return false
    if (!('documentConfigurations' in value)) return false
    return true
}

export function MerchantTermsAndConditionsResponseFromJSON(json: any): MerchantTermsAndConditionsResponse {
    return MerchantTermsAndConditionsResponseFromJSONTyped(json, false)
}

export function MerchantTermsAndConditionsResponseFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): MerchantTermsAndConditionsResponse {
    if (json == null) {
        return json
    }
    return {
        offersNewCars: json['offersNewCars'],
        offersCsvCars: json['offersCsvCars'],
        reservationEnabled: json['reservationEnabled'],
        documentConfigurations: (json['documentConfigurations'] as Array<any>).map(
            MerchantDocumentConfigResponseFromJSON
        ),
    }
}

export function MerchantTermsAndConditionsResponseToJSON(value?: MerchantTermsAndConditionsResponse | null): any {
    if (value == null) {
        return value
    }
    return {
        offersNewCars: value['offersNewCars'],
        offersCsvCars: value['offersCsvCars'],
        reservationEnabled: value['reservationEnabled'],
        documentConfigurations: (value['documentConfigurations'] as Array<any>).map(
            MerchantDocumentConfigResponseToJSON
        ),
    }
}
