import { defineMessages } from 'react-intl'

export const imprintMessages = defineMessages({
    imprintHeader: {
        id: 'merchant.form.imprintSection.header',
        defaultMessage: 'Imprint',
    },
    sectionLabel: {
        id: 'merchant.form.imprintSection.sectionLabel',
        defaultMessage: 'Imprint',
    },
})

export const documentSelectionMessages = defineMessages({
    default: {
        id: 'merchant.form.documentSelection.option.default',
        defaultMessage: 'Please select',
    },
    notRequired: {
        id: 'merchant.form.documentSelection.option.notRequired',
        defaultMessage: 'Not required (no corresponding offers)',
    },
    uploadDocument: {
        id: 'merchant.form.documentSelection.option.uploadDocument',
        defaultMessage: 'Upload separate document',
    },
    handledByFallback: {
        id: 'merchant.form.documentSelection.option.handledByFallback',
        defaultMessage: 'Handled by Fallback',
    },
    includedInFallback: {
        id: 'merchant.form.documentSelection.option.includedInFallback',
        defaultMessage: 'Included in "{fallbackDocument}"',
    },
    missingDocumentConfiguration: {
        id: 'merchant.document-management.tnc.missingDocumentConfiguration',
        defaultMessage:
            'Configuration for this document type is missing. Please contact your local marketplace operator.',
    },
})
