import { useQuery } from '@tanstack/react-query'

import { ApiError, Merchant, merchantAdapter } from '@slmpserv/common-api-merchant'

import { shouldRetry } from './shouldRetry'

export type UseFetchMerchantProps = {
    ppnId: Merchant['ppnId']
    enabled?: boolean
}

export function useFetchMerchant({ ppnId, enabled = true }: UseFetchMerchantProps) {
    const queryKey = ['merchant', ppnId]

    const queryEnabled = enabled && Boolean(ppnId)

    const queryResult = useQuery({
        queryKey,
        queryFn: () => merchantAdapter.getByPpnId(ppnId),
        enabled: queryEnabled,
        retry: (failureCount, error) => {
            return shouldRetry(error as unknown as ApiError, failureCount)
        },
    })

    return { merchant: queryResult.data, queryResult, queryKey } as const
}
