import { UseFormReset } from 'react-hook-form'
import { useCallback, useState } from 'react'
import { UseQueryResult } from '@tanstack/react-query'

import {
    DocumentType,
    MarketplaceDocumentConfig,
    MarketplaceDocumentConfigDocumentStatusEnum,
    MerchantResponse,
    MerchantTermsAndConditionsResponse,
    SelectedOption,
} from '@slmpserv/common-api-merchant'
import {
    useFetchMarketplaceDocumentConfiguration,
    useFetchMerchantDocumentConfiguration,
} from '@slmpserv/common/api/use-query-wrapper'

import {
    DocumentDecisions,
    DocumentFiles,
    DocumentsFormState,
    MerchantTncFormData,
} from '../DocumentsForm/DocumentsFormTypesAndConsts'
import { SelectedOptionDefault } from '../components/DocumentSelection/DocumentSelection'

const initialDecisions: DocumentDecisions = {
    NEW_CAR_GENERAL: SelectedOptionDefault,
    NEW_CAR_BEV: SelectedOptionDefault,
    NEW_CAR_PHEV: SelectedOptionDefault,
    CSV_CAR_GENERAL: SelectedOptionDefault,
    CSV_CAR_BEV: SelectedOptionDefault,
    CSV_CAR_PHEV: SelectedOptionDefault,
    USED_CAR_GENERAL: SelectedOptionDefault,
    USED_CAR_APPROVED_BEV: SelectedOptionDefault,
    USED_CAR_APPROVED_GENERAL: SelectedOptionDefault,
    USED_CAR_APPROVED_PHEV: SelectedOptionDefault,
    USED_CAR_BEV: SelectedOptionDefault,
    USED_CAR_PHEV: SelectedOptionDefault,
    PRIVACY_POLICY: SelectedOptionDefault,
    RESERVATION_GENERAL: SelectedOptionDefault,
    RESERVATION_PRIVACY_POLICY: SelectedOptionDefault,
}

const initialFiles: DocumentFiles = {
    // New Cars
    [DocumentType.NEW_CAR_GENERAL]: undefined,
    [DocumentType.NEW_CAR_BEV]: undefined,
    [DocumentType.NEW_CAR_PHEV]: undefined,

    // Used Cars
    [DocumentType.USED_CAR_GENERAL]: undefined,
    [DocumentType.USED_CAR_BEV]: undefined,
    [DocumentType.USED_CAR_PHEV]: undefined,
    [DocumentType.USED_CAR_APPROVED_GENERAL]: undefined,
    [DocumentType.USED_CAR_APPROVED_BEV]: undefined,
    [DocumentType.USED_CAR_APPROVED_PHEV]: undefined,

    // Custom Cars
    [DocumentType.CSV_CAR_GENERAL]: undefined,
    [DocumentType.CSV_CAR_BEV]: undefined,
    [DocumentType.CSV_CAR_PHEV]: undefined,

    // Privacy Policy
    PRIVACY_POLICY: undefined,

    // Reservation
    RESERVATION_GENERAL: undefined,
    RESERVATION_PRIVACY_POLICY: undefined,
}

type UseLoadInitialTncFormStateProps = {
    marketplaceKey: string
    merchantId: MerchantResponse['id']
    languages: string[]
    reset: UseFormReset<DocumentsFormState>
}

export function useLoadInitialTncFormState({
    marketplaceKey,
    merchantId,
    languages = [],
    reset,
}: UseLoadInitialTncFormStateProps) {
    const [formInitialized, setFormInitialized] = useState(false)
    const { merchantDocumentConfig: tnc, queryResult } = useFetchMerchantDocumentConfiguration(merchantId, {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 1000 * 60 * 60 * 24,
    })

    const reinizializeForm = useCallback(() => setFormInitialized(false), [])

    const { marketplaceDocumentConfig, queryResult: marketplaceDocumentConfigQueryResult } =
        useFetchMarketplaceDocumentConfiguration({ marketplaceKey })

    if (queryResult.isLoading || marketplaceDocumentConfigQueryResult.isLoading) {
        return { queryResults: [queryResult, marketplaceDocumentConfigQueryResult], reinizializeForm }
    }

    const marketplaceDocumentConfigMap = marketplaceDocumentConfig.reduce(
        (map, element) => ({ ...map, [element.documentType]: element }),
        {} as Record<DocumentType, MarketplaceDocumentConfig>
    )

    const merchantDocumentConfig = tnc?.documentConfigurations || []

    const documents = languages.reduce((documentConfigsByLanguage, currentLanguage) => {
        const documentsByLanguage = merchantDocumentConfig.filter(config => config.locale === currentLanguage)

        const decisions: DocumentDecisions = {
            ...initialDecisions,
            ...documentsByLanguage.reduce(
                (decisionMap, current) => ({
                    ...decisionMap,
                    [current.documentType]: current.selectedOption,
                }),
                {}
            ),
        }

        const storedFiles = documentsByLanguage
            .filter(documentConfig => documentConfig.selectedOption === SelectedOption.DOCUMENT_SPECIFIED)
            .reduce(
                (existingDocumentMap, current) => ({
                    ...existingDocumentMap,
                    [current.documentType]: current.documentMetadata,
                }),
                {}
            )

        const storedDecisions = documentsByLanguage
            .filter(documentConfig => documentConfig.selectedOption)
            .reduce(
                (existingDocumentMap, current) => ({
                    ...existingDocumentMap,
                    [current.documentType]: current.selectedOption,
                }),
                {} as DocumentDecisions
            )

        const requiredDocuments = Object.values(marketplaceDocumentConfigMap).filter(
            el => el.documentStatus === MarketplaceDocumentConfigDocumentStatusEnum.REQUIRED
        )
        requiredDocuments.forEach(({ documentType }) => {
            storedDecisions[documentType] = SelectedOption.DOCUMENT_SPECIFIED
        })

        const tncData: MerchantTncFormData = {
            language: currentLanguage,
            decisions,
            storedDecisions,
            files: initialFiles,
            storedFiles,
        }

        return { ...documentConfigsByLanguage, [currentLanguage]: tncData }
    }, {})

    const formState: DocumentsFormState = {
        offersNewCars: tnc?.offersNewCars === true,
        offersCsvCars: tnc?.offersCsvCars === true,
        reservationEnabled: tnc?.reservationEnabled === true,
        documentConfig: marketplaceDocumentConfigMap,
        documents,
    }

    if (!formInitialized && queryResult.isSuccess && marketplaceDocumentConfigQueryResult.isSuccess) {
        setFormInitialized(true)
        reset(formState)
    }

    return { queryResults: [queryResult, marketplaceDocumentConfigQueryResult], reinizializeForm }
}
