import { useEffect } from 'react'

import { apiMiddleware, basePathMiddleware } from '@slmpserv/kyc-service-api'
import { Environment } from '@slmpserv/environment'

export const useKycApiBasePath = (environment: Environment): void => {
    useEffect(() => {
        basePathMiddleware.setEnv(environment)
        apiMiddleware.setEnv(environment)
    }, [environment])
}
