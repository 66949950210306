import { defineMessages } from 'react-intl'

export const errorsMessages = defineMessages({
    justError: {
        id: 'merchant.shared.errors.justError',
        defaultMessage: 'Error',
    },
    genericError: {
        id: 'merchant.shared.errors.genericError',
        defaultMessage: 'Something went wrong, sorry!',
    },
    merchantNotFound: {
        id: 'merchant.shared.errors.merchantNotFound',
        defaultMessage: 'This is not the merchant you are looking for.',
    },
    unableToFetchContent: {
        id: 'merchant.shared.errors.unableToFetchContent',
        defaultMessage: 'Unable to fetch content from url.',
    },
    unknownError: {
        id: 'merchant.shared.errors.unknownError',
        defaultMessage: 'An unknown error occurred.',
    },
})
