const trackEvent = (category: string, action: string, name?: string, value?: string): (() => void) | undefined => {
    if (typeof window === 'undefined') {
        return undefined
    }

    const w = window as any

    return () => w._paq.push(['trackEvent', category, action, name, value])
}

export const useMatomoTrackEvent = (category: string, action: string, name?: string, value?: string) => {
    return {
        trackEvent: trackEvent(category, action, name, value),
    }
}

export const useMatomoTrackEditEvent = (category: string, onError: boolean) => {
    const action = onError ? 'click_edit_in_error_state' : 'click_edit_in_ok_state'
    return {
        trackEvent: trackEvent('merchant_self_service', action, category),
    }
}

export const useMatomoTrackTooltipClick = (category: string) => {
    return {
        trackEvent: trackEvent('merchant_self_service', 'click_tooltip', category),
    }
}

export const changeEnableTracking = (trackingEnabled: boolean) => {
    if (typeof window === 'undefined') {
        return
    }

    const w = window as any

    w._paq.push([trackingEnabled ? 'forgetUserOptOut' : 'optUserOut'])
}
