/* tslint:disable */
/* eslint-disable */
/**
 * Porsche KYC Service REST API V1
 * Documentation of KYC Service API V1
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface SubmerchantPayoutScheduleRequest
 */
export interface SubmerchantPayoutScheduleRequest {
    /**
     * Direction on how to handle any payouts that have already been scheduled
     * @type {string}
     * @memberof SubmerchantPayoutScheduleRequest
     */
    action: SubmerchantPayoutScheduleRequestActionEnum
    /**
     * The reason for the payout schedule update
     * @type {string}
     * @memberof SubmerchantPayoutScheduleRequest
     */
    reason: string
    /**
     * Payout schedule of submerchant
     * @type {string}
     * @memberof SubmerchantPayoutScheduleRequest
     */
    schedule: SubmerchantPayoutScheduleRequestScheduleEnum
}

/**
 * @export
 * @enum {string}
 */
export enum SubmerchantPayoutScheduleRequestActionEnum {
    Close = 'CLOSE',
    Update = 'UPDATE',
    Nothing = 'NOTHING',
}
/**
 * @export
 * @enum {string}
 */
export enum SubmerchantPayoutScheduleRequestScheduleEnum {
    Default = 'DEFAULT',
    Hold = 'HOLD',
    Daily = 'DAILY',
    Weekly = 'WEEKLY',
    Monthly = 'MONTHLY',
}

export function SubmerchantPayoutScheduleRequestFromJSON(json: any): SubmerchantPayoutScheduleRequest {
    return SubmerchantPayoutScheduleRequestFromJSONTyped(json, false)
}

export function SubmerchantPayoutScheduleRequestFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): SubmerchantPayoutScheduleRequest {
    if (json === undefined || json === null) {
        return json
    }
    return {
        action: json['action'],
        reason: json['reason'],
        schedule: json['schedule'],
    }
}

export function SubmerchantPayoutScheduleRequestToJSON(value?: SubmerchantPayoutScheduleRequest | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        action: value.action,
        reason: value.reason,
        schedule: value.schedule,
    }
}
