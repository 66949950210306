interface AppConfigShape {
    cdnUrl: string
    i18n: {
        defaultLocale: string
    }
}

const environmentCdnUrlMap = {
    K: 'https://dev.finder-dealer-wrapper.slmpserv.aws.porsche-preview.cloud/assets/l10n',
    PP: 'https://dev.finder-dealer-wrapper.slmpserv.aws.porsche-preview.cloud/assets/l10n',
    P: 'https://finder-dealer-wrapper.slmpserv.aws.porsche.cloud/assets/l10n',
    E2E: 'https://dev.finder-dealer-wrapper.slmpserv.aws.porsche-preview.cloud/assets/l10n',
}

export function createAppConfig(env: string): AppConfigShape {
    return {
        cdnUrl: environmentCdnUrlMap[env] ?? '/assets/l10n',
        i18n: {
            defaultLocale: 'en-GB',
        },
    }
}
