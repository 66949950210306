/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ErrorResponse, Marketplace, MarketplaceOverview, MarketplaceUpdate } from '../models/index'
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    MarketplaceFromJSON,
    MarketplaceToJSON,
    MarketplaceOverviewFromJSON,
    MarketplaceOverviewToJSON,
    MarketplaceUpdateFromJSON,
    MarketplaceUpdateToJSON,
} from '../models/index'

export interface CreateMarketplaceRequest {
    marketplace: Marketplace
}

export interface DeleteMarketplaceRequest {
    key: string
}

export interface GetMarketplaceRequest {
    key: string
}

export interface SyncAllMerchantsRequest {
    key: string
}

export interface UpdateMarketplaceRequest {
    key: string
    marketplaceUpdate: MarketplaceUpdate
}

/**
 *
 */
export class MarketplacesApi extends runtime.BaseAPI {
    /**
     */
    async createMarketplaceRaw(
        requestParameters: CreateMarketplaceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<Marketplace>> {
        if (requestParameters['marketplace'] == null) {
            throw new runtime.RequiredError(
                'marketplace',
                'Required parameter "marketplace" was null or undefined when calling createMarketplace().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request(
            {
                path: `/api/marketplaces`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: MarketplaceToJSON(requestParameters['marketplace']),
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => MarketplaceFromJSON(jsonValue))
    }

    /**
     */
    async createMarketplace(
        requestParameters: CreateMarketplaceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<Marketplace> {
        const response = await this.createMarketplaceRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async deleteMarketplaceRaw(
        requestParameters: DeleteMarketplaceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling deleteMarketplace().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/marketplaces/{key}`.replace(
                    `{${'key'}}`,
                    encodeURIComponent(String(requestParameters['key']))
                ),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.VoidApiResponse(response)
    }

    /**
     */
    async deleteMarketplace(
        requestParameters: DeleteMarketplaceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<void> {
        await this.deleteMarketplaceRaw(requestParameters, initOverrides)
    }

    /**
     */
    async getMarketplaceRaw(
        requestParameters: GetMarketplaceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<Marketplace>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getMarketplace().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/marketplaces/{key}`.replace(
                    `{${'key'}}`,
                    encodeURIComponent(String(requestParameters['key']))
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => MarketplaceFromJSON(jsonValue))
    }

    /**
     */
    async getMarketplace(
        requestParameters: GetMarketplaceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<Marketplace> {
        const response = await this.getMarketplaceRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async getMarketplacesRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<Array<MarketplaceOverview>>> {
        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/marketplaces`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(MarketplaceOverviewFromJSON))
    }

    /**
     */
    async getMarketplaces(
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<Array<MarketplaceOverview>> {
        const response = await this.getMarketplacesRaw(initOverrides)
        return await response.value()
    }

    /**
     */
    async syncAllMerchantsRaw(
        requestParameters: SyncAllMerchantsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling syncAllMerchants().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/api/marketplaces/{key}/syncAllMerchants`.replace(
                    `{${'key'}}`,
                    encodeURIComponent(String(requestParameters['key']))
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides
        )

        return new runtime.VoidApiResponse(response)
    }

    /**
     */
    async syncAllMerchants(
        requestParameters: SyncAllMerchantsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<void> {
        await this.syncAllMerchantsRaw(requestParameters, initOverrides)
    }

    /**
     */
    async updateMarketplaceRaw(
        requestParameters: UpdateMarketplaceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<runtime.ApiResponse<Marketplace>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling updateMarketplace().'
            )
        }

        if (requestParameters['marketplaceUpdate'] == null) {
            throw new runtime.RequiredError(
                'marketplaceUpdate',
                'Required parameter "marketplaceUpdate" was null or undefined when calling updateMarketplace().'
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters['Content-Type'] = 'application/json'

        const response = await this.request(
            {
                path: `/api/marketplaces/{key}`.replace(
                    `{${'key'}}`,
                    encodeURIComponent(String(requestParameters['key']))
                ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: MarketplaceUpdateToJSON(requestParameters['marketplaceUpdate']),
            },
            initOverrides
        )

        return new runtime.JSONApiResponse(response, jsonValue => MarketplaceFromJSON(jsonValue))
    }

    /**
     */
    async updateMarketplace(
        requestParameters: UpdateMarketplaceRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction
    ): Promise<Marketplace> {
        const response = await this.updateMarketplaceRaw(requestParameters, initOverrides)
        return await response.value()
    }
}
