import { PCheckboxWrapper } from '@porsche-design-system/components-react'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { DocumentType } from '@slmpserv/common-api-merchant'
import { descriptionMessages, documentManagementMessages } from '@slmpserv/common-translations'
import { CompletenessBadge, FormGroup, SpacedDivider } from '@slmpserv/common-components'

import { DocumentsFormState } from '../DocumentsForm/DocumentsFormTypesAndConsts'
import { useDocumentProgress } from '../hooks/useDocumentProgress'
import { DocumentSelection } from '../components/DocumentSelection/DocumentSelection'
import { useFilterDisabledDocumentTypes } from '../hooks/useFilterDisabledDocumentTypes'

import { StyledHeading, StyledText } from './shared.styles'

type NewCarSectionProps = {
    language: string
}

export function NewCarSection({ language }: NewCarSectionProps) {
    const { formatMessage } = useIntl()
    const { watch, control } = useFormContext<DocumentsFormState>()
    const hasOffer = watch('offersNewCars')

    const documentTypes = useFilterDisabledDocumentTypes([
        DocumentType.NEW_CAR_GENERAL,
        DocumentType.NEW_CAR_BEV,
        DocumentType.NEW_CAR_PHEV,
    ])
    const requiredDocuments = hasOffer ? documentTypes : []
    const { selectedDocumentCount, complete, total } = useDocumentProgress(language, requiredDocuments)

    return (
        <>
            <SpacedDivider />
            <FormGroup
                label={formatMessage(documentManagementMessages.newCarsSection)}
                completedBadge={<CompletenessBadge done={selectedDocumentCount} total={total} complete={complete} />}
                labelColumnWidth={1}
                contentColumnWidth={4}
            >
                <PCheckboxWrapper label={formatMessage(documentManagementMessages.newCarsOfferExists)}>
                    <Controller
                        control={control}
                        name={'offersNewCars'}
                        render={({ field: { name, onChange, onBlur, value } }) => (
                            <input type={'checkbox'} name={name} onBlur={onBlur} onChange={onChange} checked={value} />
                        )}
                    />
                </PCheckboxWrapper>

                <SpacedDivider />

                <StyledHeading tag="h4" size="large">
                    {formatMessage(descriptionMessages.newCarHeader)}
                </StyledHeading>
                <StyledText color={'neutral-contrast-high'}>
                    {formatMessage(descriptionMessages.newCarDetail)}
                </StyledText>

                <DocumentSelection
                    documentType={DocumentType.NEW_CAR_GENERAL}
                    disabled={!hasOffer}
                    language={language}
                />

                <StyledHeading tag="h4" size="large">
                    {formatMessage(descriptionMessages.newCarAdditionalDocumentsHeader)}
                </StyledHeading>
                <StyledText color={'neutral-contrast-high'}>
                    {formatMessage(descriptionMessages.newCarAdditionalDocumentsDetail)}
                </StyledText>

                <DocumentSelection documentType={DocumentType.NEW_CAR_BEV} disabled={!hasOffer} language={language} />

                <DocumentSelection documentType={DocumentType.NEW_CAR_PHEV} disabled={!hasOffer} language={language} />
            </FormGroup>
        </>
    )
}
