import { PHeading, PLinkPure, PText } from '@porsche-design-system/components-react'
import { spacingStatic } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Flex, FlexItem } from '@slcheckout/ui-kit'

import { BoxLayout, PageLayout } from '@slmpserv/common-components-legacy'
import { routesMessages } from '@slmpserv/common-translations'
import { SellerContactEmails } from '@slmpserv/self-service-online-order-configuration'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'

export function SellerContactEmailPage({ entryDn }: { entryDn: string }) {
    const { formatMessage } = useIntl()

    return (
        <PageLayout
            header={
                <>
                    <Link to={StaticRoutes.DASHBOARD}>
                        <PLinkPure icon={'arrow-head-left'} style={{ marginBottom: spacingStatic.large }}>
                            {formatMessage(routesMessages.backToDashboard)}
                        </PLinkPure>
                    </Link>
                    <Flex direction="column">
                        <FlexItem>
                            <PHeading tag="h3" size="large">
                                {formatMessage(routesMessages.inquiryEmailHeadline)}
                            </PHeading>
                        </FlexItem>
                        <FlexItem>
                            <PText color={'neutral-contrast-medium'}>
                                {formatMessage(routesMessages.inquiryEmailSubHeadline)}
                            </PText>
                        </FlexItem>
                    </Flex>
                </>
            }
        >
            <BoxLayout>
                <SellerContactEmails entryDn={entryDn} />
            </BoxLayout>
        </PageLayout>
    )
}
