import { defineMessages, MessageDescriptor } from 'react-intl'

import { DocumentType } from '@slmpserv/common-api-merchant'

export const documentTypeMessages = defineMessages({
    NEW_CAR_GENERAL: {
        id: 'merchant.api.documentType.NEW_CAR_GENERAL',
        defaultMessage: 'New Cars General',
    },
    NEW_CAR_BEV: {
        id: 'merchant.api.documentType.NEW_CAR_BEV',
        defaultMessage: 'New Car Bev',
    },
    NEW_CAR_PHEV: {
        id: 'merchant.api.documentType.NEW_CAR_PHEV',
        defaultMessage: 'New_Car_Phev',
    },
    USED_CAR_GENERAL: {
        id: 'merchant.api.documentType.USED_CAR_GENERAL',
        defaultMessage: 'Used Car General',
    },
    USED_CAR_BEV: {
        id: 'merchant.api.documentType.USED_CAR_BEV',
        defaultMessage: 'Used Car Bev',
    },
    USED_CAR_PHEV: {
        id: 'merchant.api.documentType.USED_CAR_PHEV',
        defaultMessage: 'Used Car Phev',
    },
    USED_CAR_APPROVED_GENERAL: {
        id: 'merchant.api.documentType.USED_CAR_APPROVED_GENERAL',
        defaultMessage: 'Used Car Approved General',
    },
    USED_CAR_APPROVED_BEV: {
        id: 'merchant.api.documentType.USED_CAR_APPROVED_BEV',
        defaultMessage: 'Used Car Approved Bev',
    },
    USED_CAR_APPROVED_PHEV: {
        id: 'merchant.api.documentType.USED_CAR_APPROVED_PHEV',
        defaultMessage: 'Used Car Approved Phev',
    },
    CSV_CAR_GENERAL: {
        id: 'merchant.api.documentType.CSV_CAR_GENERAL',
        defaultMessage: 'Csv Car General',
    },
    CSV_CAR_BEV: {
        id: 'merchant.api.documentType.CSV_CAR_BEV',
        defaultMessage: 'Csv Car Bev',
    },
    CSV_CAR_PHEV: {
        id: 'merchant.api.documentType.CSV_CAR_PHEV',
        defaultMessage: 'Csv Car Phev',
    },
    PRIVACY_POLICY: {
        id: 'merchant.api.documentType.PRIVACY_POLICY',
        defaultMessage: 'Privacy Policy',
    },
    RESERVATION_GENERAL: {
        id: 'merchant.api.documentType.RESERVATION_GENERAL',
        defaultMessage: 'Terms and Conditions for vehicle reservations',
    },
    RESERVATION_PRIVACY_POLICY: {
        id: 'merchant.api.documentType.RESERVATION_PRIVACY_POLICY',
        defaultMessage: 'Privacy Policy for vehicle reservations',
    },
})

export const documentTypeTranslations: Record<DocumentType, MessageDescriptor> = {
    NEW_CAR_GENERAL: documentTypeMessages.NEW_CAR_GENERAL,
    NEW_CAR_BEV: documentTypeMessages.NEW_CAR_BEV,
    NEW_CAR_PHEV: documentTypeMessages.NEW_CAR_PHEV,
    USED_CAR_GENERAL: documentTypeMessages.USED_CAR_GENERAL,
    USED_CAR_BEV: documentTypeMessages.USED_CAR_BEV,
    USED_CAR_PHEV: documentTypeMessages.USED_CAR_PHEV,
    USED_CAR_APPROVED_GENERAL: documentTypeMessages.USED_CAR_APPROVED_GENERAL,
    USED_CAR_APPROVED_BEV: documentTypeMessages.USED_CAR_APPROVED_BEV,
    USED_CAR_APPROVED_PHEV: documentTypeMessages.USED_CAR_APPROVED_PHEV,
    CSV_CAR_GENERAL: documentTypeMessages.CSV_CAR_GENERAL,
    CSV_CAR_BEV: documentTypeMessages.CSV_CAR_BEV,
    CSV_CAR_PHEV: documentTypeMessages.CSV_CAR_PHEV,
    PRIVACY_POLICY: documentTypeMessages.PRIVACY_POLICY,
    RESERVATION_GENERAL: documentTypeMessages.RESERVATION_GENERAL,
    RESERVATION_PRIVACY_POLICY: documentTypeMessages.RESERVATION_PRIVACY_POLICY,
}
