import { defineMessages } from 'react-intl'

export const messages = defineMessages({
    boxTitle: {
        id: 'dealer.dashboard.kycNotifications.box.title',
        defaultMessage: 'KYC Notifications',
    },
    editLabel: {
        id: 'dealer.dashboard.kycNotifications.box.editLabel',
        defaultMessage: 'Edit Contact',
    },
    emailLabel: {
        id: 'dealer.kycNotifications.email.label',
        defaultMessage: 'Email address for KYC notifications',
    },
    additionalEmailLabel: {
        id: 'dealer.kycNotifications.additionalEmail.label',
        defaultMessage: 'Additional Email address for KYC notifications',
    },
    emailPlaceholder: {
        id: 'dealer.kycNotifications.email.placeholder',
        defaultMessage: 'Please enter your email address',
    },
    addAdditionalEmail: {
        id: 'dealer.kycNotifications.addAdditionalEmail',
        defaultMessage: 'Add email address',
    },
    deleteAdditionalEmail: {
        id: 'dealer.kycNotifications.deleteAdditionalEmail',
        defaultMessage: 'Remove email address',
    },
    toastSubmitSuccess: {
        id: 'dealer.kycNotifications.toast.submit.success',
        defaultMessage: 'Saved',
    },
    toastSubmitError: {
        id: 'dealer.kycNotifications.toast.submit.error',
        defaultMessage: 'Error while saving',
    },
})
