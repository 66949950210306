import { PHeading } from '@porsche-design-system/components-react'
import { useIntl } from 'react-intl'

import { useCurrentDealerContext } from '@slmpserv/self-service-current-dealer-context'
import { PageLayout } from '@slmpserv/common-components-legacy'
import { routesMessages } from '@slmpserv/common-translations'
import { SellerDashboard } from '@slmpserv/self-service-dashboard'

export function SellerDashboardPage() {
    const { formatMessage } = useIntl()
    const { dealershipId } = useCurrentDealerContext()

    return (
        <PageLayout
            header={
                <PHeading tag="h3" size="large">
                    {formatMessage(routesMessages.dashboardHeadline)}
                </PHeading>
            }
        >
            <SellerDashboard entryDn={dealershipId} />
        </PageLayout>
    )
}
