export const StaticRoutes = {
    ERROR: '/error',
    ONBOARDING: '/onboarding',
    AWAITING_CONFIRMATION: '/awaiting-confirmation',
    DASHBOARD: '/dashboard',
    CONTACT: '/dashboard/contact',
    KYC_NOTIFICATIONS: '/dashboard/kyc-notifications',
    KYC_MANAGEMENT: '/dashboard/kyc-management',
    DOCUMENT_MANAGEMENT_TERMS_AND_CONDITIONS: '/dashboard/document-management/terms-and-conditions',
    DOCUMENT_MANAGEMENT_PRIVACY_POLICY: '/dashboard/document-management/privacy-policy',
    DOCUMENT_MANAGEMENT_IMPRINT: '/dashboard/document-management/imprint',
    ONLINE_ORDER_CONFIGURATION: '/dashboard/online-order-configuration',
    PAYOUT_CONTACT: '/dashboard/payout-contact',
    SELLER_CONTACT_EMAIL: '/dashboard/seller-contact-email',
    SELLER_CONTACT_PHONE: '/dashboard/seller-contact-phone',
    PURCHASE_REQUEST_CONTACT: '/dashboard/purchase-request-contact',
}
