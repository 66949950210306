import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'

const fetchTranslationRequest = async (locale: string, cdnUrl: string): Promise<Record<string, string>> => {
    const url = `${cdnUrl}/${locale}.json`

    const response = await fetch(url)

    if (response.status >= 200 && response.status < 300) {
        return response.json()
    }

    throw Error(`Status ${response.status}: Error fetching locale ${locale}`)
}

interface TranslationProps {
    cdnUrl: string
    locale: string
    defaultLocale: string
}

export function useTranslations(props: TranslationProps) {
    const [language, setLanguage] = useState(props.locale)
    const {
        data: messages,
        isError,
        ...rest
    } = useQuery<Record<string, string>>({
        queryKey: ['translations', language],
        queryFn: () => fetchTranslationRequest(language, props.cdnUrl),
        retry: false,
    })

    useEffect(() => {
        if (isError) {
            if (language !== props.defaultLocale) {
                setLanguage(props.defaultLocale)
            }
        }
    }, [isError, language, props.defaultLocale])

    return [
        { messages, language },
        { isError, ...rest },
    ] as const
}
