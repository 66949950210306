import { PButton } from '@porsche-design-system/components-react'
import { spacingFluid } from '@porsche-design-system/components-react/styles'
import { useIntl } from 'react-intl'
import { Flex, FlexItem } from '@slcheckout/ui-kit'
import { styled } from 'styled-components'

import { formButtonsMessages } from './FormButtons.messages'

const StyledFormButtons = styled(Flex)`
    margin-top: ${spacingFluid.large};
`

const StyledSaveButton = styled(PButton)`
    margin-left: ${spacingFluid.medium};
`

type FormButtonsProps = {
    className?: string
    isDisabled?: boolean
    isLoading?: boolean
    onCancel: () => void
    onClick?: () => void
}

export function FormButtons({
    className,
    isDisabled = false,
    isLoading = false,
    onCancel,
    onClick = () => undefined,
}: FormButtonsProps) {
    const { formatMessage } = useIntl()

    return (
        <StyledFormButtons justifyContent={'flex-end'} className={className}>
            <FlexItem>
                <PButton variant={'tertiary'} type={'button'} onClick={onCancel}>
                    {formatMessage(formButtonsMessages.cancel)}
                </PButton>
            </FlexItem>
            <FlexItem>
                <StyledSaveButton
                    type={'submit'}
                    loading={isLoading}
                    disabled={isDisabled}
                    onClick={onClick}
                    data-testid={'save-details-button'}
                >
                    {formatMessage(formButtonsMessages.submit)}
                </StyledSaveButton>
            </FlexItem>
        </StyledFormButtons>
    )
}
