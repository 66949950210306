/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { DocumentType } from './DocumentType'
import { DocumentTypeFromJSON, DocumentTypeFromJSONTyped, DocumentTypeToJSON } from './DocumentType'
import type { MerchantDocumentMetadata } from './MerchantDocumentMetadata'
import {
    MerchantDocumentMetadataFromJSON,
    MerchantDocumentMetadataFromJSONTyped,
    MerchantDocumentMetadataToJSON,
} from './MerchantDocumentMetadata'
import type { SelectedOption } from './SelectedOption'
import { SelectedOptionFromJSON, SelectedOptionFromJSONTyped, SelectedOptionToJSON } from './SelectedOption'

/**
 *
 * @export
 * @interface MerchantDocumentConfigResponse
 */
export interface MerchantDocumentConfigResponse {
    /**
     *
     * @type {string}
     * @memberof MerchantDocumentConfigResponse
     */
    id?: string
    /**
     *
     * @type {DocumentType}
     * @memberof MerchantDocumentConfigResponse
     */
    documentType: DocumentType
    /**
     *
     * @type {string}
     * @memberof MerchantDocumentConfigResponse
     */
    locale: string
    /**
     *
     * @type {SelectedOption}
     * @memberof MerchantDocumentConfigResponse
     */
    selectedOption: SelectedOption
    /**
     *
     * @type {MerchantDocumentMetadata}
     * @memberof MerchantDocumentConfigResponse
     */
    documentMetadata?: MerchantDocumentMetadata
}

/**
 * Check if a given object implements the MerchantDocumentConfigResponse interface.
 */
export function instanceOfMerchantDocumentConfigResponse(value: object): boolean {
    if (!('documentType' in value)) return false
    if (!('locale' in value)) return false
    if (!('selectedOption' in value)) return false
    return true
}

export function MerchantDocumentConfigResponseFromJSON(json: any): MerchantDocumentConfigResponse {
    return MerchantDocumentConfigResponseFromJSONTyped(json, false)
}

export function MerchantDocumentConfigResponseFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): MerchantDocumentConfigResponse {
    if (json == null) {
        return json
    }
    return {
        id: json['id'] == null ? undefined : json['id'],
        documentType: DocumentTypeFromJSON(json['documentType']),
        locale: json['locale'],
        selectedOption: SelectedOptionFromJSON(json['selectedOption']),
        documentMetadata:
            json['documentMetadata'] == null ? undefined : MerchantDocumentMetadataFromJSON(json['documentMetadata']),
    }
}

export function MerchantDocumentConfigResponseToJSON(value?: MerchantDocumentConfigResponse | null): any {
    if (value == null) {
        return value
    }
    return {
        id: value['id'],
        documentType: DocumentTypeToJSON(value['documentType']),
        locale: value['locale'],
        selectedOption: SelectedOptionToJSON(value['selectedOption']),
        documentMetadata: MerchantDocumentMetadataToJSON(value['documentMetadata']),
    }
}
