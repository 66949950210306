import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { DocumentType, MarketplaceDocumentConfigDocumentStatusEnum } from '@slmpserv/common-api-merchant'

import { DocumentsFormState } from '../DocumentsForm/DocumentsFormTypesAndConsts'

export function useFilterDisabledDocumentTypes(documentTypes: DocumentType[]): DocumentType[] {
    const { getValues } = useFormContext<DocumentsFormState>()
    const marketplaceDocumentConfig = getValues(`documentConfig`)

    return useMemo(() => {
        if (marketplaceDocumentConfig) {
            return documentTypes.filter(
                it =>
                    marketplaceDocumentConfig[it]?.documentStatus !==
                    MarketplaceDocumentConfigDocumentStatusEnum.DISABLED
            )
        } else {
            return []
        }
    }, [documentTypes, marketplaceDocumentConfig])
}
