import { defineMessages } from 'react-intl'

export const createMerchantMessages = defineMessages({
    saveSuccess: {
        id: 'merchant.form.create.saveSuccess',
        defaultMessage: 'Merchant saved',
    },
    saveFailure: {
        id: 'merchant.form.create.saveFailure',
        defaultMessage: 'Merchant could not be saved',
    },
})
