import { defineMessages, useIntl } from 'react-intl'
import { useState } from 'react'
import { PInlineNotification } from '@porsche-design-system/components-react'

import { useCurrentDealerContext } from '@slmpserv/self-service-current-dealer-context'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { DownPaymentConfiguration } from '@slmpserv/self-service-online-order-configuration'
import { BoxLayout, PageHeader, PageLayout } from '@slmpserv/common-components-legacy'
import { routesMessages } from '@slmpserv/common-translations'

const messages = defineMessages({
    downPaymentAdjustmentHintTitle: {
        id: 'dealer.page.downPaymentConfiguration.adjustment.hint.title',
        defaultMessage: 'Update your Terms & Conditions',
    },
    downPaymentAdjustmentHintDescription: {
        id: 'dealer.page.downPaymentConfiguration.adjustment.hint.description',
        defaultMessage:
            'The Reservation Fee has successfully been changed. It might also be necessary to update your Legal Documents (e.g., Terms & Conditions) accordingly.',
    },
})

export function DownPaymentPage() {
    const { formatMessage } = useIntl()
    const { partnerNumber } = useCurrentDealerContext()
    const [isDownPaymentUpdated, setIsDownPaymentUpdated] = useState<boolean>(false)

    return (
        <>
            {isDownPaymentUpdated && (
                <PInlineNotification
                    data-testid={'payment-update-notification'}
                    key={'updateHint'}
                    persistent={false}
                    state={'neutral'}
                    heading={formatMessage(messages.downPaymentAdjustmentHintTitle)}
                    description={formatMessage(messages.downPaymentAdjustmentHintDescription)}
                    style={{
                        marginTop: '8px',
                        width: '60%',
                        position: 'absolute',
                        zIndex: 5,
                        right: '8px',
                    }}
                    onDismiss={() => setIsDownPaymentUpdated(false)}
                />
            )}
            <PageLayout
                header={
                    <PageHeader
                        title={formatMessage(routesMessages.downPaymentConfigurationHeadline)}
                        backButton={{
                            label: formatMessage(routesMessages.backToDashboard),
                            to: StaticRoutes.DASHBOARD,
                        }}
                    />
                }
            >
                <BoxLayout>
                    <DownPaymentConfiguration
                        ppnId={partnerNumber}
                        onSuccessSaveCallback={value => {
                            setIsDownPaymentUpdated(value)
                            return value
                        }}
                    />
                </BoxLayout>
            </PageLayout>
        </>
    )
}
