import { ReactNode } from 'react'
import { spacingFluid, themeLight } from '@porsche-design-system/components-react/styles'
import { styled } from 'styled-components'
import { Pds3Grid, Pds3GridItem } from '@slcheckout/ui-kit'

const StyledPageHeaderGrid = styled(Pds3Grid).attrs({
    type: 'wide',
})`
    padding-top: ${spacingFluid.medium};
    padding-bottom: ${spacingFluid.small};
`

const StyledPageBodyGrid = styled(Pds3Grid).attrs({
    type: 'wide',
})`
    background-color: ${themeLight.background.surface};
`

type PageLayoutProps = {
    header: ReactNode
    children: ReactNode
}

export function PageLayout({ header, children }: PageLayoutProps) {
    return (
        <>
            <StyledPageHeaderGrid>
                <Pds3GridItem column={{ base: ['start', 'end'] }}>{header}</Pds3GridItem>
            </StyledPageHeaderGrid>

            <StyledPageBodyGrid backgroundColor="surface">
                <Pds3GridItem column={{ base: ['start', 'end'] }}>{children}</Pds3GridItem>
            </StyledPageBodyGrid>
        </>
    )
}
