import { marketplacesApi } from '../../merchant-api'
import { Marketplace } from '../models/Marketplace'

import { toApiError } from './ApiError'

export const marketplaceAdapter = {
    async getMarketplace(marketplaceKey: string): Promise<Marketplace> {
        try {
            return await marketplacesApi.getMarketplace({ key: marketplaceKey })
        } catch (response) {
            throw await toApiError(response)
        }
    },
}
