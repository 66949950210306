import { useIntl } from 'react-intl'

import {
    useFetchMarketplaceDocumentConfiguration,
    useIsAllowedToEditMerchant,
} from '@slmpserv/common/api/use-query-wrapper'
import { Marketplace, MerchantResponse, MerchantStatus, MerchantStatusErrors } from '@slmpserv/common-api-merchant'
import { dashboardPopoverMessages, dashboardStatusMessageTooltip, dashboardTitles } from '@slmpserv/common-translations'

import { DashboardGroup } from '../DashboardGroup/DashboardGroup'
import { DataCompletedTag, DataWriteAccessIsRestricted } from '../DashboardGroupItem/DashboardGroupItem'

import { ImprintItem } from './ImprintItem'
import { PrivacyPolicyItem } from './PrivacyPolicyItem'
import { TncItem } from './TncItem'
import { localeToLanguage } from './utils'
import { useNeededTncDocuments } from './useNeededTncDocuments'

type DocumentsGroupProps = {
    marketplace: Marketplace
    merchant: MerchantResponse
    merchantStatus: MerchantStatus
}

export function DocumentsGroup({ marketplace, merchant, merchantStatus }: DocumentsGroupProps) {
    const { marketplaceDocumentConfig } = useFetchMarketplaceDocumentConfiguration({ marketplaceKey: marketplace.key })

    const { formatMessage } = useIntl()

    const isAllowedToEditMerchant = useIsAllowedToEditMerchant(merchant.entryDn)

    const marketplaceLanguages = marketplace.locales.map(locale => localeToLanguage(locale))

    const checkoutEnabled = marketplace.checkoutEnabled
    const reservationEnabled = marketplace.reservationEnabled
    // this is only for the used/new/csv cars, we filter reservation out
    const neededDocumentTypes = useNeededTncDocuments(
        marketplaceDocumentConfig,
        marketplace.csvEnabled,
        merchant.offersNewCars,
        merchant.offersCsvCars,
        marketplace.checkoutEnabled,
        false
    )

    const neededReservationDocumentTypes = useNeededTncDocuments(
        marketplaceDocumentConfig,
        false,
        false,
        false,
        false,
        reservationEnabled
    )

    const reservationDocumentMissing = reservationEnabled
        ? merchantStatus.errors.includes(MerchantStatusErrors.RESERVATION)
        : false

    const tncDocumentMissing = merchantStatus.errors.includes(MerchantStatusErrors.TNC)
    const privacyPolicyMissing = merchantStatus.errors.includes(MerchantStatusErrors.PRIVACY_POLICY)
    const imprintMissing = merchantStatus.errors.includes(MerchantStatusErrors.IMPRINT)

    const isDataComplete = (() => {
        if (checkoutEnabled && reservationEnabled) {
            return !merchantStatus.legalDocumentsIncomplete && !merchantStatus.legalReservationDocumentsIncomplete
        } else if (checkoutEnabled && !reservationEnabled) {
            return !merchantStatus.legalDocumentsIncomplete
        } else if (!checkoutEnabled && reservationEnabled) {
            return !merchantStatus.legalReservationDocumentsIncomplete
        }
        return false
    })()

    return (
        <DashboardGroup
            boxTitle={formatMessage(dashboardTitles.documentGroup)}
            badge={
                <>
                    <DataCompletedTag isDataComplete={isDataComplete} />
                    <DataWriteAccessIsRestricted hasAllWriteAccess={isAllowedToEditMerchant} />
                </>
            }
        >
            {(marketplace.checkoutEnabled || marketplace.csvEnabled) && (
                <TncItem
                    title={formatMessage(dashboardTitles.tnc)}
                    tooltipText={formatMessage(dashboardPopoverMessages.tnc)}
                    popoverText={formatMessage(dashboardStatusMessageTooltip.tnc)}
                    languages={marketplaceLanguages}
                    neededDocumentTypes={neededDocumentTypes}
                    documentsMissing={tncDocumentMissing}
                    missingDocuments={merchantStatus.data.missingTncDocuments}
                    isAllowedToEdit={isAllowedToEditMerchant}
                    dataTestId={'tnc-edit-link'}
                />
            )}
            {marketplace.reservationEnabled && (
                <TncItem
                    title={formatMessage(dashboardTitles.reservationFlow)}
                    tooltipText={formatMessage(dashboardPopoverMessages.reservationFlow)}
                    popoverText={formatMessage(dashboardStatusMessageTooltip.reservationFlow)}
                    languages={marketplaceLanguages}
                    neededDocumentTypes={neededReservationDocumentTypes}
                    documentsMissing={reservationDocumentMissing}
                    missingDocuments={merchantStatus.data.missingReservationDocuments ?? {}}
                    isAllowedToEdit={isAllowedToEditMerchant}
                    dataTestId={'reservation-tnc-edit-link'}
                />
            )}
            {(marketplace.checkoutEnabled || marketplace.csvEnabled) && (
                <PrivacyPolicyItem
                    languages={marketplaceLanguages}
                    privacyPolicyMissing={privacyPolicyMissing}
                    missing={merchantStatus.data.missingPrivacyPolicies}
                    isAllowedToEdit={isAllowedToEditMerchant}
                />
            )}
            <ImprintItem
                languages={marketplaceLanguages}
                imprintMissing={imprintMissing}
                missing={merchantStatus.data.missingImprints}
                isAllowedToEdit={isAllowedToEditMerchant}
            />
        </DashboardGroup>
    )
}
