/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { DownPaymentMarketplaceStatus } from './DownPaymentMarketplaceStatus'
import {
    DownPaymentMarketplaceStatusFromJSON,
    DownPaymentMarketplaceStatusFromJSONTyped,
    DownPaymentMarketplaceStatusToJSON,
} from './DownPaymentMarketplaceStatus'

/**
 *
 * @export
 * @interface MarketplaceOverview
 */
export interface MarketplaceOverview {
    /**
     *
     * @type {string}
     * @memberof MarketplaceOverview
     */
    key: string
    /**
     *
     * @type {boolean}
     * @memberof MarketplaceOverview
     */
    kycEnabled: boolean
    /**
     *
     * @type {DownPaymentMarketplaceStatus}
     * @memberof MarketplaceOverview
     */
    downPaymentStatus: DownPaymentMarketplaceStatus
    /**
     *
     * @type {boolean}
     * @memberof MarketplaceOverview
     */
    csvEnabled: boolean
}

/**
 * Check if a given object implements the MarketplaceOverview interface.
 */
export function instanceOfMarketplaceOverview(value: object): boolean {
    if (!('key' in value)) return false
    if (!('kycEnabled' in value)) return false
    if (!('downPaymentStatus' in value)) return false
    if (!('csvEnabled' in value)) return false
    return true
}

export function MarketplaceOverviewFromJSON(json: any): MarketplaceOverview {
    return MarketplaceOverviewFromJSONTyped(json, false)
}

export function MarketplaceOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketplaceOverview {
    if (json == null) {
        return json
    }
    return {
        key: json['key'],
        kycEnabled: json['kycEnabled'],
        downPaymentStatus: DownPaymentMarketplaceStatusFromJSON(json['downPaymentStatus']),
        csvEnabled: json['csvEnabled'],
    }
}

export function MarketplaceOverviewToJSON(value?: MarketplaceOverview | null): any {
    if (value == null) {
        return value
    }
    return {
        key: value['key'],
        kycEnabled: value['kycEnabled'],
        downPaymentStatus: DownPaymentMarketplaceStatusToJSON(value['downPaymentStatus']),
        csvEnabled: value['csvEnabled'],
    }
}
