import { defineMessages } from 'react-intl'

import { ChangesWithConsequences } from '@slmpserv/common-types'

export const changesModalMessages = defineMessages({
    [ChangesWithConsequences.LANGUAGE_ADDED]: {
        id: 'merchant.form.update.changeConsequence.languageAdded',
        defaultMessage:
            'This change affects the "completeness" of Merchants on the marketplace and may lead to Merchants being unable to sell cars on the marketplace',
    },
    [ChangesWithConsequences.LANGUAGE_REMOVED]: {
        id: 'merchant.form.update.changeConsequence.languageRemoved',
        defaultMessage: 'This change will affect the marketplace.',
    },
    [ChangesWithConsequences.DOCUMENT_TYPE_ADDED]: {
        id: 'merchant.form.update.changeConsequence.documentTypeAdded',
        defaultMessage:
            'This change affects the "completeness" of Merchants on the marketplace and may lead to Merchants being unable to sell cars on the marketplace',
    },
    [ChangesWithConsequences.DOCUMENT_TYPE_REMOVED]: {
        id: 'merchant.form.update.changeConsequence.documentTypeRemoved',
        defaultMessage: 'This change will affect the marketplace.',
    },
    [ChangesWithConsequences.DOWN_PAYMENT_STATUS_CHANGED]: {
        id: 'merchant.form.update.changeConsequence.downpaymentChecked',
        defaultMessage: 'This change enables the communication to the Payment Service',
    },
    [ChangesWithConsequences.KYC_CHECKED]: {
        id: 'merchant.form.update.changeConsequence.kycChecked',
        defaultMessage: 'This change will affect the marketplace.',
    },
    [ChangesWithConsequences.KYC_UNCHECKED]: {
        id: 'merchant.form.update.changeConsequence.kycUnchecked',
        defaultMessage: 'This change will affect the marketplace.',
    },
    modalTitle: {
        id: 'merchant.form.update.changeConsequence.modal.title',
        defaultMessage: 'Confirm Changes',
    },
    acceptButton: {
        id: 'merchant.form.update.changeConsequence.modal.button.accept',
        defaultMessage: 'Accept',
    },
    cancelButton: {
        id: 'merchant.form.update.changeConsequence.modal.button.cancel',
        defaultMessage: 'Cancel',
    },
})
